import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, Label } from "reactstrap"
import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useDispatch } from "react-redux"
import { tagProductFromAdminGet } from "store/actions"
import { HOSTNAME, TAGPRODUCTFROMADMINGET } from "config/config"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  // const onChangeInInput = event => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };

  //--
  const dispatch = useDispatch()
  const [status, setstatus] = useState("3")
  const [query, setquery] = useState("")
  const changestatus = val => {
    setstatus(val)
    dispatch(tagProductFromAdminGet({ isdemo: 1, status: val, query: query }, {}))
  }
  const SearchByPname = e => {
    if (e.target.value) {
      setquery(e.target.value)
      dispatch(tagProductFromAdminGet({ isdemo: 1, query: e.target.value, status: status }, {}))
    } else {
      setquery("")
      dispatch(tagProductFromAdminGet({ isdemo: 1, query: e.target.value, status: status }, {}))
    }
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?query=${query}&status=${status}&isdemo=1&page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(tagProductFromAdminGet({ url: url }, {}))
  }

  const onclickForPage = (url, number) => {
    // alert(number)
    setcurrentPage(number)
    dispatch(tagProductFromAdminGet({ url: url }, {}))
  }
  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Label className="visually-hidden" htmlFor="autoSizingInput">
                  Search
                </Label>
                <Input
                  type="text"
                  onChange={e => {
                    SearchByPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name"
                />
              </div>
            </Col>
            <Col xl={2} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <select
                  onClick={e => changestatus(e.target.value)}
                  className="form-select"
                >
                  <option value="" disabled selected hidden>
                    Status
                  </option>
                  <option value="0">Pending</option>
                  <option value="1">Accepted</option>
                  <option value="2">Rejected</option>
                  <option value="3">All</option>
                </select>
              </div>
            </Col>

            {/* </Col> */}
          </Row>
        </Col>
      </Row>
      {/* <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row> */}

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?query=${query}&status=${status}&isdemo=1&page=${1}`,
                  1
                )
              }
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?query=${query}&status=${status}&isdemo=1&page=${currentPage - 1
                  }`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?query=${query}&status=${status}&isdemo=1&page=${currentPage + 1
                  }`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?query=${query}&status=${status}&isdemo=1&page=${Math.ceil(
                    count / customPageSize
                  )}`,
                  Math.ceil(count / customPageSize)
                )
              }
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
