import React from "react"
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay"
import BounceLoader from 'react-spinners/BounceLoader'
const Loadder = ({ active, children }) => {
    let [color, setColor] = useState("#d4dbf9");
  return (
    <LoadingOverlay active={active} spinner={<BounceLoader color={color}/>}>
      {children}
    </LoadingOverlay>
  )
}

export default Loadder
