// src/components/filter.
import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./customertablecontainer"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { delearCustomerGet } from "store/actions"

function extractIdAndPName(item) {
  return {
    id: item.id,
    cname: `${item.firstName} ${item.lastName}`,
    contactNumber: `${item.contactNumber}`,
    city: item.city,
  }
}

function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "cname",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "City",
        accessor: "city",
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(delearCustomerGet({}, {}))
  }, [])

  const dealer_resellingproductlistData = useSelector(
    state => state.DelearProductGet
  )

  if (
    dealer_resellingproductlistData &&
    dealer_resellingproductlistData.dealercustomerdetail
  ) {
    data =
      dealer_resellingproductlistData.dealercustomerdetail.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Customer"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Customers" />

        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            dealer_resellingproductlistData.dealercustomerdetail &&
            dealer_resellingproductlistData.dealercustomerdetail.count
          }
          next={
            dealer_resellingproductlistData.dealercustomerdetail &&
            dealer_resellingproductlistData.dealercustomerdetail.next
          }
          previous={
            dealer_resellingproductlistData.dealercustomerdetail &&
            dealer_resellingproductlistData.dealercustomerdetail.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
