import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./Table/Table"
import Loadder from "components/Loadder/Loadder"
import { HOSTNAME } from "config/config"
import { toast } from "react-toastify"

function DatatableTablesDealerMaster() {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [currentPage, setcurrentPage] = useState(1)
  const [rowData, setRowData] = useState(null)

  const location = useLocation()
  const navigate = useNavigate()

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const { id } = location.state

  const getTagToCustomer = () => {
    setLoading(true)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/get-customer-list-reselling-product/${id}/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getTagToCustomer()
  }, [])

  const columns = useMemo(
    () => [
      // {
      //     Header: ' ',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "firstName",
      },

      {
        Header: "City",
        accessor: "city",
      },
    ],
    []
  )

  //Pagination function
  const getPaginationData = (url, page) => {
    setcurrentPage(page)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Search
  const onSearch = e => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/get-customer-list-reselling-product/${id}/?query=${e.target.value}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRadio = (e, rowdata) => {
    setRowData(rowdata)
  }

  const tagData = () => {
    let data = new FormData()
    data.append("rpid", id)
    data.append("type", "AC")
    data.append("uid", rowData)
    let url = `${HOSTNAME}api/tag-reselling-product/`
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product tagged to customer sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        navigate(-1)
      })
      .catch(error => {
        toast.error(error.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  document.title = "Equipment Product"

  return (
    <Loadder active={loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Equipment Product" />

          <TableContainer
            count={tableData?.count}
            next={tableData?.next}
            previous={tableData?.previous}
            columns={columns}
            data={tableData?.results?.data || []}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
            onSearch={onSearch}
            currentPage={currentPage}
            getData={getTagToCustomer}
            handleRadio={handleRadio}
            buttonText="Tag to customer"
            tagdata={tagData}
            goToNextPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-customer-list-reselling-product/${id}/?page=${
                  currentPage + 1
                }`,
                currentPage + 1
              )
            }
            goToLastPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-customer-list-reselling-product/${id}/?page=${Math.ceil(
                  tableData?.count / 10
                )}`,
                Math.ceil(tableData?.count / 10)
              )
            }
            goToPreviousPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-customer-list-reselling-product/${id}/?page=${
                  currentPage - 1
                }`,
                currentPage - 1
              )
            }
            goToFirstPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-customer-list-reselling-product/${id}/?page=${1}`,
                1
              )
            }
            getInputPaginationData={e =>
              getPaginationData(
                `${HOSTNAME}api/get-customer-list-reselling-product/${id}/?page=${e.target.value}`,
                e.target.value
              )
            }
          />
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesDealerMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesDealerMaster
