import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import {
  InputGroup,
  Flatpickr,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"

// import { Table, Row, Col, Button, Input, CardBody ,Label} from "reactstrap";
import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useSelector, useDispatch } from "react-redux"
import { dealerListGet } from "../../../src/store/actions"
import {
  HOSTNAME,
  DEALERLISTGET,
  ADDNEWDEALER,
  DELETEMULTIDEALER,
} from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const dispatch = useDispatch()
  const [query, setquery] = useState("")
  const SearchByMPname = e => {
    if (e.target.value) {
      setquery(e.target.value)
      dispatch(dealerListGet({ query: e.target.value }, {}))
    } else {
      setquery("")
      dispatch(dealerListGet({}, {}))
    }
  }
  const onclickForPage = (url, number) => {
    // alert(number)
    setcurrentPage(number)
    dispatch(dealerListGet({ url: url }, {}))
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${DEALERLISTGET}?query=${query}&page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(dealerListGet({ url: url }, {}))
  }
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      lastName: "",
      agencyName: "",
      email: "",
      contactNumber: "",
      city: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      agencyName: Yup.string().required("Please Enter Agency Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      // contactNumber: Yup.number().required("Please Enter Dealer Contact Number"),
      contactNumber: Yup.number()
        .typeError("Please enter a valid number")
        .positive("Please enter a valid number")
        .integer("Please enter a valid number")
        .min(1000000000, "Mobile number must be 10 digits")
        .max(9999999999, "Mobile number must be 10 digits")
        .required("Please enter the dealer's contact number"),
      // email: Yup.string().required("Please Enter Email address"),
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Email address is required"),
      city: Yup.string().required("Please Enter Dealer City"),

      // addresellingproductquantity: Yup.string().required("Please Enter Your Equipment Product Quantity"),
      // addmanufacturedproducts: Yup.string().required("Please Enter Your Consumables Products"),
      // addmanufacturedquantity: Yup.string().required("Please Enter Your  Consumables Quantity"),
    }),
    onSubmit: (values, { resetForm }) => {
      let data = new FormData()
      data.append("firstName", values.firstName)
      data.append("lastName", values.lastName)
      data.append("agencyName", values.agencyName)
      data.append("email", values.email)
      data.append("contactNumber", values.contactNumber)
      data.append("city", values.city)
      let url = `${HOSTNAME}${ADDNEWDEALER}`
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          Authorization: `${getUserData.userData.token}`,
        },
        data: data,
      }

      axios
        .request(config)
        .then(response => {
          setModal(!modal)
          dispatch(dealerListGet({}, {}))
          toast.success("Dealer added successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
        .catch(error => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        })

      resetForm()
    },
  })

  const [modal, setModal] = useState(false)
  const [mdldelete, setMdldelete] = useState(false)

  const togglemodal = () => {
    setModal(!modal)
  }

  const deletetoggle = () => {
    setMdldelete(!mdldelete)
  }

  const deletetoggle1 = val => {
    if (val == "yes") {
      if (selectNumber.length === 0) {
        toast.error("Please select first Data", {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        // alert(selectNumber)
        let url = `${HOSTNAME}${DELETEMULTIDEALER}`
        let data = JSON.stringify(selectNumber)
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${getUserData.userData.token}`,
          },
          data: data,
        }

        axios
          .request(config)
          .then(response => {
            toast.success("Data deleted successfully", {
              position: toast.POSITION.TOP_RIGHT,
            })
            dispatch(dealerListGet({}, {}))
            setSelectNumber([])
          })
          .catch(error => { })
        setSelectAll(!selectAll)
      }
    }

    setMdldelete(!mdldelete)
  }

  const [selectNumber, setSelectNumber] = useState([])
  const HandleSelectBox = (e, val) => {
    // const value = e.target.value;
    if (e.target.checked) {
      // Checkbox is checked, so push the value to the selectedData array
      setSelectNumber([...selectNumber, val])
    } else {
      // Checkbox is unchecked, so remove the value from the selectedData array
      setSelectNumber(selectNumber.filter(item => item !== val))
    }
  }
  const [selectAll, setSelectAll] = useState(false)
  const handleSelectAllChange = () => {
    if (selectAll) {
      // If "Select All" is checked, unselect all rows
      setSelectNumber([])
    } else {
      // If "Select All" is unchecked, select all rows
      const allRowIds = page.map(row => row.original.id)
      setSelectNumber(allRowIds)
      // alert(allRowIds)
    }

    // Toggle the selectAll state
    setSelectAll(!selectAll)
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Input
                  type="text"
                  onChange={e => {
                    SearchByMPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name..."
                />
              </div>
            </Col>

            <Col xl={5}></Col>
            <Col xl={2} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <button
                  type="button"
                  onClick={togglemodal}
                  className="btn btn-primary"
                >
                  Add Dealer
                </button>
              </div>
            </Col>
            <Col xl={1} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <button
                  type="button"
                  onClick={deletetoggle}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      {/* modal */}
      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <Form onSubmit={validation.handleSubmit}>
            <ModalHeader toggle={togglemodal}>Add Dealer</ModalHeader>

            <ModalBody>
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">First Name</Label>
                    <Input
                      name="firstName"
                      placeholder="first name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                      invalid={
                        validation.touched.firstName &&
                          validation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstName &&
                      validation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Last Name</Label>
                    <Input
                      name="lastName"
                      placeholder="last name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                      invalid={
                        validation.touched.lastName &&
                          validation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastName &&
                      validation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Agency Name</Label>
                    <Input
                      name="agencyName"
                      placeholder="Enter Agency Name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.agencyName || ""}
                      invalid={
                        validation.touched.agencyName &&
                          validation.errors.agencyName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.agencyName &&
                      validation.errors.agencyName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.agencyName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Contact Number</Label>
                    <Input
                      name="contactNumber"
                      placeholder="123456789"
                      type="number"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.contactNumber || ""}
                      invalid={
                        validation.touched.contactNumber &&
                          validation.errors.contactNumber
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactNumber &&
                      validation.errors.contactNumber ? (
                      <FormFeedback type="invalid">
                        {validation.errors.contactNumber}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Email</Label>
                    <Input
                      name="email"
                      placeholder="abc@gmail.com"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">City</Label>
                    <Input
                      name="city"
                      placeholder="Add Dealer City"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.city || ""}
                      invalid={
                        validation.touched.city && validation.errors.city
                          ? true
                          : false
                      }
                    />
                    {validation.touched.city && validation.errors.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="submit" color="primary">
                Submit
              </Button>

              <Button type="button" onClick={togglemodal} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>

      {/* Delete Model */}

      <Modal size="sm" isOpen={mdldelete} toggle={deletetoggle} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              onClick={deletetoggle}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to permanently erase the job.
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => deletetoggle1("yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={e => deletetoggle1("no")}
              >
                No
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th>
                  {/* <label>#</label> */}
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                  {/* <input name="selectNumber" type="checkbox" className="form-check-input" /> */}
                </th>

                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    <td>
                      {/* <p>{row.original.id}</p> */}
                      <input
                        checked={selectNumber.includes(row.original.id)}
                        name="selectNumber"
                        onChange={e => HandleSelectBox(e, row.original.id)}
                        type="checkbox"
                        className="form-check-input"
                      />
                      {/* {id, original, index, depth, cells, values, originalSubRows, subRows, isExpanded, canExpand, getRowProps, allCells, toggleRowExpanded, getToggleRowExpandedProps} */}
                    </td>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              type="button"
              color="primary"
              onClick={e =>
                onclickForPage(`${HOSTNAME}${DEALERLISTGET}?query=${query}&page=${1}`, 1)
              }
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${DEALERLISTGET}?query=${query}&page=${currentPage - 1}`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${DEALERLISTGET}?query=${query}&page=${currentPage + 1}`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${DEALERLISTGET}?query=${query}&page=${Math.ceil(
                    count / customPageSize
                  )}`,
                  Math.ceil(count / customPageSize)
                )
              }
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
