import {
  MANUFACTUREDPRODUCT_GET,
  MANUFACTUREDPRODUCT_SUCCESS,
  MANUFACTUREDPRODUCT_UPLOAD_POST,
  MANUFACTUREDPRODUCT_UPLOAD_SUCCESS,
  VIEWMANUFACTUREDPRODUCT_GET,
  VIEWMANUFACTUREDPRODUCT_SUCCESS,
  MANUFACTUREDPRODUCT_ADD,
  MANUFACTUREDPRODUCT_ADD_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  manufacturedProductDetails: "",
}

const manufacturedProductGet = (state = initialState, action) => {
  switch (action.type) {
    case MANUFACTUREDPRODUCT_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case MANUFACTUREDPRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        manufacturedProductDetails:action.payload,
      }
      break
// ------- View Consumables Product 

      case VIEWMANUFACTUREDPRODUCT_GET:
        state = {
          ...state,
          loading: true,
        }
        break
      case VIEWMANUFACTUREDPRODUCT_SUCCESS:
        state = {
          ...state,
          loading: false,
          isUserLogout: false,
          manufacturedProductDetails:action.payload,
        }
        break

// --- maintan inventory add Product

      case MANUFACTUREDPRODUCT_ADD:
        state = {
          ...state,
          loading: true,
        }
        break

    case MANUFACTUREDPRODUCT_ADD_SUCCESS:
        state = {
          ...state,
          loading: false,
          isUserLogout: false,
          manufacturedProductDetails:action.payload,
        }
        break

    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break

    //-----------
    case MANUFACTUREDPRODUCT_UPLOAD_POST:
      state = {
        ...state,
        loading: true,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default manufacturedProductGet


