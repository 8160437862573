// src/components/filter.
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./buybackproductlisttablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerBuyBackProductListGet } from "store/actions"
import { useEffect } from "react"
import moment from "moment"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  let created_at = moment(new Date(item.created_at)).format("DD MMM Y")
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }
  return {
    id: item.id,
    status: item.status,
    pname: item.pname,
    pnumber: item.pnumber,
    reason: item.reason,

    rejectionreason: item.rejectionreason,
    customer_number: item.customer_number,
    firstName: item.firstName,
    lastName: item.lastName,
    city: item.city,
    remainingDays: item.remainingDays,
    statustext: statustext,
    created_at: created_at,
    serialNumbers: item.serialNumbers,
  }
}
function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View details
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <UncontrolledDropdown>
              {row.status == 2 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="bx bx-dots-vertical fs-4"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {/* <DropdownItem >
                          <i className="font-size-16 text-success me-1" id="edittooltip"></i>
                          Resend Request
                          <UncontrolledTooltip placement="top" target="edittooltip">
                          Resend Request
                          </UncontrolledTooltip>
                        </DropdownItem> */}

                    <DropdownItem onClick={e => viewrejectiontoggle1(row)}>
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      View Reason
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        View Reason
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : (
                ""
              )}
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(customerBuyBackProductListGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customerbuybackList) {
    data = customerDatas.customerbuybackList.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Product Registration"

  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }

  const [obj, setobj] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  const [viewrejection, setViewrejection] = useState(false)
  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }

  const viewrejectiontoggle1 = row => {
    setViewrejection(!viewrejection)
    setobj(row)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Products Registrations" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customerbuybackList &&
            customerDatas.customerbuybackList.count
          }
          next={
            customerDatas.customerbuybackList &&
            customerDatas.customerbuybackList.next
          }
          previous={
            customerDatas.customerbuybackList &&
            customerDatas.customerbuybackList.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Name</b>
                            <br></br>
                            <p>
                              {obj && obj.firstName} {obj && obj.lastName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer City</b>
                            <br></br>
                            <p>{obj && obj.city}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Number</b>
                            <br></br>
                            <p>{obj && obj.customer_number}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
          <ModalHeader>Customer Product Details</ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{obj && obj.pname}</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>BuyBack Date</b>
                            <br></br>
                            <p>{obj && obj.created_at}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product AMC Remaning Days</b>
                            <br></br>
                            <p>{obj && obj.remainingDays}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>

      {/* ------------------- View Reason For Rejection------------- */}
      <Modal
        isOpen={viewrejection}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={viewrejectiontoggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={viewrejectiontoggle}>
            Reason for rejection
          </ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <Col md="12">
                <FormGroup className="mb-3">
                  <p></p>
                  <Label htmlFor="validationCustom01">View reason</Label>
                  <Input
                    name="viewreason"
                    placeholder="Enter Text Here"
                    type="textarea"
                    rows={4}
                    className="form-control"
                    id="validationCustom01"
                    value={obj && obj.rejectionreason}
                    disabled
                  />
                </FormGroup>
              </Col>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
