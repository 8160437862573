import {
  ADMINAMCCUSTOMERLIST_GET,
  ADMINAMCCUSTOMERLIST_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const adminAMCcustomerGet = (query, history,type) => { 
  // alert("action")
  return {
    type: ADMINAMCCUSTOMERLIST_GET,
    payload: { query, history,type },
  }
}

export const adminAMCcustomerSuccess = data => {
  return {
    type: ADMINAMCCUSTOMERLIST_SUCCESS,
    payload: data,
  }
}



