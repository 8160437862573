// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./manufacturedProductstablecontainer"
import { manufacturedProductGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

function extractIdAndPName(item) {
  return {
    id: item.id,
    mpname: item.mpname,
    pnumber: item.pnumber,
    serialNumbers: item.serialNumbers,
  }
}

function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      // {
      //     Header: ' ',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "mpname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(manufacturedProductGet({}, {}))
  }, [])

  const manufacturedProductData = useSelector(
    state => state.ManufacturedProductGet
  )

  if (
    manufacturedProductData &&
    manufacturedProductData.manufacturedProductDetails
  ) {
    data =
      manufacturedProductData.manufacturedProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Masters"

  return (
    <Loadder active={manufacturedProductData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem={document.title} />

          <TableContainer
            count={manufacturedProductData.manufacturedProductDetails.count}
            next={manufacturedProductData.manufacturedProductDetails.next}
            previous={
              manufacturedProductData.manufacturedProductDetails.previous
            }
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
