import {
  // Direct Customer
  NEWCUSTOMER_GET,
  NEWCUSTOMER_SUCCESS,
  NEWCUSTOMER_UPLOAD_POST,
  NEWCUSTOMER_UPLOAD_SUCCESS,
  // Dealer`s Customer
  EXISTINGCUSTOMER_GET,
  EXISTINGCUSTOMER_SUCCESS,
  EXISTINGCUSTOMER_UPLOAD_POST,
  EXISTINGCUSTOMER_UPLOAD_SUCCESS,
  // New Order From Customer
  NEWORDERFROMCUSTOMER_GET,
  NEWORDERFROMCUSTOMER_SUCCESS,
//  New Order Form Dealer To Customer
  NEWORDERFROMDEALERTOCUSTOMER_GET,
  NEWORDERFROMDEALERTOCUSTOMER_SUCCESS,

  // ---CUSTOMER----
  EXISTINGCUSTOMERLIST_GET,
  EXISTINGCUSTOMERLIST_SUCCESS,
  PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET,
  PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_SUCCESS,

  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

  //  Direct Customer 
export const newCustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: NEWCUSTOMER_GET,
    payload: { query, history },
  }
}

export const newCustomerSuccess = data => {
  return {
    type: NEWCUSTOMER_SUCCESS,
    payload: data,
  }
}

export const newCustomerUploadGet = (fileData, history) => {
  // alert("action")
  return {
    type: NEWCUSTOMER_UPLOAD_POST,
    payload: { fileData, history },
  }
}

//  Dealer`s Customer -----------------------------------

export const existingCustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: EXISTINGCUSTOMER_GET,
    payload: { query, history },
  }
}

export const existingCustomerSuccess = data => {
  return {
    type: EXISTINGCUSTOMER_SUCCESS,
    payload: data,
  }
}

export const existingCustomerUploadGet = (fileData, history) => {
  // alert("action")
  return {
    type: EXISTINGCUSTOMER_UPLOAD_POST,
    payload: { fileData, history },
  }
}

//  ------------------New Order From Customer -----------------

export const newOrderFromCustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: NEWORDERFROMCUSTOMER_GET,
    payload: { query, history },
  }
}

export const newOrderFromCustomerSuccess = data => {
  return {
    type: NEWORDERFROMCUSTOMER_SUCCESS,
    payload: data,
  }
}

//  ------------------New Order From Dealer To Customer -----------------

export const newOrderFromDealerToCustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: NEWORDERFROMDEALERTOCUSTOMER_GET,
    payload: { query, history },
  }
}

export const newOrderFromDealerToCustomerSuccess = data => {
  return {
    type: NEWORDERFROMDEALERTOCUSTOMER_SUCCESS,
    payload: data,
  }
}


// ------------- Customer==> existing customer----------

export const existingCustomerListGet = (query, history) => { 
  // alert("action")
  return {
    type: EXISTINGCUSTOMERLIST_GET,
    payload: { query, history },
  }
}

export const existingCustomerListSuccess = data => {
  return {
    type: EXISTINGCUSTOMERLIST_SUCCESS,
    payload: data,
  }
}


// --------- customer==> Product verification of existing customer-----

export const productverificationofexistingcustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET,
    payload: { query, history },
  }
}

export const productverificationofexistingcustomerSuccess = data => {
  return {
    type: PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_SUCCESS,
    payload: data,
  }
}





