// src/components/filter.
import React, {useState, useMemo,useEffect } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './demoproductmastertablecontainer';
import Loadder from "components/Loadder/Loadder";
import { demoProductMasterGet } from "../../../src/store/actions"


import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Form,
    Input,
    FormFeedback,
    Label,
  } from "reactstrap"

  import { useSelector, useDispatch } from "react-redux"

  function extractIdAndPName(item) {
    return {
        id: item.id,
        pname: item.pname,
        pnumber:item.pnumber,
    };
}

function DatatableTablesDemoProductMaster() {
    const columns = useMemo(
        () => [
            // {
            //     Header: ' ',
            //     filterable: false,
            //     Cell: () => {
            //         return <input type="checkbox" className="form-check-input" />;
            //       }
            // },
            {
                Header: 'Sr.No',
                accessor: 'id',
            },
            {
                Header: 'Product Name',
                accessor: 'pname'
            },
            {
                Header: 'Product Number',
                accessor: 'pnumber'
            }
        ],
        []
    );

    let data = [];
    const dispatch = useDispatch()

    useEffect(()=>{
        const isUserLoggedIn = localStorage.getItem('isLoggedIn');
        if (!isUserLoggedIn) {
            localStorage.setItem('isLoggedIn', 'true');
            window.location.reload();
        }
        dispatch(demoProductMasterGet({}, {}))
    },[])

    const demoProductMasterData =useSelector(state => state.DemoProductGet)
    console.log("==========",demoProductMasterData)
    if(demoProductMasterData && demoProductMasterData.demoProductDetails){
        data = demoProductMasterData.demoProductDetails.results.data.map(extractIdAndPName);   
    }
    console.log("data--->",data)


    //meta title
    document.title = "Demo Product Master";

    return (
        <Loadder active={demoProductMasterData.loading}>
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Demo Product Master" />
                           
                <TableContainer
                    count={demoProductMasterData.demoProductDetails.count}
                    next={demoProductMasterData.demoProductDetails.next}
                    previous={demoProductMasterData.demoProductDetails.previous}
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>
        </div>
        </Loadder>
    );
}
DatatableTablesDemoProductMaster.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTablesDemoProductMaster;