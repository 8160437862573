// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import contact from "../../assets/images/contact.png"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import TableContainer from './buybacktablecontainer';
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

const CandidateOverview = () => {
  document.title = "Candidate Overview "
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Tables" breadcrumbItem="Contact Us" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody className="border-bottom">
                <center>
                  <h3 className="mb-3">Stuck? Contact Us!</h3>

                  <div className="">
                    <Row>
                      <Col md={4}></Col>

                      <Col xl={1} lg={4} sm={12}>
                        <a href="whatsapp://send?phone=9152020415">
                          <i
                            style={{ fontSize: "70px", color: "#556EE6" }}
                            className="bx bxl-whatsapp"
                          ></i>
                        </a>
                      </Col>

                      <Col xl={1} lg={4} sm={12}>
                        <a href="tel:18001237399">
                          <i
                            style={{ fontSize: "70px", color: "#556EE6" }}
                            className="bx bx-phone"
                          ></i>
                        </a>
                      </Col>

                      <Col xl={1} lg={4} sm={12}>
                        <a
                          href="mailto:sneha@sonastarhealthcarellp.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            style={{ fontSize: "70px", color: "#556EE6" }}
                            className="bx bx-envelope"
                          ></i>
                        </a>
                      </Col>
                      <Col md={5}></Col>
                    </Row>
                  </div>
                </center>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row>
                    <Sidebar />
                    <AboutUs />
                </Row> */}
      </div>
    </React.Fragment>
  )
}

export default CandidateOverview
