import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import {
  InputGroup,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  Label,
} from "reactstrap"
import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import "react-datepicker/dist/react-datepicker.css"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { customerSpendListGet } from "store/actions"
import { useEffect } from "react"
import {
  ADMINEARNINGDOWNLOAD,
  CUSTOMERSPENDPRODUCT,
  HOSTNAME,
} from "config/config"

const getUserData = JSON.parse(localStorage.getItem("authUser"))
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  result,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const scrollRef = useRef()

  const scrollToLeft = () => {
    const element = scrollRef.current
    element.scrollBy({
      left: -window.innerWidth + 100, // Adjust the value to scroll by a different distance
      behavior: "smooth",
    })
  }

  const scrollToRight = () => {
    const element = scrollRef.current
    // console.log("🚀 ~ file: viewtaggedproducttablecontainer.js:200 ~ scrollToRight ~ element:", element,element.scrollLeft,element.offsetWidth,element.scrollRight)
    element.scrollBy({
      left: window.innerWidth - 100, // Adjust the value to scroll by a different distance
      behavior: "smooth",
    })
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  // const onChangeInInput = event => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };

  //------
  const dispatch = useDispatch()
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const [searchData, setsearchData] = useState("")
  // const handleDateChange = (dates) => {
  //   console.log(dates)
  //   setSelectedDateRange(dates);
  //   const startDate = dates[0].toLocaleDateString();
  //   const endDate = dates[1].toLocaleDateString();
  //   dispatch(customerSpendListGet({"startDate":startDate,"endDate":endDate}, {}))
  // };

  const handleDateChange = dates => {
    setSelectedDateRange(dates)

    if (dates[0] && dates[1]) {
      const startDate = dates[0].toLocaleDateString()
      const endDate = dates[1].toLocaleDateString()
      dispatch(
        customerSpendListGet({ startDate: startDate, endDate: endDate }, {})
      )
    } else {
      console.error("Invalid date format")
    }
  }
  const [startDate_, setstartDate_] = useState("")
  const [endDate_, setendDate_] = useState("")

  const clearData = e => {
    setSelectedDateRange(null)
    setsearchData("")
    setstartDate_("")
    setendDate_("")
    dispatch(customerSpendListGet({}, {}))
  }
  const SearchByPname = e => {
    if (e.target.value) {
      setsearchData(e.target.value)
      if (selectedDateRange == null) {
        dispatch(customerSpendListGet({ query: e.target.value, startDate: startDate_, endDate: endDate_ }, {}))
      } else {
        let startDate = selectedDateRange[0].toLocaleDateString()
        let endDate = selectedDateRange[1].toLocaleDateString()
        setstartDate_(startDate)
        setendDate_(endDate)
        dispatch(
          customerSpendListGet(
            { query: e.target.value, startDate: startDate, endDate: endDate },
            {}
          )
        )
      }
    } else {
      setsearchData("")
      dispatch(customerSpendListGet({ query: searchData, startDate: startDate_, endDate: endDate_ }, {}))
    }
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${CUSTOMERSPENDPRODUCT}?query=${searchData}&startDate=${startDate_}&endDate=${endDate_}&page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(customerSpendListGet({ url: url }, {}))
  }

  const onclickForPage = (url, number) => {
    setcurrentPage(number)
    dispatch(customerSpendListGet({ url: url }, {}))
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4}>
              <div className="col-sm-auto  mb-1">
                <Input
                  value={searchData}
                  type="text"
                  onChange={e => {
                    SearchByPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name..."
                />
              </div>
            </Col>

            <Col xl={3} className="mb-3 mb-1">
              <Flatpickr
                className="form-control d-block"
                placeholder="Date Range"
                onChange={handleDateChange}
                value={selectedDateRange}
                options={{
                  mode: "range",
                  // dateFormat: "Y-m-d",
                  dateFormat: "d-m-Y",
                }}
              />
            </Col>

            <Col xl={1}>
              <div className="col-sm-auto">
                <button
                  onClick={e => clearData(e)}
                  type="button"
                  className="btn btn-primary"
                >
                  Clear
                </button>
              </div>
            </Col>

            <Col xl={1}>
              <div className="col-sm-auto mt-2">
                <a
                  className="downloadbtn"
                  href={`${HOSTNAME}${ADMINEARNINGDOWNLOAD}?userType=${getUserData && getUserData.userData.payload.userType
                    }&dType=RP&iscustomer=1&token=${getUserData.userData.token}`}
                >
                  Download
                </a>
              </div>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      {/* <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row> */}

      <div
        className="table-responsive react-table producttable"
        ref={scrollRef}
      >
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      <div className="mobile-view">
        <div className="d-flex justify-content-between my-2 ">
          <a href="#" className="previous round" onClick={scrollToLeft}>
            &#8249;
          </a>
          <a href="#" className="next round " onClick={scrollToRight}>
            &#8250;
          </a>
        </div>
      </div>
      <Row
        className="justify-content-md-end justify-content-center align-items-center mobile-pagination"
        style={{ marginBottom: "20px" }}
      >
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSPENDPRODUCT}?query=${searchData}&startDate=${startDate_}&endDate=${endDate_}&page=${1}`,
                  1
                )
              }
              disabled={isPast}
              className="paginate-left-button"
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSPENDPRODUCT}?query=${searchData}&startDate=${startDate_}&endDate=${endDate_}&page=${currentPage - 1}`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto middleView">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1 nextView">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSPENDPRODUCT}?query=${searchData}&startDate=${startDate_}&endDate=${endDate_}&page=${currentPage + 1}`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSPENDPRODUCT}?query=${searchData}&startDate=${startDate_}&endDate=${endDate_}&page=${Math.ceil(
                    count / customPageSize
                  )}`,
                  Math.ceil(count / customPageSize)
                )
              }
              className="paginate-right-button"
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
