import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input } from "reactstrap"

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
  onTableSearchChange,
  showStatusSelect,
  isRightSide,
  onSearch,
  goToNextPage,
  goToLastPage,
  goToPreviousPage,
  goToFirstPage,
  currentPage,
  getInputPaginationData,
  showGo,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: customPageSize,
        },
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination
    )

  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)

  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row className="d-flex align-items-center">
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Input
                  type="text"
                  onChange={onSearch}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name"
                  onKeyDown={onSearch}
                />
              </div>
            </Col>
            <Col xl={2} className="mt-1 mb-1">
              {showStatusSelect && showStatusSelect}
            </Col>
            <Col xl={1}>{showGo && showGo}</Col>
            <Col xl={3}></Col>
            <Col xl={2}>{isRightSide && isRightSide}</Col>
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={goToFirstPage} disabled={isPast}>
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={goToPreviousPage}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            defaultValue={currentPage}
            value={currentPage}
            onChange={getInputPaginationData}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={goToNextPage}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button color="primary" onClick={goToLastPage} disabled={isNext}>
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
