import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  InputGroup,
  Flatpickr,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Modal, ModalBody } from "reactstrap"
import { Table, Row, Col, Button, Input, CardBody, Label } from "reactstrap"
import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { viewResellingProductGet } from "../../../src/store/actions"
import { useSelector, useDispatch } from "react-redux"
import {
  HOSTNAME,
  VIEWRESELLINGPRODUCTGET,
  RESELLINGPRODUCTGET,
} from "config/config"
import { toast } from "react-toastify"
import axios from "axios"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const dispatch = useDispatch()

  const [query, setquery] = useState("")
  const SearchByPname = e => {
    if (e.target.value) {
      setquery(e.target.value)
      dispatch(viewResellingProductGet({ query: e.target.value }, {}))
    } else {
      dispatch(viewResellingProductGet({}, {}))
    }
  }
  const onclickForPage = (url, number) => {
    // alert(url)
    setcurrentPage(number)
    dispatch(viewResellingProductGet({ url: url }, {}))
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })
  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}?query=${query}&page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(viewResellingProductGet({ url: url }, {}))
  }

  const [mdldelete, setMdldelete] = useState(false)
  const [selectNumber, setSelectNumber] = useState([])
  const deletetoggle = () => {
    setMdldelete(!mdldelete)
  }
  const HandleSelectBox = (e, val) => {
    // const value = e.target.value;
    if (e.target.checked) {
      // Checkbox is checked, so push the value to the selectedData array
      setSelectNumber([...selectNumber, val])
    } else {
      // Checkbox is unchecked, so remove the value from the selectedData array
      setSelectNumber(selectNumber.filter(item => item !== val))
    }
  }
  const deletetoggle1 = val => {
    if (val == "yes") {
      if (selectNumber.length === 0) {
        // alert("Please select first Data")
        toast.error("Please select first Data", {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        // alert(selectNumber)
        let data = JSON.stringify(selectNumber)
        let url = `${HOSTNAME}${RESELLINGPRODUCTGET}`
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${getUserData.userData.token}`,
          },
          data: data,
        }

        axios
          .request(config)
          .then(response => {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            dispatch(viewResellingProductGet({}, {}))
            dispatch(viewResellingProductGet({}, {}))
            setSelectNumber([])
          })
          .catch(error => { })
        setSelectAll(!selectAll)
      }

      dispatch(viewResellingProductGet({}, {}))
      setSelectNumber([])
      dispatch(viewResellingProductGet({}, {}))
    }

    setMdldelete(!mdldelete)
  }

  const [selectAll, setSelectAll] = useState(false)
  const handleSelectAllChange = () => {
    if (selectAll) {
      // If "Select All" is checked, unselect all rows
      setSelectNumber([])
    } else {
      // If "Select All" is unchecked, select all rows
      const allRowIds = page.map(row => row.original.id)
      setSelectNumber(allRowIds)
      // alert(allRowIds)
    }

    // Toggle the selectAll state
    setSelectAll(!selectAll)
  }
  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row className="d-flex align-items-center">
            {/* <Col lg={6}> */}
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Input
                  type="text"
                  onChange={e => {
                    SearchByPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by product name..."
                />
              </div>
            </Col>

            <Col xl={5}></Col>
            <Col xl={2}>
              Total Products: <b>{count}</b>
            </Col>

            <Col xl={1} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <button
                  type="button"
                  onClick={deletetoggle}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </Col>

            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th>
                  {/* <label>#</label> */}
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                  {/* <input name="selectNumber" type="checkbox" className="form-check-input" /> */}
                </th>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    <td>
                      {/* <p>{row.original.id}</p> */}
                      <input
                        checked={selectNumber.includes(row.original.id)}
                        name="selectNumber"
                        onChange={e => HandleSelectBox(e, row.original.id)}
                        type="checkbox"
                        className="form-check-input"
                      />
                      {/* {id, original, index, depth, cells, values, originalSubRows, subRows, isExpanded, canExpand, getRowProps, allCells, toggleRowExpanded, getToggleRowExpandedProps} */}
                    </td>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              type="button"
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}?query=${query}&page=${1}`,
                  1
                )
              }
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}?query=${query}&page=${currentPage - 1
                  }`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}?query=${query}&page=${currentPage + 1
                  }`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}?query=${query}&page=${Math.ceil(
                    count / customPageSize
                  )}`,
                  Math.ceil(count / customPageSize)
                )
              }
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>

        <Modal
          size="sm"
          isOpen={mdldelete}
          toggle={deletetoggle}
          centered={true}
        >
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button
                type="button"
                onClick={deletetoggle}
                className="btn-close position-absolute end-0 top-0 m-3"
              ></button>
              <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">
                {" "}
                Are you sure you want to permanently erase the job.
              </p>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={e => deletetoggle1("yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={e => deletetoggle1("no")}
                >
                  No
                </button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
