import {
  RESELLINGPRODUCT_GET,
  RESELLINGPRODUCT_SUCCESS,
  RESELLINGPRODUCT_UPLOAD_POST,
  RESELLINGPRODUCT_ADD,
  RESELLINGPRODUCT_ADD_SUCCESS,
  RESELLINGPRODUCT_UPLOAD_SUCCESS,
  TAGLISTOFCUSTOMER_GET,
  TAGLISTOFCUSTOMER_SUCCESS,
  TAGLISTOFDEALER_GET,
  TAGLISTOFDEALER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESELLINGTAGTODEALER_GET,
  RESELLINGTAGTODEALER_SUCCESS,
  // TAGTODEALER_GET,
  // TAGTODEALER_SUCCESS,
  TAGCUSTOMER_GET,
  TAGCUSTOMER_SUCCESS,
  VIEWRESELLINGPRODUCT_GET,
  VIEWRESELLINGPRODUCT_SUCCESS,

  // ************** DEALER ****************
  TAGPRODUCTFROMADMIN_GET,
  TAGPRODUCTFROMADMIN_SUCCESS,
  DEALER_RESELLINGPRODUCTLIST_GET,
  DEALER_RESELLINGPRODUCTLIST_SUCCESS,
  DELEAR_TAGTOCUSTOMER_GET,
  DELEAR_TAGTOCUSTOMER_SUCCESS,
  PARENT_RESELLINGPRODUCT_GET,
  PARENT_RESELLINGPRODUCT_SUCCESS,
  PARENT_RESELLINGPRODUCT_UPLOAD_POST,
  RESELLINGCHILDPRODUCT_ADD,
  RESELLINGCHILDPRODUCT_ADD_SUCCESS,
  NEWTAGLISTOFDEALER_SUCCESS,
  NEWTAGLISTOFDEALER_GET,


  // ******************************
} from "./actionTypes"

export const parentresellingProductGet = (query, history) => {
  // alert("action")
  return {
    type: PARENT_RESELLINGPRODUCT_GET,
    payload: { query, history },
  }
}

export const parentresellingProductSuccess = data => {
  return {
    type: PARENT_RESELLINGPRODUCT_SUCCESS,
    payload: data,
  }
}

export const parentresellingProductUploadGet = (fileData, history) => {
  // alert(fileData)
  return {
    type: PARENT_RESELLINGPRODUCT_UPLOAD_POST,
    payload: { fileData, history },
  }
}

// ------------------------
export const resellingProductAdd = (data, history) => {
  // alert("action")
  return {
    type: RESELLINGPRODUCT_ADD,
    payload: { data, history },
  }
}

export const resellingProductAddSuccess = data => {
  return {
    type: RESELLINGPRODUCT_ADD_SUCCESS,
    payload: data,
  }
}

export const resellingChildProductAdd = (data, history) => {
  // alert("action")
  return {
    type: RESELLINGCHILDPRODUCT_ADD,
    payload: { data, history },
  }
}

export const resellingChildProductAddSuccess = data => {
  return {
    type: RESELLINGCHILDPRODUCT_ADD_SUCCESS,
    payload: data,
  }
}

export const resellingProductGet = (query, history) => {
  // alert("action")
  return {
    type: RESELLINGPRODUCT_GET,
    payload: { query, history },
  }
}

export const resellingProductSuccess = data => {
  return {
    type: RESELLINGPRODUCT_SUCCESS,
    payload: data,
  }
}

export const resellingProductUploadGet = (fileData, history) => {
  // alert(fileData)
  return {
    type: RESELLINGPRODUCT_UPLOAD_POST,
    payload: { fileData, history },
  }
}

// TAG LIST OF CUSTOMER-------------

export const tagListOfCustomerGet = (query, history) => {
  // alert("action")
  return {
    type: TAGLISTOFCUSTOMER_GET,
    payload: { query, history },
  }
}

export const tagListOfCustomerSuccess = data => {
  return {
    type: TAGLISTOFCUSTOMER_SUCCESS,
    payload: data,
  }
}

// TAG LIST OF DEALER---------------------

export const tagListOfDealerGet = (query, history) => {
  // alert("action")
  return {
    type: TAGLISTOFDEALER_GET,
    payload: { query, history },
  }
}

export const tagListOfDealerSuccess = data => {
  return {
    type: TAGLISTOFDEALER_SUCCESS,
    payload: data,
  }
}


// New TAG LIST OF DEALER---------------------

export const newtagListOfDealerGet = (query, history) => {
  // alert("action")
  return {
    type: NEWTAGLISTOFDEALER_GET,
    payload: { query, history },
  }
}

export const newtagListOfDealerSuccess = data => {
  return {
    type: NEWTAGLISTOFDEALER_SUCCESS,
    payload: data,
  }
}

// TAG to  DEALER---------------------

export const tagToDealerGet = (query, history,id) => {
  // alert("action")
  return {
    type: RESELLINGTAGTODEALER_GET,
    payload: { query, history,id },
  }
}

export const tagToDealerSuccess = data => {
  return {
    type: RESELLINGTAGTODEALER_SUCCESS,
    payload: data,
  }
}

// TAG to  CUSTOMER---------------------

export const tagToCustomerGet = (query, history,id) => {
  // alert("action")
  return {
    type: TAGCUSTOMER_GET,
    payload: { query, history,id },
  }
}

export const tagToCustomerSuccess = data => {
  return {
    type: TAGCUSTOMER_SUCCESS,
    payload: data,
  }
}

// View Equipment Product---------------------

export const viewResellingProductGet = (query, history) => {
  // alert("action")
  return {
    type: VIEWRESELLINGPRODUCT_GET,
    payload: { query, history },
  }
}

export const viewResellingProductSuccess = data => {
  return {
    type: VIEWRESELLINGPRODUCT_SUCCESS,
    payload: data,
  }
}


// ***************************************************************
//                          DELEAR
// ***************************************************************

export const tagProductFromAdminGet = (query, history) => {
  // alert("action")
  return {
    type: TAGPRODUCTFROMADMIN_GET,
    payload: { query, history },
  }
}

export const tagProductFromAdminSuccess = data => {
  return {
    type: TAGPRODUCTFROMADMIN_SUCCESS,
    payload: data,
  }
}

// --------- reselling product list--------

export const dealer_resellingproductlistGet = (query, history) => {
  // alert("action")
  return {
    type: DEALER_RESELLINGPRODUCTLIST_GET,
    payload: { query, history },
  }
}

export const dealer_resellingproductlistSuccess = data => {
  return {
    type: DEALER_RESELLINGPRODUCTLIST_SUCCESS,
    payload: data,
  }
}

// --------- TAG TO CUSTOMER---------

export const dealer_tagtocustomerGet = (query, history,id) => {
  // alert("action")
  return {
    type: DELEAR_TAGTOCUSTOMER_GET,
    payload: { query, history,id },
  }
}

export const dealer_tagtocustomerSuccess = data => {
  return {
    type: DELEAR_TAGTOCUSTOMER_SUCCESS,
    payload: data,
  }
}