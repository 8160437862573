// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./taggeddealerstablecontainer"
import { taggeddealerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import { HOSTNAME, REMOVETAGPRODUCT } from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
function extractIdAndPName(item) {
  return {
    id: item.id,
    agencyName: item.agencyName,
    firstName: item.firstName,
    contactNumber: item.contactNumber,
    city: item.city,
    productName: item.productName,
    serialNumbers: item.serialNumbers,
    status: item.status,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Agency Name",
        accessor: "agencyName",
      },
      {
        Header: "Dealer Name",
        accessor: "firstName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Tagged Product Name",
        accessor: "productName",
      },
      {
        Header: "Tagged Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row
          return row.original.status == 0 ? (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={e => removetoggle1(row.original.id)}>
                  <i
                    className="font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  Remove Tag Product
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Remove Tag Product
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            ""
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(taggeddealerGet({}, {}))
  }, [])

  const taggeddealerData = useSelector(state => state.DelearProductGet)

  if (taggeddealerData && taggeddealerData.delearDetails) {
    data = taggeddealerData.delearDetails.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Dealers"

  const [mdlremove, setRemove] = useState(false)

  const removetoggle = () => {
    setRemove(!mdlremove)
  }
  const [deleteid, setdeleteid] = useState(0)
  const removetoggle1 = id => {
    // alert(id)
    setdeleteid(id)
    setRemove(!mdlremove)
  }

  const deletedata = check => {
    // alert(check)
    if (check == "yes") {
      // alert(deleteid)
      let data = new FormData()
      data.append("tagid", deleteid)
      let url = `${HOSTNAME}${REMOVETAGPRODUCT}`
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          Authorization: `${getUserData.userData.token}`,
        },
        data: data,
      }

      axios
        .request(config)
        .then(response => {
          dispatch(taggeddealerGet({}, {}))
          toast.success("Tagged product rmoved successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
        .catch(error => {
          toast.error("Something went Wrong", {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
    }
    setRemove(!mdlremove)
    setdeleteid(0)
  }

  return (
    <Loadder active={taggeddealerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Dealers" />
          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={taggeddealerData.delearDetails.count}
            next={taggeddealerData.delearDetails.next}
            previous={taggeddealerData.delearDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
      </div>

      {/* Remove Model */}

      <Modal size="sm" isOpen={mdlremove} toggle={removetoggle} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              onClick={removetoggle}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <b>
              <p className="text-muted font-size-16 mb-4">Are you sure ?</p>
            </b>
            <p className="text-muted font-size-16 mb-4">
              you want to delte the job.
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => deletedata("yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={e => deletedata("no")}
              >
                No
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </Loadder>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
