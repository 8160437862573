// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./servicerequestfromcustomertablecontainer"
import { serviceRequestFromcustomerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"

import { Link } from "react-router-dom"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { HOSTNAME, SERVICEREQUSETFROMCUSTOMERGET } from "config/config"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
function extractIdAndPName(item) {
  return {
    id: item.id,
    firstName: item.firstName,
    contactNumber: item.contactNumber,
    city: item.city,
    productName: item.productName,
    serviceStatus: item.serviceStatus,
    types: item.types,
    lastName: item.lastName,
    pid: item.pid,
    pnumber: item.pnumber,
    message: item.message,
    serviceStatustext: item.serviceStatustext,
    pnumber: item.pnumber,
    serialNumbers: item.serialNumbers,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "firstName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Customer City",
        accessor: "city",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "serviceStatustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row.original)}>
                  View Message
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <UncontrolledDropdown>
              {/* <Row>
                        <Col xl={12}>
                            <a href="">View details</a>
                        </Col>
                        </Row> */}
              {row.original.serviceStatus == 0 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={e => updateSTatus(1, row.original.id)}
                    >
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      On Going
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        On Going
                      </UncontrolledTooltip>
                    </DropdownItem>

                    <DropdownItem
                      onClick={e => updateSTatus(2, row.original.id)}
                    >
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      Completed
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Completed
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : row.original.serviceStatus == 1 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={e => updateSTatus(2, row.original.id)}
                    >
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      Completed
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Completed
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : row.original.serviceStatus == 2 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={e => updateSTatus(3, row.original.id)}
                    >
                      <i
                        className=" font-size-16 text-danger me-1"
                        id="deletetooltip"
                      ></i>
                      Marke as a Closed
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Marke as a Closed
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : (
                ""
              )}
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  const updateSTatus = (status, id) => {
    // alert(status)
    // alert(id)
    let url = `${HOSTNAME}${SERVICEREQUSETFROMCUSTOMERGET}&status=${status}&id=${id}`
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Status updated.", {
          position: toast.POSITION.TOP_RIGHT,
        })
        dispatch(serviceRequestFromcustomerGet({}, {}))
      })
      .catch(error => { })
  }
  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }

    dispatch(serviceRequestFromcustomerGet({}, {}))
  }, [])

  const servicerequestfromcustomerData = useSelector(state => state.serviceGet)

  if (
    servicerequestfromcustomerData &&
    servicerequestfromcustomerData.serviceDetails
  ) {
    data =
      servicerequestfromcustomerData.serviceDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Service"

  const [modal, setModal] = useState(false)
  const [objData, setobjData] = useState({})
  const togglemodal = () => {
    setModal(!modal)
  }

  const togglemodal1 = row => {
    // alert(row)
    let fdata = {
      customername: `${row.firstName} ${row.lastName}`,
      contactNumber: row.contactNumber,
      city: row.city,
      productName: row.productName,
      serviceStatus: row.serviceStatus,
      types: row.types,
      pid: row.pid,
      pnumber: row.pnumber,
      message: row.message,
    }

    setModal(!modal)
    // const foundData = data && data.find(item => item.id === row);
    setobjData(fdata)
  }

  return (
    <Loadder>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Services" />
          <TableContainer
            count={servicerequestfromcustomerData.serviceDetails.count}
            next={servicerequestfromcustomerData.serviceDetails.next}
            previous={servicerequestfromcustomerData.serviceDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        {/* modal */}
        <Modal
          isOpen={modal}
          role="dialog"
          size="lg"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>Ticket Message</ModalHeader>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Product Number</Label>
                    <Input
                      name="pid"
                      disabled
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      value={objData && objData.pnumber}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Customer Name</Label>
                    <Input
                      name="customername"
                      disabled
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      value={objData && objData.customername}
                    />
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Product Name</Label>
                    <Input
                      name="productname"
                      disabled
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      value={objData && objData.productName}
                    />
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Message</Label>
                    <Input
                      name="message"
                      disabled
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      value={objData && objData.message}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
