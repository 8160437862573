// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import Loadder from "components/Loadder/Loadder"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ResellingTableContainer from "./resellingproductstablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { adminEarningDataGet } from "../../store/actions"
import { checkTokenExpiration } from "../../../src/config/getTokenExpirationTime"
// const reports = [
//   { title: "Total Number of Equipment Product sold", iconClass: "bx-purchase-tag-alt", description: "150" },

// ];
function extractIdAndPName(item) {
  return {
    productname: item.product.pname,
    customername: `${item.customer.firstName} ${item.customer.lastName}`,
    noofproductsold: item.total_sold,

    product: item.product,
    customer: item.customer,
    soldto: item.soldto,
    pnumber: item.product.pnumber,
    // RPD_total_sold:item.RPD_total_sold
    serialNumbers: item.product.serialNumbers,
  }
}
function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr. No",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            row.index+1
          )
        },
      },
      {
        Header: "Product Name",
        accessor: "productname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      // {
      //     Header: 'Product Sold Date',
      //     accessor: 'productsolddate',
      // },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      {
        Header: "No of Product Sold",
        accessor: "noofproductsold",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row.original)}>
                  View Details
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  const dispatch = useDispatch()
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    checkTokenExpiration()
    dispatch(adminEarningDataGet({ userType: "AC" }, {}))
  }, [dispatch])

  let data = []
  const adminearningdatas = useSelector(
    state => state.adminEarningGet.adminEarningDetails
  )
  console.log("adminearningdatas=>---------", adminearningdatas)
  if (adminearningdatas && adminearningdatas.results) {
    console.log(adminearningdatas.results.data, "=>")
    data = adminearningdatas.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Earnings"
  const [objData, setobjData] = useState({})
  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }

  const togglemodal1 = row => {
    // alert(row)
    let fdata = {
      customername: row.customername,
      productname: row.productname,
      noofproductsold: row.noofproductsold,

      product: row.product,
      customer: row.customer,
      soldto: row.soldto,
      manufactureData: row.product && row.product.manufactureData,
    }

    setModal(!modal)
    // const foundData = data && data.find(item => item.id === row);
    setobjData(fdata)
    console.log("foundData=>", fdata)
  }

  return (
    <Loadder active={adminearningdatas.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Earnings" />

          {/* <Table columns={columns} data={data} /> */}
          <ResellingTableContainer
            count={adminearningdatas.count}
            next={adminearningdatas.next}
            previous={adminearningdatas.previous}
            columns={columns}
            data={data}
            result={adminearningdatas.results}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={modal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Name</b>
                              <br></br>
                              <p>{objData && objData.customername}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer City</b>
                              <br></br>
                              <p>{objData.customer && objData.customer.city}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Number</b>
                              <br></br>
                              <p>
                                {objData.customer &&
                                  objData.customer.contactNumber}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
            <ModalHeader>Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Name</b>
                              <br></br>
                              <p>{objData.product && objData.product.pname}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Number</b>
                              <br></br>
                              <p>
                                {objData.product && objData.product.pnumber}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Number of Product Sold</b>
                              <br></br>
                              <p>
                                {objData.product && objData.noofproductsold}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>

            {/* <ModalHeader>Consumables Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        {objData.product &&
                          objData.product.manufactureData.map(item => (
                            <div key={item.id} className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <b>Product Name</b>
                                  <p>
                                    <span style={{ fontWeight: "bold" }}>
                                      {item.mpname}
                                    </span>
                                    <span style={{ color: "#777" }}> X </span>
                                    <span style={{ color: "#555" }}>
                                      {" "}
                                      {item.mpquantity}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody> */}
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
