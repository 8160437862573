import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"

// Login Redux States
import { 
  LOGOUT_USER,
  MANUFACTUREDPRODUCT_GET,
  MANUFACTUREDPRODUCT_SUCCESS,
  MANUFACTUREDPRODUCT_UPLOAD_POST,
  VIEWMANUFACTUREDPRODUCT_GET,
  VIEWMANUFACTUREDPRODUCT_SUCCESS,
  MANUFACTUREDPRODUCT_ADD,
  MANUFACTUREDPRODUCT_ADD_SUCCESS,

} from "./actionTypes"

import { 
  manufacturedProductGet,
  manufacturedProductSuccess,
  viewManufacturedProductGet,
  viewManufacturedProductSuccess, 
  manufacturedProductAdd,
  manufacturedProductAddSuccess,

} from "./actions"

import { 
  HOSTNAME,
  MANUFACTUREDPRODUCTGET,
  MANUFACTUREDPRODUCTUPLOAD,
  VIEWMANUFACTUREDPRODUCTGET, 
  MANUFACTUREDPRODUCTADD,

} from "config/config"

import { apiError } from "store/actions"
import { useSelector, useDispatch } from "react-redux"
// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser")) 

function* manufacturedProducts({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${MANUFACTUREDPRODUCTGET}`
            if(query.query){
              url =`${HOSTNAME}${MANUFACTUREDPRODUCTGET}?query=${query.query}`
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(manufacturedProductSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// View Consumables Product Get

function* viewManufacturedProducts({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${VIEWMANUFACTUREDPRODUCTGET}`
            if(query.query){
              url =`${HOSTNAME}${VIEWMANUFACTUREDPRODUCTGET}?query=${query.query}`
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(viewManufacturedProductSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/pre-login")
  } catch (error) {
    yield put(apiError(error))
  }
}

// -- maintain inventory add product
function* manufacturedProductAddData({ payload: { data, history } }) {
  try {
            // alert(data)
            // let data = new FormData();
            // data.append('file', fileData.data);
            let mydata = JSON.stringify(data.data)
            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${HOSTNAME}${MANUFACTUREDPRODUCTADD}`,
              headers: { 
                'Authorization': `${getUserData.userData.token}`, 
                'Content-Type': 'application/json', 
              },
              data : mydata
            };


            const response = yield axios.request(config);
            // alert(response.status)
            if(response.status ==400 || response.status ==500)
            {
              toast.error("Invalid Data", {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }
            console.log("+++++++++++++",response);
            if (response.data.status_code === 201) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              // const dispatch = useDispatch()
              // dispatch(manufacturedProductGet({}, {}))
              yield put(manufacturedProductGet({}, {}));
              

            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error("Invalid Data", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}



function* manufacturedProductUpload({ payload: { fileData, history } }) {
  try {
            // alert(fileData)
            // let data = new FormData();
            // data.append('file', data);
            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${HOSTNAME}${MANUFACTUREDPRODUCTUPLOAD}`,
              headers: { 
                'Authorization': `${getUserData.userData.token}`, 
                'Content-Type': 'multipart/form-data',
              },
              data : fileData.data
            };
            const response = yield axios.request(config);
            if(response.status==400)
            {
              toast.error("File Note Valid", {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }
            console.log("+++++++++++++",response);
            if (response.data.status_code === 201) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              toast.success(`Total created: ${response.data.data.created}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              toast.success(`Total updated: ${response.data.data.updated}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(manufacturedProductGet({},{}));

            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error("something went wrong", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}


function* manufacturedProductsSaga() {
  yield takeEvery(MANUFACTUREDPRODUCT_GET, manufacturedProducts)
  yield takeEvery(VIEWMANUFACTUREDPRODUCT_GET, viewManufacturedProducts)
  yield takeEvery(MANUFACTUREDPRODUCT_ADD, manufacturedProductAddData)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(MANUFACTUREDPRODUCT_UPLOAD_POST,manufacturedProductUpload)
  
}

export default manufacturedProductsSaga
