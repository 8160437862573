// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./parentresellingProductMastertablecontainer"
import {
  parentresellingProductGet,
  resellingProductGet,
} from "../../../src/store/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Loadder from "components/Loadder/Loadder"
import { checkTokenExpiration } from "../../../src/config/getTokenExpirationTime"
function extractIdAndPName(item) {
  return {
    id: item.id,
    pname: item.pname,
    pnumber: item.pnumber,
  }
}

function DatatableTablesResellingMaster() {
  // const [resellingProductData,setresellingProductData]=useState([])
  const columns = useMemo(
    () => [
      // {
      //     Header: ' ',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
    ],
    []
  )
  let data = []
  const dispatch = useDispatch()
  //meta title
  document.title = "Masters"
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    checkTokenExpiration()
    dispatch(parentresellingProductGet({}, {}))
  }, [])

  const resellingProductData = useSelector(state => state.resellingProductGet)

  if (
    resellingProductData &&
    resellingProductData.parentresellingProductDetails
  ) {
    data =
      resellingProductData.parentresellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  return (
    <Loadder active={resellingProductData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem={document.title} />
          <Row>
            <Col lg={10}></Col>
          </Row>

          <TableContainer
            count={resellingProductData.parentresellingProductDetails.count}
            next={resellingProductData.parentresellingProductDetails.next}
            previous={
              resellingProductData.parentresellingProductDetails.previous
            }
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesResellingMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesResellingMaster
