// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loadder from "components/Loadder/Loadder"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./buybackrequestfromcustomertablecontainer"
import { useSelector, useDispatch } from "react-redux"
import { checkTokenExpiration } from "../../../src/config/getTokenExpirationTime"
import { adminBuyBackDataGet } from "../../store/actions"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import { ADMINBUYBACKGET, HOSTNAME } from "config/config"
import axios from "axios"
import moment from "moment"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
function extractIdAndPName(item) {
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }

  let AMCStartDate = moment(new Date(item.AMCStartDate)).format("DD MMM Y")
  let AMCEndDate = moment(new Date(item.AMCEndDate)).format("DD MMM Y")

  return {
    id: item.id,
    customername: `${item.firstName} ${item.lastName}`,
    ccity: item.city,
    customercontactnumber: item.contactNumber,
    delearname: `${item.dealerfirstName} ${item.dealerlastName}`,
    delearcontactnumber: item.dealercontactNumber,
    productname: item.productName,
    pnumber: item.pnumber,
    status: item.status,
    reason: item.reason,
    dealeragency: item.dealeragency,
    AMCStartDate: AMCStartDate,
    AMCEndDate: AMCEndDate,
    remainingdays:
      (new Date(item.AMCEndDate) - new Date()) / (1000 * 60 * 60 * 24),
    rejectionreason: item.rejectionreason,
    statustext: statustext,
    serialNumbers: item.serialNumbers,
  }
}
function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      {
        Header: "Contact Number",
        accessor: "customercontactnumber",
      },
      {
        Header: "Customer City",
        accessor: "ccity",
      },
      {
        Header: "Product Name",
        accessor: "productname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    {row.original.status == 1 ? (
                      <button
                        disabled
                        className="btn btn-success btn-block"
                        type="button"
                      >
                        {" "}
                        Accepted
                      </button>
                    ) : row.original.status == 2 ? (
                      ""
                    ) : (
                      <button
                        onClick={e => writerejecttoggle3(row.original, 1)}
                        className="btn btn-success btn-block"
                        type="button"
                      >
                        Accept
                      </button>
                    )}
                  </div>
                </Col>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    {row.original.status == 2 ? (
                      <button
                        disabled
                        className="btn btn-warning btn-block"
                        type="button"
                      >
                        Rejected
                      </button>
                    ) : row.original.status == 1 ? (
                      ""
                    ) : (
                      <button
                        onClick={e => writerejecttoggle1(row.original, 2)}
                        className="btn btn-warning btn-block"
                        type="button"
                      >
                        Reject
                      </button>
                    )}
                  </div>
                </Col>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link
                  className="mt-2 d-grid"
                  onClick={e => togglemodal1(row.original)}
                  to="#"
                >
                  View details
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "    ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={e => viewrejectiontoggle1(row.original)}
                  //   onClick={() => {
                  //     const customerData = cellProps.row.original;
                  //     handleCustomerClick(customerData);
                  //   }
                  //   }
                >
                  <i
                    className="font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  View Reason
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View Reason
                  </UncontrolledTooltip>
                </DropdownItem>

                {/* <DropdownItem
                        //   onClick={() => {
                        //     const customerData = cellProps.row.original;
                        //     handleCustomerClick(customerData);
                        //   }
                        //   }
                        >
                          <i className="font-size-16 text-success me-1" id="edittooltip"></i>
                          Move to Equipment Product
                          <UncontrolledTooltip placement="top" target="edittooltip">
                          Move to Equipment Product
                          </UncontrolledTooltip>
                        </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  let data = []

  const adminbuybackdata = useSelector(
    state => state.adminBuyBackGet.adminBuyBackDetails
  )

  if (adminbuybackdata && adminbuybackdata.results) {
    data =
      adminbuybackdata && adminbuybackdata.results.data.map(extractIdAndPName)
  }
  const [objData, setobjData] = useState({})

  const [rowid, setrowid] = useState("")
  const [status, setstatus] = useState("")
  const [rejectreason, setrejectreason] = useState("")

  const togglemodal1 = row => {
    // alert(row)
    let fdata = {
      id: row.id,
      customername: row.customername,
      ccity: row.ccity,
      customercontactnumber: row.customercontactnumber,
      delearname: row.delearname,
      delearcontactnumber: row.delearcontactnumber,
      productname: row.productname,
      status: row.status,
      reason: row.reason,
      dealeragency: row.dealeragency,
      AMCStartDate: row.AMCStartDate,
      AMCEndDate: row.AMCEndDate,
      remainingdays: parseInt(row.remainingdays, 10),
      rejectionreason: row.rejectionreason,
    }
    setModal(!modal)
    // const foundData = data && data.find(item => item.id === row);
    setobjData(fdata)
  }
  //meta title
  document.title = "Refurbished"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      notes:
        "Parsley is a javascript form validation before sending it to your server.",
      writereason: "",
    },
    validationSchema: Yup.object({
      notes: Yup.string().required("Please Enter Notes"),
      writereason: Yup.string().required("Please Enter Your Reason"),
    }),
    onSubmit: values => {},
  })

  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }

  const [writereject, setWritereject] = useState(false)
  const [viewrejection, setViewrejection] = useState(false)

  const writerejecttoggle = () => {
    setWritereject(!writereject)
  }

  const writerejecttoggle1 = (row, status) => {
    // alert(row)
    // alert(status)
    setWritereject(!writereject)
    setrowid(row.id)
    setstatus(status)
  }
  const writerejecttoggle3 = (row, status) => {
    // alert(row)
    // alert(status)
    setrowid(row.id)
    setstatus(status)
    writerejecttoggle2(status, row.id)
  }

  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }
  const viewrejectiontoggle1 = row => {
    let fdata = {
      id: row.id,
      customername: row.customername,
      ccity: row.ccity,
      customercontactnumber: row.customercontactnumber,
      delearname: row.delearname,
      delearcontactnumber: row.delearcontactnumber,
      productname: row.productname,
      status: row.status,
      reason: row.reason,
      dealeragency: row.dealeragency,
      AMCStartDate: row.AMCStartDate,
      AMCEndDate: row.AMCEndDate,
      remainingdays: parseInt(row.remainingdays, 10),
      rejectionreason: row.rejectionreason,
    }
    setViewrejection(!viewrejection)
    setobjData(fdata)
  }

  const writerejecttoggle2 = (status, rowid) => {
    // alert(rowid)
    // alert(status)
    // alert(rejectreason)
    // -------------
    let url = `${HOSTNAME}${ADMINBUYBACKGET}?status=${status}&id=${rowid}&reason=${rejectreason}`
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        dispatch(adminBuyBackDataGet({ type: "AC" }, {}))
        setrowid("")
        setstatus("")
        setrejectreason("")
      })
      .catch(error => {})
    setrowid("")
    setstatus("")
    setrejectreason("")
    if (status == 1) {
    } else {
      setWritereject(!writereject)
    }
  }

  const dispatch = useDispatch()
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    // alert("called")
    checkTokenExpiration()
    dispatch(adminBuyBackDataGet({ type: "AC" }, {}))
  }, [dispatch])

  return (
    <Loadder active={adminbuybackdata.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Refurbished" />

          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={adminbuybackdata.count}
            next={adminbuybackdata.next}
            previous={adminbuybackdata.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={modal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Name</b>
                              <br></br>
                              <p>{objData && objData.customername}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer City</b>
                              <br></br>
                              <p>{objData && objData.ccity}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Number</b>
                              <br></br>
                              <p>{objData && objData.customercontactnumber}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
            <ModalHeader>Customer Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Name</b>
                              <br></br>
                              <p>{objData && objData.productname}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Registration Date</b>
                              <br></br>
                              <p>{objData && objData.AMCEndDate}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product AMC Remaning Days</b>
                              <br></br>
                              <p>{objData && objData.remainingdays}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Notes</Label>
                    <Input
                      disabled
                      name="notes"
                      placeholder="Add Notes"
                      type="textarea"
                      className="form-control"
                      id="validationCustom01"
                      value={objData && objData.reason}

                      // style={{ height: "100px", resize: "none" }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>

        {/* ------------------- Write Reason For Rejection------------- */}
        <Modal
          isOpen={writereject}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={writerejecttoggle}
        >
          <div className="modal-content">
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <h5>Reason</h5>
                    <p style={{ fontSize: "12px" }}></p>
                    <Label htmlFor="validationCustom01">Write reason</Label>
                    <Input
                      name="writereason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      onChange={e => setrejectreason(e.target.value)}
                      // onBlur={validation.handleBlur}
                      value={rejectreason}
                      // invalid={
                      //   validation.touched.writereason && validation.errors.writereason ? true : false
                      // }
                    />
                    {/* {validation.touched.writereason && validation.errors.writereason ? (
                            <FormFeedback type="invalid">{validation.errors.writereason}</FormFeedback>
                          ) : null} */}
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>

            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button
                onClick={e => writerejecttoggle2(status, rowid)}
                type="button"
                color="primary"
              >
                Submit
              </Button>

              <Button
                type="button"
                onClick={writerejecttoggle}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Modal>

        {/* ------------------- View Reason For Rejection------------- */}
        <Modal
          isOpen={viewrejection}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={viewrejectiontoggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={viewrejectiontoggle}>
              Reason for rejection
            </ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <p> </p>
                    <Label htmlFor="validationCustom01">View reason</Label>
                    <Input
                      name="viewreason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      disabled
                      value={objData && objData.rejectionreason}
                    />
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
