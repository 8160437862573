// View Demo Product
// ----------------MATAIN INVENTORY ADD PRODUCT------------------------------------------
export const DEMOPRODUCT_ADD="DEMOPRODUCT_ADD"
export const DEMOPRODUCT_ADD_SUCCESS="DEMOPRODUCT_ADD_SUCCESS"
// -------------------------------------------------------------

// ---------------- Master Demo product Master---------

export const DEMOPRODUCTMASTER_GET="DEMOPRODUCTMASTER_GET"
export const DEMOPRODUCTMASTER_SUCCESS="DEMOPRODUCTMASTER_SUCCESS"

export const DEMOPRODUCTMASTER_UPLOAD_POST="DEMOPRODUCTMASTER_UPLOAD_POST"
export const DEMOPRODUCTMASTER_UPLOAD_SUCCESS="DEMOPRODUCTMASTER_UPLOAD_SUCCESS"

// --------------------------------------------------------
export const VIEWDEMOPRODUCT_GET="VIEWDEMOPRODUCT_GET"
export const VIEWDEMOPRODUCT_SUCCESS="VIEWDEMOPRODUCT_SUCCESS"

export const TAGTODEALER_GET="TAGTODEALER_GET"
export const TAGTODEALER_SUCCESS="TAGTODEALER_SUCCESS"

export const TAGGEDPRODUCTLIST_GET="TAGGEDPRODUCTLIST_GET"
export const TAGGEDPRODUCTLIST_SUCCESS="TAGGEDPRODUCTLIST_SUCCESS"


export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

