// src/components/filter.
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./servicelisttablecontainer"
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerServicesListGet } from "store/actions"
import { useEffect } from "react"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  return {
    id: item.id,
    types: item.types,
    message: item.message,
    serviceStatus: item.serviceStatus,
    customer: item.customer,
    productName: item.productName,
    pid: item.pid,
    pnumber: item.pnumber,
    serviceStatustext: item.serviceStatustext,
    serialNumbers: item.serialNumbers,
  }
}
function DatatableTables() {
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   filterable: false,
      //   Cell: () => {
      //       return <input type="checkbox" className="form-check-input" />;
      //     }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "serviceStatustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View Message
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(customerServicesListGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customerserviceslist) {
    data =
      customerDatas.customerserviceslist.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Services"

  const [modal, setModal] = useState(false)

  const togglemodal = () => {
    setModal(!modal)
  }
  const [obj, setobj] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Services" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customerserviceslist &&
            customerDatas.customerserviceslist.count
          }
          next={
            customerDatas.customerserviceslist &&
            customerDatas.customerserviceslist.next
          }
          previous={
            customerDatas.customerserviceslist &&
            customerDatas.customerserviceslist.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Ticket Message</ModalHeader>

          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Product Number</Label>
                  <Input
                    name="pid"
                    placeholder="Product Number"
                    type="text"
                    className="form-control"
                    disabled
                    value={obj && obj.pnumber}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Product Name</Label>
                  <Input
                    name="productname"
                    placeholder="Select Product Name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    disabled
                    value={obj && obj.productName}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Message</Label>
                  <Input
                    name="message"
                    placeholder="Add Dealer City"
                    type="textarea"
                    className="form-control"
                    id="validationCustom01"
                    disabled
                    value={obj && obj.message}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
