import {
  SERVICEREQUSETFROMCUSTOMER_GET,
  SERVICEREQUSETFROMCUSTOMER_SUCCESS,
  SERVICEREQUESTDEALERTOCUSTOMER_GET,
  SERVICEREQUESTDEALERTOCUSTOMER_SUCCESS,
  SERVICECENTRICCUSTOMER_GET,
  SERVICECENTRICCUSTOMER_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  serviceDetails: "",
}

const serviceGet = (state = initialState, action) => {
  switch (action.type) {
    case SERVICEREQUSETFROMCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case SERVICEREQUSETFROMCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        serviceDetails:action.payload,
      }
      break

// --- service request dealer to customer

    case SERVICEREQUESTDEALERTOCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case SERVICEREQUESTDEALERTOCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        serviceDetails:action.payload,
      }
      break

// ------- service  centric customer

    case SERVICECENTRICCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case SERVICECENTRICCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        serviceDetails:action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default serviceGet


