// src/components/filter.
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./viewtaggedproducttablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { taggedProductCustomerGet } from "store/actions"
import { ACCEPTREJECTTAGPRODUCT, HOSTNAME } from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  let dealercustomerstatustext

  if (item.dealercustomerstatus === 0) {
    dealercustomerstatustext = "Pending"
  } else if (item.dealercustomerstatus === 1) {
    dealercustomerstatustext = "Accepted"
  } else if (item.dealercustomerstatus === 2) {
    dealercustomerstatustext = "Rejected"
  } else {
    dealercustomerstatustext = "Unknown Status"
  }
  return {
    id: item.id,
    RPD: item.RPD,
    status: item.status,
    dealercustomerstatus: item.dealercustomerstatus,
    pname: item.pname,
    pnumber: item.pnumber,
    menufactureProduct: item.menufactureProduct,
    reason: item.reason,
    statustext: item.statustext,
    dealercustomerstatustext: dealercustomerstatustext,
    serialNumbers: item.serialNumbers,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "dealercustomerstatustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return row.dealercustomerstatus == 1 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      disabled
                      onClick={writerejecttoggle}
                      className="btn btn-success btn-block"
                      type="button"
                    >
                      Accepted
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : row.dealercustomerstatus == 0 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      onClick={e => CallAcceptRejectApi(row.id, 1, "")}
                      className="btn btn-success btn-block"
                      type="button"
                    >
                      Accept
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : (
            ""
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return row.dealercustomerstatus == 2 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      disabled
                      onClick={viewrejectiontoggle}
                      className="btn btn-warning btn-block"
                      type="button"
                    >
                      Rejected
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : row.dealercustomerstatus == 0 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      onClick={e => writerejecttoggle1(row.id)}
                      className="btn btn-warning btn-block"
                      type="button"
                    >
                      Reject
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : (
            ""
          )
        },
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View Consumables Products
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "    ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return row.dealercustomerstatus == 2 ? (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                {row.dealercustomerstatus == 2 ? (
                  <DropdownItem onClick={e => viewrejectiontoggle1(row)}>
                    <i
                      className="font-size-16 text-success me-1"
                      id="edittooltip"
                    ></i>
                    View Reason
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      View Reason
                    </UncontrolledTooltip>
                  </DropdownItem>
                ) : (
                  ""
                )}
                {/* 
                      {row.dealercustomerstatus ==1
                      ?
                      <DropdownItem>
                        <i className="font-size-16 text-success me-1" id="edittooltip"></i>
                        Verify Product
                        <UncontrolledTooltip placement="top" target="edittooltip">
                        Verify Product
                        </UncontrolledTooltip>
                      </DropdownItem>
                      :
                      ""} */}
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            ""
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(taggedProductCustomerGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customertaggedproductDetails) {
    data =
      customerDatas.customertaggedproductDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Products Registrations"

  const [modal, setModal] = useState(false)

  const togglemodal = () => {
    setModal(!modal)
  }

  const [obj, setobj] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  const [writereject, setWritereject] = useState(false)
  const [viewrejection, setViewrejection] = useState(false)

  const writerejecttoggle = () => {
    setWritereject(!writereject)
  }
  const [reason, setreason] = useState("")
  const [id, setId] = useState("")
  const writerejecttoggle1 = id => {
    setWritereject(!writereject)
    setId(id)
  }

  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }
  const viewrejectiontoggle1 = row => {
    setViewrejection(!viewrejection)
    setobj(row)
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      notes:
        "Parsley is a javascript form validation before sending it to your server.",
      writereason: "",
    },
    validationSchema: Yup.object({
      notes: Yup.string().required("Please Enter Notes"),
      writereason: Yup.string().required("Please Enter Your Reason"),
    }),
    onSubmit: (values, { resetForm }) => {
      CallAcceptRejectApi(id, 2, values.writereason)
      resetForm()
      setId("")
      setWritereject(!writereject)
    },
  })

  const CallAcceptRejectApi = (tagid, status, reason) => {
    // alert(tagid)
    // alert(status)
    // alert(reason)
    let data = new FormData()
    data.append("tagid", tagid)
    data.append("status", status)
    data.append("reason", reason)

    let url = `${HOSTNAME}${ACCEPTREJECTTAGPRODUCT}`
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        dispatch(taggedProductCustomerGet({}, {}))
        if (status == 1) {
          toast.success("Product accepted successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.success("Product rejected successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      })
      .catch(error => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        toast.error(error.response.data.detail, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Products Registrations" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customertaggedproductDetails &&
            customerDatas.customertaggedproductDetails.count
          }
          next={
            customerDatas.customertaggedproductDetails &&
            customerDatas.customertaggedproductDetails.next
          }
          previous={
            customerDatas.customertaggedproductDetails &&
            customerDatas.customertaggedproductDetails.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            Equipment Product Details
          </ModalHeader>
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <b>Product Name</b>
                        <br></br>
                        <p>{obj && obj.pname}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
          <ModalHeader>Consumables Products Details</ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                  </div>
                  {obj.menufactureProduct &&
                    obj.menufactureProduct.map((item, index) => {
                      return (
                        <div key={index} className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Name</b>
                              <br></br>
                              {/* <p>{item.mpname} - {item.mpnumber}</p> */}
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  {item.mpname}
                                </span>
                                <span style={{ color: "#777" }}> X </span>
                                <span style={{ color: "#555" }}>
                                  {" "}
                                  {item.mpquantity}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>

      {/* ------------------- Write Reason For Rejection------------- */}
      <Modal
        isOpen={writereject}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={writerejecttoggle}
      >
        <Form onSubmit={validation.handleSubmit}>
          <div className="modal-content">
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <h5>Reason for rejection</h5>
                    <p style={{ fontSize: "12px" }}></p>
                    <Label htmlFor="validationCustom01">Write reason</Label>
                    <Input
                      name="writereason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.writereason || ""}
                      invalid={
                        validation.touched.writereason &&
                          validation.errors.writereason
                          ? true
                          : false
                      }
                    />
                    {validation.touched.writereason &&
                      validation.errors.writereason ? (
                      <FormFeedback type="invalid">
                        {validation.errors.writereason}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>

            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="submit" color="primary">
                Submit
              </Button>

              <Button
                type="button"
                onClick={writerejecttoggle}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Form>
      </Modal>

      {/* ------------------- View Reason For Rejection------------- */}
      <Modal
        isOpen={viewrejection}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={viewrejectiontoggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={viewrejectiontoggle}>
            Reason for rejection
          </ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <Col md="12">
                <FormGroup className="mb-3">
                  <p> </p>
                  <Label htmlFor="validationCustom01">View reason</Label>
                  <Input
                    name="viewreason"
                    placeholder="Enter Text Here"
                    type="textarea"
                    rows={4}
                    className="form-control"
                    id="validationCustom01"
                    value={obj && obj.reason}
                    disabled
                  />
                </FormGroup>
              </Col>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
