import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"

import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "./Table/Table"
import { HOSTNAME } from "config/config"
import { toast } from "react-toastify"
import Loadder from "components/Loadder/Loadder"

const TaggedConsumablesProductByAdmin = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [modal, setModal] = useState(false)
  const [products, setProducts] = useState(null)
  const [productNames, setProductNames] = useState(null)
  const [productId, setProductId] = useState(null)
  const [qty, setQty] = useState("")
  const [currentPage, setcurrentPage] = useState(1)

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  // Get Tagged Consumables Product
  const getTaggedConsumablesProductByAdmin = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/dealer-tagged-inventory-product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Get Product
  const getProducts = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/main-productname/list/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios.request(config).then(response => {
      setProducts(response?.data?.data)
    })
  }

  useEffect(() => {
    getTaggedConsumablesProductByAdmin()
    getProducts()
  }, [])

  // Get Product name
  const getProductnames = e => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/productname/list/${e.target.value}/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios.request(config).then(response => {
      setProductNames(response?.data?.data)
    })
  }

  // Toogle Modal
  const togglemodal = () => {
    setModal(!modal)
    setProductNames(null)
    setProductId(null)
    setQty("")
  }

  // Add Consumable Request
  const addConsumableRequest = () => {
    let data = new FormData()
    data.append("id", productId)
    data.append("qty", qty)

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/inventory-manufacture-product-request-dealer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }
    axios
      .request(config)
      .then(response => {
        toast.success("Product tagged successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })

        getTaggedConsumablesProductByAdmin()
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setModal(false)
        setProductId(null)
        setQty(null)
      })
  }

  //Columns
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "RPD.pnumber.pname",
      },
      {
        Header: "Product Number",
        accessor: "RPD.pnumber.pnumber",
      },
      {
        Header: "Product  Consumables Name",
        accessor: "RPD.mpname",
      },
      {
        Header: "Product  Quantity",
        accessor: "qty",
      },
    ],
    []
  )

  // Search
  const [query, setquery] = useState("")
  const onSearch = e => {
    setquery(e.target.value)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/dealer-tagged-inventory-product/?query=${e.target.value}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //Pagination function
  const getPaginationData = (url, page) => {
    setcurrentPage(page)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Loadder active={loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Consumable Products" />

          <TableContainer
            count={tableData?.count}
            next={tableData?.next}
            previous={tableData?.previous}
            columns={columns}
            data={tableData?.results?.data || []}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
            removeCheckbox
            currentPage={currentPage}
            isRightSide={
              <Button onClick={togglemodal} color="primary">
                New Consumables Request
              </Button>
            }
            onSearch={onSearch}
            goToNextPage={() =>
              getPaginationData(
                `${HOSTNAME}api/dealer-tagged-inventory-product/?query=${query}&page=${currentPage + 1
                }`,
                currentPage + 1
              )
            }
            goToLastPage={() =>
              getPaginationData(
                `${HOSTNAME}api/dealer-tagged-inventory-product/?query=${query}&page=${Math.ceil(
                  tableData?.count / 10
                )}`,
                Math.ceil(tableData?.count / 10)
              )
            }
            goToPreviousPage={() =>
              getPaginationData(
                `${HOSTNAME}api/dealer-tagged-inventory-product/?query=${query}&page=${currentPage - 1
                }`,
                currentPage - 1
              )
            }
            goToFirstPage={() =>
              getPaginationData(
                `${HOSTNAME}api/dealer-tagged-inventory-product/?query=${query}&page=${1}`,
                1
              )
            }
            getInputPaginationData={e =>
              getPaginationData(
                `${HOSTNAME}api/dealer-tagged-inventory-product/?query=${query}&page=${e.target.value}`,
                e.target.value
              )
            }
          />

          <Modal
            isOpen={modal}
            role="dialog"
            size="lg"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={togglemodal}
          >
            <div className="modal-content">
              <ModalHeader toggle={togglemodal}>
                Cousumables Product
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="">Products Name</Label>
                      <Input
                        name="product-name"
                        type="select"
                        onChange={e => getProductnames(e)}
                      >
                        <option value="" disabled selected hidden>
                          Product Name
                        </option>
                        {products?.map(el => (
                          <option value={el?.id} key={el?.id}>
                            {el?.pname}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="">Add Consumables Products</Label>
                      <Input
                        name="consumables-products"
                        type="select"
                        disabled={!productNames || productNames?.length <= 0}
                        onChange={e => setProductId(e.target.value)}
                      >
                        <option value="" disabled selected hidden>
                          Consumable Products
                        </option>
                        {productNames?.map(el => (
                          <option value={el?.id} key={el?.id}>
                            {el?.mpname}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Add Consumables Product Qty
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        placeholder="Add Consumables Product Qty"
                        value={qty}
                        onChange={e => {
                          const value = e.target.value
                          if (value === "" || parseFloat(value) >= 0) {
                            setQty(value)
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
                <Button
                  type="button"
                  color="primary"
                  disabled={qty <= 0}
                  onClick={addConsumableRequest}
                >
                  Submit
                </Button>

                <Button type="button" onClick={togglemodal} color="secondary">
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        </div>
      </div>
    </Loadder>
  )
}

export default TaggedConsumablesProductByAdmin
