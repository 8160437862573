// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./taglistofcustomertablecontainer"
import { tagListOfCustomerGet } from "../../../src/store/actions"

import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Button,
  ModalFooter,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import Loadder from "components/Loadder/Loadder"
import { toast } from "react-toastify"
import { ADMINSOLDPRODUCT, HOSTNAME } from "config/config"
import axios from "axios"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
function extractIdAndPName(item) {
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }
  return {
    id: item.id,
    firstName: item.firstName,
    contactNumber: item.contactNumber,
    productName: item.productName,
    pnumber: item.pnumber,
    city: item.city,
    status: item.status,
    isSold: item.isSold,
    statustext: statustext,
    serialNumbers: item.serialNumbers,
    reason: item.reason,
  }
}

function DatatableTablesDemoProductMaster() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "firstName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <UncontrolledDropdown>
              {row.original.status == 1 || row.original.status == "1" ? (
                row.original.isSold == false ? (
                  <>
                    <DropdownToggle tag="a" className="card-drop">
                      <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={e => soldData(e, row.original.id, 0)}
                      >
                        <i
                          className="font-size-16 text-success me-1"
                          id="edittooltip"
                        ></i>
                        Mark as sold in this year
                        <UncontrolledTooltip
                          placement="top"
                          target="edittooltip"
                        >
                          Mark as sold in this year
                        </UncontrolledTooltip>
                      </DropdownItem>

                      <DropdownItem
                        onClick={e => soldData(e, row.original.id, 1)}
                      >
                        <i
                          className=" font-size-16 text-danger me-1"
                          id="deletetooltip"
                        ></i>
                        Mark as sold in next year
                        <UncontrolledTooltip
                          placement="top"
                          target="deletetooltip"
                        >
                          Mark as sold in next year
                        </UncontrolledTooltip>
                      </DropdownItem>
                    </DropdownMenu>
                  </>
                ) : (
                  ""
                )
              ) : row.original.status == 2 ? (
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={e => togglemodal1(row.original)}>
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      View Reason
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        View Reason
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                ""
              )}
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  const [modal, setModal] = useState(false)
  const [rowData, setrowData] = useState([])
  const togglemodal = () => {
    setModal(!modal)
  }
  const togglemodal1 = row => {
    setrowData(row)
    setModal(!modal)
  }

  let data = []
  const dispatch = useDispatch()
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }

    dispatch(tagListOfCustomerGet({}, {}))
  }, [])

  const taglistofCustomerData = useSelector(state => state.resellingProductGet)

  if (taglistofCustomerData && taglistofCustomerData.resellingProductDetails) {
    data =
      taglistofCustomerData.resellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  const soldData = (e, id, type) => {
    // alert(id)
    // alert(type)
    let url = `${HOSTNAME}${ADMINSOLDPRODUCT}?year=${type}&oid=${id}&soldType=0`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        dispatch(tagListOfCustomerGet({}, {}))
        toast.success("Product maked as a sold successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(error => {
        toast.error("something worng", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    dispatch(tagListOfCustomerGet({}, {}))
  }

  //meta title
  document.title = "Equipments Product"

  return (
    <Loadder active={taglistofCustomerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Equipments Products" />

          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={taglistofCustomerData.resellingProductDetails.count}
            next={taglistofCustomerData.resellingProductDetails.next}
            previous={taglistofCustomerData.resellingProductDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={modal}
          role="dialog"
          size="lg"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>View Reason</ModalHeader>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    {/* <Label htmlFor="validationCustom01">Product Number</Label> */}
                    <textarea
                      disabled
                      name=""
                      placeholder=""
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      // onBlur={validation.handleBlur}
                      value={rowData && rowData.reason}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="button" onClick={togglemodal} color="secondary">
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTablesDemoProductMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesDemoProductMaster
