import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"

// Login Redux States
import { 
  LOGOUT_USER,
  ADMINAMCCUSTOMERLIST_GET,
  ADMINAMCCUSTOMERLIST_SUCCESS,

} from "./actionTypes"

import { 
  adminAMCcustomerGet,
  adminAMCcustomerSuccess,

} from "./actions"

import { 
  HOSTNAME,
  ADMINAMCCUSTOMERLISTGET


} from "config/config"

import { apiError } from "store/actions"
import { useSelector, useDispatch } from "react-redux"
// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser")) 

function* amcCustomer({ payload: { query, history,type } }) {
  try {
            // alert(query.query)
            // let url = `${HOSTNAME}${ADMINAMCCUSTOMERLISTGET}?type=${type.type}`
            // if(query.query){
            //   url =`${HOSTNAME}${ADMINAMCCUSTOMERLISTGET}?query=${query.query}&type=${type.type}`
            // }
            // else if(query.url){
            //   url=query.url
            // }
            let url = `${HOSTNAME}${ADMINAMCCUSTOMERLISTGET}`
            const queryParams = [];
            if (query.query) {
              queryParams.push(`query=${query.query}`);
            }
            if (query.status  && query.status !== '3') {
              queryParams.push(`status=${query.status}`);
            }
            if (type.type) {
              queryParams.push(`type=${type.type}`);
            }
            if (queryParams.length > 0) {
              url = `${HOSTNAME}${ADMINAMCCUSTOMERLISTGET}?${queryParams.join('&')}`;
            }
            if(query.url){
              url = query.url;
            }
            
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(adminAMCcustomerSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}


function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/pre-login")
  } catch (error) {
    yield put(apiError(error))
  }
}




function* AmcCustomerListSaga() {
  yield takeEvery(ADMINAMCCUSTOMERLIST_GET, amcCustomer)
 
  yield takeEvery(LOGOUT_USER, logoutUser)  
}

export default AmcCustomerListSaga
