// src/components/filter.
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./tagtocustomertablecontainer"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { dealer_tagtocustomerGet } from "store/actions"
import { useEffect } from "react"

function extractIdAndPName(item) {
  return {
    id: item.id,
    customername: `${item.firstName && item.firstName}  ${
      item.lastName && item.lastName
    }`,
    contactNumber: item.contactNumber,
  }
}

function DatatableTablesDealerMaster() {
  const columns = useMemo(
    () => [
      // {
      //     Header: ' ',
      //     filterable: false,
      //     Cell: (cellProps) => {
      //         const row = cellProps.row.original
      //         return <input name="tagto" onChange={e=>setcid(row.id)}  type="radio" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
    ],
    []
  )

  const location = useLocation()
  const { id } = location.state

  let data = []

  const dispatch = useDispatch()
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(dealer_tagtocustomerGet({}, {}, { id: id }))
  }, [])

  const dealer_tagTocustomerData = useSelector(
    state => state.resellingProductGet
  )

  if (
    dealer_tagTocustomerData &&
    dealer_tagTocustomerData.resellingProductDetails
  ) {
    data =
      dealer_tagTocustomerData.resellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  const [tagtoId, setcid] = useState("")
  //meta title
  document.title = "Demo Product"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Demo Product" />
        {/* <Row>
                    <Col lg={10}>
                    </Col>
                    <Col lg={1}>
                    <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                          >
                            Upload
                          </button>
                        </div>
                    </Col>
                    <Col lg={1}>
                    <div className="mt-4 d-grid">
                          <button
                            className="btn btn-secondary btn-block"
                            type="button"
                          >
                            Download
                          </button>
                           <a href="">Download</a>
                        </div>
                    </Col>
                </Row> */}

        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          columns={columns}
          data={data}
          id={id}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
DatatableTablesDealerMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesDealerMaster
