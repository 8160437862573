// src/components/filter.
import React, { useState, Fragment, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./verifyproducttablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerVerifyProductListGet } from "store/actions"
import { useEffect } from "react"
import {
  CUSTOMERVERIFYOTP,
  HOSTNAME,
  SENDOTPFORVERIFYPRODUCT,
} from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  return {
    id: item.id,
    pname: item.pname,
    pnumber: item.pnumber,
    status: item.status,
    customer_number: item.customer_number,
    serialNumbers: item.serialNumbers,
  }
}
function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  Verify Product
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(customerVerifyProductListGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customerverifyproductlist) {
    data =
      customerDatas.customerverifyproductlist.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Product Registration"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pid: "AB101",
      productname: "ES8431 OKI Printer",
      customernumber: "9860778899",
    },
    validationSchema: Yup.object({
      pid: Yup.string().required("Please Enter Your Product id"),
      productname: Yup.string().required("Please Enter Product Name"),
      customernumber: Yup.string().required("Please Enter Customer Number"),
    }),
    onSubmit: values => {},
  })

  const [modal, setModal] = useState(false)
  const [otpmodal, setotpmodal] = useState(false)

  const togglemodal = () => {
    setModal(!modal)
  }
  const otptogglemodal = () => {
    setotpmodal(!otpmodal)
  }

  const [obj, setobj] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  const sendDataForVerify = obj => {
    let data = new FormData()
    data.append("tagid", obj.id)
    data.append("number", obj.customer_number)

    let url = `${HOSTNAME}${SENDOTPFORVERIFYPRODUCT}`
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        // ...data.getHeaders()
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        setModal(!modal)
        otptogglemodal()
      })
      .catch(error => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    // setModal(!modal);
  }

  const [otp1, setOtp1] = useState("")
  const [otp2, setOtp2] = useState("")
  const [otp3, setOtp3] = useState("")
  const [otp4, setOtp4] = useState("")
  const [otp5, setOtp5] = useState("")
  const [otp6, setOtp6] = useState("")
  const clearOtp = () => {
    setOtp1("")
    setOtp2("")
    setOtp3("")
    setOtp4("")
    setOtp5("")
    setOtp6("")
  }
  const verifyOpt = () => {
    let data = new FormData()
    data.append("tagid", obj.id)
    data.append("number", obj.customer_number)
    let otp = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`
    data.append("otp", otp)

    let url = `${HOSTNAME}${CUSTOMERVERIFYOTP}`
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        // ...data.getHeaders()
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        setotpmodal(!otpmodal)
        dispatch(customerVerifyProductListGet({}, {}))
        setobj({})
        clearOtp()
        toast.success("Otp verifyed", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(error => {
        clearOtp()
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Products Registrations" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customerverifyproductlist &&
            customerDatas.customerverifyproductlist.count
          }
          next={
            customerDatas.customerverifyproductlist &&
            customerDatas.customerverifyproductlist.next
          }
          previous={
            customerDatas.customerverifyproductlist &&
            customerDatas.customerverifyproductlist.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      {/* modal */}
      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Verify Product</ModalHeader>

          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Product Number</Label>
                  <Input
                    disabled
                    name="pid"
                    placeholder="Product Number"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={obj && obj.pnumber}
                    invalid={
                      validation.touched.pid && validation.errors.pid
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pid && validation.errors.pid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pid}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Product Name</Label>
                  <Input
                    disabled
                    name="productname"
                    placeholder="Enter Product Name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={obj && obj.pname}
                    invalid={
                      validation.touched.productname &&
                      validation.errors.productname
                        ? true
                        : false
                    }
                  />
                  {validation.touched.productname &&
                  validation.errors.productname ? (
                    <FormFeedback type="invalid">
                      {validation.errors.productname}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Customer Number</Label>
                  <Input
                    disabled
                    name="customernumber"
                    placeholder="Add Customer Number"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={obj && obj.customer_number}
                    invalid={
                      validation.touched.customernumber &&
                      validation.errors.customernumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched.customernumber &&
                  validation.errors.customernumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.customernumber}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
            <Button
              type="button"
              onClick={e => sendDataForVerify(obj)}
              color="primary"
            >
              Send OTP
            </Button>

            <Button type="button" onClick={togglemodal} color="secondary">
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      {/* ----------- */}
      <Modal
        isOpen={otpmodal}
        role="dialog"
        size="sl"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={otptogglemodal}
      >
        <Form>
          <div className="modal-content">
            <ModalHeader toggle={otptogglemodal}>Verify Product</ModalHeader>

            <ModalBody>
              <Row>
                <Col className="col-2">
                  <div className="mb-2">
                    <Label htmlFor="digit1-input" className="visually-hidden">
                      Dight 1
                    </Label>
                    <Input
                      type="text"
                      className="form-control form-control-sl text-center two-step"
                      maxLength="1"
                      data-value="1"
                      id="digit1-input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                      }}
                      value={otp1}
                      // onChange={(e) => setOtp1(e.target.value)}
                      onChange={e => {
                        setOtp1(e.target.value)
                        if (
                          e.target.value !== "" &&
                          document.getElementById("digit2-input")
                        ) {
                          document.getElementById("digit2-input").focus()
                        }
                      }}
                      tabIndex={1}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="mb-2">
                    <Label htmlFor="digit2-input" className="visually-hidden">
                      Dight 2
                    </Label>
                    <Input
                      type="text"
                      className="form-control form-control-sl text-center two-step"
                      maxLength="1"
                      data-value="2"
                      id="digit2-input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                      }}
                      value={otp2}
                      // onChange={(e) => setOtp2(e.target.value)}
                      onChange={e => {
                        setOtp2(e.target.value)
                        if (
                          e.target.value !== "" &&
                          document.getElementById("digit3-input")
                        ) {
                          document.getElementById("digit3-input").focus()
                        }
                      }}
                      tabIndex={2}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="mb-2">
                    <Label htmlFor="digit3-input" className="visually-hidden">
                      Dight 3
                    </Label>
                    <Input
                      type="text"
                      className="form-control form-control-sl text-center two-step"
                      maxLength="1"
                      data-value="3"
                      id="digit3-input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                      }}
                      value={otp3}
                      // onChange={(e) => setOtp3(e.target.value)}
                      onChange={e => {
                        setOtp3(e.target.value)
                        if (
                          e.target.value !== "" &&
                          document.getElementById("digit4-input")
                        ) {
                          document.getElementById("digit4-input").focus()
                        }
                      }}
                      tabIndex={3}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="mb-2">
                    <Label htmlFor="digit4-input" className="visually-hidden">
                      Dight 4
                    </Label>
                    <Input
                      type="text"
                      className="form-control form-control-sl text-center two-step"
                      maxLength="1"
                      data-value="4"
                      id="digit4-input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                      }}
                      value={otp4}
                      // onChange={(e) => setOtp4(e.target.value)}
                      onChange={e => {
                        setOtp4(e.target.value)
                        if (
                          e.target.value !== "" &&
                          document.getElementById("digit5-input")
                        ) {
                          document.getElementById("digit5-input").focus()
                        }
                      }}
                      tabIndex={4}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="mb-2">
                    <Label htmlFor="digit5-input" className="visually-hidden">
                      Dight 4
                    </Label>
                    <Input
                      type="text"
                      className="form-control form-control-sl text-center two-step"
                      maxLength="1"
                      data-value="4"
                      id="digit5-input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                      }}
                      value={otp5}
                      // onChange={(e) => setOtp5(e.target.value)}
                      onChange={e => {
                        setOtp5(e.target.value)
                        if (
                          e.target.value !== "" &&
                          document.getElementById("digit6-input")
                        ) {
                          document.getElementById("digit6-input").focus()
                        }
                      }}
                      tabIndex={5}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="mb-2">
                    <Label htmlFor="digit6-input" className="visually-hidden">
                      Dight 4
                    </Label>
                    <Input
                      type="text"
                      className="form-control form-control-sl text-center two-step"
                      maxLength="1"
                      data-value="4"
                      id="digit6-input"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                      }}
                      value={otp6}
                      onChange={e => setOtp6(e.target.value)}
                      // onChange={(e) => {
                      //   setOtp6(e.target.value);
                      //   // Focus on the next input if value is entered
                      //   if (e.target.value !== '' && inputRefs[5].current) {
                      //     inputRefs[5].current.focus();
                      //   }
                      // }}
                      // ref={inputRefs[5]}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="button" onClick={e => verifyOpt()} color="primary">
                Verify OTP
              </Button>

              <Button type="button" onClick={otptogglemodal} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
