// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./dealerslisttablecontainer"
import { dealerListGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  CardBody,
  Card,
  Input,
  Label,
  FormGroup,
  ModalFooter,
  Button,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import { HOSTNAME } from "config/config"
import axios from "axios"
import { toast } from "react-toastify"

function extractIdAndPName(item) {
  return {
    id: item.id,
    agencyName: item.agencyName,
    firstName: item.firstName,
    lastName: item.lastName,
    city: item.city,
    contactNumber: item.contactNumber,
    fullName: item.fullName,
    totalSoldProductbyDealer: item.totalSoldProductbyDealer,
    totalTagProductbyDealer: item.totalTagProductbyDealer,
    totalTagDemoProductbyDealer: item.totalTagDemoProductbyDealer,
    email: item.email,
  }
}

function DatatableTablesManufacture() {
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [rowData, setRowData] = useState({})

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const initialValues = {
    agencyName: rowData?.agencyName || "",
    firstName: rowData?.firstName || "",
    lastName: rowData?.lastName || "",
    contactNumber: rowData?.contactNumber || "",
    email: rowData?.email || "",
    city: rowData?.city || "",
  }

  const validationSchema = Yup.object().shape({
    agencyName: Yup.string().required("Agency Name is required"),
    firstName: Yup.string().required("Dealer First Name is required"),
    lastName: Yup.string().required("Dealer Last Name is required"),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    city: Yup.string().required("City is required"),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    let data = new FormData()

    data.append("agencyName", values.agencyName)
    data.append("firstName", values.firstName)
    data.append("lastName", values.lastName)
    data.append("contactNumber", values.contactNumber)
    data.append("email", values.email)
    data.append("city", values.city)

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/update-dealer-product/${rowData?.id}/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        dispatch(dealerListGet({}, {}))
        toast.success("Dealer updated succesfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setSubmitting(false)
        setModal(false)
      })
  }

  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Agency Name",
        accessor: "agencyName",
      },
      {
        Header: "Dealer Name",
        accessor: "fullName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={6}>
                <Link to="#" onClick={e => togglemodal1(row.original)}>
                  View Details
                </Link>
                {/* <a href="">View Details</a> */}
              </Col>
              <Col xl={6}>
                <Link to="#" onClick={() => togglemodal(row.original)}>
                  Edit Profile
                </Link>
                {/* <a href="">View Details</a> */}
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  const togglemodal = row => {
    setModal(!modal)
    setRowData(row)
  }

  let data = []

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(dealerListGet({}, {}))
  }, [])

  const dealerListData = useSelector(state => state.DelearProductGet)

  if (dealerListData && dealerListData.delearDetails) {
    data = dealerListData.delearDetails.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Dealers"

  const [details, setDetails] = useState(false)
  const [objData, setobjData] = useState({})

  const detailstoggle = () => {
    setDetails(!details)
  }
  const togglemodal1 = row => {
    // alert(row)
    let fdata = {
      agencyName: row.agencyName,
      fullName: row.fullName,
      contactNumber: row.contactNumber,
      city: row.city,
      email: row.email,
      totalSoldProductbyDealer: row.totalSoldProductbyDealer,
      totalTagProductbyDealer: row.totalTagProductbyDealer,
      totalTagDemoProductbyDealer: row.totalTagDemoProductbyDealer,
    }

    setDetails(!details)
    // const foundData = data && data.find(item => item.id === row);
    setobjData(fdata)
  }

  return (
    <Loadder active={dealerListData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Dealers" />

          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={dealerListData.delearDetails.count}
            next={dealerListData.delearDetails.next}
            previous={dealerListData.delearDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />

          <Modal
            isOpen={details}
            role="dialog"
            size="xl"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={detailstoggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={detailstoggle}>Dealer Details</ModalHeader>
              <ModalBody>
                <SimpleBar style={{ maxHeight: "376px" }}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <Row>
                        <Col md={3}>
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Dealer Agency Name</b>
                                <br></br>
                                <p>{objData && objData.agencyName}</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Dealer Name</b>
                                <br></br>
                                <p>{objData && objData.fullName}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Dealer Email</b>
                                <br></br>
                                <p>{objData && objData.email}</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md={3}></Col>

                        <Col md={3}>
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Dealer Number</b>
                                <br></br>
                                <p>{objData && objData.contactNumber}</p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Dealer City</b>
                                <br></br>
                                <p>{objData && objData.city}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </SimpleBar>
              </ModalBody>

              <ModalBody>
                <Row>
                  <Col md={4}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Total Product Sold by Dealer
                            </p>
                            <h4 className="mb-0">
                              {objData && objData.totalSoldProductbyDealer}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " +
                                  "bx-purchase-tag-alt" +
                                  " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Total Products Tagged to Dealer
                            </p>
                            <h4 className="mb-0">
                              {objData && objData.totalTagProductbyDealer}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " +
                                  "bx-purchase-tag-alt" +
                                  " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Total Demo Products Assigned to Dealer
                            </p>
                            <h4 className="mb-0">
                              {objData && objData.totalTagDemoProductbyDealer}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " +
                                  "bx-purchase-tag-alt" +
                                  " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </div>
          </Modal>

          {/* Edit Profile Modal */}
          <Modal
            isOpen={modal}
            role="dialog"
            size="lg"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={togglemodal}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="modal-content">
                    <ModalHeader toggle={togglemodal}>Edit Profile</ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="agencyName">Agency Name</Label>
                            <Field
                              type="text"
                              name="agencyName"
                              id="agencyName"
                              className="form-control"
                              placeholder="Agency Name"
                              as={Input}
                            />
                            <ErrorMessage
                              name="agencyName"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="firstName">Dealer First Name</Label>
                            <Field
                              type="text"
                              name="firstName"
                              id="firstName"
                              className="form-control"
                              placeholder="Dealer First Name"
                              as={Input}
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="lastName">Dealer Last Name</Label>
                            <Field
                              type="text"
                              name="lastName"
                              id="lastName"
                              className="form-control"
                              placeholder="Dealer Last Name"
                              as={Input}
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactNumber">
                              Contact Number
                            </Label>
                            <Field
                              type="number"
                              name="contactNumber"
                              id="contactNumber"
                              className="form-control"
                              placeholder="Contact Number"
                              as={Input}
                            />
                            <ErrorMessage
                              name="contactNumber"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="email">Contact Email</Label>
                            <Field
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="Email"
                              as={Input}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="city">City</Label>
                            <Field
                              type="text"
                              name="city"
                              id="city"
                              className="form-control"
                              placeholder="City"
                              as={Input}
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter
                      style={{ display: "flex", justifyContent: "left" }}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                      <Button
                        type="button"
                        onClick={togglemodal}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
