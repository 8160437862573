// src/components/filter.
import React, { useState,useMemo } from "react";
import PropTypes from 'prop-types';
import SimpleBar from "simplebar-react";
import Loadder from "components/Loadder/Loadder";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './manufacturedproductdealertablecontainer';
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenExpiration } from "config/getTokenExpirationTime";
import { adminEarningMPDataGet } from "store/actions";
import { useEffect } from "react";

  // const reports = [
  //   { title: "Total Number of Equipment Product sold", iconClass: "bx-purchase-tag-alt", description: "150" },

  // ];

  function extractIdAndPName(item) {
    return {
       
        customername: `${item.customer.firstName} ${item.customer.lastName}`,
        customercontactnumber:item.customer.contactNumber,
        dealername:`${item.customer.dealerdetail && item.customer.dealerdetail.firstName} ${item.customer.dealerdetail && item.customer.dealerdetail.lastName}`,
        dealercontactnumber:item.customer.dealerdetail && item.customer.dealerdetail.contactNumber,
        productname:item.product.mpname,
        city:item.customer.city,
        mpnumber:item.product.mpnumber,
        mpquantity:item.product.mpquantity,

        agencyName:item.customer && item.customer.dealerdetail && item.customer.dealerdetail.agencyName,
        dealercity:item.customer && item.customer.dealerdetail && item.customer.dealerdetail.city,

        pname:item.RPDproduct.pname,
        pnumber:item.RPDproduct.pnumber,
        pquantity:item.RPDproduct.pquantity,
        total_sold:item.total_sold
    };
  }

function DatatableTablesManufacture() {
    const columns = useMemo(
        () => [
            // {
            //     Header: '#',
            //     filterable: false,
            //     Cell: () => {
            //         return <input type="checkbox" className="form-check-input" />;
            //       }
            // },
            // {
            //     Header: 'Sr.No',
            //     accessor: 'srno',
            // },
            {
              Header: 'Customer Name',
              accessor: 'customername'
          },
          {
              Header: 'Customer Contact Number',
              accessor: 'customercontactnumber'
          },
          {
              Header: 'Dealer Name',
              accessor: 'dealername'
          },
          {
              Header: 'Dealer Contact Number',
              accessor: 'dealercontactnumber'
          },
          {
              Header: 'Product Name',
              accessor: 'productname'
          },
          {
            Header: 'Product Number',
            accessor: 'mpnumber'
          },
          {
            Header: 'No of Product Sold',
            accessor: 'total_sold'
          },
            {
                Header: ' ',
                Cell: (cellProps) => {
                const row = cellProps.row
                  return (
                    <Row>
                        <Col xl={12}>
                        <Link to="#" onClick={e => togglemodal1(row.original)}>View Details</Link>
                        </Col>
                    </Row>
                  );
                }
              },
        ],
        []
    );

    const dispatch = useDispatch();
    useEffect(()=>{
        
        checkTokenExpiration()
        dispatch(adminEarningMPDataGet({"userType":"DC"}, {}))
    },[])
    let data =[]

    const adminearningMPdata =useSelector(state => state.adminEarningGet.adminEarningDetails)
    console.log("adminearningMPdata=>",adminearningMPdata)
    if(adminearningMPdata && adminearningMPdata.results){
        console.log(adminearningMPdata.results.data,"=>")
        // alert(adminearningMPdata.results.data)
        data = adminearningMPdata.results.data.map(extractIdAndPName);   
    } 
    //meta title
    document.title = "Earnings";
    const [objData,setobjData]=useState({})
    const [modal, setModal] = useState(false);
    const togglemodal = () => {
        setModal(!modal);
      };

      const togglemodal1 = (row) => {
        // alert(row)
        let fdata ={
        "customername": row.customername,
        "city":row.city,
        "customercontactnumber":row.customercontactnumber,
        "productname":row.productname,
        "mpnumber":row.mpnumber,
        "mpquantity":row.mpquantity,
        "agencyName":row.agencyName && row.agencyName,
        "dealername":row.dealername,
        "dealercontactnumber":row.dealercontactnumber,
        "dealercity":row.dealercity,
        "pname":row.pname,
        "pnumber":row.pnumber,
        "pquantity":row.pquantity,
        "total_sold":row.total_sold
        }
    
        
        setModal(!modal);
        // const foundData = data && data.find(item => item.id === row);
        setobjData(fdata)
        console.log("foundData=>",fdata)
        };

    return (
        <Loadder active={adminearningMPdata.loading}>
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Earnings" />
        
                {/* <Table columns={columns} data={data} /> */}
                <TableContainer
                    count={adminearningMPdata.count}
                    next={adminearningMPdata.next}
                    previous={adminearningMPdata.previous}
                    columns={columns}
                    data={data}
                    result={adminearningMPdata.results}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>

            <Modal
                    isOpen={modal}
                    role="dialog"
                    size="md"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={togglemodal}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={togglemodal}>
                       Customer Details
                      </ModalHeader>
                        <ModalBody>
                        <SimpleBar style={{ maxHeight: "376px"}}>
                            <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Customer Name</b><br></br>
                                                    <p>{objData && objData.customername}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Customer City</b><br></br>
                                                    <p>{objData && objData.city}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                                <br/>

                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Customer Number</b><br></br>
                                                        <p>{objData && objData.customercontactnumber}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row>                          
                                </li>
                            </ul>
                         </SimpleBar>
                        </ModalBody>

                      <ModalHeader>
                        Customer Equipment Product Details
                      </ModalHeader>
                    
                      <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product Name</b><br></br>
                                                        <p>{objData && objData.pname}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Number</b><br></br>
                                                    <p>{objData && objData.pnumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> 
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>

                      <ModalHeader>
                        Dealer Details
                      </ModalHeader>

                      <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>DealerAgency Name</b><br></br>
                                                        <p>{objData && objData.agencyName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Dealer Name</b><br></br>
                                                        <p>{objData && objData.dealername}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row> 
                                <br/>
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Dealer Number</b><br></br>
                                                    <p>{objData && objData.dealercontactnumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Dealer City</b><br></br>
                                                    <p>{objData && objData.dealercity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>  
                    
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>

                      <ModalHeader>
                        Consumables Product Details
                      </ModalHeader>

                      <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product Name</b><br></br>
                                                        <p>{objData && objData.productname}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row> 
                                <br/>
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Number</b><br></br>
                                                    <p>{objData && objData.mpnumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    {/* <Col md={6}>
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Quantity</b><br></br>
                                                    <p>{objData && objData.mpquantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col> */}
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Number of Product Sold</b><br></br>
                                                        <p>{objData && objData.total_sold}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row>  
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>

                    </div>
            </Modal>
        </div>
        </Loadder>
    );
}
DatatableTablesManufacture.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTablesManufacture;