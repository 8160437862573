import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{new Date().getFullYear()} © SonaStar.</span>
              <span className="d-flex justify-content-end align-items-center footerView">
                Powered By&nbsp;
                <a
                  href="https://digiglobeads.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="p-0"
                >
                  digiglobeads
                </a>
              </span>
            </Col>
            {/* <Col md={6}>
            <div className="text-sm-end d-none d-sm-block">
                Design & Develop by SonaStar Team
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
