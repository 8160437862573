// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./activatedproducttablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerActivatedProductListGet } from "store/actions"
import moment from "moment"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  let AMCStartDate = moment(new Date(item.AMCStartDate)).format("DD MMM Y")
  let AMCEndDate = moment(new Date(item.AMCEndDate)).format("DD MMM Y")
  return {
    id: item.id,
    pname: item.pname,
    pnumber: item.pnumber,
    status: item.status,
    dealercustomerstatus: item.dealercustomerstatus,
    customer_number: item.customer_number,
    firstName: item.firstName,
    lastName: item.lastName,
    city: item.city,
    isAMCStart: item.isAMCStart,
    AMCStartDate: AMCStartDate,
    AMCEndDate: AMCEndDate,
    remainingDays: item.remainingDays,
    statustext: item.statustext,
    dealercustomerstatustext: item.dealercustomerstatustext,
    serialNumbers: item.serialNumbers,
  }
}
function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Days Remaning for Next AMC",
        accessor: "remainingDays",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View details
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(customerActivatedProductListGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customeractivatedproductlist) {
    data =
      customerDatas.customeractivatedproductlist.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Product Registration"

  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }
  const [obj, setobj] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Products Registrations" />

        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customeractivatedproductlist &&
            customerDatas.customeractivatedproductlist.count
          }
          next={
            customerDatas.customeractivatedproductlist &&
            customerDatas.customeractivatedproductlist.next
          }
          previous={
            customerDatas.customeractivatedproductlist &&
            customerDatas.customeractivatedproductlist.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Name</b>
                            <br></br>
                            <p>
                              {obj && obj.firstName} {obj && obj.lastName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer City</b>
                            <br></br>
                            <p>{obj && obj.city}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Number</b>
                            <br></br>
                            <p>{obj && obj.customer_number}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
          <ModalHeader>Customer Product Details</ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{obj && obj.pname}</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Registration Date</b>
                            <br></br>
                            <p>{obj && obj.AMCStartDate}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product AMC Remaning Days</b>
                            <br></br>
                            <p>{obj && obj.remainingDays}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
