import React from "react"
import { Navigate, useLocation } from "react-router-dom"

const Authmiddleware = props => {
  const getUserData = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : null
  const location = useLocation()

  if (!getUserData) {
    return (
      <Navigate
        to={{ pathname: "/pre-login", state: { from: props.location } }}
      />
    )
  }
  if (location.pathname !== "/") {
    if (!location.pathname.startsWith(`/${getUserData.userType}`)) {
      alert(
        "You can't access this page contact Sonastar customer care for more details."
      )
      return null
    }
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
