import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"

import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"

import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useDispatch } from "react-redux"
import {
  customerVi,
  customerViewOrdersListGet,
  customerViewOrdersListGetewOrdersListGet,
} from "store/actions"
import { useEffect } from "react"
import { useState } from "react"
import {
  CUSTOMERMANUFACTUREPRODUCTNAMELIST,
  CUSTOMERRESELLINGPRODUCTNAMELIST,
  HOSTNAME,
  VIEWORDERS,
} from "config/config"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const [isbtnDisable, setisbtnDisable] = useState(false)
  const scrollRef = useRef()

  const scrollToLeft = () => {
    const element = scrollRef.current
    element.scrollBy({
      left: -window.innerWidth + 100, // Adjust the value to scroll by a different distance
      behavior: "smooth",
    })
  }

  const scrollToRight = () => {
    const element = scrollRef.current
    // console.log("🚀 ~ file: viewtaggedproducttablecontainer.js:200 ~ scrollToRight ~ element:", element,element.scrollLeft,element.offsetWidth,element.scrollRight)
    element.scrollBy({
      left: window.innerWidth - 100, // Adjust the value to scroll by a different distance
      behavior: "smooth",
    })
  }
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  // const onChangeInInput = event => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };

  const dispatch = useDispatch()
  const [query, setquery] = useState("")
  const SearchByMPname = e => {
    if (e.target.value) {
      setquery(e.target.value)
      dispatch(
        customerViewOrdersListGet({ status: status, query: e.target.value }, {})
      )
    } else {
      setquery("")
      dispatch(customerViewOrdersListGet({ status: status }, {}))
    }
  }

  const onclickForPage = (url, number) => {
    // alert(number)
    setcurrentPage(number)
    dispatch(customerViewOrdersListGet({ url: url }, {}))
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    setisbtnDisable(false)
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${VIEWORDERS}?query=${query}&page=${event.target.value}&status=${status === "3" ? "" : status
      }`
    setcurrentPage(event.target.value)
    dispatch(customerViewOrdersListGet({ url: url }, {}))
  }
  const [status, setstatus] = useState("3")
  const setAndCallApi = e => {
    setstatus(e.target.value)
    if (e.target.value === "3") {
      setstatus("")
    }
    dispatch(customerViewOrdersListGet({ status: e.target.value, query: query }, {}))
  }
  const [obj, setobj] = useState({})
  const [buttonClicked, setButtonClicked] = useState(false)
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      mid: "",
      mqty: "",
      pimage: "",
    },
    validationSchema: Yup.object({
      mid: Yup.string().required("Please Enter mid"),
      // mqty: Yup.string().required("Please Enter Quantity"),
      mqty: Yup.string()
        .matches(/^[1-9][0-9]*$/, "Quantity must be a positive non-zero number")
        .required("Please Enter Quantity"),
      pimage: Yup.mixed().required("Please Upload an Image"),
    }),
    onSubmit: (values, { resetForm }) => {
      setisbtnDisable(true)
      setisbtnDisable(true)
      if (!buttonClicked) {
        setButtonClicked(true)
        if (!isbtnDisable) {
          let data = new FormData()
          data.append("mqty", values.mqty)
          data.append(
            "pimage",
            document.querySelector('input[name="pimage"]').files[0]
          )
          data.append("mid", values.mid)

          let url = `${HOSTNAME}${VIEWORDERS}`
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: url,
            headers: {
              Authorization: `${getUserData.userData.token}`,
            },
            data: data,
          }

          axios
            .request(config)
            .then(response => {
              dispatch(customerViewOrdersListGet({}, {}))
              setModal(!modal)
              resetForm()
              toast.success("Data added!", {
                position: toast.POSITION.TOP_RIGHT,
              })
              setisbtnDisable(false)
            })
            .catch(error => {
              toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            })
          setisbtnDisable(false)
        }
      }
    },
  })

  const [modal, setModal] = useState(false)
  const [getResellingProductList, setgetResellingProductList] = useState([])
  const [getManufactureProductList, setgetManufactureProductList] = useState([])
  const [equipProduct, setEquipProduct] = useState([])
  const [consumableProduct, setConsumableProduct] = useState([])
  const [consumableProductId, setConsumableProductId] = useState(null)
  const [qty, setQty] = useState("")
  const [image, setImage] = useState(null)

  const togglemodal = () => {
    setModal(!modal)
    setConsumableProduct([])
    setQty("")
    // setobj({})
  }

  const togglemodal1 = () => {
    setButtonClicked(false)
    setModal(!modal)
    // getResellingProductList
    let url = `${HOSTNAME}${CUSTOMERRESELLINGPRODUCTNAMELIST}`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        setgetResellingProductList(response.data.data)
      })
      .catch(error => { })
  }

  const setmanufactureData = val => {
    setgetManufactureProductList([])
    let url = `${HOSTNAME}${CUSTOMERMANUFACTUREPRODUCTNAMELIST}?rid=${val}`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        setgetManufactureProductList(response.data.data)
      })
      .catch(error => { })
  }

  const getEquipProduct = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/main-productname/list/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios.request(config).then(response => {
      setEquipProduct(response?.data?.data)
    })
  }

  const getConsumableProduct = id => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/productname/list/${id}/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios.request(config).then(response => {
      setConsumableProduct(response?.data?.data)
    })
  }

  useEffect(() => {
    getEquipProduct()
  }, [])

  const onSubmit = () => {
    let data = new FormData()

    data.append("id", consumableProductId)
    data.append("pimage", image)
    data.append("qty", qty)

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/customer-request-inventory-product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }
    axios
      .request(config)
      .then(response => {
        toast.success("Order placed successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        setModal(false)
        setQty("")
        dispatch(customerViewOrdersListGet({}, {}))
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4}>
              <div className="col-sm-auto mb-1">
                <Label className="visually-hidden" htmlFor="autoSizingInput">
                  Search
                </Label>
                <Input
                  type="text"
                  onChange={e => {
                    SearchByMPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name"
                />
              </div>
            </Col>
            <Col xl={2}>
              <div className="col-sm-auto mb-1">
                <select
                  onChange={e => setAndCallApi(e)}
                  className="form-select"
                >
                  <option value="" disabled selected hidden>
                    Status
                  </option>
                  <option value="0">Pending</option>
                  <option value="1">
                    <a>Accepted</a>
                  </option>
                  <option value="2">
                    <a>Rejected</a>
                  </option>
                  <option value="3">
                    <a>All</a>
                  </option>
                </select>
              </div>
            </Col>

            <Col xl={2}>
              <div className="col-sm-auto">
                <button
                  onClick={e => togglemodal1()}
                  type="button"
                  className="btn btn-primary"
                >
                  Place New Order
                </button>
              </div>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>
      {/* <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row> */}

      <div
        className="table-responsive react-table producttable"
        ref={scrollRef}
      >
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      <div className="mobile-view">
        <div className="d-flex justify-content-between my-2 ">
          <a href="#" className="previous round" onClick={scrollToLeft}>
            &#8249;
          </a>
          <a href="#" className="next round " onClick={scrollToRight}>
            &#8250;
          </a>
        </div>
      </div>
      <Row
        className="justify-content-md-end justify-content-center align-items-center mobile-pagination"
        style={{ marginBottom: "20px" }}
      >
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${VIEWORDERS}?query=${query}&page=${1}&status=${status === "3" ? "" : status
                  }`,
                  1
                )
              }
              disabled={isPast}
              className="paginate-left-button"
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${VIEWORDERS}?query=${query}&page=${currentPage - 1}&status=${status === "3" ? "" : status
                  }`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto middleView">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1 nextView">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${VIEWORDERS}?query=${query}&page=${currentPage + 1}&status=${status === "3" ? "" : status
                  }`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${VIEWORDERS}?page=${Math.ceil(
                    count / customPageSize
                  )}&status=${status === "3" ? "" : status}`,
                  Math.ceil(count / customPageSize)
                )
              }
              className="paginate-right-button"
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>

        <Modal
          isOpen={modal}
          role="dialog"
          size="lg"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <Form
              encType="multipart/form-data"
              onSubmit={validation.handleSubmit}
            >
              <ModalHeader toggle={togglemodal}>Place New Order</ModalHeader>

              <ModalBody>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Select Equipment Product
                      </Label>
                      <div className="col-sm-auto">
                        <select
                          name="rid"
                          // value={validation.values.rid || ""}
                          onChange={e => getConsumableProduct(e.target.value)}
                          onBlur={validation.handleBlur}
                          className="form-select"
                        >
                          <option value="" disabled selected hidden>
                            Select product
                          </option>
                          {equipProduct.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.pname}
                            </option>
                          ))}
                        </select>
                        {validation.touched.rid && validation.errors.rid ? (
                          <FormFeedback type="invalid">
                            {validation.errors.rid}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Select Consumable Product
                      </Label>
                      <div className="col-sm-auto">
                        <select
                          name="mid"
                          // value={validation.values.mid || ""}
                          onChange={e => setConsumableProductId(e.target.value)}
                          onBlur={validation.handleBlur}
                          className="form-select"
                          disabled={consumableProduct.length <= 0}
                        >
                          <option value="" disabled selected hidden>
                            Select product
                          </option>
                          {consumableProduct.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.mpname}
                            </option>
                          ))}
                        </select>
                        {validation.touched.mid && validation.errors.mid ? (
                          <FormFeedback type="invalid">
                            {validation.errors.mid}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Consumable Product Quantity
                      </Label>
                      <Input
                        placeholder="Quantity"
                        type="number"
                        className="form-control"
                        value={qty}
                        onChange={e => {
                          const value = e.target.value
                          if (value >= 0 || value === "") {
                            setQty(value)
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Upload Image <span style={{ color: "red" }}></span>
                        <span style={{ color: "red" }}>
                          (Max upload 1 MB)
                        </span>{" "}
                      </Label>
                      <Input
                        name="pimage"
                        placeholder="pimage"
                        type="file"
                        className="form-control"
                        accept=".jpeg, .jpg, .png"
                        onChange={e => setImage(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
                <Button
                  disabled={!consumableProductId || qty <= 0}
                  onClick={onSubmit}
                  type="submit"
                  color="primary"
                >
                  Submit
                </Button>

                <Button type="button" onClick={togglemodal} color="secondary">
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
