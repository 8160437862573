// src/components/filter.
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./resellingproductstablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { checkTokenExpiration } from "config/getTokenExpirationTime"
import { delearResellingEarningGet } from "store/actions"
import { useEffect } from "react"
import moment from "moment"

// const reports = [
//   { title: "Total Number of Equipment Product sold", iconClass: "bx-purchase-tag-alt", description: "150" },

// ];
function extractIdAndPName(item) {
  let soldDate
  soldDate = moment(new Date(item.soldDate)).format("DD MMM Y")
  let AMCStartDate = moment(new Date(item.AMCStartDate)).format("DD MMM Y")
  let AMCEndDate = moment(new Date(item.AMCEndDate)).format("DD MMM Y")
  return {
    id: item.id,
    productname: item.product.pname,
    customername: `${item.customer.firstName} ${item.customer.lastName}`,
    noofproductsold: item.total_sold,

    product: item.product,
    customer: item.customer,
    soldto: item.soldto,
    soldDate: soldDate,
    pnumber: item.product && item.product.pnumber,
    // RPD_total_sold:item.RPD_total_sold
    AMCStartDate: AMCStartDate,
    AMCEndDate: AMCEndDate,
    MPTotal: item.MPTotal,
  }
}
function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "productname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "product.serialNumbers",
      },
      // {
      //     Header: 'Product Sold Date',
      //     accessor: 'soldDate',
      // },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      // {
      //     Header: 'No of Product Sold',
      //     accessor: 'noofproductsold',
      // },
      {
        Header: "Action",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View Details
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  const dispatch = useDispatch()
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    checkTokenExpiration()
    dispatch(delearResellingEarningGet({}, {}))
  }, [dispatch])

  let data = []
  const dealerresellingearning = useSelector(
    state => state.DelearProductGet.dealerresellingearning
  )
  console.log("dealerresellingearning=>---------", dealerresellingearning)
  if (dealerresellingearning && dealerresellingearning.results) {
    console.log(dealerresellingearning.results.data, "=>")
    data = dealerresellingearning.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Earnings"

  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }
  const [objData, setobjData] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobjData(row)
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Earnings" />

        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={dealerresellingearning.count}
          next={dealerresellingearning.next}
          previous={dealerresellingearning.previous}
          columns={columns}
          data={data}
          result={dealerresellingearning.results}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Name</b>
                            <br></br>
                            <p>{objData && objData.customername}</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer City</b>
                            <br></br>
                            <p>{objData.customer && objData.customer.city}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Number</b>
                            <br></br>
                            <p>
                              {objData.customer &&
                                objData.customer.contactNumber}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
          <ModalHeader>Customer Product Details</ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{objData.product && objData.product.pname}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product AMC Start Date</b>
                            <br></br>
                            <p>{objData && objData.AMCStartDate}</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product AMC End Date</b>
                            <br></br>
                            <p>{objData && objData.AMCEndDate}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  {/* <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Number of Product Sold</b><br></br>
                                                        <p>{objData && objData.MPTotal}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row>  */}
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>

          {/* <ModalHeader>Consumables Product Details</ModalHeader>
          <ModalBody>
            <SimpleBar>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={12}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      {objData.product &&
                        objData.product.mproduct.map(item => (
                          <div key={item.id} className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Product Name</b>
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.mpname}
                                  </span>
                                  <span style={{ color: "#777" }}> X </span>
                                  <span style={{ color: "#555" }}>
                                    {" "}
                                    {item.mpquantity}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody> */}
        </div>
      </Modal>
    </div>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
