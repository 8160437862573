import {
  ADMINAMCCUSTOMERLIST_GET,
  ADMINAMCCUSTOMERLIST_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  adminamccustomerDetails: "",
  
}

const adminamccustomerGet = (state = initialState, action) => {
  switch (action.type) {
    case ADMINAMCCUSTOMERLIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADMINAMCCUSTOMERLIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        adminamccustomerDetails:action.payload,
      }
      break

    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default adminamccustomerGet


