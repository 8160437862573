import {
  ADMINFINANCIAL_DATA_GET,
  ADMINFINANCIAL_DATA_SUCCESS
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  adminFinancialDetails: "",
}

const adminFinancialGet = (state = initialState, action) => {
  switch (action.type) {
    case ADMINFINANCIAL_DATA_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADMINFINANCIAL_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        adminFinancialDetails:action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default adminFinancialGet


