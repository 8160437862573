import {
  TAGGED_PRODUCTCUSTOMER_GET,
  TAGGED_PRODUCTCUSTOMER_SUCCESS,
  CUSTOMER_VERIFY_PRODUCT_LIST_GET,
  CUSTOMER_VERIFY_PRODUCT_LIST_SUCCESS,
  CUSTOMER_ACTIVATED_PRODUCT_LIST_GET,
  CUSTOMER_ACTIVATED_PRODUCT_LIST_SUCCESS,
  CUSTOMER_BUYBACK_PRODUCT_LIST_GET,
  CUSTOMER_BUYBACK_PRODUCT_LIST_SUCCESS,
  CUSTOMER_VIEWORDERS_LIST_GET,
  CUSTOMER_VIEWORDERS_LIST_SUCCESS,
  CUSTOMER_NEWPRODUCTREQUEST_LIST_SUCCESS,
  CUSTOMER_NEWPRODUCTREQUEST_LIST_GET,
  CUSTOMER_SPEND_LIST_GET,
  CUSTOMER_SPEND_LIST_SUCCESS,
  CUSTOMER_SERVICES_LIST_GET,
  CUSTOMER_SERVICES_LIST_SUCCESS,
  CUSTOMER_CENTRIC_LIST_GET,
  CUSTOMER_CENTRIC_LIST_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  customertaggedproductDetails: "",
  customerverifyproductlist: "",
  customeractivatedproductlist: "",
  customerbuybackproductlist: "",
  customervieworderslist: "",
  customernewproductrequestlist: "",
  customerspendlist: "",
  customerserviceslist: "",
  customercentriclist: "",
  customerbuybackList: "",
  customervieworderslist1: "",
}

const customerProductGet = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_CENTRIC_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_CENTRIC_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customercentriclist: action.payload,
      }
      break
    // ------

    case CUSTOMER_BUYBACK_PRODUCT_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_BUYBACK_PRODUCT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerbuybackList: action.payload,
      }
      break
    // ------

    case CUSTOMER_SERVICES_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_SERVICES_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerserviceslist: action.payload,
      }
      break
    // ------

    case CUSTOMER_SPEND_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_SPEND_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerspendlist: action.payload,
      }
      break
    // ------

    case CUSTOMER_NEWPRODUCTREQUEST_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_NEWPRODUCTREQUEST_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customernewproductrequestlist: action.payload,
      }
      break
    // ------

    case CUSTOMER_VIEWORDERS_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_VIEWORDERS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customervieworderslist1: action.payload,
      }
      break
    // ------

    case CUSTOMER_ACTIVATED_PRODUCT_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_ACTIVATED_PRODUCT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customeractivatedproductlist: action.payload,
      }
      break
    // ------

    case CUSTOMER_VERIFY_PRODUCT_LIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case CUSTOMER_VERIFY_PRODUCT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerverifyproductlist: action.payload,
      }
      break
    // ------

    case TAGGED_PRODUCTCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case TAGGED_PRODUCTCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customertaggedproductDetails: action.payload,
      }
      break
    // ------
    default:
      state = { ...state }
      break
  }
  return state
}

export default customerProductGet
