import {
  ADMINBUYBACK_DATA_GET,
  ADMINBUYBACK_DATA_SUCCESS
} from "./actionTypes"

export const adminBuyBackDataGet = (query, history) => { 
  // alert("action")
  return {
    type: ADMINBUYBACK_DATA_GET,
    payload: { query, history },
  }
}

export const adminBuyBackDataSuccess = data => {
  return {
    type: ADMINBUYBACK_DATA_SUCCESS,
    payload: data,
  }
}