import {
  RESELLINGPRODUCT_GET,
  RESELLINGPRODUCT_SUCCESS,
  RESELLINGPRODUCT_UPLOAD_POST,
  RESELLINGPRODUCT_ADD,
  RESELLINGPRODUCT_ADD_SUCCESS,
  RESELLINGPRODUCT_UPLOAD_SUCCESS,
  TAGLISTOFCUSTOMER_GET,
  TAGLISTOFCUSTOMER_SUCCESS,
  TAGLISTOFDEALER_GET,
  TAGLISTOFDEALER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,

  RESELLINGTAGTODEALER_GET,
  RESELLINGTAGTODEALER_SUCCESS,

  // TAGTODEALER_GET,
  // TAGTODEALER_SUCCESS,
  TAGCUSTOMER_GET,
  TAGCUSTOMER_SUCCESS,
  VIEWRESELLINGPRODUCT_GET,
  VIEWRESELLINGPRODUCT_SUCCESS,

//******************************
//          DEALER
// *****************************

  TAGPRODUCTFROMADMIN_GET,
  TAGPRODUCTFROMADMIN_SUCCESS,
  DEALER_RESELLINGPRODUCTLIST_GET,
  DEALER_RESELLINGPRODUCTLIST_SUCCESS,
  DELEAR_TAGTOCUSTOMER_GET,
  DELEAR_TAGTOCUSTOMER_SUCCESS,
  PARENT_RESELLINGPRODUCT_GET,
  PARENT_RESELLINGPRODUCT_SUCCESS,
  PARENT_RESELLINGPRODUCT_UPLOAD_POST,
  NEWTAGLISTOFDEALER_SUCCESS,
  NEWTAGLISTOFDEALER_GET,

} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  resellingProductDetails: "",
  parentresellingProductDetails:"",
  newresellingProductDetails:""
}

const resellingProductGet = (state = initialState, action) => {
  switch (action.type) {
    case PARENT_RESELLINGPRODUCT_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case PARENT_RESELLINGPRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        parentresellingProductDetails:action.payload,
      }
      break
    //--------
    case RESELLINGPRODUCT_ADD:
      state = {
        ...state,
        loading: true,
      }
      break
    case RESELLINGPRODUCT_ADD_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        resellingProductDetails:action.payload,
      }
      break

    case RESELLINGPRODUCT_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case RESELLINGPRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        resellingProductDetails:action.payload,
      }
      break
      // TAG LIST OF CUSTOMER
      case TAGLISTOFCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case TAGLISTOFCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        resellingProductDetails:action.payload,
      }
      break
      // TAG LIST OF DEALER
      case TAGLISTOFDEALER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
      case TAGLISTOFDEALER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        resellingProductDetails:action.payload,
      }
      break

      // New 
      case NEWTAGLISTOFDEALER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
      case NEWTAGLISTOFDEALER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        newresellingProductDetails:action.payload,
      }
      break

        // TAG To DEALER
        case RESELLINGTAGTODEALER_GET:
          state = {
            ...state,
            loading: true,
          }
          break
        case RESELLINGTAGTODEALER_SUCCESS:
          state = {
            ...state,
            loading: false,
            isUserLogout: false,
            resellingProductDetails:action.payload,
          }
          break

       // TAG To CUSTOMER
       case TAGCUSTOMER_GET:
        state = {
          ...state,
          loading: true,
        }
        break
      case TAGCUSTOMER_SUCCESS:
        state = {
          ...state,
          loading: false,
          isUserLogout: false,
          resellingProductDetails:action.payload,
        }
        break
      // View Equipment Product 
      case VIEWRESELLINGPRODUCT_GET:
          state = {
            ...state,
            loading: true,
          }
          break
        case VIEWRESELLINGPRODUCT_SUCCESS:
          state = {
            ...state,
            loading: false,
            isUserLogout: false,
            resellingProductDetails:action.payload,
          }
          break

    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break

    //-----------Upload
    case PARENT_RESELLINGPRODUCT_UPLOAD_POST:
      state = {
        ...state,
        loading: true,
      }
      break

    case RESELLINGPRODUCT_UPLOAD_POST:
      state = {
        ...state,
        loading: true,
      }
      break


// ************ DEALER ******************
// RESELLING PRODUCT ---->TAG PRODUCT FROM ADMIN

    case TAGPRODUCTFROMADMIN_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case TAGPRODUCTFROMADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        resellingProductDetails:action.payload,
      }
      break

// ---- Equipment Product list-------

    case DEALER_RESELLINGPRODUCTLIST_GET:
          state = {
            ...state,
            loading: true,
          }
          break
        case DEALER_RESELLINGPRODUCTLIST_SUCCESS:
          state = {
            ...state,
            loading: false,
            isUserLogout: false,
            resellingProductDetails:action.payload,
          }
          break

// ------- TAG TO CUSTOMER---------

        case DELEAR_TAGTOCUSTOMER_GET:
                  state = {
            ...state,
            loading: true,
          }
          break
        case DELEAR_TAGTOCUSTOMER_SUCCESS:
          state = {
            ...state,
            loading: false,
            isUserLogout: false,
            resellingProductDetails:action.payload,
          }
          break


    default:
      state = { ...state }
      break
  }
  return state
}

export default resellingProductGet


