// Direct Customer
export const NEWCUSTOMER_GET="NEWCUSTOMER_GET"
export const NEWCUSTOMER_SUCCESS="NEWCUSTOMER_SUCCESS"

export const NEWCUSTOMER_UPLOAD_POST="NEWCUSTOMER_UPLOAD_POST"
export const NEWCUSTOMER_UPLOAD_SUCCESS="NEWCUSTOMER_UPLOAD_SUCCESS"

//  Dealer`s Customer

export const EXISTINGCUSTOMER_GET="EXISTINGCUSTOMER_GET"
export const EXISTINGCUSTOMER_SUCCESS="EXISTINGCUSTOMER_SUCCESS"

export const EXISTINGCUSTOMER_UPLOAD_POST="EXISTINGCUSTOMER_UPLOAD_POST"
export const EXISTINGCUSTOMER_UPLOAD_SUCCESS="EXISTINGCUSTOMER_UPLOAD_SUCCESS"

//  NEW ORDER FROM CUSTOMER

export const NEWORDERFROMCUSTOMER_GET="NEWORDERFROMCUSTOMER_GET"
export const NEWORDERFROMCUSTOMER_SUCCESS="NEWORDERFROMCUSTOMER_SUCCESS"

//  NEW ORDER FROM DEALER TO CUSTOMER

export const NEWORDERFROMDEALERTOCUSTOMER_GET="NEWORDERFROMDEALERTOCUSTOMER_GET"
export const NEWORDERFROMDEALERTOCUSTOMER_SUCCESS="NEWORDERFROMDEALERTOCUSTOMER_SUCCESS"

// --- Customer---------

export const EXISTINGCUSTOMERLIST_GET="EXISTINGCUSTOMERLIST_GET"
export const EXISTINGCUSTOMERLIST_SUCCESS="EXISTINGCUSTOMERLIST_SUCCESS"

// export const NEWCUSTOMERLIST_GET="NEWCUSTOMERLIST_GET"
// export const NEWCUSTOMERLIST_SUCCESS="NEWCUSTOMERLIST_SUCCESS"

export const PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET="PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET"
export const PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_SUCCESS="PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_SUCCESS"


// Login User 
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

