import {
  DELEARPRODUCT_GET,
  DELEARPRODUCT_SUCCESS,
  DELEAR_UPLOAD_POST,
  DELEAR_UPLOAD_SUCCESS,
  DEALERLIST_GET,
  DEALERLIST_SUCCESS,
  TAGGEDDEALER_GET,
  TAGGEDDEALER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  DEALER_NEW_PRODUCT_REQUEST_GET,
  DEALER_NEW_PRODUCT_REQUEST_SUCCESS,
  DEALER_MANUFACTURE_ORDERS_GET,
  DEALER_MANUFACTURE_ORDERS_SUCCESS,
  DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET,
  DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_SUCCESS,
  DEALER_CUSTOMER_GET,
  DEALER_CUSTOMER_SUCCESS,
  DEALER_SERVICES_GET,
  DEALER_SERVICES_SUCCESS,
  DEALER_BUYBACK_GET,
  DEALER_BUYBACK_SUCCESS,
  DEALER_RESELLINGEARNING_GET,
  DEALER_RESELLINGEARNING_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  delearDetails: "",
  newproductrequestDetails:"",
  manufactureordersDetails:"",
  resellingtagproductcustomerDetails:"",
  dealercustomerdetail : "",
  dealerServices:"",
  dealerbuyback:"",
  dealerresellingearning:""
}

const delearProductGet = (state = initialState, action) => {
  switch (action.type) {
    case DEALER_RESELLINGEARNING_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALER_RESELLINGEARNING_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        dealerresellingearning:action.payload,
      }
      break
    // ------

    case DEALER_BUYBACK_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALER_BUYBACK_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        dealerbuyback:action.payload,
      }
      break
    // ------

    case DEALER_SERVICES_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALER_SERVICES_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        dealerServices:action.payload,
      }
      break
    // ------

    case DEALER_CUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALER_CUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        dealercustomerdetail:action.payload,
      }
      break
    // ------
    case DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        resellingtagproductcustomerDetails:action.payload,
      }
      break
    // ------

    case DEALER_MANUFACTURE_ORDERS_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALER_MANUFACTURE_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        manufactureordersDetails:action.payload,
      }
      break
    // ------
    case DEALER_NEW_PRODUCT_REQUEST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALER_NEW_PRODUCT_REQUEST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        newproductrequestDetails:action.payload,
      }
      break
    // ---
    case DELEARPRODUCT_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELEARPRODUCT_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        delearDetails:action.payload,
      }
      break

// ----- Dealer List-------
    case DEALERLIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case DEALERLIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        delearDetails:action.payload,
      }
      break
// ----- Tagged Dealer-------
    case TAGGEDDEALER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case TAGGEDDEALER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        delearDetails:action.payload,
      }
      break
      
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break

    //-----------
    case DELEAR_UPLOAD_POST:
      state = {
        ...state,
        loading: true,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default delearProductGet


