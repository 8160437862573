import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import axios from "axios"
import SimpleBar from "simplebar-react"

import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "./Table/Table"
import { HOSTNAME } from "config/config"
import Loadder from "components/Loadder/Loadder"

const NewOrderRequestFromCustomer = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [rejectModal, setRejectModal] = useState(false)
  const [rejectReasonModal, setRejectReasonModal] = useState(false)
  const [viewImageModal, setViewImageModal] = useState(false)
  const [productImage, setProductImage] = useState(null)
  const [viewOrderModal, setViewOrdeModal] = useState(false)
  const [reason, setReason] = useState(null)
  const [rejectReason, setRejectReason] = useState("")
  const [rowData, setRowData] = useState(null)
  const [currentPage, setcurrentPage] = useState(1)

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const getDealerRequestProduct = () => {
    setLoading(true)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/customer-request-inventory-product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getDealerRequestProduct()
  }, [])

  const viewRejectReason = row => {
    setRejectReasonModal(!rejectReasonModal)
    setReason(row?.note)
  }

  const viewImage = row => {
    setViewImageModal(!viewImageModal)
    setProductImage(row)
  }

  const viewOrder = row => {
    setViewOrdeModal(!viewOrderModal)
    setRowData(row)
  }

  const toogelRejectModal = row => {
    setRowData(row)
    setRejectModal(!rejectModal)
  }

  const accepted = row => {
    let data = new FormData()
    data.append("tagId", row.id)
    data.append("status", 1)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/accept/tag-inventory-manufacture-product-customer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        getDealerRequestProduct()
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  const reject = () => {
    let data = new FormData()
    data.append("tagId", rowData?.id)
    data.append("status", 2)
    data.append("note", rejectReason)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/accept/tag-inventory-manufacture-product-customer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        getDealerRequestProduct()
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setRejectModal(false)
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer  Name",
        Cell: cellProps => {
          const row = cellProps.row.original.customer

          return (
            <p>
              {row.firstName} {row.lastName}
            </p>
          )
        },
      },
      {
        Header: "Contact Number",
        accessor: "customer.contactNumber",
      },
      {
        Header: "Customer City",
        accessor: "customer.city",
      },
      {
        Header: "Product Name",
        accessor: "RPD.mpname",
      },
      {
        Header: "Product Report Images",
        Cell: cellProps => {
          const row = cellProps.row.original.pimage
          return (
            <Row>
              <Col xl={12}>
                <Link onClick={() => viewImage(row)} href="#">
                  View Image
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link onClick={() => viewOrder(row)} href="#">
                  View Order
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <>
              {row.status === 0 && (
                <div className="d-flex justify-content-between">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      accepted(row)
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => toogelRejectModal(row)}
                    size="sm"
                    color="warning"
                  >
                    Reject
                  </Button>
                </div>
              )}
              {row.status === 1 && (
                <Button size="sm" color="success">
                  Accepted
                </Button>
              )}
              {row.status === 2 && (
                <div className="d-flex justify-content-between align-items-center">
                  <Button size="sm" color="warning">
                    Rejected
                  </Button>
                  <UncontrolledDropdown className="me-2" direction="start">
                    <DropdownToggle size="sm" color="">
                      <i className="bx bx-dots-vertical fs-4"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem onClick={() => viewRejectReason(row)}>
                        View Reason
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </>
          )
        },
      },
    ],
    []
  )

  // Search
  const [query, setquery] = useState("")
  const onSearch = e => {
    setquery(e.target.value)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/customer-request-inventory-product/?query=${e.target.value}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //Pagination function
  const getPaginationData = (url, page) => {
    setcurrentPage(page)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Loadder active={loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Consumable Products" />

          <TableContainer
            count={tableData?.count}
            next={tableData?.next}
            previous={tableData?.previous}
            columns={columns}
            data={tableData?.results?.data || []}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
            removeCheckbox
            currentPage={currentPage}
            onSearch={onSearch}
            goToNextPage={() =>
              getPaginationData(
                `${HOSTNAME}api/customer-request-inventory-product/?query=${query}&page=${currentPage + 1
                }`,
                currentPage + 1
              )
            }
            goToLastPage={() =>
              getPaginationData(
                `${HOSTNAME}api/customer-request-inventory-product/?query=${query}&page=${Math.ceil(
                  tableData?.count / 10
                )}`,
                Math.ceil(tableData?.count / 10)
              )
            }
            goToPreviousPage={() =>
              getPaginationData(
                `${HOSTNAME}api/customer-request-inventory-product/?query=${query}&page=${currentPage - 1
                }`,
                currentPage - 1
              )
            }
            goToFirstPage={() =>
              getPaginationData(
                `${HOSTNAME}api/customer-request-inventory-product/?query=${query}&page=${1}`,
                1
              )
            }
            getInputPaginationData={e =>
              getPaginationData(
                `${HOSTNAME}api/customer-request-inventory-product/?query=${query}&page=${e.target.value}`,
                e.target.value
              )
            }
          />

          {/* View Image Modal */}
          <Modal
            isOpen={viewImageModal}
            role="dialog"
            size="md"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={viewImage}
          >
            <div className="modal-content">
              <ModalHeader toggle={viewImage}></ModalHeader>
              <ModalBody>
                <img src={productImage} className="img-thumbnail" alt="..." />
                <p>
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
              </ModalBody>
            </div>
          </Modal>

          {/* Reject Modal */}
          <Modal
            isOpen={rejectModal}
            role="dialog"
            size="md"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={toogelRejectModal}
          >
            <div className="modal-content">
              <ModalHeader toggle={toogelRejectModal}>
                Reason for rejection
              </ModalHeader>
              <ModalBody>
                <p> </p>

                <FormGroup className="mb-3">
                  <Label htmlFor="">Write reason</Label>
                  <Input
                    type="textarea"
                    name="product-name"
                    className="form-control"
                    placeholder="Enter Text Here"
                    onChange={e => setRejectReason(e.target.value)}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
                <Button
                  disabled={rejectReason?.length <= 0}
                  type="button"
                  color="primary"
                  onClick={reject}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  onClick={toogelRejectModal}
                  color="secondary"
                >
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </Modal>

          {/* Reject Reason Modal */}
          <Modal
            isOpen={rejectReasonModal}
            role="dialog"
            size="md"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={viewRejectReason}
          >
            <div className="modal-content">
              <ModalHeader toggle={viewRejectReason}>
                Reason for rejection
              </ModalHeader>
              <ModalBody>
                <p> </p>

                <FormGroup className="mb-3">
                  <Label htmlFor="">View reason</Label>
                  <Input
                    type="textarea"
                    name="product-name"
                    className="form-control"
                    placeholder="Enter Text Here"
                    value={reason}
                    disabled
                  />
                </FormGroup>
              </ModalBody>
            </div>
          </Modal>

          {/* View Order Modal */}
          <Modal
            isOpen={viewOrderModal}
            role="dialog"
            size="md"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={viewOrder}
          >
            <div className="modal-content">
              <ModalHeader toggle={viewOrder}>
                Equipment Product Details
              </ModalHeader>
              <ModalBody>
                <SimpleBar style={{ maxHeight: "376px" }}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{rowData && rowData.RPD?.pnumber?.pname}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Quantity</b>
                            <br></br>
                            <p>{rowData && rowData.qty}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </SimpleBar>
              </ModalBody>
              <ModalHeader>Consumables Product Details</ModalHeader>
              <ModalBody>
                <SimpleBar style={{ maxHeight: "376px" }}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{rowData && rowData.RPD?.mpname}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </SimpleBar>
              </ModalBody>
            </div>
          </Modal>
        </div>
      </div>
    </Loadder>
  )
}

export default NewOrderRequestFromCustomer
