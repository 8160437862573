import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGOUT_USER,
  RESELLINGPRODUCT_GET,
  RESELLINGPRODUCT_SUCCESS,
  RESELLINGPRODUCT_UPLOAD_POST,
  RESELLINGPRODUCT_ADD,
  TAGLISTOFCUSTOMER_GET,
  TAGLISTOFCUSTOMER_SUCCESS,
  TAGLISTOFDEALER_GET,
  TAGLISTOFDEALER_SUCCESS,
  TAGCUSTOMER_GET,
  TAGCUSTOMER_SUCCESS,
  // TAGTODEALER_GET,
  // TAGTODEALER_SUCCESS,
  RESELLINGTAGTODEALER_GET,
  RESELLINGTAGTODEALER_SUCCESS,
  VIEWRESELLINGPRODUCT_GET,
  VIEWRESELLINGPRODUCT_SUCCESS,

  // ************ DEALER *****************
  TAGPRODUCTFROMADMIN_GET,
  TAGPRODUCTFROMADMIN_SUCCESS,
  DEALER_RESELLINGPRODUCTLIST_GET,
  DEALER_RESELLINGPRODUCTLIST_SUCCESS,
  DELEAR_TAGTOCUSTOMER_GET,
  DELEAR_TAGTOCUSTOMER_SUCCESS,
  PARENT_RESELLINGPRODUCT_GET,
  PARENT_RESELLINGPRODUCT_UPLOAD_POST,
  RESELLINGCHILDPRODUCT_ADD,
  NEWTAGLISTOFDEALER_GET,
} from "./actionTypes"

import {
  resellingProductGet,
  resellingProductSuccess,
  resellingProductAdd,
  tagListOfCustomerGet,
  tagListOfCustomerSuccess,
  tagListOfDealerGet,
  tagListOfDealerSuccess,
  tagToDealerGet,
  tagToDealerSuccess,
  tagToCustomerGet,
  tagToCustomerSuccess,
  viewResellingProductGet,
  viewResellingProductSuccess,

  // ************** DEALER *****************
  tagProductFromAdminGet,
  tagProductFromAdminSuccess,
  dealer_resellingproductlistGet,
  dealer_resellingproductlistSuccess,
  dealer_tagtocustomerGet,
  dealer_tagtocustomerSuccess,
  parentresellingProductSuccess,
  parentresellingProductGet,
  newtagListOfDealerSuccess,
} from "./actions"
import { apiError } from "store/actions"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import {
  HOSTNAME,
  RESELLINGPRODUCTGET,
  RESELLINGPRODUCTUPLOAD,
  RESELLINGPRODUCTADD,
  TAGLISTOFCUSTOMER,
  TAGLISTOFDEALER,
  TAGTODELEAR,
  TAGTOCUSTOMER,
  VIEWRESELLINGPRODUCTGET,

  // ******* DEALER **********
  TAGPRODUCTFROMADMINGET,
  DEALER_RESELLINGPRODUCTLISTGET,
  DEALER_TAGTOCUSTOMERGET,
  PARENTRESELLINGPRODUCTGET,
  PARENTRESELLINGPRODUCTUPLOAD,
  NEWTAGLISTOFDEALER,
} from "config/config"

const getUserData = JSON.parse(localStorage.getItem("authUser"))
function* resellingProducts({ payload: { query, history } }) {
  try {
    // alert(query.query)
    // let url = `${HOSTNAME}${RESELLINGPRODUCTGET}`
    // if(query.query){
    //   url =`${HOSTNAME}${RESELLINGPRODUCTGET}?query=${query.query}`
    // }
    // else if(query.url){
    //   url=query.url
    // }
    let isdemoproduct = 0
    let url = `${HOSTNAME}${RESELLINGPRODUCTGET}`
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status && query.status !== "3") {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemoproduct) {
      isdemoproduct = query.isdemoproduct
      queryParams.push(`isdemoproduct=${query.isdemoproduct}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${RESELLINGPRODUCTGET}?${queryParams.join("&")}`
    }
    if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(resellingProductSuccess(response.data))

      // yield put(resellingProductGet({"isdemoproduct":isdemoproduct}));
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
      // alert(isdemoproduct)
      // yield put(resellingProductGet({"isdemoproduct":isdemoproduct}));
    }
    // yield put(resellingProductGet({"isdemoproduct":isdemoproduct}));
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* parentresellingProducts({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${PARENTRESELLINGPRODUCTGET}`
    if (query.query) {
      url = `${HOSTNAME}${PARENTRESELLINGPRODUCTGET}?query=${query.query}`
    } else if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(parentresellingProductSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------------ Tag List Of Customer-------------

function* taglistofcustomer({ payload: { query, history } }) {
  try {
    // alert(query.query)
    // let url = `${HOSTNAME}${TAGLISTOFCUSTOMER}`
    // if(query.query){
    //   url =`${HOSTNAME}${TAGLISTOFCUSTOMER}&query=${query.query}`
    // }
    // else if(query.url){
    //   url=query.url
    // }

    let url = `${HOSTNAME}${TAGLISTOFCUSTOMER}`
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status && query.status !== "3") {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemoproduct) {
      isdemoproduct = query.isdemoproduct
      queryParams.push(`isdemoproduct=${query.isdemoproduct}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${TAGLISTOFCUSTOMER}&${queryParams.join("&")}`
    }
    console.log("query.url",query.url);
    if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(tagListOfCustomerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------------ Tag List Of Dealer-------------

function* taglistofdealer({ payload: { query, history } }) {
  try {
    // alert(query.query)
    // let url = `${HOSTNAME}${TAGLISTOFDEALER}`
    // if(query.query){
    //   url =`${HOSTNAME}${TAGLISTOFDEALER}&query=${query.query}`
    // }
    // else if(query.url){
    //   url=query.url
    // }

    let url = `${HOSTNAME}${TAGLISTOFDEALER}`
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status && query.status !== "3") {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemoproduct) {
      isdemoproduct = query.isdemoproduct
      queryParams.push(`isdemoproduct=${query.isdemoproduct}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${TAGLISTOFDEALER}&${queryParams.join("&")}`
    }
    if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(tagListOfDealerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------------ New Tag List Of Dealer-------------

function* newtaglistofdealer({ payload: { query, history } }) {
  try {
    // alert(query.query)
    // let url = `${HOSTNAME}${TAGLISTOFDEALER}`
    // if(query.query){
    //   url =`${HOSTNAME}${TAGLISTOFDEALER}&query=${query.query}`
    // }
    // else if(query.url){
    //   url=query.url
    // }

    let url = `${HOSTNAME}${NEWTAGLISTOFDEALER}`
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status && query.status !== "3") {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemoproduct) {
      isdemoproduct = query.isdemoproduct
      queryParams.push(`isdemoproduct=${query.isdemoproduct}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${NEWTAGLISTOFDEALER}&${queryParams.join("&")}`
    }
    if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(newtagListOfDealerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------------ Tag To Dealer-------------

function* tagtodealer({ payload: { query, history, id } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${TAGTODELEAR}`
    if (id.id) {
      // alert(id.id)
      url = url + `${id.id}/`
    }

    if (query.query) {
      url = url + `?query=${query.query}`
    } else if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(tagToDealerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------------ Tag To Customer-------------

function* tagtocustomer({ payload: { query, history, id } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${TAGTOCUSTOMER}`

    if (id.id) {
      // alert(id.id)
      url = url + `${id.id}/`
    }

    if (query.query) {
      url = url + `?query=${query.query}`
    } else if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(tagToCustomerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------------ View Equipment Product Get -------------

function* viewresellingproduct({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}`
    if (query.query) {
      url = `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}?query=${query.query}`
    } else if (query.url) {
      // alert("==>")
      url = query.url
      // alert(url)
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(viewResellingProductSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/pre-login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* resellingProductUpload({ payload: { fileData, history } }) {
  try {
    // alert(fileData)
    // let data = new FormData();
    // data.append('file', fileData.data);
    let isdemoproduct = 0
    if (history.isdemoproduct) {
      isdemoproduct = history.isdemoproduct
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${RESELLINGPRODUCTUPLOAD}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        "Content-Type": "multipart/form-data",
      },
      data: fileData.data,
    }

    const response = yield axios.request(config)
    if (response.status == 400) {
      toast.error("File Note Valid", {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }

    if (response.data.status_code === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total created: ${response.data.data.created}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total updated: ${response.data.data.updated}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(resellingProductGet({ isdemoproduct: isdemoproduct }, {}))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* parentresellingProductUpload({ payload: { fileData, history } }) {
  try {
    // alert(fileData)
    // let data = new FormData();
    // data.append('file', fileData.data);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${PARENTRESELLINGPRODUCTUPLOAD}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        "Content-Type": "multipart/form-data",
      },
      data: fileData.data,
    }

    const response = yield axios.request(config)
    if (response.status == 400) {
      toast.error("File Note Valid", {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }

    if (response.data.status_code === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total created: ${response.data.data.created}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total updated: ${response.data.data.updated}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(parentresellingProductGet({}, {}))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* resellingProductAddData({ payload: { data, history } }) {
  try {
    // alert(data)
    // let data = new FormData();
    // data.append('file', fileData.data);
    let mydata = JSON.stringify(data.data)
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${RESELLINGPRODUCTADD}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        "Content-Type": "application/json",
      },
      data: mydata,
    }

    const response = yield axios.request(config)
    // alert(response.status)
    if (response.status == 400 || response.status == 500) {
      toast.error("Invalid Data", {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }

    if (response.data.status_code === 201) {
      toast.success("Equipment product added", {
        position: toast.POSITION.TOP_RIGHT,
      })
      // const dispatch = useDispatch()
      // dispatch(resellingProductGet({}, {}))
      yield put(resellingProductGet({}, {}))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error("Invalid Data!!", {
      position: toast.POSITION.TOP_RIGHT,
    })

    yield put(apiError(error))
  }
}
function* resellingChildProductAddData({ payload: { data, history } }) {
  try {
    // alert(data)
    // let data = new FormData();
    // data.append('file', fileData.data);
    let mydata = JSON.stringify(data.data)
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${RESELLINGPRODUCTADD}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        "Content-Type": "application/json",
      },
      data: mydata,
    }

    const response = yield axios.request(config)
    // alert(response.status)
    if (response.status == 400 || response.status == 500) {
      toast.error("Invalid Data", {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }

    if (response.data.status_code === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      // const dispatch = useDispatch()
      // dispatch(resellingProductGet({}, {}))
      yield put(resellingProductGet({}, {}))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error("Invalid Data!!", {
      position: toast.POSITION.TOP_RIGHT,
    })

    yield put(apiError(error))
  }
}
// --------------------- DEALER ----------------------------

function* tagProductfromAdminData({ payload: { query, history } }) {
  try {
    // alert(query)
    let url = `${HOSTNAME}${TAGPRODUCTFROMADMINGET}`
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status && query.status !== "3") {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemo) {
      queryParams.push(`isdemo=${query.isdemo}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?${queryParams.join("&")}`
    }
    if (query.url) {
      url = query.url
    }

    // if ("status" in query && !("query" in query) ) {
    //   url = `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?status=${query.status}`;
    // }
    // if("query" in query && "status" in query){
    //   // alert("ddd")
    //   url = `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?query=${query.query}&status=${query.status}`;
    // }
    // if("query" in query && !("status" in query)){
    //   url = `${HOSTNAME}${TAGPRODUCTFROMADMINGET}?query=${query.query}`;
    // }
    // if("status" in query && !(query.status ==1)){
    //   url += `&status=${query.status}`
    // }
    // alert(url)

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(tagProductFromAdminSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------------------ DEALER --> RESELLING PRODUCT LIST--------

function* dealer_resellingproductlistData({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${DEALER_RESELLINGPRODUCTLISTGET}`
    // if(query.query){
    //   url =`${HOSTNAME}${DEALER_RESELLINGPRODUCTLISTGET}?query=${query.query}`
    // }
    // else if(query.url){
    //   url=query.url
    // }
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status) {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemo) {
      queryParams.push(`isdemo=${query.isdemo}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${DEALER_RESELLINGPRODUCTLISTGET}?${queryParams.join(
        "&"
      )}`
    }
    if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(dealer_resellingproductlistSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ----------------delear---> tag to customer--------------

function* dealer_tagtocustomer({ payload: { query, history, id } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${DEALER_TAGTOCUSTOMERGET}`
    if (id.id) {
      url = url + `${id.id}/`
      // alert(url)
    }

    if (query.query) {
      url = `${HOSTNAME}${DEALER_TAGTOCUSTOMERGET}${id.id}/?query=${query.query}`
    } else if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(dealer_tagtocustomerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* resellingProductsSaga() {
  yield takeEvery(RESELLINGPRODUCT_GET, resellingProducts)
  yield takeEvery(PARENT_RESELLINGPRODUCT_GET, parentresellingProducts)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(RESELLINGPRODUCT_UPLOAD_POST, resellingProductUpload)
  yield takeEvery(
    PARENT_RESELLINGPRODUCT_UPLOAD_POST,
    parentresellingProductUpload
  )
  yield takeEvery(RESELLINGPRODUCT_ADD, resellingProductAddData)
  yield takeEvery(RESELLINGCHILDPRODUCT_ADD, resellingChildProductAddData)
  yield takeEvery(TAGLISTOFCUSTOMER_GET, taglistofcustomer)
  yield takeEvery(TAGLISTOFDEALER_GET, taglistofdealer)
  yield takeEvery(NEWTAGLISTOFDEALER_GET, newtaglistofdealer)
  yield takeEvery(RESELLINGTAGTODEALER_GET, tagtodealer)
  yield takeEvery(TAGCUSTOMER_GET, tagtocustomer)
  yield takeEvery(VIEWRESELLINGPRODUCT_GET, viewresellingproduct)
  yield takeEvery(TAGPRODUCTFROMADMIN_GET, tagProductfromAdminData)
  yield takeEvery(
    DEALER_RESELLINGPRODUCTLIST_GET,
    dealer_resellingproductlistData
  )
  yield takeEvery(DELEAR_TAGTOCUSTOMER_GET, dealer_tagtocustomer)
}

export default resellingProductsSaga
