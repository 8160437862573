// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./amccustomerlisttablecontainer"
import { adminAMCcustomerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"

import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { Link } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { HOSTNAME } from "config/config"
import { toast } from "react-toastify"
import axios from "axios"

function extractIdAndPName(item) {
  let AMCStartDate = moment(new Date(item.AMCStartDate)).format("DD MMM Y")
  let AMCEndDate = moment(new Date(item.AMCEndDate)).format("DD MMM Y")
  return {
    id: item.id,
    firstName: item.firstName,
    productName: item.productName,
    AMCStartDate: AMCStartDate,
    AMCEndDate: AMCEndDate,
    remaining_days: item.remaining_days,
    city: item.city,
    contactNumber: item.contactNumber,
    pnumber: item.pnumber,
    serialNumbers: item.serialNumbers,
  }
}

function DatatableTables() {
  const [type, setType] = useState("AC")
  let data = []
  const dispatch = useDispatch()

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const reset = id => {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/get-amc-details/${id}/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios
      .request(config)
      .then(response => {
        dispatch(adminAMCcustomerGet({}, {}, {}))
        toast.success(response.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "firstName",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },

      {
        Header: "Product Registartion Date",
        accessor: "AMCStartDate",
      },
      {
        Header: "Days Remaning for Next AMC",
        accessor: "remaining_days",
        Cell: cellProps => {
          const row = cellProps.row.original

          return (
            <>
              {row?.remaining_days < 0 ? (
                <>
                  <span style={{ paddingRight: "10px" }}>
                    {row.remaining_days}
                  </span>{" "}
                  <Button
                    size="sm"
                    type="button"
                    color="warning"
                    onClick={() => reset(row.id)}
                  >
                    Reset
                  </Button>
                </>
              ) : (
                row?.remaining_days
              )}
            </>
          )
        },
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => vieworder(e, row)}>
                  View Details
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    // dispatch(adminAMCcustomerGet({}, {},{"type":type}))
    dispatch(adminAMCcustomerGet({}, {}, {}))
  }, [])

  const AmcCustomerListData = useSelector(state => state.AdminamccustomerGet)

  if (AmcCustomerListData && AmcCustomerListData.adminamccustomerDetails) {
    data =
      AmcCustomerListData.adminamccustomerDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Policy Renewal"

  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }

  const [customername, setCustomername] = useState("")
  const [customercity, setCustomercity] = useState("")
  const [customernumber, setCustomernumber] = useState("")
  const [productname, setProductname] = useState("")
  const [productAMCStartDate, setProductAMCStartDate] = useState("")
  const [productAMCEndDate, setProductAMCEndDate] = useState("")

  const vieworder = (e, row) => {
    setCustomername(row.original.firstName)
    setCustomercity(row.original.city)
    setCustomernumber(row.original.contactNumber)

    setProductname(row.original.productName)
    setProductAMCStartDate(row.original.AMCStartDate)
    setProductAMCEndDate(row.original.AMCEndDate)
    setModal(!modal)
  }
  return (
    <Loadder active={AmcCustomerListData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Policy Renewal" />
          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={AmcCustomerListData.adminamccustomerDetails.count}
            next={AmcCustomerListData.adminamccustomerDetails.next}
            previous={AmcCustomerListData.adminamccustomerDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            type={type}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={modal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Name</b>
                              <br></br>
                              <p>{customername}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer City</b>
                              <br></br>
                              <p>{customercity}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Number</b>
                              <br></br>
                              <p>{customernumber}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
            <ModalHeader>Customer Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Name</b>
                              <br></br>
                              <p>{productname}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product AMC Start Date</b>
                              <br></br>
                              <p>{productAMCStartDate}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product AMC End Date</b>
                              <br></br>
                              <p>{productAMCEndDate}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
