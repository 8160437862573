// src/components/filter.
import React, { useState,useMemo } from "react";
import PropTypes from 'prop-types';
import SimpleBar from "simplebar-react";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './manufacturedproducttablecontainer';
import {
    FormFeedback,
    ModalFooter,
    Table,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Label,
    Button,
    Input,
    Form,
    FormGroup,
    UncontrolledAlert,
    UncontrolledTooltip,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
  } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenExpiration } from "config/getTokenExpirationTime";
import { adminEarningMPDataGet, customerSpendListGet } from "store/actions";
import { useEffect } from "react";
import moment from "moment";

  const reports = [
    // { title: "Total Number of Equipment Product sold", iconClass: "bx-purchase-tag-alt", description: "150" },


  ];
  function extractIdAndPName(item) {
    let AMCStartDate = moment(new Date(item.AMCStartDate)).format("DD MMM Y"); 
    let AMCEndDate = moment(new Date(item.AMCEndDate)).format("DD MMM Y"); 
    let soldDate = moment(new Date(item.soldDate)).format("DD MMM Y"); 
    return {
        id:item.product.id,
        productname:item.product.mpname,
        customername: `${item.customer.firstName} ${item.customer.lastName}`,
        product:item.product,
        customer:item.customer,
        soldto:item.soldto,
        soldDate:soldDate,
        pnumber:item.product && item.product.mpnumber,
        serialNumbers:item.product && item.RPDproduct.serialNumbers,
        // RPD_total_sold:item.RPD_total_sold
        total_sold:item.total_sold,
        RPDproduct:item.RPDproduct,
        AMCStartDate:AMCStartDate,
        AMCEndDate:AMCEndDate,
        MPTotal:item.MPTotal,
        remainingdays:parseInt((new Date(item.AMCEndDate)- new Date()) / (1000 * 60 * 60 * 24), 10),
    };
  }  
function DatatableTablesManufacture() {
    const columns = useMemo(
        () => [
            {
                Header: 'Sr.No',
                accessor: 'id',
            },
            {
                Header: 'Product Name',
                accessor: 'productname'
            },
            {
                Header: 'Product Number',
                accessor: 'pnumber'
            },
            {
                Header: 'Product Serial Number',
                accessor: 'serialNumbers'
            },
            // {
            //     Header: 'Product Bought Date',
            //     accessor: 'soldDate',
            // },
            {
                Header: ' ',
                Cell: (cellProps) => {
                    const row = cellProps.row.original
                  return (
                    <Row>
                        <Col xl={12}>
                        <Link to="#" onClick={e=>togglemodal1(row)}>View details</Link>
                        </Col>
                    </Row>
                  );
                }
              },
        ],
        []
    );

    const dispatch = useDispatch();
    useEffect(()=>{
        checkTokenExpiration()
        const getUserData = JSON.parse(localStorage.getItem("authUser")) 
        // console.log(getUserData && getUserData.userData.payload.userType,"->>>>>customer")
        // dispatch(customerSpendListGet({}, {}))
        dispatch(adminEarningMPDataGet({"userType":getUserData && getUserData.userData.payload.userType,"iscustomer":1}, {}))
    },[dispatch])

    let data =[]
    const customerspendlists =useSelector(state => state.adminEarningGet.adminEarningDetails)
    console.log("customerspendlist=>---------",customerspendlists && customerspendlists)
    if(customerspendlists && customerspendlists.results){
        console.log(customerspendlists && customerspendlists.results.data,"=>")
        data = customerspendlists && customerspendlists.results.data.map(extractIdAndPName);   
    } 

    //meta title
    document.title = "Spends";

    const [modal, setModal] = useState(false);
    const togglemodal = () => {
        setModal(!modal);
      };
    const [obj,setobj] =useState({})
    const togglemodal1 = (row) => {
    setModal(!modal);
    setobj(row)
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Spends" />
          
                
                {/* <Table columns={columns} data={data} /> */}
                <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>

            <Modal
                    isOpen={modal}
                    role="dialog"
                    size="md"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={togglemodal}
                  >
                    <div className="modal-content">
                  
                      <ModalHeader toggle={togglemodal}>
                        Customer Equipment Product Details
                      </ModalHeader>
                    
                      <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product Name</b><br></br>
                                                        <p>{obj.RPDproduct && obj.RPDproduct.pname}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product Registration Date</b><br></br>
                                                        <p>{obj && obj.soldDate}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row> 
                                {/* <br/> */}
                                {/* <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product AMC Remaning Days</b><br></br>
                                                    <p>{obj && obj.remainingdays}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>   */}
                        
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>

                      <ModalHeader>
                        Consumables Product Details
                      </ModalHeader>
                      <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Name</b>
                                                    <p >{obj.product && obj.product.mpname}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Number</b>
                                                    <p >{obj.product && obj.product.mpnumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> 
                                <br/>
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Sold Quantity</b>
                                                    <p >{obj && obj.total_sold}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                               
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>
                 
                    </div>
            </Modal>
        </div>
    );
}
DatatableTablesManufacture.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTablesManufacture;