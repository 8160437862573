import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"

import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "./Table/Table"
import { HOSTNAME } from "config/config"
import Loadder from "components/Loadder/Loadder"

const ViewInventory = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [currentPage, setcurrentPage] = useState(1)

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const getDealerRequestProduct = () => {
    setLoading(true)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/inventory-manufacture-product-dealer-logs/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getDealerRequestProduct()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product  Name",
        accessor: "RPD.pnumber.pname",
      },
      {
        Header: "Product Number",
        accessor: "RPD.pnumber.pnumber",
      },
      {
        Header: "Consumer Number",
        accessor: "RPD.mpname",
      },
      {
        Header: "Quantity",
        accessor: "qty",
      },
    ],
    []
  )

  // Search
  const [query, setquery] = useState("")
  const onSearch = e => {
    setquery(e.target.value)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/inventory-manufacture-product-dealer-logs/?query=${e.target.value}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //Pagination function
  const getPaginationData = (url, page) => {
    setcurrentPage(page)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Loadder active={loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="CONSUMABLE PRODUCTS" />

          <TableContainer
            count={tableData?.count}
            next={tableData?.next}
            previous={tableData?.previous}
            columns={columns}
            data={tableData?.results?.data || []}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
            removeCheckbox
            currentPage={currentPage}
            onSearch={onSearch}
            goToNextPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-dealer-logs/?query=${query}&page=${currentPage + 1
                }`,
                currentPage + 1
              )
            }
            goToLastPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-dealer-logs/?query=${query}&page=${Math.ceil(
                  tableData?.count / 10
                )}`,
                Math.ceil(tableData?.count / 10)
              )
            }
            goToPreviousPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-dealer-logs/?query=${query}&page=${currentPage - 1
                }`,
                currentPage - 1
              )
            }
            goToFirstPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-dealer-logs/?query=${query}&page=${1}`,
                1
              )
            }
            getInputPaginationData={e =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-dealer-logs/?query=${query}&page=${e.target.value}`,
                e.target.value
              )
            }
          />
        </div>
      </div>
    </Loadder>
  )
}

export default ViewInventory
