// src/components/filter.
import React, { useMemo,useEffect ,useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FinancialTableContainer from './financialyeartablecontainer';
import { useSelector, useDispatch } from "react-redux";
import { adminFinancialDataGet } from "../../store/actions";
import {checkTokenExpiration} from '../../../src/config/getTokenExpirationTime';
import moment from "moment";
function extractIdAndPName(item) {
    let soldDate = moment(new Date(item.soldDate)).format("DD MMM Y");   
    return {
        // id: item.id,
        productname: item.product.pname,
        tqty:item.total_sold,
        soldto:item.soldto,
        product:item.product,
        soldDate:soldDate

    };
}

const DatatableTables=()=> {
    const dispatch = useDispatch();
    useEffect(()=>{
        // const isUserLoggedIn = localStorage.getItem('isLoggedIn');
        // if (!isUserLoggedIn) {
        //     localStorage.setItem('isLoggedIn', 'true');
        //     window.location.reload();
        // }
        checkTokenExpiration()
        dispatch(adminFinancialDataGet({}, {}))
    },[dispatch])
    let data =[]
    const adminFinancialDatas =useSelector(state => state.adminFinancialGet.adminFinancialDetails)
    console.log("adminFinancialDatas=>",adminFinancialDatas)
    if(adminFinancialDatas && adminFinancialDatas.results){
        console.log(adminFinancialDatas.results.data,"=>")
        data = adminFinancialDatas.results.data.map(extractIdAndPName);   
    }

    const columns = useMemo(
        () => [
            // {
            //     Header: 'Sr.No',
            //     accessor: 'id',
            // },
            {
                Header: 'Product Name',
                accessor: 'productname'
            },
            {
                Header:'Product Serial Number',
                accessor: 'product.serialNumbers'
            },
            {
                Header: 'Total Quantity',
                accessor: 'tqty'
            },
            {
                Header: 'Sold To',
                accessor: 'soldto'
            },
            {
                Header: 'Sold Date',
                accessor: 'soldDate'
            },
        ],
        []
    );


    //meta title
    document.title = "Financial Year";

    return (

        
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Financial Year" />
                {/* <Table columns={columns} data={data} /> */}
                <FinancialTableContainer
                    count={adminFinancialDatas.count}
                    next={adminFinancialDatas.next}
                    previous={adminFinancialDatas.previous}
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>
        </div>
    );
}
DatatableTables.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTables;