import {
  VIEWDEMOPRODUCT_GET,
  VIEWDEMOPRODUCT_SUCCESS,
  TAGTODEALER_GET,
  TAGTODEALER_SUCCESS,
  TAGGEDPRODUCTLIST_GET,
  TAGGEDPRODUCTLIST_SUCCESS,
  DEMOPRODUCT_ADD,
  DEMOPRODUCT_ADD_SUCCESS,
  DEMOPRODUCTMASTER_GET,
  DEMOPRODUCTMASTER_SUCCESS,
  DEMOPRODUCTMASTER_UPLOAD_POST,
  DEMOPRODUCTMASTER_UPLOAD_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

// -------- View Consumables Product--------

export const viewDemoProductGet = (query, history) => {  
  // alert("action")
  return {
    type: VIEWDEMOPRODUCT_GET,
    payload: { query, history },
  }
}

export const viewDemoProductSuccess = data => {
  return {
    type: VIEWDEMOPRODUCT_SUCCESS,
    payload: data,
  }
}

// -------- Tag To Dealer --------

export const tagtoDealerGet = (query, history,id) => {  
  // alert("action")
  return {
    type: TAGTODEALER_GET,
    payload: { query, history,id },
  }
}

export const tagtoDealerSuccess = data => {
  return {
    type: TAGTODEALER_SUCCESS,
    payload: data,
  }
}

// -------- Tagged Product List----------

export const taggedProductListGet = (query, history) => {  
  // alert("action")
  return {
    type: TAGGEDPRODUCTLIST_GET,
    payload: { query, history },
  }
}

export const taggedProductListSuccess = data => {
  return {
    type: TAGGEDPRODUCTLIST_SUCCESS,
    payload: data,
  }
}

// ----------- MAINTAIN INVENTORY ADD PRODUCT-----

export const demoProductAdd = (data, history) => { 
  // alert("action")
  return {
    type: DEMOPRODUCT_ADD,
    payload: { data, history },
  }
}

export const demoProductAddSuccess = data => {
  return {
    type: DEMOPRODUCT_ADD_SUCCESS,
    payload: data,
  }
}

// ----- Demo Product Master----

export const demoProductMasterGet = (query, history) => {  
  // alert("action")
  return {
    type: DEMOPRODUCTMASTER_GET,
    payload: { query, history },
  }
}

export const demoProductMasterSuccess = data => {
  return {
    type: DEMOPRODUCTMASTER_SUCCESS,
    payload: data,
  }
}

// ----- Demo Product Master Upload ----

export const demoProductMasterUploadGet = (fileData, history) => {
  // alert("action")
  return {
    type: DEMOPRODUCTMASTER_UPLOAD_POST,
    payload: { fileData, history },
  }
}