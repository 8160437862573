// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./productverificationofexistingcustomertablecontainer"
import { productverificationofexistingcustomerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import { ADMINSOLDPRODUCT, HOSTNAME } from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"

function extractIdAndPName(item) {
  return {
    id: item.id,
    pnumber: item.pnumber,
    customername: `${item.customerDetail && item.customerDetail.firstName} ${
      item.customerDetail && item.customerDetail.lastName
    }`,
    contactNumber: `${
      item.customerDetail && item.customerDetail.contactNumber
    }`,
    dealerName: `${item.dealerDetail && item.dealerDetail.firstName} ${
      item.dealerDetail && item.dealerDetail.lastName
    }`,
    dealercontactNumber: `${
      item.dealerDetail && item.dealerDetail.contactNumber
    }`,
    city: item.city,
    productName: item.productName,
    isverify: item.isverify ? "Yes" : "No",
    serialNumbers: item.serialNumbers,
    isSold: item.isSold,
    status: item.status,
  }
}

function DatatableTablesManufacture() {
  const dispatch = useDispatch()

  const autoProductVerification = row => {
    let data = new FormData()
    data.append("tagId", row.id)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/autoverify/tag/product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product verified successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        dispatch(productverificationofexistingcustomerGet({}, {}))
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Dealer Name",
        accessor: "dealerName",
      },
      {
        Header: "Dealer Contact Number",
        accessor: "dealercontactNumber",
      },
      {
        Header: "Customer City",
        accessor: "city",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Product Verification",
        accessor: "isverify",
      },
      {
        Header: " ",
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              {cellProps.row.original.isverify === "No" ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>
                      <Link
                        to="#"
                        onClick={() =>
                          autoProductVerification(cellProps.row.original)
                        }
                      >
                        Auto Product Verification
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : (
                " "
              )}
            </UncontrolledDropdown>
          )
        },
      },

      // {
      //     Header: '   ',
      //     Cell: (cellProps) => {
      //       return (
      //         <UncontrolledDropdown>
      //           <DropdownToggle tag="a" className="card-drop">
      //             <i className="mdi mdi-dots-horizontal font-size-18"></i>
      //           </DropdownToggle>

      //           <DropdownMenu className="dropdown-menu-end">
      //             <DropdownItem
      //             //   onClick={() => {
      //             //     const customerData = cellProps.row.original;
      //             //     handleCustomerClick(customerData);
      //             //   }
      //             //   }
      //             >
      //               <i className="font-size-16 text-success me-1" id="edittooltip"></i>
      //              View Reason

      //               <UncontrolledTooltip placement="top" target="edittooltip">
      //               View Reason
      //               </UncontrolledTooltip>
      //             </DropdownItem>

      //             <DropdownItem
      //             //   onClick={() => {
      //             //     const customerData = cellProps.row.original;
      //             //     handleCustomerClick(customerData);
      //             //   }
      //             //   }
      //             >
      //               <i className="font-size-16 text-success me-1" id="edittooltip"></i>
      //              Send Request

      //               <UncontrolledTooltip placement="top" target="edittooltip">
      //               Send Request
      //               </UncontrolledTooltip>
      //             </DropdownItem>

      //           </DropdownMenu>
      //         </UncontrolledDropdown>
      //       );
      //     }
      //   },

      // {
      //   Header: "   ",
      //   Cell: cellProps => {
      //     const row = cellProps.row
      //     return (
      //       <UncontrolledDropdown>
      //         {row.original.status == 1 || row.original.status == "1" ? (
      //           row.original.isSold == false &&
      //           row.original.isverify == "Yes" ? (
      //             <>
      //               <DropdownToggle tag="a" className="card-drop">
      //                 <i className="mdi mdi-dots-horizontal font-size-18"></i>
      //               </DropdownToggle>

      //               <DropdownMenu className="dropdown-menu-end">
      //                 <DropdownItem
      //                   onClick={e => soldData(e, row.original.id, 0)}
      //                 >
      //                   <i
      //                     className="font-size-16 text-success me-1"
      //                     id="edittooltip"
      //                   ></i>
      //                   Mark as sold in this year
      //                   <UncontrolledTooltip
      //                     placement="top"
      //                     target="edittooltip"
      //                   >
      //                     Mark as sold in this year
      //                   </UncontrolledTooltip>
      //                 </DropdownItem>

      //                 <DropdownItem
      //                   onClick={e => soldData(e, row.original.id, 1)}
      //                 >
      //                   <i
      //                     className=" font-size-16 text-danger me-1"
      //                     id="deletetooltip"
      //                   ></i>
      //                   Mark as sold in next year
      //                   <UncontrolledTooltip
      //                     placement="top"
      //                     target="deletetooltip"
      //                   >
      //                     Mark as sold in next year
      //                   </UncontrolledTooltip>
      //                 </DropdownItem>
      //               </DropdownMenu>
      //             </>
      //           ) : (
      //             ""
      //           )
      //         ) : (
      //           ""
      //         )}
      //       </UncontrolledDropdown>
      //     )
      //   },
      // },
    ],
    []
  )

  let data = []

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(productverificationofexistingcustomerGet({}, {}))
  }, [])
  const getUserData = JSON.parse(localStorage.getItem("authUser"))
  const productverificationofexistingcustomerData = useSelector(
    state => state.CustomerGet
  )

  if (
    productverificationofexistingcustomerData &&
    productverificationofexistingcustomerData.customerDetails
  ) {
    data =
      productverificationofexistingcustomerData.customerDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Customer"

  const soldData = (e, id, type) => {
    // alert(id)
    // alert(type)
    let url = `${HOSTNAME}${ADMINSOLDPRODUCT}?year=${type}&oid=${id}&soldType=0`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        dispatch(productverificationofexistingcustomerGet({}, {}))
        toast.success("Product sold !", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(error => {
        toast.error("something worng", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    dispatch(productverificationofexistingcustomerGet({}, {}))
  }

  return (
    <Loadder active={productverificationofexistingcustomerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Customers" />

          <TableContainer
            count={
              productverificationofexistingcustomerData.customerDetails.count
            }
            next={
              productverificationofexistingcustomerData.customerDetails.next
            }
            previous={
              productverificationofexistingcustomerData.customerDetails.previous
            }
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
