// src/components/filter.
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./buybacktablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { delearBuyBackGet } from "store/actions"
import { useEffect } from "react"
import { DEALERBUYBACKDETAILS, HOSTNAME } from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
import moment from "moment"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  let AMCStartDate = moment(new Date(item.AMCStartDate)).format("DD MMM Y")
  let AMCEndDate = moment(new Date(item.AMCEndDate)).format("DD MMM Y")
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }
  return {
    id: item.id,
    customerName: `${item.firstName} ${item.lastName}`,
    contactNumber: `${item.contactNumber}`,
    city: `${item.city}`,
    status: item.status,
    statustext: statustext,

    message: item.rejectionreason,
    reason: item.reason,
    productName: item.productName,
    pid: item.pid,
    pnumber: item.pnumber,
    AMCStartDate: AMCStartDate,
    AMCEndDate: AMCEndDate,
    remainingdays: parseInt(
      (new Date(item.AMCEndDate) - new Date()) / (1000 * 60 * 60 * 24)
    ),
    serialNumbers: item.serialNumbers,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "product Name",
        accessor: "productName",
      },
      {
        Header: "product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Customer City",
        accessor: "city",
      },
      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          if (row.status == 0) {
            return (
              <Row>
                <Col xl={12}>
                  <Col xl={6}>
                    <div className="mt-0 d-grid">
                      <button
                        onClick={e => callAcceptRejectApi(row.id, "", 1)}
                        className="btn btn-success btn-block"
                        type="button"
                      >
                        Accept
                      </button>
                    </div>
                  </Col>
                </Col>
              </Row>
            )
          } else if (row.status == 1) {
            return (
              <Row>
                <Col xl={12}>
                  <Col xl={6}>
                    <div className="mt-0 d-grid">
                      <button
                        disabled
                        onClick={e => writerejecttoggle1(row.id)}
                        className="btn btn-success btn-block"
                        type="button"
                      >
                        Accepted
                      </button>
                    </div>
                  </Col>
                </Col>
              </Row>
            )
          } else {
            return ""
          }
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          if (row.status == 0) {
            return (
              <Row>
                <Col xl={12}>
                  <Col xl={6}>
                    <div className="mt-0 d-grid">
                      <button
                        onClick={e => writerejecttoggle1(row.id)}
                        className="btn btn-warning btn-block"
                        type="button"
                      >
                        Reject
                      </button>
                    </div>
                  </Col>
                </Col>
              </Row>
            )
          } else if (row.status == 2) {
            return (
              <Row>
                <Col xl={12}>
                  <Col xl={6}>
                    <div className="mt-0 d-grid">
                      <button
                        disabled
                        onClick={e => writerejecttoggle1(row.id)}
                        className="btn btn-warning btn-block"
                        type="button"
                      >
                        Rejected
                      </button>
                    </div>
                  </Col>
                </Col>
              </Row>
            )
          } else {
            return ""
          }
        },
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row.original)}>
                  View Details
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "    ",
        Cell: cellProps => {
          const row = cellProps.row.original
          if (row.status == 2) {
            return (
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="card-drop">
                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    onClick={e => viewrejectiontoggle1(row)}
                    //   onClick={() => {
                    //     const customerData = cellProps.row.original;
                    //     handleCustomerClick(customerData);
                    //   }
                    //   }
                  >
                    <i
                      className="font-size-16 text-success me-1"
                      id="edittooltip"
                    ></i>
                    View Reason
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      View Reason
                    </UncontrolledTooltip>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          } else {
            return ""
          }
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()
  const [tagid, settagid] = useState("")
  const writerejecttoggle1 = id => {
    settagid(id)
    setWritereject(!writereject)
  }
  const viewrejectiontoggle1 = row => {
    setViewrejection(!viewrejection)
    let fdata = {
      id: row.id,
      pname: row.pname,
      message: row.message,
      reason: row.reason,
      status: row.status,
    }
    setobjData(fdata)
  }
  const callAcceptRejectApi = (id, reason, status) => {
    let url = `${HOSTNAME}${DEALERBUYBACKDETAILS}?status=${status}&id=${id}&reason=${reason}`
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        if (status == 1) {
          toast.success("Product accepted successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.success("Product rejected successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        }

        dispatch(delearBuyBackGet({ type: "DC" }, {}))
      })
      .catch(error => {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(delearBuyBackGet({ type: "DC" }, {}))
  }, [])

  const dealer_resellingproductlistData = useSelector(
    state => state.DelearProductGet
  )
  if (
    dealer_resellingproductlistData &&
    dealer_resellingproductlistData.dealerbuyback
  ) {
    data =
      dealer_resellingproductlistData.dealerbuyback.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Refurbished"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // notes: 'Parsley is a javascript form validation before sending it to your server.',
      writereason: "",
    },
    validationSchema: Yup.object({
      // notes: Yup.string().required("Please Enter Notes"),
      writereason: Yup.string().required("Please Enter Your Reason"),
    }),
    onSubmit: (values, { resetForm }) => {
      callAcceptRejectApi(tagid, values.writereason, 2)
      resetForm()
      setWritereject(!writereject)
    },
  })

  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }
  const [objData, setobjData] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobjData(row)
  }
  const [writereject, setWritereject] = useState(false)
  const [viewrejection, setViewrejection] = useState(false)

  const writerejecttoggle = () => {
    setWritereject(!writereject)
  }

  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Refurbished" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            dealer_resellingproductlistData.dealerbuyback &&
            dealer_resellingproductlistData.dealerbuyback.count
          }
          next={
            dealer_resellingproductlistData.dealerbuyback &&
            dealer_resellingproductlistData.dealerbuyback.next
          }
          previous={
            dealer_resellingproductlistData.dealerbuyback &&
            dealer_resellingproductlistData.dealerbuyback.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Name</b>
                            <br></br>
                            <p>{objData && objData.customerName}</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer City</b>
                            <br></br>
                            <p>{objData && objData.city}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Customer Number</b>
                            <br></br>
                            <p>{objData && objData.contactNumber}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
          <ModalHeader>Customer Product Details</ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{objData && objData.productName}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {objData && objData.status == 1 ? (
                      ""
                    ) : (
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Registration Date</b>
                              <br></br>
                              <p>{objData && objData.AMCStartDate}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <br />
                  {objData.status == 1 ? (
                    ""
                  ) : (
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product AMC Remaning Days</b>
                              <br></br>
                              <p>{objData && objData.remainingdays}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>

          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Notes</Label>
                  <Input
                    name="notes"
                    placeholder="Notes"
                    type="textarea"
                    className="form-control"
                    id="validationCustom01"
                    value={objData && objData.reason}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>

      {/* ------------------- Write Reason For Rejection------------- */}
      <Modal
        isOpen={writereject}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={writerejecttoggle}
      >
        <Form onSubmit={validation.handleSubmit}>
          <div className="modal-content">
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <h5>Reason for rejection</h5>
                    <p style={{ fontSize: "12px" }}></p>
                    <Label htmlFor="validationCustom01">Write reason</Label>
                    <Input
                      name="writereason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.writereason || ""}
                      invalid={
                        validation.touched.writereason &&
                        validation.errors.writereason
                          ? true
                          : false
                      }
                    />
                    {validation.touched.writereason &&
                    validation.errors.writereason ? (
                      <FormFeedback type="invalid">
                        {validation.errors.writereason}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>

            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="submit" color="primary">
                Submit
              </Button>

              <Button
                type="button"
                onClick={writerejecttoggle}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Form>
      </Modal>

      {/* ------------------- View Reason For Rejection------------- */}
      <Modal
        isOpen={viewrejection}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={viewrejectiontoggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={viewrejectiontoggle}>
            Reason for rejection
          </ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <Col md="12">
                <FormGroup className="mb-3">
                  <p> </p>
                  <Label htmlFor="validationCustom01">View reason</Label>
                  <Input
                    name="viewreason"
                    placeholder="Enter Text Here"
                    type="textarea"
                    rows={4}
                    className="form-control"
                    id="validationCustom01"
                    value={objData && objData.message}
                    disabled
                  />
                </FormGroup>
              </Col>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
