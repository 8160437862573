// src/components/filter.
import React, { useState,useMemo, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './servicetablecontainer';
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { delearServicesGet } from "store/actions";

function extractIdAndPName(item) {
  return {
      id: item.id,
      customerName:`${item.customer && item.customer.firstName} ${item.customer && item.customer.lastName}`,
      contactNumber:`${item.customer && item.customer.contactNumber}`,
      city:`${item.customer && item.customer.city}`,
      status: item.serviceStatus,
      serviceStatustext:item.serviceStatustext,
      message:item.message,
      productName:item.productName,
      pid:item.pid,
      pnumber:item.pnumber,

  };
}

function DatatableTables() { 
    const columns = useMemo(
        () => [
            {
                Header: 'Sr.No',
                accessor: 'id',
            },
            {
              Header: 'Customer Name',
              accessor: 'customerName'
            },
            {
              Header: 'Contact Number',
              accessor: 'contactNumber'
            },
            {
              Header: 'Customer City',
              accessor: 'city'
            },
            {
                Header: 'Status',
                accessor: 'serviceStatustext'
            },
            {
              Header: ' ',
              Cell: (cellProps) => {
                const row = cellProps.row.original
                return (
                  <Row>
                     <Col xl={12}>
                      <Link to="#" onClick={e=>togglemodal1(row)}>View Message</Link>
                      </Col>
                  </Row>
                );
              }
          },
             
        ],
        []
    );

    let data = [];
    const dispatch = useDispatch()

    useEffect(()=>{
      const isUserLoggedIn = localStorage.getItem('isLoggedIn');
      if (!isUserLoggedIn) {
        localStorage.setItem('isLoggedIn', 'true');
        window.location.reload();
      }
        dispatch(delearServicesGet({}, {}))
    },[])

    const dealer_resellingproductlistData =useSelector(state => state.DelearProductGet)
    console.log("dealerServices==========",dealer_resellingproductlistData)
    if(dealer_resellingproductlistData && dealer_resellingproductlistData.dealerServices){
        data = dealer_resellingproductlistData.dealerServices.results.data.map(extractIdAndPName);   
    }

    //meta title
    document.title = "Services";

    
    const [modal, setModal] = useState(false);
  
    const togglemodal = () => {
      setModal(!modal);
    };
    const [objData,setobjData]=useState({})
    const togglemodal1 = (row) => {
      setModal(!modal);
      setobjData(row)
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Services" />
                {/* <Table columns={columns} data={data} /> */}
                <TableContainer
                    count={dealer_resellingproductlistData.resellingtagproductcustomerDetails && dealer_resellingproductlistData.resellingtagproductcustomerDetails.count}
                    next={dealer_resellingproductlistData.resellingtagproductcustomerDetails && dealer_resellingproductlistData.resellingtagproductcustomerDetails.next}
                    previous={dealer_resellingproductlistData.resellingtagproductcustomerDetails && dealer_resellingproductlistData.resellingtagproductcustomerDetails.previous}
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>

            <Modal
                    isOpen={modal}
                    role="dialog"
                    size="lg"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={togglemodal}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={togglemodal}>
                      Ticket Message
                       
                      </ModalHeader>
                    
                      <ModalBody>
                     
                      <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Product Number</Label>
                          <Input
                            name="pid"
                            placeholder="Product Number"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={objData && objData.pnumber}
                            disabled
                          />
              
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Customer Name</Label>
                          <Input
                            name="customername"
                            placeholder="Customer Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={objData && objData.customerName}
                            disabled
                          />
                         
                        </FormGroup>
                      </Col>
                      
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Product Name</Label>
                          <Input
                            name="productname"
                            placeholder="Product Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={objData && objData.productName}
                            disabled
                          />
                         
                        </FormGroup>
                      </Col>
                     
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Message</Label>
                          <Input
                            name="message"
                            placeholder="Message"
                            type="textarea"
                            className="form-control"
                            id="validationCustom01"
                            value={objData && objData.message}
                            disabled
                          />
                         
                        </FormGroup>
                      </Col>
                     
                    </Row>
                    
                      </ModalBody>
                      
                    </div>
                     
         </Modal>

        </div>
    );
}
DatatableTables.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTables;