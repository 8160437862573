// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./existingcustomerlisttablecontainer"
import { existingCustomerListGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  FormFeedback,
  ModalFooter,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  Input,
  FormGroup,
  Row,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { HOSTNAME } from "config/config"
import { toast } from "react-toastify"
import axios from "axios"

function extractIdAndPName(item) {
  return {
    id: item.id,
    firstName: `${item.firstName} ${item.lastName}`,
    contactNumber: item.contactNumber,
    dealerName: `${item.dealerDetails && item.dealerDetails.firstName} ${
      item.dealerDetails && item.dealerDetails.lastName
    }`,
    customerFirstName: item.firstName,
    customerLastName: item.lastName,
    dealercontactNumber: `${
      item.dealerDetails && item.dealerDetails.contactNumber
    }`,
    // dealer:item.dealer,
    city: item.city,
    email: item.email,
    dealerId: item?.dealerDetails?.id,
  }
}

function DatatableTablesManufacture() {
  const [modal, setModal] = useState(false)
  const [rowData, setRowData] = useState({})

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const initialValues = {
    firstName: rowData?.customerFirstName || "",
    lastName: rowData?.customerLastName || "",
    contactNumber: rowData?.contactNumber || "",
    email: rowData?.email || "",
    city: rowData?.city || "",
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Dealer First Name is required"),
    lastName: Yup.string().required("Dealer Last Name is required"),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    city: Yup.string().required("City is required"),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    let data = new FormData()

    data.append("firstName", values.firstName)
    data.append("lastName", values.lastName)
    data.append("contactNumber", values.contactNumber)
    data.append("email", values.email)
    data.append("city", values.city)
    data.append("dealerId", rowData?.dealerId)

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/update-customer-product/${rowData?.id}/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        dispatch(existingCustomerListGet({}, {}))
        toast.success("Dealers customer succesfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setSubmitting(false)
        setModal(false)
      })
  }

  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "firstName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Dealer Name",
        accessor: "dealerName",
      },
      {
        Header: "Dealer Contact Number",
        accessor: "dealercontactNumber",
      },
      {
        Header: "Customer City",
        accessor: "city",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={() => togglemodal(row.original)}>
                  Edit Profile
                </Link>
                {/* <a href="">View Details</a> */}
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  const togglemodal = row => {
    setModal(!modal)
    setRowData(row)
  }

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(existingCustomerListGet({}, {}))
  }, [])

  const existingCustomerListData = useSelector(state => state.CustomerGet)

  if (existingCustomerListData && existingCustomerListData.customerDetails) {
    data =
      existingCustomerListData.customerDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Customer"

  const [writereject, setWritereject] = useState(false)
  const [viewrejection, setViewrejection] = useState(false)

  const writerejecttoggle = () => {
    setWritereject(!writereject)
  }

  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      writereason: "",
    },
    validationSchema: Yup.object({
      writereason: Yup.string().required("Please Enter Your Reason"),
    }),
    onSubmit: values => {},
  })

  return (
    <Loadder active={existingCustomerListData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Customers" />

          <TableContainer
            count={existingCustomerListData.customerDetails.count}
            next={existingCustomerListData.customerDetails.next}
            previous={existingCustomerListData.customerDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        {/* Edit Profile Modal */}
        <Modal
          isOpen={modal}
          role="dialog"
          size="lg"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="modal-content">
                  <ModalHeader toggle={togglemodal}>Edit Profile</ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="firstName">Customer First Name</Label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="form-control"
                            placeholder="Customer First Name"
                            as={Input}
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="lastName">Customer Last Name</Label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="form-control"
                            placeholder="Customer Last Name"
                            as={Input}
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="contactNumber">Contact Number</Label>
                          <Field
                            type="number"
                            name="contactNumber"
                            id="contactNumber"
                            className="form-control"
                            placeholder="Contact Number"
                            as={Input}
                          />
                          <ErrorMessage
                            name="contactNumber"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="email">Contact Email</Label>
                          <Field
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Email"
                            as={Input}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="city">City</Label>
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            className="form-control"
                            placeholder="City"
                            as={Input}
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      onClick={togglemodal}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* ------------------- Write Reason For Rejection------------- */}
        <Modal
          isOpen={writereject}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={writerejecttoggle}
        >
          <div className="modal-content">
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <h5>Reason for rejection</h5>
                    <p style={{ fontSize: "12px" }}></p>
                    <Label htmlFor="validationCustom01">Write reason</Label>
                    <Input
                      name="writereason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.writereason || ""}
                      invalid={
                        validation.touched.writereason &&
                        validation.errors.writereason
                          ? true
                          : false
                      }
                    />
                    {validation.touched.writereason &&
                    validation.errors.writereason ? (
                      <FormFeedback type="invalid">
                        {validation.errors.writereason}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>

            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="button" color="primary">
                Submit
              </Button>

              <Button
                type="button"
                onClick={writerejecttoggle}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Modal>

        {/* ------------------- View Reason For Rejection------------- */}
        <Modal
          isOpen={viewrejection}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={viewrejectiontoggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={viewrejectiontoggle}>
              Reason for rejection
            </ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <p> </p>
                    <Label htmlFor="validationCustom01">View reason</Label>
                    <Input
                      name="viewreason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
