import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { ADMINBUYBACK_DATA_GET, ADMINBUYBACK_DATA_SUCCESS } from "./actionTypes"

import { adminBuyBackDataGet, adminBuyBackDataSuccess } from "./actions"

import { HOSTNAME, ADMINBUYBACKGET } from "config/config"

import { apiError } from "store/actions"
import axios from "axios"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function* buyBackData({ payload: { query, history } }) {
  try {
    // alert(query)
    let url = `${HOSTNAME}${ADMINBUYBACKGET}`
    if ("type" in query && !("query" in query)) {
      url = `${HOSTNAME}${ADMINBUYBACKGET}?type=${query.type}`
    }
    if ("query" in query && "type" in query) {
      // alert("ddd")
      url = `${HOSTNAME}${ADMINBUYBACKGET}?query=${query.query}&type=${query.type}`
    }
    if ("query" in query && !("type" in query)) {
      url = `${HOSTNAME}${ADMINBUYBACKGET}?query=${query.query}`
    }
    if ("status" in query && !(query.status == 3)) {
      url += `&status=${query.status}`
    }
    if ("url" in query) {
      url = query.url
    }
    // alert(url)
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(adminBuyBackDataSuccess(response.data))
      console.log("---======----==--=-=-=-=-", response.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* buyBackDataSaga() {
  yield takeEvery(ADMINBUYBACK_DATA_GET, buyBackData)
}

export default buyBackDataSaga
