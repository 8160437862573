import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import axios from "axios"
import { baseUrl } from "constants/Url"
import { toast } from "react-toastify"
import { HOSTNAME,VERIFYUSER } from "config/config"
const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
            let createObj = {
              userData: "",
              userType: "",
            }
            let data = new FormData();
            data.append('uname', user.uname);
            data.append('otp', user.otp);

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${HOSTNAME}${VERIFYUSER}`,
              headers: {},
              data: data,
            };

            const response = yield axios.request(config);

            console.log("-----",response.data);
            if (response.data.status_code === 200) {
                if (response.data.data.payload.userType =="A"){
                    createObj = {
                      userData: response.data.data,
                      userType: "admin",
                      }
                    localStorage.setItem("authUser", JSON.stringify(createObj))
                    yield put(loginSuccess(createObj))
                    // history("/admin-dashboard")
                    window.location.href = '/admin-dashboard';
                    toast.success("Login Sucessfully !", {
                      position: toast.POSITION.TOP_RIGHT,
                    })

                  }
                else if(response.data.data.payload.userType =="D"){

                  createObj = {
                    userData: response.data.data,
                    userType: "delear",
                    }
                  localStorage.setItem("authUser", JSON.stringify(createObj))
                  yield put(loginSuccess(createObj))
                  // history("/delear-dashboard")
                  window.location.href = '/delear-dashboard';
                  toast.success("Login Sucessfully !", {
                    position: toast.POSITION.TOP_RIGHT,
                  })

                }
                else{
                  createObj = {
                    userData: response.data.data,
                    userType: "customer",
                    }
                  localStorage.setItem("authUser", JSON.stringify(createObj))
                  yield put(loginSuccess(createObj))
                  // history("/customer-view-tagged-product")
                  window.location.href = '/customer-view-tagged-product';
                  toast.success("Login Sucessfully !", {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }
              
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error("something went wrong", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("isLoggedIn")

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout)
    //   yield put(logoutUserSuccess(response))
    // }
    history("/pre-login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/dashboard")
      } else {
        history("/pre-login")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/dashboard")
  } catch (error) {
    console.log("error", error)
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
