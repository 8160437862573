import React, { useState,useEffect  } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
//redux
import { toast } from "react-toastify"
import { useSelector, useDispatch } from "react-redux"
import { GetLoginData } from "../redux/action/action1"
import { Link, json } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import Loadder from "components/Loadder/Loadder"
import { useNavigate } from "react-router-dom";
import {HOSTNAME,PRELOGIN} from "../../src/config/config";
const Login = props => {
  const history = useNavigate();
  //meta title
  document.title = "Login | MEMS - Medical Equipment Management "
  const [isLoader,setisLoader]=useState(false)
  const dispatch = useDispatch()
  const [uname,setUname]=useState("")
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      uname: uname || "",

    },
    
    validationSchema: Yup.object({
      uname: Yup.string().required("Please Enter Your UserName Or mobile number"),

    }),
    // onSubmit: values => {
    //   dispatch(loginUser(values, props.router.navigate))
    // },
    
    
  })
  useEffect(() => {
    const getUserData = JSON.parse(localStorage.getItem("authUser")) 
    // console.log("=>>>>getUserData",getUserData)
    if (getUserData && getUserData.userType =="admin"){
      history("/admin-dashboard")
    }
    if (getUserData && getUserData.userType =="delear"){
      history("/delear-dashboard")
    }
    if (getUserData && getUserData.userType =="customer"){
      history("/customer-view-tagged-product")
    }
  },[]);

  const SendOtp=(e)=>
  {    
    setisLoader(true)
    let data = new FormData();
      data.append('uname', uname);
      if(window?.innerWidth < 600){
      data.append('login_type', 1);
      }
      // alert(uname.length)
      if (uname.length>10 || uname.length<1){
        toast.error("Please put 10 digit mobile number", {
          position: toast.POSITION.TOP_RIGHT,
        })
        setisLoader(false);
      }
      else{

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${HOSTNAME}${PRELOGIN}`,
          headers: { 
            
          },
          data : data
        };
  
        axios.request(config)
        .then((result) => {
          console.log(result.data)
          // console.log(JSON.stringify(response.data));
          if (result.data.status_code === 200) {
                  // alert(result.data.message);
                  toast.success(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setisLoader(false);
                  const dataToPass = {
                    uname: uname,
                  };
                  history("/login",{ state: { data: dataToPass } })
                }
          else{
            // alert(result.data.message)
            // console.log("==========error",error)
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT,
              })
            setisLoader(false);
          }
        })
        .catch((error) => {
          toast.error(window?.innerWidth < 450 && error?.response?.data?.status_code === 409 ? <div className="d-flex">Customer not found request you to connect with admin or email us on <span><a href="mailto:support@sonastarhealthcarellp.com" style={{display:"block",padding:"0px"}}>support@sonastarhealthcarellp.com</a></span></div>:error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick:false,
            autoClose:false
          })
          setisLoader(false);
        });

      }
      
    // var formdata = new FormData();
    // formdata.append("uname", uname);

    // var requestOptions = {
    //   method: 'POST',
    //   body: formdata,
    //   redirect: 'follow'
    // };
    // try {
    //   fetch(`${HOSTNAME}${PRELOGIN}`, requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //     if (result.status_code === 200) {
    //       alert(result.message);
    //       setisLoader(false);
    //       const dataToPass = {
    //         uname: uname,
    //       };
    //       history("/login",{ state: { data: dataToPass } })
    //     }
    //     else{
    //       alert(result.message)
    //       setisLoader(false);
    //     }
    //     })
    //   .catch(error => {
    //     alert(error);
    //     setisLoader(false);
    //   });


    // } catch (error) {
    //   alert(error);
    //   setisLoader(false);
    // }
    

  }

  // const { error } = useSelector(state => ({
  //   error: state.Login.error,
  // }));
  // const loginState = useSelector(state => state.Login)
  // console.log("loginState", loginState)
  // const signIn = type => {
  //     dispatch(socialLogin(type, props.router.navigate));
  // };

  //for facebook and google authentication
  // const socialResponse = type => {
  //   signIn(type);
  // };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}


  return (
    <Loadder  active={isLoader}>
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container className="d-flex justify-content-center ">
            <Row className="justify-content-center w-100" style={{"max-width": "100%"}}>
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                        <div className="mb-3">
                          <Label className="form-label">Mobile Number</Label>
                          <Input
                            name="uname"
                            className="form-control"
                            placeholder="Enter Mobile Name"
                            type="number"
                            // onChange={validation.handleChange}
                            onChange={(e)=>setUname(e.target.value)}
                            onBlur={validation.handleBlur}
                            value={validation.values.uname || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {/* {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null} */}
                        </div>
                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={SendOtp}
                          >
                            Send Otp
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                  {/* <p>
                    © {new Date().getFullYear()} MEMS with{" "}
                    <i className="mdi mdi-heart text-danger" />
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </Loadder>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
