import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  LOGOUT_USER,
  DELEARPRODUCT_GET,
  DELEARPRODUCT_SUCCESS,
  DELEAR_UPLOAD_POST,
  DEALERLIST_GET,
  DEALERLIST_SUCCESS, 
  TAGGEDDEALER_GET,
  TAGGEDDEALER_SUCCESS,
  DEALER_NEW_PRODUCT_REQUEST_GET,
  DEALER_MANUFACTURE_ORDERS_GET,
  DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET,
  DEALER_CUSTOMER_GET,
  DEALER_SERVICES_GET,
  DEALER_BUYBACK_GET,
  DEALER_RESELLINGEARNING_GET,

} from "./actionTypes"

import { 
  delearProductGet,
  delearProductSuccess,
  dealerListGet,
  dealerListSuccess,
  taggeddealerGet,
  taggeddealerSuccess,
  delearNewProductRequestSuccess,
  delearManufactureOrdersSuccess,
  delearResellingTagProductCustomerSuccess,
  delearCustomerSuccess,
  delearServicesSuccess,
  delearBuyBackSuccess,
  delearResellingEarningSuccess,
  

} from "./actions"

import { 
  HOSTNAME,
  DELEARPRODUCTGET,
  DELEARPRODUCTUPLOAD,
  DEALERLISTGET, 
  TAGGEDDEALERGET,
  DEALERNEWPRODUCTREQUEST,
  DEALERMANUFACTUREORDERSGET,
  DEALERRESELLINGTAGPRODUCTLISTCUSTOMER,
  DEALERGETCUSTOMERDETAILS,
  DEALERSERVICESDETAILS,
  DEALERBUYBACKDETAILS,
  DEALERRESELLINGEARNING,

} from "config/config"

import { apiError } from "store/actions"
import axios from "axios"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser")) 

function* delearProducts({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DELEARPRODUCTGET}`
            if(query.query){
              url =`${HOSTNAME}${DELEARPRODUCTGET}?query=${query.query}`
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearProductSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

//  --- Dealer List-------
function* delearList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERLISTGET}`
            if(query.query){
              url =`${HOSTNAME}${DEALERLISTGET}?query=${query.query}`
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(dealerListSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------ Tagged Dealer-----
function* taggeddealer({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${TAGGEDDEALERGET}`
            if(query.query){
              url =`${HOSTNAME}${TAGGEDDEALERGET}&query=${query.query}`
              // alert(url)
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(taggeddealerSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ----------New Product Request --------------------
function* delearNewProductRequestList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERNEWPRODUCTREQUEST}`
            if(query.query){
              url =`${HOSTNAME}${DEALERNEWPRODUCTREQUEST}?query=${query.query}`
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearNewProductRequestSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* delearManufactureOrderstList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERMANUFACTUREORDERSGET}`
            if(query.url){
              url=query.url
            }
            else{
                const queryParams = [];
                if (query.query) {
                  queryParams.push(`query=${query.query}`);
                }
                if (query.status && query.status !== '3') {
                  queryParams.push(`status=${query.status}`);
                }
                if (queryParams.length > 0) {
                  url = `${HOSTNAME}${DEALERMANUFACTUREORDERSGET}?${queryParams.join('&')}`;
                }
            }
            

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearManufactureOrdersSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* delearResellingTagProductCustomerList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERRESELLINGTAGPRODUCTLISTCUSTOMER}`
            if(query.url){
              url=query.url
            }
            else{
                const queryParams = [];
                if (query.query) {
                  queryParams.push(`query=${query.query}`);
                }
                if (query.status && query.status !== '3') {
                  queryParams.push(`status=${query.status}`);
                }
                if (query.isdemo) {
                  queryParams.push(`isdemo=${query.isdemo}`);
                }
                if (queryParams.length > 0) {
                  url = `${HOSTNAME}${DEALERRESELLINGTAGPRODUCTLISTCUSTOMER}?${queryParams.join('&')}`;
                }
            }
            

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearResellingTagProductCustomerSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* delearCustomerList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERGETCUSTOMERDETAILS}`
            if(query.url){
              url=query.url
            }
            else{
                const queryParams = [];
                if (query.query) {
                  queryParams.push(`query=${query.query}`);
                }
                if (query.status && query.status !== '3') {
                  queryParams.push(`status=${query.status}`);
                }
                if (query.isdemo) {
                  queryParams.push(`isdemo=${query.isdemo}`);
                }
                if (queryParams.length > 0) {
                  url = `${HOSTNAME}${DEALERGETCUSTOMERDETAILS}?${queryParams.join('&')}`;
                }
            }
            

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearCustomerSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* delearServicesList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERSERVICESDETAILS}`
            if(query.url){
              url=query.url
            }
            else{
                const queryParams = [];
                if (query.query) {
                  queryParams.push(`query=${query.query}`);
                }
                if (query.status && query.status !== '4') {
                  queryParams.push(`status=${query.status}`);
                }
                if (query.isdemo) {
                  queryParams.push(`isdemo=${query.isdemo}`);
                }
                if (queryParams.length > 0) {
                  url = `${HOSTNAME}${DEALERSERVICESDETAILS}?${queryParams.join('&')}`;
                }
            }
            

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearServicesSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* delearBuyBackList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERBUYBACKDETAILS}`
            if(query.url){
              url=query.url
            }
            else{
                const queryParams = [];
                if (query.query) {
                  queryParams.push(`query=${query.query}`);
                }
                if (query.status && query.status !== '4' && query.status !== '3') {
                  queryParams.push(`status=${query.status}`);
                }
                if (query.type) {
                  queryParams.push(`type=${query.type}`);
                }
                if (queryParams.length > 0) {
                  url = `${HOSTNAME}${DEALERBUYBACKDETAILS}?${queryParams.join('&')}`;
                }
            }
            

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearBuyBackSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* delearResellingEarningList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERRESELLINGEARNING}`
            if(query.url){
              url=query.url
            }
            else{
                const queryParams = [];
                if (query.query) {
                  queryParams.push(`query=${query.query}`);
                }
                if (query.status && query.status !== '3') {
                  queryParams.push(`status=${query.status}`);
                }
                if (query.type) {
                  queryParams.push(`type=${query.type}`);
                }
                if (query.startDate && query.endDate) {
                  queryParams.push(`startDate=${query.startDate}`);
                  queryParams.push(`endDate=${query.endDate}`);
                }
                if (queryParams.length > 0) {
                  url = `${HOSTNAME}${DEALERRESELLINGEARNING}?${queryParams.join('&')}`;
                }
            }
            

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(delearResellingEarningSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------
function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/pre-login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* delearProductUpload({ payload: { fileData, history } }) {
  try {
            // alert(fileData)
            // let data = new FormData();
            // data.append('file', data);
            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${HOSTNAME}${DELEARPRODUCTUPLOAD}`,
              headers: { 
                'Authorization': `${getUserData.userData.token}`, 
                'Content-Type': 'multipart/form-data',
              },
              data : fileData.data
            };
            const response = yield axios.request(config);
            if(response.status==400)
            {
              toast.error("File Note Valid", {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }
            console.log("+++++++++++++",response);
            if (response.data.status_code === 201) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              toast.success(`Total created: ${response.data.data.created}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              toast.success(`Total updated: ${response.data.data.updated}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(delearProductGet({},{}));

            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error("something went wrong", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}


function* delearProductsSaga() {
  yield takeEvery(DELEARPRODUCT_GET, delearProducts)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(DELEAR_UPLOAD_POST,delearProductUpload)
  yield takeEvery(DEALERLIST_GET, delearList)
  yield takeEvery(TAGGEDDEALER_GET, taggeddealer)
  yield takeEvery(DEALER_NEW_PRODUCT_REQUEST_GET, delearNewProductRequestList)
  yield takeEvery(DEALER_MANUFACTURE_ORDERS_GET, delearManufactureOrderstList)
  yield takeEvery(DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET, delearResellingTagProductCustomerList)
  yield takeEvery(DEALER_CUSTOMER_GET, delearCustomerList)
  yield takeEvery(DEALER_SERVICES_GET, delearServicesList)
  yield takeEvery(DEALER_BUYBACK_GET, delearBuyBackList)
  yield takeEvery(DEALER_RESELLINGEARNING_GET, delearResellingEarningList)
  
  
  
}

export default delearProductsSaga
