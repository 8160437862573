import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADMINEARNING_DATA_GET,
  ADMINEARNING_DATA_SUCCESS,
  ADMINEARNING_MP_DATA_GET,
  ADMINEARNING_MP_DATA_SUCCESS,
} from "./actionTypes"

import {
  adminEarningDataGet,
  adminEarningSuccess,
  adminEarningMPDataGet,
  adminEarningMPSuccess,
} from "./actions"

import { HOSTNAME, ADMINEARNINGGET, ADMINEARNINGMPDATAGET } from "config/config"

import { apiError } from "store/actions"
import axios from "axios"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function* earningData({ payload: { query, history } }) {
  try {
    // alert(query)
    let url = `${HOSTNAME}${ADMINEARNINGGET}`
    if ("userType" in query && !("query" in query)) {
      url = `${HOSTNAME}${ADMINEARNINGGET}?userType=${query.userType}`
    }
    if ("query" in query && "userType" in query) {
      // alert("ddd")
      url = `${HOSTNAME}${ADMINEARNINGGET}?query=${query.query}&userType=${query.userType}`
    }
    if ("query" in query && !("userType" in query)) {
      url = `${HOSTNAME}${ADMINEARNINGGET}?query=${query.query}`
    }
    if ("status" in query) {
      url += `&status=${query.status}`
    }
    if ("startDate" in query) {
      url += `&startDate=${query.startDate}`
    }
    if ("endDate" in query) {
      url += `&endDate=${query.endDate}`
    }
    if ("url" in query) {
      url = query.url
    }
    // alert(url)
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(adminEarningSuccess(response.data))
      console.log("---======----==--=-=-=-=-", response.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

//  Earning Consumables Product

function* earningManufacturedData({ payload: { query, history } }) {
  try {
    // alert(query)
    let url = `${HOSTNAME}${ADMINEARNINGMPDATAGET}`
    if ("userType" in query && !("query" in query)) {
      url = `${HOSTNAME}${ADMINEARNINGMPDATAGET}?userType=${query.userType}`
    }
    if ("query" in query && "userType" in query) {
      // alert("ddd")
      url = `${HOSTNAME}${ADMINEARNINGMPDATAGET}?query=${query.query}&userType=${query.userType}`
    }
    if ("query" in query && !("userType" in query)) {
      url = `${HOSTNAME}${ADMINEARNINGMPDATAGET}?query=${query.query}`
    }
    if ("status" in query) {
      url += `&status=${query.status}`
    }
    if ("startDate" in query) {
      url += `&startDate=${query.startDate}`
    }
    if ("endDate" in query) {
      url += `&endDate=${query.endDate}`
    }
    if ("isdealer" in query) {
      url += `&isdealer=${query.isdealer}`
    }
    if ("iscustomer" in query) {
      url += `&iscustomer=${query.iscustomer}`
    }
    // alert(url)
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(adminEarningMPSuccess(response.data))
      console.log("---======----==--=-=-=-=-", response.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* adminEarningDataSaga() {
  yield takeEvery(ADMINEARNING_DATA_GET, earningData)
  yield takeEvery(ADMINEARNING_MP_DATA_GET, earningManufacturedData)
}

export default adminEarningDataSaga
