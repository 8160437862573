import {
    TAGGED_PRODUCTCUSTOMER_SUCCESS,
    TAGGED_PRODUCTCUSTOMER_GET,

    CUSTOMER_VERIFY_PRODUCT_LIST_GET,
    CUSTOMER_VERIFY_PRODUCT_LIST_SUCCESS,

    CUSTOMER_ACTIVATED_PRODUCT_LIST_GET,
    CUSTOMER_ACTIVATED_PRODUCT_LIST_SUCCESS,

    CUSTOMER_BUYBACK_PRODUCT_LIST_GET,
    CUSTOMER_BUYBACK_PRODUCT_LIST_SUCCESS,

    CUSTOMER_VIEWORDERS_LIST_GET,
    CUSTOMER_VIEWORDERS_LIST_SUCCESS,

    CUSTOMER_NEWPRODUCTREQUEST_LIST_GET,
    CUSTOMER_NEWPRODUCTREQUEST_LIST_SUCCESS,

    CUSTOMER_SPEND_LIST_GET,
    CUSTOMER_SPEND_LIST_SUCCESS,

    CUSTOMER_SERVICES_LIST_GET,
    CUSTOMER_SERVICES_LIST_SUCCESS,

    CUSTOMER_CENTRIC_LIST_GET,
    CUSTOMER_CENTRIC_LIST_SUCCESS,
} from "./actionTypes";

export const customerCentricListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_CENTRIC_LIST_GET,
    payload: { query, history },
  }
}

export const customerCentricListSuccess = data => {
  return {
    type: CUSTOMER_CENTRIC_LIST_SUCCESS,
    payload: data,
  }
}
//---

export const customerServicesListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_SERVICES_LIST_GET,
    payload: { query, history },
  }
}

export const customerServicesListSuccess = data => {
  return {
    type: CUSTOMER_SERVICES_LIST_SUCCESS,
    payload: data,
  }
}
//---

export const customerSpendListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_SPEND_LIST_GET,
    payload: { query, history },
  }
}

export const customerSpendListSuccess = data => {
  return {
    type: CUSTOMER_SPEND_LIST_SUCCESS,
    payload: data,
  }
}
//---

export const customerNewProductRequestListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_NEWPRODUCTREQUEST_LIST_GET,
    payload: { query, history },
  }
}

export const customerNewProductRequestListSuccess = data => {
  return {
    type: CUSTOMER_NEWPRODUCTREQUEST_LIST_SUCCESS,
    payload: data,
  }
}
//---

export const customerViewOrdersListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_VIEWORDERS_LIST_GET,
    payload: { query, history },
  }
}

export const customerViewOrdersListSuccess = data => {
  return {
    type: CUSTOMER_VIEWORDERS_LIST_SUCCESS,
    payload: data,
  }
}
//---

export const customerBuyBackProductListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_BUYBACK_PRODUCT_LIST_GET,
    payload: { query, history },
  }
}

export const customerBuyBackProductListSuccess = data => {
  return {
    type: CUSTOMER_BUYBACK_PRODUCT_LIST_SUCCESS,
    payload: data,
  }
}
//---

export const customerActivatedProductListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_ACTIVATED_PRODUCT_LIST_GET,
    payload: { query, history },
  }
}

export const customerActivatedProductListSuccess = data => {
  return {
    type: CUSTOMER_ACTIVATED_PRODUCT_LIST_SUCCESS,
    payload: data,
  }
}
//---

export const customerVerifyProductListGet = (query, history) => { 
  // alert("action")
  return {
    type: CUSTOMER_VERIFY_PRODUCT_LIST_GET,
    payload: { query, history },
  }
}

export const customerVerifyProductListSuccess = data => {
  return {
    type: CUSTOMER_VERIFY_PRODUCT_LIST_SUCCESS,
    payload: data,
  }
}
//---
export const taggedProductCustomerGet = (query, history) => { 
    // alert("action")
    return {
      type: TAGGED_PRODUCTCUSTOMER_GET,
      payload: { query, history },
    }
  }
  
  export const taggedProductCustomerSuccess = data => {
    return {
      type: TAGGED_PRODUCTCUSTOMER_SUCCESS,
      payload: data,
    }
  }