import React from "react"
import { Navigate } from "react-router-dom"
// login ///
import Prelogin from "../Authentication/Prelogin"
import Login from "../Authentication/Login"
// ADMIN ------- START

import Dashboard from "admin/dashboard/index"
import DatatableTables from "admin/financialyear/DatatableTables"
import DatatableTablesResellingMaster from "admin/masters/resellingProductMaster"
import DatatableTablesDemoResellingMaster from "admin/masters/demochildresellingProductMaster"
import ParentDatatableTablesResellingMaster from "admin/masters/parentresellingProductMaster"
import DatatableTablesManufacture from "admin/masters/manufacturedProducts"
import DatatableTablesDealerMaster from "admin/masters/dealerMaster"
import DatatableTablesExistingCustomerMaster from "admin/masters/existingcustomermaster"
import DatatableTablesNewCustomerMaster from "admin/masters/newcustomermaster"
import DatatableTablesDemoProductMaster from "admin/masters/demoproductmaster"

import MaintainInventory from "admin/resellingproduct/maintainInventory"
import ViewResellingProduct from "admin/resellingproduct/viewResellingProduct"
import Tagtodealer from "admin/resellingproduct/tagtodealer"
import Tagtocustomer from "admin/resellingproduct/tagtocustomer"
import Taglistofcustomer from "admin/resellingproduct/taglistofcustomer"
import Taglistofdelear from "admin/resellingproduct/taglistofdelear"

import Manufacturedproduct_MaintainInventory from "admin/manufacturedproduct/maintainInventory"
import BulkConsumablesProduct from "admin/manufacturedproduct/bulkConsumablesProduct"
import ViewDealerTaggedProduct from "admin/manufacturedproduct/viewDealerTaggedProduct"
import DealerRequestForProduct from "admin/manufacturedproduct/dealerRequestForProduct"
import Viewmanufacturedproduct from "admin/manufacturedproduct/viewmanufacturedproduct"
import Neworderformcustomer from "admin/manufacturedproduct/neworderformcustomer"
import Neworderfromdealertocustomer from "admin/manufacturedproduct/neworderfromdealertocustomer"

import Demoproduct_MaintainInventory from "admin/demoproduct/maintainInventory"
import Viewdemoproduct from "admin/demoproduct/viewdemoproduct"
import Demoproducttagtodealer from "admin/demoproduct/tagtodealer"
import Taggedproductlist from "admin/demoproduct/taggedproductlist"

import Amccustomerlist from "admin/policyrenewal/amccustomerlist"

import Dealerslist from "admin/dealears/dealerslist"
import Taggeddealers from "admin/dealears/taggeddealers"

import Existingcustomerlist from "admin/customer/existingcustomerlist"
import Newcustomerlist from "admin/customer/newcustomerlist"
import Productverificationofexistingcustomer from "admin/customer/productverificationofexistingcustomer"
import Productverificationofnewcustomer from "admin/customer/productverificationofnewcustomer"

import Resellingproducts from "admin/earnings/resellingproducts"
import Resellingproductdealer from "admin/earnings/resellingproductdealer"
import Manufacturedproducts from "admin/earnings/manufacturedproducts"
import Manufacturedproductdealer from "admin/earnings/manufacturedproductdealer"

import Servicerequestfromcustomer from "admin/service/servicerequestfromcustomer"
import Servicesrequestdeleartocustomer from "admin/service/servicesrequestdeleartocustomer"
import Servicecentriccustomer from "admin/service/servicecentriccustomer"

import Buybackrequestfromcustomer from "admin/buyback/buybackrequestfromcustomer"
import Buybackrequestdeleartocustomer from "admin/buyback/buybackrequestdeleartocustomer"

// ADMIN ----------------- END

// DELEAR ------------------- START

import Deleardashboard from "delear/dashboard/index"
import CandidateOverview from "delear/contactus/index"

import MaintainInventoryDelear from "delear/consumablesProducts/MaintainInventoryDelear"
import TaggedConsumablesProductByAdmin from "delear/consumablesProducts/TaggedConsumablesProductByAdmin"
import ViewInventory from "delear/consumablesProducts/ViewInventory"
import NewOrderRequest from "delear/consumablesProducts/NewOrderRequest"
import NewOrderRequestFromCustomer from "delear/consumablesProducts/NewOrderRequestFromCustomer"

import Tagproductfromadmin from "delear/resellingproducts/tagproductfromadmin"
import Resellingproductlist from "delear/resellingproducts/resellingproductlist"
import Resellingtagtocustomer from "delear/resellingproducts/tagtocustomer"

import Newproductrequest from "delear/resellingproducts/newproductrequest"
import Taggedcustomerlist from "delear/resellingproducts/taggedcustomerlist"

import Delearmanufacturedproducts from "delear/manufacturedproducts/manufacturedproducts"

import Demoproducttagproductfromadmin from "delear/demoproduct/tagproductfromadmin"
import Demoproductlist from "delear/demoproduct/demoproductlist"
import Demotagtocustomer from "delear/demoproduct/tagtocustomer"

import Demoproducttaggedproductlist from "delear/demoproduct/taggedproductlist"

import Customer from "delear/customer/customer"

import Earningsresellingproducts from "delear/earnings/resellingproducts"
import Earningsmanufacturedproducts from "delear/earnings/manufacturedproducts"

import Service from "delear/service/service"
import Buyback from "delear/buyback/buyback"

// DELEAR --------------------- END

// CUSTOMER ---------------------- START
// import Customerdashboard from "customer/dashboard/index";

import Viewtaggedproduct from "customer/productregistration/viewtaggedproduct"
import Verifyproduct from "customer/productregistration/verifyproduct"
import Activatedproduct from "customer/productregistration/activatedproduct"
import Buybackproductlist from "customer/productregistration/buybackproductlist"

import Vieworders from "customer/neworders/vieworders"
import Neworderrequest from "customer/neworders/neworderrequest"
import Customerresellingproducts from "customer/spends/resellingproducts"
import Manufacturedproduct from "customer/spends/manufacturedproduct"

import Servicelist from "customer/service/servicelist"
import Servicecentric from "customer/service/servicecentric"

import CustomercandidateOverview from "customer/contactus/index"
// CUSTOMER ---------------------- END

// Pages Component
import Chat from "../pages/Chat/Chat"

// File Manager
import FileManager from "../pages/FileManager/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// //Tasks
import TasksList from "../pages/Tasks/tasks-list"
import TasksCreate from "../pages/Tasks/tasks-create"

// //Projects
import ProjectsGrid from "../pages/Projects/projects-grid"
import ProjectsList from "../pages/Projects/projects-list"
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"
import ProjectsCreate from "../pages/Projects/projects-create"

// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailBasicTemplte from "../pages/Email/email-basic-templte"
import EmailAlertTemplte from "../pages/Email/email-template-alert"
import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
// import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard

// import DashboardSaas from "../pages/Dashboard-saas/index"; // not unused
// import DashboardCrypto from "../pages/Dashboard-crypto/index"; // not unused
// import Blog from "../pages/Dashboard-Blog/index"; // not unused
// import DashboardJob from "../pages/DashboardJob/index"; // not unused

//Crypto
import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet"
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell"
import CryptoExchange from "../pages/Crypto/crypto-exchange"
import CryptoLending from "../pages/Crypto/crypto-lending"
import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application"
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
// import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import DragDropTables from "../pages/Tables/DragDropTables"

//Blog
import BlogList from "../pages/Blog/BlogList/index"
import BlogGrid from "../pages/Blog/BlogGrid/index"
import BlogDetails from "../pages/Blog/BlogDetails"

//Job
import JobGrid from "../pages/JobPages/JobGrid/index"
import JobDetails from "../pages/JobPages/JobDetails"
import JobCategories from "../pages/JobPages/JobCategories"
import JobList from "../pages/JobPages/JobList"
import ApplyJobs from "../pages/JobPages/ApplyJobs/index"
import CandidateList from "../pages/JobPages/CandidateList"
// import CandidateOverview from "../pages/JobPages/CandidateOverview";

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormLayouts from "../pages/Forms/FormLayouts"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiOffCanvas from "pages/Ui/UiOffCanvas"
import UiUtilitie from "../pages/Ui/UiUtilitie"
import UiPlaceholders from "../pages/Ui/UiPlaceholders"
import UiToasts from "../pages/Ui/UiToast"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

const authProtectedRoutes = [
  // ADMIN------START
  { path: "/", component: <Prelogin /> },
  { path: "/admin-dashboard", component: <Dashboard /> },
  { path: "/admin-financial-year", component: <DatatableTables /> },
  {
    path: "/admin-reselling-master",
    component: <DatatableTablesResellingMaster />,
  },
  {
    path: "/admin-parent-reselling-master",
    component: <ParentDatatableTablesResellingMaster />,
  },
  {
    path: "/admin-manufacture-master",
    component: <DatatableTablesManufacture />,
  },
  { path: "/admin-dealer-master", component: <DatatableTablesDealerMaster /> },
  {
    path: "/admin-existing-customer-master",
    component: <DatatableTablesExistingCustomerMaster />,
  },
  {
    path: "/admin-new-customer-master",
    component: <DatatableTablesNewCustomerMaster />,
  },
  {
    path: "/admin-demo-product-master",
    component: <DatatableTablesDemoProductMaster />,
  },
  {
    path: "/admin-demo-reselling-master",
    component: <DatatableTablesDemoResellingMaster />,
  },
  { path: "/admin-maintain-inventory", component: <MaintainInventory /> },
  {
    path: "/admin-view-reselling-product",
    component: <ViewResellingProduct />,
  },
  { path: "/admin-tag-to-dealer", component: <Tagtodealer /> },
  { path: "/admin-tag-to-customer", component: <Tagtocustomer /> },
  { path: "/admin-tag-list-of-customer", component: <Taglistofcustomer /> },
  { path: "/admin-tag-list-of-delear", component: <Taglistofdelear /> },
  {
    path: "/admin-maintain-inventry",
    component: <Manufacturedproduct_MaintainInventory />,
  },
  {
    path: "/admin-bulk-consumables-product",
    component: <BulkConsumablesProduct />,
  },
  {
    path: "/admin-view-dealer-tagged-product",
    component: <ViewDealerTaggedProduct />,
  },
  {
    path: "/admin-dealer-request-for-product",
    component: <DealerRequestForProduct />,
  },
  {
    path: "/admin-view-manufactured-product",
    component: <Viewmanufacturedproduct />,
  },
  {
    path: "/admin-new-order-form-customer",
    component: <Neworderformcustomer />,
  },
  {
    path: "/admin-new-order-form-dealer-to-customer",
    component: <Neworderfromdealertocustomer />,
  },
  {
    path: "/admin-demo-maintain-inventory",
    component: <Demoproduct_MaintainInventory />,
  },
  { path: "/admin-view-demo-product", component: <Viewdemoproduct /> },
  { path: "/admin-demo-tag-to-dealer", component: <Demoproducttagtodealer /> },
  { path: "/admin-tagged-product-list", component: <Taggedproductlist /> },
  { path: "/admin-policy-renewal", component: <Amccustomerlist /> },
  { path: "/admin-dealers-list", component: <Dealerslist /> },
  { path: "/admin-tagged-dealers", component: <Taggeddealers /> },
  {
    path: "/admin-existing-customer-list",
    component: <Existingcustomerlist />,
  },
  { path: "/admin-new-customer-list", component: <Newcustomerlist /> },
  {
    path: "/admin-product-verification-of-existing-customer",
    component: <Productverificationofexistingcustomer />,
  },
  {
    path: "/admin-product-verification-of-new-customer",
    component: <Productverificationofnewcustomer />,
  },
  { path: "/admin-reselling-products", component: <Resellingproducts /> },
  {
    path: "/admin-reselling-product-dealer",
    component: <Resellingproductdealer />,
  },
  { path: "/admin-manufactured-products", component: <Manufacturedproducts /> },
  {
    path: "/admin-manufactured-product-dealer",
    component: <Manufacturedproductdealer />,
  },
  {
    path: "/admin-service-request-from-customer",
    component: <Servicerequestfromcustomer />,
  },
  {
    path: "/admin-services-request-delear-to-customer",
    component: <Servicesrequestdeleartocustomer />,
  },
  {
    path: "/admin-services-centric-customer",
    component: <Servicecentriccustomer />,
  },
  {
    path: "/admin-buy-back-request-from-customer",
    component: <Buybackrequestfromcustomer />,
  },
  {
    path: "/admin-buy-back-request-delear-to-customer",
    component: <Buybackrequestdeleartocustomer />,
  },

  // ADMIN ------END

  // DELEAR------ START
  { path: "/delear-contact-support", component: <CustomercandidateOverview /> },
  // { path: "/delear-contact-support", component: <CandidateOverview /> },
  { path: "/delear-dashboard", component: <Deleardashboard /> },
  {
    path: "/delear-maintain-inventory",
    component: <MaintainInventoryDelear />,
  },
  {
    path: "/delear-tagged-consumables-product-by-admin",
    component: <TaggedConsumablesProductByAdmin />,
  },
  {
    path: "/delear-view-inventory",
    component: <ViewInventory />,
  },
  {
    path: "/delear-new-order-request",
    component: <NewOrderRequest />,
  },
  {
    path: "/delear-new-order-request-from-customer",
    component: <NewOrderRequestFromCustomer />,
  },
  {
    path: "/delear-tag-product-from-admin",
    component: <Tagproductfromadmin />,
  },
  {
    path: "/delear-reselling-product-list",
    component: <Resellingproductlist />,
  },
  {
    path: "/delear-reselling-tag-to-customer",
    component: <Resellingtagtocustomer />,
  },
  { path: "/delear-new-product-request", component: <Newproductrequest /> },
  { path: "/delear-tagged-customer-list", component: <Taggedcustomerlist /> },
  {
    path: "/delear-manufactured-products",
    component: <Delearmanufacturedproducts />,
  },
  {
    path: "/delear-demo-product-tag-product-from-admin",
    component: <Demoproducttagproductfromadmin />,
  },
  { path: "/delear-demo-product-list", component: <Demoproductlist /> },
  {
    path: "/delear-demo-product-tag-to-customer",
    component: <Demotagtocustomer />,
  },
  {
    path: "/delear-demo-tagged-product-list",
    component: <Demoproducttaggedproductlist />,
  },
  { path: "/delear-customer", component: <Customer /> },
  {
    path: "/delear-reselling-products",
    component: <Earningsresellingproducts />,
  },
  {
    path: "/delear-earnings-manufactured-products",
    component: <Earningsmanufacturedproducts />,
  },
  { path: "/delear-service", component: <Service /> },
  { path: "/delear-buyback", component: <Buyback /> },

  // DELEAR------ END

  // CUSTOMER -------- START

  //  { path: "/customer-dashboard",component: <Customerdashboard/>},
  { path: "/customer-view-tagged-product", component: <Viewtaggedproduct /> },
  { path: "/customer-verify-product", component: <Verifyproduct /> },
  { path: "/customer-activated-product", component: <Activatedproduct /> },
  { path: "/customer-buyback-product-list", component: <Buybackproductlist /> },
  { path: "/customer-view-orders", component: <Vieworders /> },
  { path: "/customer-new-order-request", component: <Neworderrequest /> },
  {
    path: "/customer-reselling-product",
    component: <Customerresellingproducts />,
  },
  {
    path: "/customer-manufactured-product",
    component: <Manufacturedproduct />,
  },
  { path: "/customer-service-list", component: <Servicelist /> },
  { path: "/customer-service-centric", component: <Servicecentric /> },
  {
    path: "/customer-contact-support",
    component: <CustomercandidateOverview />,
  },

  // CUSTOMER --------- END

  // { path: "/dashboard-saas", component: <DashboardSaas /> },
  // { path: "/dashboard-crypto", component: <DashboardCrypto /> },
  // { path: "/blog", component: <Blog /> },
  // { path: "/dashboard-job", component: <DashboardJob /> },

  //Crypto
  { path: "/crypto-wallet", component: <CryptoWallet /> },
  { path: "/crypto-buy-sell", component: <CryptoBuySell /> },
  { path: "/crypto-exchange", component: <CryptoExchange /> },
  { path: "/crypto-lending", component: <CryptoLending /> },
  { path: "/crypto-orders", component: <CryptoOrders /> },
  { path: "/crypto-kyc-application", component: <CryptoKYCApplication /> },

  //chat
  { path: "/chat", component: <Chat /> },

  //File Manager
  { path: "/apps-filemanager", component: <FileManager /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Ecommerce
  {
    path: "/ecommerce-product-detail/:id",
    component: <EcommerceProductDetail />,
  },
  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-shops", component: <EcommerceShops /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },
  { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  { path: "/email-template-billing", component: <EmailTemplateBilling /> },

  //Invoices
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/invoices-detail/:id", component: <InvoiceDetail /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },

  // Tasks
  { path: "/tasks-list", component: <TasksList /> },
  { path: "/tasks-create", component: <TasksCreate /> },

  //Projects
  { path: "/projects-grid", component: <ProjectsGrid /> },
  { path: "/projects-list", component: <ProjectsList /> },
  { path: "/projects-overview", component: <ProjectsOverview /> },
  { path: "/projects-overview/:id", component: <ProjectsOverview /> },
  { path: "/projects-create", component: <ProjectsCreate /> },

  //Blog
  { path: "/blog-list", component: <BlogList /> },
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-details", component: <BlogDetails /> },

  //job
  { path: "/job-grid", component: <JobGrid /> },
  { path: "/job-details", component: <JobDetails /> },
  { path: "/job-categories", component: <JobCategories /> },
  { path: "/job-list", component: <JobList /> },
  { path: "/job-apply", component: <ApplyJobs /> },
  { path: "/candidate-list", component: <CandidateList /> },
  { path: "/candidate-overview", component: <CandidateOverview /> },

  // Contacts
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartist-charts", component: <ChartistChart /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/re-charts", component: <ReCharts /> },

  // Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-dragndrop", component: <DragDropTables /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },
  { path: "/maps-leaflet", component: <MapsLeaflet /> },

  // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-layouts", component: <FormLayouts /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-repeater", component: <FormRepeater /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-notifications", component: <UiNotifications /> },
  { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  { path: "/ui-utilities", component: <UiUtilitie /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-toasts", component: <UiToasts /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/pre-login" />,
  },
]

const publicRoutes = [
  { path: "/pre-login", component: <Prelogin /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  {
    path: "/auth-two-step-verification-2",
    component: <TwostepVerification2 />,
  },
]

export { authProtectedRoutes, publicRoutes }
