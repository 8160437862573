import {
  SERVICEREQUSETFROMCUSTOMER_GET,
  SERVICEREQUSETFROMCUSTOMER_SUCCESS,
  SERVICEREQUESTDEALERTOCUSTOMER_GET,
  SERVICEREQUESTDEALERTOCUSTOMER_SUCCESS,
  SERVICECENTRICCUSTOMER_GET,
  SERVICECENTRICCUSTOMER_SUCCESS,
} from "./actionTypes"

export const serviceRequestFromcustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: SERVICEREQUSETFROMCUSTOMER_GET,
    payload: { query, history },
  }
}

export const serviceRequestFromcustomerSuccess = data => {
  // alert("data")
  return {
    type: SERVICEREQUSETFROMCUSTOMER_SUCCESS,
    payload: data,
  }
}

// ------ service request dealer to customer

export const serviceRequestdealertocustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: SERVICEREQUESTDEALERTOCUSTOMER_GET,
    payload: { query, history },
  }
}

export const serviceRequestdealertocustomerSuccess = data => {
  // alert("data")
  return {
    type: SERVICEREQUESTDEALERTOCUSTOMER_SUCCESS,
    payload: data,
  }
}

// -----  SERVICE CENTRIC CUSTOMER------------

export const servicecentriccustomerGet = (query, history) => { 
  // alert("action") 
  return {
    type: SERVICECENTRICCUSTOMER_GET,
    payload: { query, history },
  }
}

export const servicecentriccustomerSuccess = data => {
  // alert("data")
  return {
    type: SERVICECENTRICCUSTOMER_SUCCESS,
    payload: data,
  }
}