// import React, { Fragment } from "react"
import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { toast } from "react-toastify"

import { useSelector, useDispatch } from "react-redux"
import { HOSTNAME, ADMINFINANCIALYEAR, GETYEARDATA } from "config/config"
import { adminFinancialDataGet } from "../../../src/store/actions"
import {
  InputGroup,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import "flatpickr/dist/themes/material_blue.css"
import "react-datepicker/dist/react-datepicker.css"
import Flatpickr from "react-flatpickr"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import axios from "axios"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const FinancialTableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)

    let url = `${HOSTNAME}${ADMINFINANCIALYEAR}?page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(adminFinancialDataGet({ url: url }, {}))
  }

  const dispatch = useDispatch()

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }
  const SearchByPname = e => {
    if (e.target.value) {
      let newq = {}
      newq["query"] = e.target.value
      if (selectedDateRange) {
        const startDate = selectedDateRange[0].toLocaleDateString()
        const endDate = selectedDateRange[1].toLocaleDateString()
        newq["startDate"] = startDate
        newq["endDate"] = endDate
      }
      if (getyear) {
        newq["year"] = getyear
      }
      if (soldto) {
        newq["soldto"] = soldto
      }
      console.log(newq, "===========")
      dispatch(adminFinancialDataGet(newq, {}))
    } else {
      dispatch(adminFinancialDataGet({}, {}))
    }
  }

  const [selectedFiles, setselectedFiles] = useState([])
  const [isOpen, setisOpen] = useState(false)
  const toggle1 = () => setisOpen(!isOpen)

  const SendFile = e => {
    // alert("okay")
    let data = new FormData()
    data.append("file", selectedFiles[0])
    dispatch(resellingProductUploadGet({ data: data }, {}))
    dispatch(adminFinancialDataGet({}, {}))
    setselectedFiles([])
    toggle1()
  }
  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  const onclickForPage = (url, number) => {
    // alert(number)
    setcurrentPage(number)
    dispatch(adminFinancialDataGet({ url: url }, {}))
  }
  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    getYearData()
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  }, [next, previous, currentPage, TotalPages])

  const [addnew, setAddnew] = useState(false)
  const addtoggle = () => {
    setAddnew(!addnew)
    setnewyear("")
  }

  const yearsetdata = e => {
    if (e == "0") {
      setAddnew(!addnew)
    } else {
      setgetyear(e)

      let newq = {}
      newq["year"] = e
      if (selectedDateRange) {
        const startDate = selectedDateRange[0].toLocaleDateString()
        const endDate = selectedDateRange[1].toLocaleDateString()
        newq["startDate"] = startDate
        newq["endDate"] = endDate
      }
      if (soldto) {
        newq["soldto"] = soldto
      }

      dispatch(adminFinancialDataGet(newq, {}))
    }

    // alert(e)
  }
  const [yearData, setyearData] = useState([])
  const getYearData = () => {
    let url = `${HOSTNAME}${GETYEARDATA}`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        // console.log(response.data.results.data)
        setyearData(response.data.results && response.data.results.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const [newyear, setnewyear] = useState("")
  const [getyear, setgetyear] = useState("")
  const [soldto, setsoldto] = useState("")
  const AddNewYear = () => {
    // alert(newyear)
    let data = new FormData()
    data.append("year", newyear)
    let url = `${HOSTNAME}${GETYEARDATA}`
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        // ...data.getHeaders()
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        console.log(JSON.stringify(response.data))
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        getYearData()
      })
      .catch(error => {
        console.log(error)
      })
    setAddnew(!addnew)
    setnewyear("")
  }
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const [searchData, setsearchData] = useState("")
  // const handleDateChange = (dates) => {
  //   console.log(dates,"==============")
  //   setSelectedDateRange(dates);
  //   const startDate = dates[0].toLocaleDateString();
  //   const endDate = dates[1].toLocaleDateString();
  //   // dispatch(adminEarningDataGet({"userType":"AC","startDate":startDate,"endDate":endDate}, {}))
  //   // dispatch(adminFinancialDataGet({"startDate":startDate,"endDate":endDate}, {}))
  //   let newq = {}
  //   newq["startDate"]=startDate
  //   newq["endDate"]=endDate
  //   if(soldto){
  //     newq["soldto"]=soldto
  //   }
  //   if(getyear){
  //     newq["year"]=getyear
  //   }
  //   dispatch(adminFinancialDataGet(newq, {}))
  // };
  const handleDateChange = dates => {
    console.log(dates)
    setSelectedDateRange(dates)

    if (dates[0] && dates[1]) {
      const startDate = dates[0].toLocaleDateString()
      const endDate = dates[1].toLocaleDateString()

      let newq = {
        startDate: startDate,
        endDate: endDate,
      }

      if (soldto) {
        newq["soldto"] = soldto
      }

      if (getyear) {
        newq["year"] = getyear
      }

      dispatch(adminFinancialDataGet(newq, {}))
    } else {
      console.error("Invalid date format")
    }
  }

  const clearData = e => {
    setSelectedDateRange(null)
    setsearchData("")
    setgetyear("")
    setsoldto("")
    // dispatch(adminEarningDataGet({"userType":"AC"}, {}))
    dispatch(adminFinancialDataGet({}, {}))
  }

  const setsoldData = e => {
    setsoldto(e.target.value)

    let newq = {}
    newq["soldto"] = e.target.value
    if (selectedDateRange) {
      const startDate = selectedDateRange[0].toLocaleDateString()
      const endDate = selectedDateRange[1].toLocaleDateString()
      newq["startDate"] = startDate
      newq["endDate"] = endDate
    }
    if (getyear) {
      newq["year"] = getyear
    }
    console.log(newq, "===========")
    dispatch(adminFinancialDataGet(newq, {}))
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={3} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Input
                  onChange={e => SearchByPname(e)}
                  type="text"
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by product name..."
                />
              </div>
            </Col>
            <Col xl={2} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <select
                  value={getyear}
                  onChange={e => yearsetdata(e.target.value)}
                  className="form-select"
                >
                  <option value="" disabled selected hidden>
                    Choose Financial Year
                  </option>
                  {yearData &&
                    yearData.map((item, index) => (
                      // <li key={index}>{item}</li>
                      <option key={index} value={item.year}>
                        {item.year}
                      </option>
                    ))}

                  <option value="0">
                    <a href="#">Add New Year</a>
                  </option>
                </select>
              </div>
            </Col>

            <Col xl={2} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <select
                  value={soldto}
                  onChange={e => setsoldData(e)}
                  className="form-select"
                >
                  <option value="" disabled selected hidden>
                    Sold to All
                  </option>
                  <option value="D">Sold to Dealer</option>
                  <option value="AC">
                    <a>Sold to Customer</a>
                  </option>
                  <option value="">
                    <a>Sold to All</a>
                  </option>
                </select>
              </div>
            </Col>

            <Col xl={3} className="mt-1 mb-1">
              <Flatpickr
                className="form-control d-block"
                placeholder="Date Range"
                onChange={handleDateChange}
                value={selectedDateRange}
                options={{
                  mode: "range",
                  // dateFormat: "Y-m-d",
                  dateFormat: "d-m-Y",
                }}
              />
              {/* </InputGroup> */}
              {/* </FormGroup>
              </Form> */}
            </Col>

            <Col xl={1} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <button
                  onClick={e => clearData(e)}
                  type="button"
                  className="btn btn-primary"
                >
                  Clear
                </button>
              </div>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>
      {/* <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row> */}

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(`${HOSTNAME}${ADMINFINANCIALYEAR}?page=${1}`, 1)
              }
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${ADMINFINANCIALYEAR}?page=${currentPage - 1}`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${ADMINFINANCIALYEAR}?page=${currentPage + 1}`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${ADMINFINANCIALYEAR}?page=${Math.ceil(
                    count / customPageSize
                  )}`,
                  Math.ceil(count / customPageSize)
                )
              }
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>

      <Modal
        isOpen={addnew}
        role="dialog"
        size="sm"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={addtoggle}
      >
        <div className="modal-content">
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <Col md="12">
                <FormGroup className="mb-3">
                  <h5>Add New Year</h5>
                  <p style={{ fontSize: "12px" }}></p>
                  <Label htmlFor="validationCustom01">Year</Label>
                  <Input
                    onChange={e => setnewyear(e.target.value)}
                    name="newyear"
                    placeholder="Add new year"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    value={newyear}
                  />
                </FormGroup>
              </Col>
            </SimpleBar>
          </ModalBody>

          <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
            <Button onClick={e => AddNewYear()} type="button" color="primary">
              Submit
            </Button>

            <Button type="button" onClick={addtoggle} color="secondary">
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </Fragment>
  )
}

// TableContainer.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// };

export default FinancialTableContainer
