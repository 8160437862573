import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap"
import axios from "axios"
import { HOSTNAME } from "config/config"

import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "./Table/Table"
import Loadder from "components/Loadder/Loadder"

const NewOrderRequest = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [rejectReasonModal, setRejectReasonModal] = useState(false)
  const [rejectReason, setRejectReason] = useState(null)
  const [currentPage, setcurrentPage] = useState(1)
  const [status, setStatus] = useState("")
  const [serchValue, setSearchValue] = useState("")

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const getNewOrderRequest = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/inventory-manufacture-product-request-dealer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getNewOrderRequest()
  }, [])

  const viewRejectReason = row => {
    setRejectReasonModal(!rejectReasonModal)
    setRejectReason(row?.note)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "RPD.pnumber.pname",
      },
      {
        Header: "Product Number",
        accessor: "RPD.pnumber.pnumber",
      },
      {
        Header: "Product  Consumables Name",
        accessor: "RPD.mpname",
      },
      {
        Header: "Product  Quantity",
        accessor: "qty",
      },
      {
        Header: "Status",
        Cell: cellProps => {
          const row = cellProps.row.original.status
          return (
            <p>
              {row === 0
                ? "Pending"
                : row === 1
                ? "Accepted"
                : row === 2
                ? "Rejected"
                : null}
            </p>
          )
        },
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <>
              {row.status === 2 && (
                <UncontrolledDropdown direction="start">
                  <DropdownToggle size="sm" color="">
                    <i className="bx bx-dots-vertical fs-4"></i>
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem onClick={() => viewRejectReason(row)}>
                      View Reason
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </>
          )
        },
      },
    ],
    []
  )

  // Search
  const onSearch = (value, dropdown) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?status=${
        dropdown || status
      }&query=${value || serchValue}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //Pagination function
  const getPaginationData = (url, page) => {
    setcurrentPage(page)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Loadder active={loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Consumable Products" />

          <TableContainer
            count={tableData?.count}
            next={tableData?.next}
            previous={tableData?.previous}
            columns={columns}
            data={tableData?.results?.data || []}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
            removeCheckbox
            onSearch={e => {
              setSearchValue(e.target.value)
              onSearch(e.target.value, status)
            }}
            showStatusSelect={
              <div className="col-sm-auto">
                <select
                  onClick={e => {
                    setStatus(e.target.value)
                    onSearch(serchValue, e.target.value)
                  }}
                  className="form-select"
                >
                  <option value="" disabled selected hidden>
                    Status
                  </option>
                  <option value="">All</option>
                  <option value="0">Pending</option>
                  <option value="1">Accepted</option>
                  <option value="2">Rejected</option>
                </select>
              </div>
            }
            currentPage={currentPage}
            goToNextPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?status=${status}&page=${
                  currentPage + 1
                }`,
                currentPage + 1
              )
            }
            goToLastPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?status=${status}&page=${Math.ceil(
                  tableData?.count / 10
                )}`,
                Math.ceil(tableData?.count / 10)
              )
            }
            goToPreviousPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?status=${status}&page=${
                  currentPage - 1
                }`,
                currentPage - 1
              )
            }
            goToFirstPage={() =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?status=${status}&page=${1}`,
                1
              )
            }
            getInputPaginationData={e =>
              getPaginationData(
                `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?status=${status}&page=${e.target.value}`,
                e.target.value
              )
            }
          />

          <Modal
            isOpen={rejectReasonModal}
            role="dialog"
            size="md"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={viewRejectReason}
          >
            <div className="modal-content">
              <ModalHeader toggle={viewRejectReason}>
                Reason for rejection
              </ModalHeader>
              <ModalBody>
                <p> </p>

                <FormGroup className="mb-3">
                  <Label htmlFor="">View reason</Label>
                  <Input
                    type="textarea"
                    name="product-name"
                    className="form-control"
                    placeholder="Enter Text Here"
                    disabled
                    value={rejectReason}
                  />
                </FormGroup>
              </ModalBody>
            </div>
          </Modal>
        </div>
      </div>
    </Loadder>
  )
}

export default NewOrderRequest
