import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import { toast } from "react-toastify"
import {
  FormFeedback,
  ModalFooter,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  Input,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./neworderfromdealertocustomertablecontainer"
import { newOrderFromDealerToCustomerGet } from "../../../src/store/actions"
import {
  ADMINSOLDPRODUCT,
  HOSTNAME,
  NEWORDERFROMDEALERTOCUSTOMERGET,
} from "config/config"
import Loadder from "components/Loadder/Loadder"

const DatatableTables = () => {
  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(newOrderFromDealerToCustomerGet({}, {}))
  }, [])

  const newOrderFromDealerToCustomerData = useSelector(
    state => state.CustomerGet
  )

  if (
    newOrderFromDealerToCustomerData &&
    newOrderFromDealerToCustomerData.customerDetails
  ) {
    data = newOrderFromDealerToCustomerData.customerDetails.results.data
  }

  //meta title
  document.title = "Consumable Product"

  const [imgmodal, setImgmodal] = useState(false)
  const [writereject, setWritereject] = useState(false)
  const [viewrejection, setViewrejection] = useState(false)
  const [imgurl, setImgurl] = useState("")
  const [modal, setModal] = useState(false)
  const [rowdata, setrowdata] = useState({})

  const imagtoggle = () => {
    setImgmodal(!imgmodal)
  }

  const imagtoggle1 = (e, row) => {
    setImgurl(row.original.pimage)
    setImgmodal(!imgmodal)
  }

  const togglemodal = () => {
    setModal(!modal)
  }

  const togglemodal1 = row => {
    setModal(!modal)
    setrowdata(row)
  }

  const writerejecttoggle = () => {
    setWritereject(!writereject)
  }

  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      writereason: "",
    },
    validationSchema: Yup.object({
      writereason: Yup.string().required("Please Enter Your Reason"),
    }),
    onSubmit: values => {
      console.log("values", values)
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <span>
              {row?.customer?.firstName} {row?.customer?.lastName}
            </span>
          )
        },
      },
      {
        Header: "Product Name",
        accessor: "RPD.pnumber.pname",
      },
      {
        Header: "Product Number",
        accessor: "RPD.pnumber.pnumber",
      },
      // {
      //   Header: "Product Serial Number",
      //   accessor: "RPD.pnumber.serialNumbers",
      // },
      {
        Header: "Dealer Name",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <span>
              {row?.dealer?.firstName} {row?.dealer?.lastName}
            </span>
          )
        },
      },
      {
        Header: "Product Report Images",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link
                  to="#"
                  onClick={e => imagtoggle1(e, row)}
                  className="mt-2 d-grid"
                >
                  View Image
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link
                  to="#"
                  onClick={e => togglemodal1(row)}
                  className="mt-2 d-grid"
                >
                  View order
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  return (
    <Loadder active={newOrderFromDealerToCustomerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem=" Consumable Products" />

          <TableContainer
            count={newOrderFromDealerToCustomerData.customerDetails.count}
            next={newOrderFromDealerToCustomerData.customerDetails.next}
            previous={newOrderFromDealerToCustomerData.customerDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={imgmodal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={imagtoggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={imagtoggle}></ModalHeader>
            <ModalBody>
              <Card>
                <CardBody>
                  <div className="">
                    <img
                      src={imgurl}
                      className="img-fluid"
                      alt="Responsive image"
                    />
                  </div>
                </CardBody>
              </Card>
            </ModalBody>
          </div>
        </Modal>

        {/* ------------------- Write Reason For Rejection------------- */}
        <Modal
          isOpen={writereject}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={writerejecttoggle}
        >
          <div className="modal-content">
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <h5>Reason for rejection</h5>
                    <p style={{ fontSize: "12px" }}></p>
                    <Label htmlFor="validationCustom01">Write reason</Label>
                    <Input
                      name="writereason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.writereason || ""}
                      invalid={
                        validation.touched.writereason &&
                          validation.errors.writereason
                          ? true
                          : false
                      }
                    />
                    {validation.touched.writereason &&
                      validation.errors.writereason ? (
                      <FormFeedback type="invalid">
                        {validation.errors.writereason}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>

            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="button" color="primary">
                Submit
              </Button>

              <Button
                type="button"
                onClick={writerejecttoggle}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Modal>

        {/* ------------------- View Reason For Rejection------------- */}
        <Modal
          isOpen={viewrejection}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={viewrejectiontoggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={viewrejectiontoggle}>
              Reason for rejection
            </ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <p> </p>
                    <Label htmlFor="validationCustom01">View reason</Label>
                    <Input
                      name="viewreason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>

        <Modal
          isOpen={modal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>
              Equipment Product Details
            </ModalHeader>
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div>
                          <b>Product Name</b>
                          <br></br>
                          <p>{rowdata && rowdata?.RPD?.pnumber?.pname}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
            <ModalHeader>Consumables Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div>
                          <b>Product Name</b>
                          <br></br>
                          <p> {rowdata && rowdata?.RPD?.mpname}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div>
                          <b>Order Quantity</b>
                          <br></br>
                          <p> {rowdata && rowdata?.qty}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
