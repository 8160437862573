// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./vieworderstablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { customerViewOrdersListGet } from "store/actions"
import Loadder from "components/Loadder/Loadder"
import { HOSTNAME } from "config/config"
import axios from "axios"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }
  return {
    id: item.id,
    customer: item.customer,
    mproduct: item.mproduct,
    pimage: item.pimage,
    isSold: item.isSold,
    yearSold: item.yearSold,
    reason: item.reason,
    status: item.status,
    statustext: statustext,
    customerName: item.customerName,
    contactNumber: item.contactNumber,
    city: item.city,
    dealerName: item.dealerName,
    pname: item.pname,
    pnumber: item.pnumber,
    serialNumbers: item.serialNumbers,
    mproductQty: item.mproductQty,
  }
}
function DatatableTables() {
  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(customerViewOrdersListGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customervieworderslist1) {
    data = customerDatas.customervieworderslist1
  }

  //meta title
  document.title = "New Orders"

  const [modal, setModal] = useState(false)
  const [viewrejection, setViewrejection] = useState(false)
  const [obj, setobj] = useState({})

  const togglemodal = () => {
    setModal(!modal)
  }
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }
  const viewrejectiontoggle1 = row => {
    setViewrejection(!viewrejection)
    setobj(row)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "RPD.pnumber.pname",
      },
      {
        Header: "Product Number",
        accessor: "RPD.pnumber.pnumber",
      },
      // {
      //   Header: "Product Serial Numbers",
      //   accessor: "RPD.pnumber.serialNumbers",
      // },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View Consumables Products
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "Status",
        Cell: cellProps => {
          const row = cellProps.row.original.status
          return (
            <p>
              {row === 0
                ? "Pending"
                : row === 1
                  ? "Accepted"
                  : row === 2
                    ? "Rejected"
                    : null}
            </p>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return row.status == 2 ? (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => viewrejectiontoggle1(row)}>
                  <i
                    className="font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  View Reason
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View Reason
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            ""
          )
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="New Orders" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customervieworderslist1 &&
            customerDatas.customervieworderslist1.count
          }
          next={
            customerDatas.customervieworderslist1 &&
            customerDatas.customervieworderslist1.next
          }
          previous={
            customerDatas.customervieworderslist1 &&
            customerDatas.customervieworderslist1.previous
          }
          columns={columns}
          data={data?.results?.data || []}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            Equipment Product Details
          </ModalHeader>
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <b>Product Name</b>
                        <br></br>
                        <p>{obj && obj?.RPD?.pnumber?.pname}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
          <ModalHeader>Consumables Product Details</ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <b>Product Name</b>
                        <br></br>
                        <p>{obj && obj?.RPD?.mpname}</p>
                      </div>
                    </div>
                  </div>
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <b>Product Quantity</b>
                        <br></br>
                        <p>{obj && obj?.qty}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>

      {/* ------------------- View Reason For Rejection------------- */}
      <Modal
        isOpen={viewrejection}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={viewrejectiontoggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={viewrejectiontoggle}>
            Reason for rejection
          </ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <Col md="12">
                <FormGroup className="mb-3">
                  <p> </p>
                  <Label htmlFor="validationCustom01">View reason</Label>
                  <Input
                    name="viewreason"
                    placeholder="Enter Text Here"
                    type="textarea"
                    rows={4}
                    className="form-control"
                    id="validationCustom01"
                    value={obj && obj.note}
                    disabled
                  />
                </FormGroup>
              </Col>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
