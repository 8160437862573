// src/components/filter.
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import img2 from "../../assets/images/small/img-6.jpg"
import SimpleBar from "simplebar-react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./servicecentrictablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerCentricListGet } from "store/actions"
import { useEffect } from "react"

function extractIdAndPName(item) {
  return {
    id: item.id,
    serviceStatus: item.serviceStatus,
    message: item.message,
    imgdata: item.imgdata,
    productName: item.productName,
    pid: item.pid,
    pnumber: item.pnumber,
    type: item.type,
    serviceStatustext: item.serviceStatustext,
    serialNumbers: item.serialNumbers,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "serviceStatustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => imagtoggle1(row)}>
                  View Image
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(customerCentricListGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customercentriclist) {
    data = customerDatas.customercentriclist.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Services"

  const [imgmodal, setImgmodal] = useState(false)

  const imagtoggle = () => {
    setImgmodal(!imgmodal)
  }
  const [obj, setobj] = useState({})
  const imagtoggle1 = row => {
    setImgmodal(!imgmodal)
    setobj(row)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Services" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customercentriclist &&
            customerDatas.customercentriclist.count
          }
          next={
            customerDatas.customercentriclist &&
            customerDatas.customercentriclist.next
          }
          previous={
            customerDatas.customercentriclist &&
            customerDatas.customercentriclist.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={imgmodal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={imagtoggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={imagtoggle}></ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                <div className="">
                  <img
                    src={obj && obj.imgdata}
                    className="img-fluid"
                    alt="Responsive image"
                  />
                </div>
                <br />
                <p className="card-title-desc">{obj && obj.message}</p>
              </CardBody>
            </Card>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
