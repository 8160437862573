import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik, Form, Field, ErrorMessage, Formik } from "formik"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  FormGroup,
  UncontrolledAlert,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
// import { Table, Row, Col, Button, Input, CardBody ,Label} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import {
  CUSTOMERPROFILEUPDATE,
  DEALERPROFILEUPDATE,
  HOSTNAME,
} from "config/config"
import axios from "axios"
import { toast } from "react-toastify"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const getUserData = JSON.parse(localStorage.getItem("authUser"))
  const [menu, setMenu] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [username, setusername] = useState("Admin")
  const [modal, setModal] = useState(false)
  const [dealerData, setdealerData] = useState({})

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const profiletoggle = () => {
    setModal(!modal)
    // alert("hello")
  }

  const profiletoggle1 = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${CUSTOMERPROFILEUPDATE}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        setdealerData(response.data && response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
    // alert("hello")
  }

  useEffect(() => {
    profiletoggle1()
  }, [])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      agencyname: "",
      dealername: "",
      contactNumber: "",
      city: "",
    },
    validationSchema: Yup.object({
      agencyname: Yup.string().required("Please Enter Your Agency Name"),
      dealername: Yup.string().required("Please Enter Your Dealer Name"),
      contactNumber: Yup.string().required(
        "Please Enter Customer Contact Number"
      ),
      city: Yup.string().required("Please Enter City"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log("values=>>>>", values)
    },
  })

  const buttonStyle = {
    // position: 'relative',
    // backgroundColor: '#007bff', // Primary color
    // color: '#ffffff',
    // border: 'none',
    // padding: '10px 20px',
    // fontSize: '16px',
    // cursor: 'pointer'
  }

  const tooltipStyle = {
    visibility: "hidden",
    backgroundColor: "#000",
    color: "#fff",
    textAlign: "center",
    padding: "5px",
    borderRadius: "6px",
    position: "absolute",
    zIndex: "1",
    // bottom: '125%', /* Position the tooltip above the button */
    // left: '50%',
    // marginLeft: '-60px', /* Adjust this value to center the tooltip */
    opacity: "0",
    transition: "opacity 0.3s",
  }

  const handleHover = () => {
    document.getElementById("tooltip").style.visibility = "visible"
    document.getElementById("tooltip").style.opacity = "1"
  }

  const handleLeave = () => {
    document.getElementById("tooltip").style.visibility = "hidden"
    document.getElementById("tooltip").style.opacity = "0"
  }

  const initialValues = {
    firstName: dealerData?.firstName || "",
    lastName: dealerData?.lastName || "",
    email: dealerData?.email || "",
    city: dealerData?.city || "",
    contactNumber: dealerData?.contactNumber || "",
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    city: Yup.string().required("City is required"),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits"),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    const data = new FormData()

    data.append("firstName", values.firstName)
    data.append("lastName", values.lastName)
    data.append("email", values.email)
    data.append("city", values.city)
    data.append("contactNumber", values.contactNumber)

    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/customer-profile-update/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }
    axios
      .request(config)
      .then(response => {
        toast.success(response.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${HOSTNAME}${CUSTOMERPROFILEUPDATE}`,
          headers: {
            Authorization: `${getUserData.userData.token}`,
          },
        }

        axios
          .request(config)
          .then(response => {
            setdealerData(response.data && response.data.data)
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(err => {
        toast.error(err?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setSubmitting(false)
        setModal(false)
      })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to="#"
            className="dropdown-item"
            onClick={() => {
              profiletoggle()
            }}
          >
            <i className="bx bxs-user-rectangle font-size-16 align-middle me-1 text-primary" />
            <span>{props.t("Profile")}</span>
          </Link>

          <Link
            to="#"
            className="dropdown-item"
            onClick={() => {
              tog_backdrop()
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop()
        }}
        backdrop={"static"}
        id="staticBackdrop"
        size="sm"
      >
        <div className="modal-header">
          <h3 className="modal-title" id="staticBackdropLabel">
            <i
              className="bx bx-power-off align-middle me-1 text-danger"
              style={{ fontSize: "30px" }}
            />
            Logout
          </h3>
        </div>
        <div className="modal-body">
          <b>
            <p>Are you sure you want to logout ???</p>
          </b>
        </div>

        <div className="modal-footer">
          <Link to="/logout">
            <button type="button" className="btn btn-primary">
              Yes
            </button>
          </Link>

          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setmodal_backdrop(false)
            }}
          >
            No
          </button>
        </div>
      </Modal>

      {/* modal */}
      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={profiletoggle}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="modal-content">
                <ModalHeader toggle={profiletoggle}>Your Profile</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="firstName">First Name</Label>
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="form-control"
                          placeholder="First Name"
                          as={Input}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="lastName">Last Name</Label>
                        <Field
                          type="text"
                          name="lastName"
                          id="lastName"
                          className="form-control"
                          placeholder="Last Name"
                          as={Input}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="contactNumber">Contact Number</Label>
                        <Field
                          type="number"
                          name="contactNumber"
                          id="contactNumber"
                          className="form-control"
                          placeholder="Contact Number"
                          as={Input}
                        />
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="email">Email</Label>
                        <Field
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          as={Input}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="city">City</Label>
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          className="form-control"
                          placeholder="City"
                          as={Input}
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter
                  style={{ display: "flex", justifyContent: "left" }}
                >
                  <Button type="submit" color="primary" disabled={isSubmitting}>
                    Submit
                  </Button>
                  <Button
                    type="button"
                    onClick={profiletoggle}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
