import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  DEALERDASHBOARD_DATA_GET,
  DEALERDASHBOARD_DATA_SUCCESS
} from "./actionTypes"

import { 
  dealerDashboardDataGet,
  dealerDashboardDataSuccess
} from "./actions"

import { 
  HOSTNAME,
  DEALERDASHBOARD

} from "config/config"

import { apiError } from "store/actions"
import axios from "axios"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser")) 

function* dealerdashboardData({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEALERDASHBOARD}`
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(dealerDashboardDataSuccess(response.data.data));
              // console.log("---======----==--=-=-=-=-",response.data.results.data)
            }
            else if (response.status === 200) {
              localStorage.removeItem('authUser'); 
              window.location.href = '/pre-login';
            } 
            else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    if(error.response.status==401){
      localStorage.removeItem('authUser'); 
      window.location.href = '/pre-login';
    }
    toast.error(error.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    
    yield put(apiError(error))
  }
}

function* dealerDashboardDataSaga() {
  yield takeEvery(DEALERDASHBOARD_DATA_GET, dealerdashboardData) 
}

export default dealerDashboardDataSaga
