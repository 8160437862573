import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { GetLoginData } from "../redux/action/action1"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useLocation } from 'react-router-dom';
// actions
import { loginUser, socialLogin } from "../../src/store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import Loadder from "components/Loadder/Loadder"

const Login = props => {
  //meta title
  const location = useLocation();
  const passedData = location.state && location.state.data
  console.log(passedData)
  document.title = "Login | MEMS - Medical Equipment Management "

  const dispatch = useDispatch()
  const [uname,setUname]=useState(passedData.uname)

  const [otp,setOtp]=useState("")
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      uname: uname || "",
      otp: otp || "",

    },
    
    validationSchema: Yup.object({
      uname: Yup.string().required("Please Enter Your uname"),
      otp: Yup.string().required("Please Enter Your otp"),
    }),
    onSubmit: values => {
      // alert(values.otp)
      dispatch(loginUser(values, props.router.navigate))
    },

   
  })

  // const { error } = useSelector(state => ({
  //   error: state.Login.error,
  // }));
  const loginState = useSelector(state => state.Login)
  console.log("loginState", loginState)
  // const signIn = type => {
  //     dispatch(socialLogin(type, props.router.navigate));
  // };

  //for facebook and google authentication
  // const socialResponse = type => {
  //   signIn(type);
  // };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}


  return (
    <Loadder active={loginState.loading}>
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                        <div className="mb-3">
                          <Label className="form-label">Mobile Number</Label>
                          <Input
                            name="uname"
                            className="form-control"
                            type="text"
                            disabled={true}
                            // onChange={validation.handleChange}
                            onChange={(e)=>setUname(e.target.value)}
                            onBlur={validation.handleBlur}
                            value={validation.values.uname || ""}
                            invalid={
                              validation.touched.uname && validation.errors.uname ? true : false
                            }
                          />
                          {/* {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null} */}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">OTP</Label>
                          <Input
                            name="otp"
                            value={validation.values.otp || ""}
                            type="password"
                            placeholder="Enter OTP"
                            onChange={(e)=>setOtp(e.target.value)}
                            // onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.otp && validation.errors.otp ? true : false
                            }
                          />
                          {/* {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null} */}
                        </div>

                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                     

                        <div className="mt-4 text-center">
                          <Link style={{ fontSize: '15px'}} to="/" className="text-muted">
                          <i style={{ fontSize: '15px'}} className="bx bx-left-arrow-circle"></i>
                            {/* <i className="mdi mdi-arrow me-1" /> */}
                            {/* <i className=" bx-bx-left-arrow-alt" /> */}
                           
                            Back
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                  {/* <p>
                    © {new Date().getFullYear()} MEMS with{" "}
                    <i className="mdi mdi-heart text-danger" />
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </Loadder>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
