import {
  VIEWDEMOPRODUCT_GET,
  VIEWDEMOPRODUCT_SUCCESS,
  TAGTODEALER_GET,
  TAGTODEALER_SUCCESS,
  TAGGEDPRODUCTLIST_GET,
  TAGGEDPRODUCTLIST_SUCCESS,
  DEMOPRODUCT_ADD,
  DEMOPRODUCT_ADD_SUCCESS,
  DEMOPRODUCTMASTER_GET,
  DEMOPRODUCTMASTER_SUCCESS,
  DEMOPRODUCTMASTER_UPLOAD_POST,
  DEMOPRODUCTMASTER_UPLOAD_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  demoProductDetails: "",
}

const demoProductGet = (state = initialState, action) => {
  switch (action.type) {
// ------- View Consumables Product 
      case VIEWDEMOPRODUCT_GET:
        state = {
          ...state,
          loading: true,
        }
        break
      case VIEWDEMOPRODUCT_SUCCESS:
        state = {
          ...state,
          loading: false,
          isUserLogout: false,
          demoProductDetails:action.payload,
        }
        break
// ------- Tag to dealer----------

      case TAGTODEALER_GET:
        state = {
          ...state,
          loading: true,
        }
        break
      case TAGTODEALER_SUCCESS:
        state = {
          ...state,
          loading: false,
          isUserLogout: false,
          demoProductDetails:action.payload,
        }
        break
// ---------- Tagged Product List---------

      case TAGGEDPRODUCTLIST_GET:
        state = {
          ...state,
          loading: true,
        }
        break
      case TAGGEDPRODUCTLIST_SUCCESS:
        state = {
          ...state,
          loading: false,
          isUserLogout: false,
          demoProductDetails:action.payload,
        }
        break
  
// --- maintan inventory add Product

        case DEMOPRODUCT_ADD:
          state = {
            ...state,
            loading: true,
          }
          break

      case DEMOPRODUCT_ADD_SUCCESS:
              state = {
                ...state,
                loading: false,
                isUserLogout: false,
                demoProductDetails:action.payload,
              }
              break

// ------ Demo Product MAster----

      case DEMOPRODUCTMASTER_GET:
              state = {
                ...state,
                loading: true,
              }
        break
      case DEMOPRODUCTMASTER_SUCCESS:
              state = {
                ...state,
                loading: false,
                isUserLogout: false,
                demoProductDetails:action.payload,
              }
        break

//----------- Demo Product Master Upload----------
      case DEMOPRODUCTMASTER_UPLOAD_POST:
        state = {
          ...state,
          loading: true,
        }
        break

      case LOGOUT_USER:
        state = { ...state }
        break
      case LOGOUT_USER_SUCCESS:
        state = { ...state, isUserLogout: true }
        break
      case API_ERROR:
        state = {
          ...state,
          error: action.payload,
          loading: false,
          isUserLogout: false,
        }
        break

      default:
        state = { ...state }
        break
  }
  return state
}

export default demoProductGet


