import React from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { Row, Col, CardBody, Card, UncontrolledTooltip } from "reactstrap"

const CandidateOverview = () => {
  document.title = "Candidate Overview "

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Tables" breadcrumbItem="Contact Us" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody className="border-bottom">
                <div style={{ display: "grid", placeContent: "center" }}>
                  <h3 className="mb-3" style={{ textAlign: "center" }}>
                    Stuck? Contact Us!
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <a
                      id="whatsappTooltip"
                      href="whatsapp://send?text=hello&phone=9152020415"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        style={{ fontSize: "70px", color: "#556EE6" }}
                        className="bx bxl-whatsapp"
                      ></i>
                    </a>

                    <a
                      id="contactNumberTooltip"
                      href="tel:18001237399"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <i
                        style={{ fontSize: "70px", color: "#556EE6" }}
                        className="bx bx-phone"
                      ></i>
                    </a>

                    <a
                      id="mailTooltip"
                      href='https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=support@sonastarhealthcarellp.com'
                      // href="mailto:support@sonastarhealthcarellp.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        style={{ fontSize: "70px", color: "#556EE6" }}
                        className="bx bx-envelope"
                      ></i>
                    </a>
                  </div>
                </div>

                <UncontrolledTooltip
                  placement="bottom"
                  target="whatsappTooltip"
                >
                  9152020415
                </UncontrolledTooltip>
                <UncontrolledTooltip
                  placement="bottom"
                  target="contactNumberTooltip"
                >
                  18001237399
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="bottom" target="mailTooltip">
                  support@sonastarhealthcarellp.com
                </UncontrolledTooltip>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default CandidateOverview
