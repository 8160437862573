import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import axios from "axios"
import { withTranslation } from "react-i18next"

const getUserData = JSON.parse(localStorage.getItem("authUser"))
import { HOSTNAME, MANUFACTUREDMAINTAININVENTORY } from "config/config"
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
const MaintainInventory = props => {
  const [reportTempData, setreportTempData] = useState([])

  const dashboardReport = () => {
    const url = `${HOSTNAME}${MANUFACTUREDMAINTAININVENTORY}`
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        setreportTempData(response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    dashboardReport()
  }, [])

  const reports = [
    {
      title: "Total Consumables Products",
      iconClass: "bx-purchase-tag-alt",
      description: `${
        reportTempData?.TotalManufactureProduct
          ? reportTempData?.TotalManufactureProduct
          : 0
      }`,
    },
    {
      title: "Stocked Consumables Products",
      iconClass: "bx-purchase-tag-alt",
      description: `${
        reportTempData?.StockedManufactureProduct
          ? reportTempData?.StockedManufactureProduct
          : 0
      }`,
    },
    {
      title: "Tagged Consumables Products",
      iconClass: "bx-purchase-tag-alt",
      description: `${
        reportTempData?.TotalManufactureTaagedActive
          ? reportTempData?.TotalManufactureTaagedActive
          : 0
      }`,
    },
    // {
    //   title: "Pending Request of Consumables by Dealer",
    //   iconClass: "bx-purchase-tag-alt",
    //   description: `${
    //     reportTempData?.TotalManufactureTaagedPendingByDealer
    //       ? reportTempData?.TotalManufactureTaagedPendingByDealer
    //       : 0
    //   }`,
    // },
    // {
    //   title: "Pending Request of Consumables by Customers",
    //   iconClass: "bx-purchase-tag-alt",
    //   description: `${
    //     reportTempData?.TotalManufactureTaagedPendingByCustomer
    //       ? reportTempData?.TotalManufactureTaagedPendingByCustomer
    //       : 0
    //   }`,
    // },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Consumables Products")}
          />
          <Row>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

MaintainInventory.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(MaintainInventory)
