import {
  MANUFACTUREDPRODUCT_GET,
  MANUFACTUREDPRODUCT_SUCCESS,
  MANUFACTUREDPRODUCT_UPLOAD_POST,
  MANUFACTUREDPRODUCT_UPLOAD_SUCCESS,
  VIEWMANUFACTUREDPRODUCT_GET,
  VIEWMANUFACTUREDPRODUCT_SUCCESS,
  MANUFACTUREDPRODUCT_ADD,
  MANUFACTUREDPRODUCT_ADD_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const manufacturedProductGet = (query, history) => { 
  // alert("action")
  return {
    type: MANUFACTUREDPRODUCT_GET,
    payload: { query, history },
  }
}

export const manufacturedProductSuccess = data => {
  return {
    type: MANUFACTUREDPRODUCT_SUCCESS,
    payload: data,
  }
}

// -------- View Consumables Product--------

export const viewManufacturedProductGet = (query, history) => { 
  // alert("action")
  return {
    type: VIEWMANUFACTUREDPRODUCT_GET,
    payload: { query, history },
  }
}

export const viewManufacturedProductSuccess = data => {
  return {
    type: VIEWMANUFACTUREDPRODUCT_SUCCESS,
    payload: data,
  }
}

export const manufacturedProductUploadGet = (fileData, history) => {
  // alert("action")
  return {
    type: MANUFACTUREDPRODUCT_UPLOAD_POST,
    payload: { fileData, history },
  }
}

// -- Maintain  Inventory add manufactured product

export const manufacturedProductAdd = (data, history) => { 
  // alert("action")
  return {
    type: MANUFACTUREDPRODUCT_ADD,
    payload: { data, history },
  }
}

export const manufacturedProductAddSuccess = data => {
  return {
    type: MANUFACTUREDPRODUCT_ADD_SUCCESS,
    payload: data,
  }
}



