// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./tagproductfromadmintablecontainer"
import { tagProductFromAdminGet } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"

import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import Loadder from "components/Loadder/Loadder"
import { toast } from "react-toastify"
import { DEALERTAGEDACCEPTREJECT, HOSTNAME } from "config/config"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }
  return {
    id: item.id,
    pname: `${item.product && item.product.pname}`,
    pnumber: `${item.product && item.product.pnumber}`,
    // mpname:`${item.manufactureData && item.manufactureData.mpname}`,
    manufactured: item.manufactureData && item.manufactureData,
    status: item.status,
    statustext: statustext,
    serialNumbers: `${item.product && item.product.serialNumbers}`,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return row.status == 0 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      onClick={e => callAcceptRejectApi(row.id, "", 1)}
                      className="btn btn-success btn-block"
                      type="button"
                    >
                      Accept
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : row.status == 1 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      disabled
                      className="btn btn-success btn-block"
                      type="button"
                    >
                      Accepted
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : (
            ""
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return row.status == 0 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      onClick={e => writerejecttoggle1(row.id)}
                      className="btn btn-warning btn-block"
                      type="button"
                    >
                      Reject
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : row.status == 2 ? (
            <Row>
              <Col xl={12}>
                <Col xl={6}>
                  <div className="mt-0 d-grid">
                    <button
                      disabled
                      className="btn btn-warning btn-block"
                      type="button"
                    >
                      Rejected
                    </button>
                  </div>
                </Col>
              </Col>
            </Row>
          ) : (
            ""
          )
        },
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal(row.original)}>
                  View Consumables Products
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }

    dispatch(tagProductFromAdminGet({}, {}))
  }, [])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      notes:
        "Parsley is a javascript form validation before sending it to your server.",
      writereason: "",
    },
    validationSchema: Yup.object({
      notes: Yup.string().required("Please Enter Notes"),
      writereason: Yup.string().required("Please Enter Your Reason"),
    }),
    onSubmit: (values, { resetForm }) => {
      callAcceptRejectApi(tagid, values.writereason, 2)
      resetForm()
      setWritereject(!writereject)
    },
  })

  const tagProductfromAdminData = useSelector(
    state => state.resellingProductGet
  )

  if (
    tagProductfromAdminData &&
    tagProductfromAdminData.resellingProductDetails
  ) {
    data =
      tagProductfromAdminData.resellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  const callAcceptRejectApi = (id, reason, status) => {
    // alert(id)
    // alert(reason)
    // alert(status)
    let data = new FormData()
    data.append("tagid", id)
    data.append("status", status)
    data.append("reason", reason)
    let url = `${HOSTNAME}${DEALERTAGEDACCEPTREJECT}`
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        if (status == 2) {
          toast.success("Product rejected successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.success("Product accepted successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        }

        dispatch(tagProductFromAdminGet({}, {}))
      })
      .catch(error => {
        toast.error("Not taged", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
  const [tagid, settagid] = useState("")
  const [writereject, setWritereject] = useState(false)
  const writerejecttoggle = () => {
    setWritereject(!writereject)
  }
  const writerejecttoggle1 = id => {
    settagid(id)
    setWritereject(!writereject)
  }

  //meta title
  document.title = "Equipments Products"

  const [modal, setModal] = useState(false)
  const [objData, setobjData] = useState({})

  const togglemodal = row => {
    let fdata = { id: row.id, pname: row.pname, manufactured: row.manufactured }
    setModal(!modal)
    setobjData(fdata)
  }

  return (
    <Loadder active={tagProductfromAdminData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Equipments Products" />
          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={tagProductfromAdminData.resellingProductDetails.count}
            next={tagProductfromAdminData.resellingProductDetails.next}
            previous={tagProductfromAdminData.resellingProductDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
        <Modal
          isOpen={modal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>
              Equipment Product Details
            </ModalHeader>
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div>
                          <b>Product Name</b>
                          <br></br>
                          <p>{objData && objData.pname}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
            <ModalHeader>Consumables Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    {objData.manufactured && objData.manufactured.length > 0 ? (
                      <>
                        {objData.manufactured &&
                          objData.manufactured.map(item => (
                            <div key={item.id} className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <b>Product Name</b>
                                  <br></br>
                                  {/* <p>{item.mpname}</p> */}
                                  <p>
                                    <span style={{ fontWeight: "bold" }}>
                                      {item.mpname}
                                    </span>
                                    <span style={{ color: "#777" }}> X </span>
                                    <span style={{ color: "#555" }}>
                                      {" "}
                                      {item.mpquantity}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    ) : (
                      <>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Name</b>
                              <br></br>
                              <p>Data Not Found</p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>

        {/* ------------------- Write Reason For Rejection------------- */}
        <Modal
          isOpen={writereject}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={writerejecttoggle}
        >
          <Form onSubmit={validation.handleSubmit}>
            <div className="modal-content">
              <ModalBody>
                <SimpleBar style={{ maxHeight: "376px" }}>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <h5>Reason for rejection</h5>
                      <p style={{ fontSize: "12px" }}></p>
                      <Label htmlFor="validationCustom01">Write reason</Label>
                      <Input
                        name="writereason"
                        placeholder="Enter Text Here"
                        type="textarea"
                        rows={4}
                        className="form-control"
                        id="validationCustom01"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.writereason || ""}
                        invalid={
                          validation.touched.writereason &&
                          validation.errors.writereason
                            ? true
                            : false
                        }
                      />
                      {validation.touched.writereason &&
                      validation.errors.writereason ? (
                        <FormFeedback type="invalid">
                          {validation.errors.writereason}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                </SimpleBar>
              </ModalBody>

              <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
                <Button type="submit" color="primary">
                  Submit
                </Button>

                <Button
                  type="button"
                  onClick={writerejecttoggle}
                  color="secondary"
                >
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </Form>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
