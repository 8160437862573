import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  InputGroup,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"

const reports = [
  {
    title: "Total Number of Consumables Product sold",
    iconClass: "bx-purchase-tag-alt",
    description: "150",
  },
]

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  Label,
} from "reactstrap"
import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import "react-datepicker/dist/react-datepicker.css"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { adminEarningMPDataGet, delearResellingEarningGet } from "store/actions"
import { useEffect } from "react"
import {
  ADMINEARNINGDOWNLOAD,
  DEALERRESELLINGEARNING,
  HOSTNAME,
} from "config/config"

const getUserData = JSON.parse(localStorage.getItem("authUser"))

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  result,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  // const generateSortingIndicator = column => {
  //   return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  // };

  // const onChangeInSelect = event => {
  //   setPageSize(Number(event.target.value));
  // };

  // const onChangeInInput = event => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };
  //-----------
  const reports = [
    {
      title: "Total Number of Consumables Product sold",
      iconClass: "bx-purchase-tag-alt",
      description: result && result.MPD_total_sold,
    },
  ]
  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }
  const dispatch = useDispatch()
  const [selectedDateRange, setSelectedDateRange] = useState(null)
  const [searchData, setsearchData] = useState("")
  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  // const handleDateChange = (dates) => {
  //   console.log(dates)
  //   setSelectedDateRange(dates);
  //   const startDate = dates[0].toLocaleDateString();
  //   const endDate = dates[1].toLocaleDateString();
  //   dispatch(delearResellingEarningGet({"startDate":startDate,"endDate":endDate}, {}))
  // };
  const handleDateChange = dates => {
    console.log(dates)
    setSelectedDateRange(dates)

    if (dates[0] && dates[1]) {
      const startDate = dates[0].toLocaleDateString()
      const endDate = dates[1].toLocaleDateString()
      // dispatch(delearResellingEarningGet({"startDate":startDate,"endDate":endDate}, {}))
      dispatch(
        adminEarningMPDataGet(
          {
            startDate: startDate,
            endDate: endDate,
            userType: "DC",
            isdealer: 1,
          },
          {}
        )
      )
    } else {
      console.error("Invalid date format")
    }
  }

  const clearData = e => {
    setSelectedDateRange(null)
    setsearchData("")
    // dispatch(delearResellingEarningGet({}, {}))
    dispatch(adminEarningMPDataGet({ userType: "DC", isdealer: 1 }, {}))
  }
  const SearchByPname = e => {
    if (e.target.value) {
      setsearchData(e.target.value)
      if (selectedDateRange == null) {
        // dispatch(delearResellingEarningGet({"query":e.target.value}, {}))
        dispatch(
          adminEarningMPDataGet(
            { query: e.target.value, userType: "DC", isdealer: 1 },
            {}
          )
        )
      } else {
        let startDate = selectedDateRange[0].toLocaleDateString()
        let endDate = selectedDateRange[1].toLocaleDateString()
        // dispatch(delearResellingEarningGet({"query":e.target.value,"startDate":startDate,"endDate":endDate}, {}))
        dispatch(
          adminEarningMPDataGet(
            {
              userType: "DC",
              isdealer: 1,
              query: e.target.value,
              startDate: startDate,
              endDate: endDate,
            },
            {}
          )
        )
      }
    } else {
      setsearchData("")
      // dispatch(delearResellingEarningGet({}, {}))
      dispatch(adminEarningMPDataGet({ userType: "DC", isdealer: 1 }, {}))
    }
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${DEALERRESELLINGEARNING}?page=${event.target.value}&userType=DC&isdealer=1`
    setcurrentPage(event.target.value)
    // dispatch(delearResellingEarningGet({"url":url}, {}))
    dispatch(adminEarningMPDataGet({ url: url }, {}))
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Input
                  value={searchData}
                  type="text"
                  onChange={e => {
                    SearchByPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name..."
                />
              </div>
            </Col>

            <Col xl={4} className="mt-1 mb-1">
              <Flatpickr
                className="form-control d-block"
                placeholder="Date Range"
                onChange={handleDateChange}
                value={selectedDateRange}
                options={{
                  mode: "range",
                  // dateFormat: "Y-m-d",
                  dateFormat: "d-m-Y",
                }}
              />
            </Col>

            <Col xl={1} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <button
                  onClick={e => clearData(e)}
                  type="button"
                  className="btn btn-primary"
                >
                  Clear
                </button>
              </div>
            </Col>

            <Col xl={2}></Col>

            <Col xl={1} className="mt-1 mb-1">
              <div className="col-sm-auto mt-2">
                <a
                  href={`${HOSTNAME}${ADMINEARNINGDOWNLOAD}?userType=DC&dType=MP&isdealer=1&token=${getUserData.userData.token}`}
                >
                  Download
                </a>
              </div>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xl="12">
          <Row>
            {/* Reports Render */}
            {reports.map((report, key) => (
              <Col md="4" key={"_col_" + key}>
                <Card className=" mt-3 mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {/* <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row> */}

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${DEALERRESELLINGEARNING}?page=${1}&userType=DC&isdealer=1`,
                  1
                )
              }
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${DEALERRESELLINGEARNING}?page=${
                    currentPage - 1
                  }&userType=DC&isdealer=1`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${DEALERRESELLINGEARNING}?page=${
                    currentPage + 1
                  }&userType=DC&isdealer=1`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${DEALERRESELLINGEARNING}?page=${Math.ceil(
                    count / customPageSize
                  )}&userType=DC&isdealer=1`,
                  Math.ceil(count / customPageSize)
                )
              }
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
