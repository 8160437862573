// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./resellingproductstablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { checkTokenExpiration } from "config/getTokenExpirationTime"
import { customerSpendListGet } from "store/actions"
import moment from "moment"

function extractIdAndPName(item) {
  let AMCStartDate = moment(new Date(item.AMCStartDate)).format("DD MMM Y")
  let AMCEndDate = moment(new Date(item.AMCEndDate)).format("DD MMM Y")
  let soldDate = moment(new Date(item.soldDate)).format("DD MMM Y")
  return {
    id: item.id,
    productname: item.product.pname,
    customername: `${item.customer.firstName} ${item.customer.lastName}`,
    product: item.product,
    customer: item.customer,
    soldto: item.soldto,
    soldDate: soldDate,
    pnumber: item.product && item.product.pnumber,
    serialNumbers: item.product && item.product.serialNumbers,
    // RPD_total_sold:item.RPD_total_sold
    AMCStartDate: AMCStartDate,
    AMCEndDate: AMCEndDate,
    MPTotal: item.MPTotal,
    remainingdays: parseInt(
      (new Date(item.AMCEndDate) - new Date()) / (1000 * 60 * 60 * 24),
      10
    ),
  }
}
function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "productname",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Product Bought Date",
        accessor: "soldDate",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View details
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  const dispatch = useDispatch()
  useEffect(() => {
    checkTokenExpiration()
    dispatch(customerSpendListGet({}, {}))
  }, [dispatch])

  let data = []
  const customerspendlists = useSelector(
    state => state.customerProductGet.customerspendlist
  )
  console.log(
    "customerspendlist=>---------",
    customerspendlists && customerspendlists
  )
  if (customerspendlists && customerspendlists.results) {
    console.log(customerspendlists && customerspendlists.results.data, "=>")
    data =
      customerspendlists &&
      customerspendlists.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Spends"

  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }
  const [obj, setobj] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Spends" />

        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={customerspendlists.count}
          next={customerspendlists.next}
          previous={customerspendlists.previous}
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            Customer Product Details
          </ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{obj && obj.productname}</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Registration Date</b>
                            <br></br>
                            <p>{obj && obj.AMCStartDate}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product AMC Remaning Days</b>
                            <br></br>
                            <p>{obj && obj.remainingdays + 1}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>

          {/* <ModalHeader>Consumables Product Details</ModalHeader>
          <ModalBody>
            <SimpleBar>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <Row>
                    <Col md={12}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      {obj.product &&
                        obj.product.mproduct.map(item => (
                          <div key={item.id} className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Product Name</b>
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.mpname}
                                  </span>
                                  <span style={{ color: "#777" }}> X </span>
                                  <span style={{ color: "#555" }}>
                                    {" "}
                                    {item.mpquantity}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody> */}
        </div>
      </Modal>
    </div>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
