import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./Table/Table"
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap"
import { HOSTNAME } from "config/config"
import { toast } from "react-toastify"

const DealerRequestForProduct = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [rejectModal, setRejectModal] = useState(false)
  const [rejectReasonModal, setRejectReasonModal] = useState(false)
  const [rowData, setRowData] = useState(null)
  const [rejectReason, setRejectReason] = useState("")
  const [reason, setReason] = useState(null)
  const [currentPage, setcurrentPage] = useState(1)

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const getDealerRequestProduct = () => {
    setLoading(true)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/inventory-manufacture-product-request-dealer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getDealerRequestProduct()
  }, [])

  const viewRejectReason = row => {
    setRejectReasonModal(!rejectReasonModal)
    setReason(row?.note)
  }

  const toogelRejectModal = row => {
    setRowData(row)
    setRejectModal(!rejectModal)
  }

  const accepted = row => {
    let data = new FormData()
    data.append("tagId", row.id)
    data.append("status", 1)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/accept/tag-inventory-manufacture-product-dealer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product accepted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        getDealerRequestProduct()
      })
      .catch(err => {
        toast.error("Insufficient Quantity", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  const reject = () => {
    let data = new FormData()
    data.append("tagId", rowData?.id)
    data.append("status", 2)
    data.append("note", rejectReason)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/accept/tag-inventory-manufacture-product-dealer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product rejected successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        getDealerRequestProduct()
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setRejectModal(false)
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "RPD.pnumber.pname",
      },
      {
        Header: "Product Number",
        accessor: "RPD.pnumber.pnumber",
      },
      {
        Header: "Product  Consumables Name",
        accessor: "RPD.mpname",
      },
      {
        Header: "Product Quantity",
        accessor: "qty",
      },
      {
        Header: "Dealer Name",
        accessor: "dname",
        Cell: cellProps => {
          const row = cellProps.row.original.dealer

          return (
            <p>
              {row.firstName} {row.lastName}
            </p>
          )
        },
      },
      {
        Header: "Dealer’s City",
        accessor: "dealer.city",
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <>
              {row.status === 0 && (
                <div className="d-flex justify-content-between">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      accepted(row)
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => toogelRejectModal(row)}
                    size="sm"
                    color="warning"
                  >
                    Reject
                  </Button>
                </div>
              )}
              {row.status === 1 && (
                <Button size="sm" color="success">
                  Accepted
                </Button>
              )}
              {row.status === 2 && (
                <div className="d-flex justify-content-between align-items-center">
                  <Button size="sm" color="warning">
                    Rejected
                  </Button>
                  <UncontrolledDropdown className="me-2" direction="start">
                    <DropdownToggle size="sm" color="">
                      <i className="bx bx-dots-vertical fs-4"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem onClick={() => viewRejectReason(row)}>
                        View Reason
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </>
          )
        },
      },
    ],
    []
  )

  // Search
  const [query, setquery] = useState("")
  const onSearch = e => {
    setquery(e.target.value)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?query=${e.target.value}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //Pagination function
  const getPaginationData = (url, page) => {
    setcurrentPage(page)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Consumables Products" />

        <TableContainer
          count={tableData?.count}
          next={tableData?.next}
          previous={tableData?.previous}
          columns={columns}
          onSearch={onSearch}
          data={tableData?.results?.data || []}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
          removeCheckbox
          removeDelete
          currentPage={currentPage}
          goToNextPage={() =>
            getPaginationData(
              `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?query=${query}&page=${currentPage + 1
              }`,
              currentPage + 1
            )
          }
          goToLastPage={() =>
            getPaginationData(
              `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?query=${query}&page=${Math.ceil(
                tableData?.count / 10
              )}`,
              Math.ceil(tableData?.count / 10)
            )
          }
          goToPreviousPage={() =>
            getPaginationData(
              `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?query=${query}&page=${currentPage - 1
              }`,
              currentPage - 1
            )
          }
          goToFirstPage={() =>
            getPaginationData(
              `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?query=${query}&page=${1}`,
              1
            )
          }
          getInputPaginationData={e =>
            getPaginationData(
              `${HOSTNAME}api/inventory-manufacture-product-request-dealer/?query=${query}&page=${e.target.value}`,
              e.target.value
            )
          }
        />

        <Modal
          isOpen={rejectModal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={toogelRejectModal}
        >
          <div className="modal-content">
            <ModalHeader toggle={toogelRejectModal}>
              Reason for rejection
            </ModalHeader>
            <ModalBody>
              <p> </p>

              <FormGroup className="mb-3">
                <Label htmlFor="">Write reason</Label>
                <Input
                  type="textarea"
                  name="product-name"
                  className="form-control"
                  placeholder="Enter Text Here"
                  onChange={e => setRejectReason(e.target.value)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button
                disabled={rejectReason?.length <= 0}
                type="button"
                color="primary"
                onClick={reject}
              >
                Submit
              </Button>

              <Button
                type="button"
                onClick={toogelRejectModal}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Modal>
        <Modal
          isOpen={rejectReasonModal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={viewRejectReason}
        >
          <div className="modal-content">
            <ModalHeader toggle={viewRejectReason}>
              Reason for rejection
            </ModalHeader>
            <ModalBody>
              <p> </p>

              <FormGroup className="mb-3">
                <Label htmlFor="">View reason</Label>
                <Input
                  type="textarea"
                  name="product-name"
                  className="form-control"
                  placeholder="Enter Text Here"
                  value={reason}
                  disabled
                />
              </FormGroup>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default DealerRequestForProduct
