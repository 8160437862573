import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  CUSTOMER_ACTIVATED_PRODUCT_LIST_GET,
  CUSTOMER_BUYBACK_PRODUCT_LIST_GET,
  CUSTOMER_CENTRIC_LIST_GET,
  CUSTOMER_NEWPRODUCTREQUEST_LIST_GET,
  CUSTOMER_SERVICES_LIST_GET,
  CUSTOMER_SPEND_LIST_GET,
  CUSTOMER_VERIFY_PRODUCT_LIST_GET,
  CUSTOMER_VIEWORDERS_LIST_GET,
  TAGGED_PRODUCTCUSTOMER_GET,
  // TAGGED_PRODUCTCUSTOMER_SUCCESS
} from "./actionTypes"

import {
  customerActivatedProductListSuccess,
  customerBuyBackProductListSuccess,
  customerCentricListGet,
  customerCentricListSuccess,
  customerNewProductRequestListSuccess,
  customerServicesListSuccess,
  customerSpendListSuccess,
  customerVerifyProductListSuccess,
  customerViewOrdersListSuccess,
  taggedProductCustomerGet,
  taggedProductCustomerSuccess,
} from "./actions"

import {
  HOSTNAME,
  GETTAGGEDPRODUCTCUSTOMER,
  CUSTOMERVERIFYPRODUCTLIST,
  ACTIVATEDPRODUCTLIST,
  CUSTOMERADDBUYBACKDATA,
  VIEWORDERS,
  CUSTOMERNEWPRODUCTREQUEST,
  CUSTOMERSPENDPRODUCT,
  CUSTOMERSERVICES,
  CUSTOMERCENTRIC,
} from "config/config"

import { apiError } from "store/actions"
import axios from "axios"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function* customerTaggedProductList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${GETTAGGEDPRODUCTCUSTOMER}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "3") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${GETTAGGEDPRODUCTCUSTOMER}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(taggedProductCustomerSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------
function* customerVerifyProductList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${CUSTOMERVERIFYPRODUCTLIST}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "3") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${CUSTOMERVERIFYPRODUCTLIST}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerVerifyProductListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------
function* customerActivatedProductList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${ACTIVATEDPRODUCTLIST}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "3") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${ACTIVATEDPRODUCTLIST}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerActivatedProductListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------

function* customerBuyBackProductList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${CUSTOMERADDBUYBACKDATA}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "3") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${CUSTOMERADDBUYBACKDATA}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerBuyBackProductListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------

function* customerViewOrdersList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${VIEWORDERS}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "3") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${VIEWORDERS}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerViewOrdersListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------

function* customerNewProductRequestList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${CUSTOMERNEWPRODUCTREQUEST}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "3") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${CUSTOMERNEWPRODUCTREQUEST}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerNewProductRequestListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------

function* customerSpendList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${CUSTOMERSPENDPRODUCT}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "3") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${CUSTOMERSPENDPRODUCT}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerSpendListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------

function* customerServiceList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${CUSTOMERSERVICES}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "4") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${CUSTOMERSERVICES}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerServicesListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------

function* customerCentricList({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${CUSTOMERCENTRIC}`
    if (query.url) {
      url = query.url
    } else {
      const queryParams = []
      if (query.query) {
        queryParams.push(`query=${query.query}`)
      }
      if (query.status && query.status !== "4") {
        queryParams.push(`status=${query.status}`)
      }
      if (query.type) {
        queryParams.push(`type=${query.type}`)
      }
      if (query.startDate && query.endDate) {
        queryParams.push(`startDate=${query.startDate}`)
        queryParams.push(`endDate=${query.endDate}`)
      }
      if (queryParams.length > 0) {
        url = `${HOSTNAME}${CUSTOMERCENTRIC}?${queryParams.join("&")}`
      }
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(customerCentricListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}
// ----------

function* CustomerProductsSaga() {
  yield takeEvery(TAGGED_PRODUCTCUSTOMER_GET, customerTaggedProductList)
  yield takeEvery(CUSTOMER_VERIFY_PRODUCT_LIST_GET, customerVerifyProductList)
  yield takeEvery(
    CUSTOMER_ACTIVATED_PRODUCT_LIST_GET,
    customerActivatedProductList
  )
  yield takeEvery(CUSTOMER_BUYBACK_PRODUCT_LIST_GET, customerBuyBackProductList)
  yield takeEvery(CUSTOMER_VIEWORDERS_LIST_GET, customerViewOrdersList)
  yield takeEvery(
    CUSTOMER_NEWPRODUCTREQUEST_LIST_GET,
    customerNewProductRequestList
  )
  yield takeEvery(CUSTOMER_SPEND_LIST_GET, customerSpendList)
  yield takeEvery(CUSTOMER_SERVICES_LIST_GET, customerServiceList)
  yield takeEvery(CUSTOMER_CENTRIC_LIST_GET, customerCentricList)
}

export default CustomerProductsSaga
