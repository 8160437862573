import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGOUT_USER,
  NEWCUSTOMER_GET,
  NEWCUSTOMER_SUCCESS,
  NEWCUSTOMER_UPLOAD_POST,
  EXISTINGCUSTOMER_GET,
  EXISTINGCUSTOMER_SUCCESS,
  EXISTINGCUSTOMER_UPLOAD_POST,
  NEWORDERFROMCUSTOMER_GET,
  NEWCUSTOMER_UPLOAD_SUCCESS,
  NEWORDERFROMDEALERTOCUSTOMER_GET,
  NEWORDERFROMDEALERTOCUSTOMER_SUCCESS,
  EXISTINGCUSTOMERLIST_GET,
  EXISTINGCUSTOMERLIST_SUCCESS,
  PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET,
  PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_SUCCESS,
} from "./actionTypes"

import {
  newCustomerGet,
  newCustomerSuccess,
  existingCustomerGet,
  existingCustomerSuccess,
  newOrderFromCustomerGet,
  newOrderFromCustomerSuccess,
  newOrderFromDealerToCustomerGet,
  newOrderFromDealerToCustomerSuccess,
  existingCustomerListGet,
  existingCustomerListSuccess,
  productverificationofexistingcustomerGet,
  productverificationofexistingcustomerSuccess,
} from "./actions"

import {
  HOSTNAME,
  NEWCUSTOMERGET,
  NEWCUSTOMERUPLOAD,
  EXISTINGCUSTOMERGET,
  EXISTINGCUSTOMERUPLOAD,
  NEWORDERFROMCUSTOMERGET,
  NEWORDERFROMDEALERTOCUSTOMERGET,
  EXISTINGCUSTOMERLISTGET,
  PRODUCTVERIFICATIONOFEXISTINGCUSTOMERGET,
} from "config/config"

import { apiError } from "store/actions"
import axios from "axios"

// import { baseUrl } from "constants/Url"
import { toast } from "react-toastify"
// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function* newCustomer({ payload: { query, history } }) {
  try {
    // alert(query.query)
    let url = `${HOSTNAME}${NEWCUSTOMERGET}?type=0`
    if (query.query) {
      url = `${HOSTNAME}${NEWCUSTOMERGET}?type=0&query=${query.query}`
    } else if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    const response = yield axios.request(config)
    console.log("response.data=>", response.data)
    if (response.status === 200) {
      yield put(newCustomerSuccess(response.data))
      // // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ----------- EXISTING CUSTOMER------------

function* existingCustomer({ payload: { query, history } }) {
  try {
    // alert("okay")
    let url = `${HOSTNAME}${EXISTINGCUSTOMERGET}?type=1`
    if (query.query) {
      url = `${HOSTNAME}${EXISTINGCUSTOMERGET}?type=1&query=${query.query}`
    } else if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    const response = yield axios.request(config)
    console.log("response.data=>", response.data)
    if (response.status === 200) {
      yield put(existingCustomerSuccess(response.data))
      // // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ----------- NEW ORDER FROM CUSTOMER------------
function* newOrderFromCustomer({ payload: { query, history } }) {
  try {
    // alert(query.query)
    // let url = `${HOSTNAME}${NEWORDERFROMCUSTOMERGET}`
    // if(query.query){
    //   url =`${HOSTNAME}${NEWORDERFROMCUSTOMERGET}?query=${query.query}`
    // }
    // else if(query.url){
    //   url=query.url
    // }
    let url = `${HOSTNAME}${NEWORDERFROMCUSTOMERGET}`
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status && query.status !== "3") {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemoproduct) {
      isdemoproduct = query.isdemoproduct
      queryParams.push(`isdemoproduct=${query.isdemoproduct}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${NEWORDERFROMCUSTOMERGET}&${queryParams.join("&")}`
    }
    if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(newOrderFromCustomerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ----------- NEW ORDER FROM DEALER TO CUSTOMER------------

function* newOrderFromDealerToCustomer({ payload: { query, history } }) {
  try {
    // alert(query.query)
    // let url = `${HOSTNAME}${NEWORDERFROMDEALERTOCUSTOMERGET}`
    // if(query.query){
    //   url =`${HOSTNAME}${NEWORDERFROMDEALERTOCUSTOMERGET}?query=${query.query}`
    // }
    // else if(query.url){
    //   url=query.url
    // }
    let url = `${HOSTNAME}${NEWORDERFROMDEALERTOCUSTOMERGET}`
    const queryParams = []
    if (query.query) {
      queryParams.push(`query=${query.query}`)
    }
    if (query.status && query.status !== "3") {
      queryParams.push(`status=${query.status}`)
    }
    if (query.isdemoproduct) {
      isdemoproduct = query.isdemoproduct
      queryParams.push(`isdemoproduct=${query.isdemoproduct}`)
    }
    if (queryParams.length > 0) {
      url = `${HOSTNAME}${NEWORDERFROMDEALERTOCUSTOMERGET}&${queryParams.join(
        "&"
      )}`
    }
    if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    const response = yield axios.request(config)

    if (response.status === 200) {
      yield put(newOrderFromDealerToCustomerSuccess(response.data))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/pre-login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* newCustomerUpload({ payload: { fileData, history } }) {
  try {
    // alert(fileData)
    // let data = new FormData();
    // data.append('file', data);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${NEWCUSTOMERUPLOAD}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        "Content-Type": "multipart/form-data",
      },
      data: fileData.data,
    }
    const response = yield axios.request(config)
    if (response.status == 400) {
      toast.error("File Note Valid", {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
    console.log("+++++++++++++", response)
    if (response.data.status_code === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total created: ${response.data.data.created}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total updated: ${response.data.data.updated}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(newCustomerGet({}, {}))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error("something went wrong", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ---------Dealer`s Customer File-----------

function* existingCustomerUpload({ payload: { fileData, history } }) {
  try {
    // alert(fileData)
    // let data = new FormData();
    // data.append('file', data);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${EXISTINGCUSTOMERUPLOAD}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
        "Content-Type": "multipart/form-data",
      },
      data: fileData.data,
    }
    const response = yield axios.request(config)
    if (response.status == 400) {
      toast.error("File Note Valid", {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
    console.log("+++++++++++++", response)
    if (response.data.status_code === 201) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total created: ${response.data.data.created}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      toast.success(`Total updated: ${response.data.data.updated}`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(existingCustomerGet({}, {}))
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error("something went wrong", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// --------- Customer==> Dealer`s Customer List--------

function* existingCustomerList({ payload: { query, history } }) {
  try {
    // alert("okay")
    let type = 1
    if ("type" in query) {
      // alert(query.type)
      type = query.type
    }
    let url = `${HOSTNAME}${EXISTINGCUSTOMERLISTGET}?type=${type}`
    if (query.query) {
      url = `${HOSTNAME}${EXISTINGCUSTOMERLISTGET}?type=${type}&query=${query.query}`
    } else if (query.url) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    const response = yield axios.request(config)
    console.log("response.data=>", response.data)
    if (response.status === 200) {
      yield put(existingCustomerListSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// ------- customer---> product verification of existing customer

function* productverificationofexistingcustomer({
  payload: { query, history },
}) {
  try {
    // alert("okay")
    let type = "DC"
    let isverify = 2
    if ("isverify" in query && ~(query.isverify == 2)) {
      isverify = query.isverify
    }
    if ("type" in query) {
      // alert(query.type)
      type = query.type
    }
    let url = `${HOSTNAME}${PRODUCTVERIFICATIONOFEXISTINGCUSTOMERGET}?type=${type}&isverify=${isverify}`
    if (query.query) {
      url = `${HOSTNAME}${PRODUCTVERIFICATIONOFEXISTINGCUSTOMERGET}?type=${type}&query=${query.query}&isverify=${isverify}`
    } else if (query.url) {
      url = query.url
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    const response = yield axios.request(config)
    console.log("response.data=>", response.data)
    if (response.status === 200) {
      yield put(productverificationofexistingcustomerSuccess(response.data))
      // console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* CustomerSaga() {
  yield takeEvery(NEWCUSTOMER_GET, newCustomer)
  yield takeEvery(NEWCUSTOMER_UPLOAD_POST, newCustomerUpload)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(EXISTINGCUSTOMER_GET, existingCustomer)
  yield takeEvery(EXISTINGCUSTOMER_UPLOAD_POST, existingCustomerUpload)
  yield takeEvery(NEWORDERFROMCUSTOMER_GET, newOrderFromCustomer)
  yield takeEvery(
    NEWORDERFROMDEALERTOCUSTOMER_GET,
    newOrderFromDealerToCustomer
  )
  yield takeEvery(EXISTINGCUSTOMERLIST_GET, existingCustomerList)
  yield takeEvery(
    PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET,
    productverificationofexistingcustomer
  )
}

export default CustomerSaga
