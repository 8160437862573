import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADMINFINANCIAL_DATA_GET,
  ADMINFINANCIAL_DATA_SUCCESS
} from "./actionTypes"

import { 
  adminFinancialDataGet,
  adminFinancialDataSuccess
} from "./actions"

import { 
  HOSTNAME,
  ADMINFINANCIALYEAR

} from "config/config"

import { apiError } from "store/actions"
import axios from "axios"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser")) 

function* financialData({ payload: { query, history } }) {
  try {
            let url = `${HOSTNAME}${ADMINFINANCIALYEAR}?`
            if("query" in query){
              url +=`&query=${query.query}`
            }
            if("startDate" in query && "endDate" in query){
              url +=`&startDate=${query.startDate}&endDate=${query.endDate}`
            }
            if("year" in query){
              url +=`&year=${query.year}`
            }
            if("soldto" in query){
              url +=`&soldto=${query.soldto}`
            }
            if (query.url) {
              url = query.url
            }
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(adminFinancialDataSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* financialDataSaga() {
  yield takeEvery(ADMINFINANCIAL_DATA_GET, financialData) 
}

export default financialDataSaga
