// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loadder from "components/Loadder/Loadder"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./buybackrequestdeleartocustomertablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { checkTokenExpiration } from "../../../src/config/getTokenExpirationTime"
import { adminBuyBackDataGet } from "../../store/actions"
function extractIdAndPName(item) {
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }
  return {
    id: item.id,
    customername: `${item.firstName} ${item.lastName}`,
    ccity: item.city,
    customercontactnumber: item.contactNumber,
    delearname: `${item.dealerfirstName} ${item.dealerlastName}`,
    delearcontactnumber: item.dealercontactNumber,
    productname: item.productName,
    pnumber: item.pnumber,
    status: item.status,
    reason: item.reason,
    dealeragency: item.dealeragency,
    AMCStartDate: item.AMCStartDate,
    AMCEndDate: item.AMCEndDate,
    remainingdays:
      (new Date(item.AMCEndDate) - new Date()) / (1000 * 60 * 60 * 24),
    statustext: statustext,
    serialNumbers: item.serialNumbers,
  }
}

function DatatableTablesManufacture() {
  const columns = useMemo(
    () => [
      // {
      //     Header: '#',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      {
        Header: "Customer Contact Number",
        accessor: "customercontactnumber",
      },
      {
        Header: "Delear Name",
        accessor: "delearname",
      },
      {
        Header: "Delear Contact Number",
        accessor: "delearcontactnumber",
      },
      {
        Header: "Product Name",
        accessor: "productname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row.original)}>
                  View details
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={e => viewrejectiontoggle1(row.original)}>
                  <i
                    className="font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  View Reason
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View Reason
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  let data = []

  const adminbuybackdata = useSelector(
    state => state.adminBuyBackGet.adminBuyBackDetails
  )

  if (adminbuybackdata && adminbuybackdata.results) {
    data =
      adminbuybackdata && adminbuybackdata.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Refurbished"

  const [objData, setobjData] = useState({})
  const [modal, setModal] = useState(false)
  const togglemodal = () => {
    setModal(!modal)
  }

  const togglemodal1 = row => {
    // alert(row)
    const dateObj1 = new Date(row.AMCStartDate)
    let formattedDate1 = "-"
    // const formattedDate1 = dateObj1.toISOString().split('T')[0]; // Extract date part
    try {
      formattedDate1 = dateObj1.toISOString().split("T")[0] // Extract date part
      // Use formattedDate1
    } catch (error) {
      // If an error occurs, set formattedDate1 to "-"
      formattedDate1 = "-"
      // Handle the error or perform any necessary actions
    }

    let fdata = {
      id: row.id,
      customername: row.customername,
      ccity: row.ccity,
      customercontactnumber: row.customercontactnumber,
      delearname: row.delearname,
      delearcontactnumber: row.delearcontactnumber,
      productname: row.productname,
      status: row.status,
      reason: row.reason,
      dealeragency: row.dealeragency,
      AMCStartDate: formattedDate1,
      AMCEndDate: row.AMCEndDate,
      remainingdays: isNaN(row.remainingdays)
        ? 0
        : parseInt(row.remainingdays, 10),
    }
    setModal(!modal)
    // const foundData = data && data.find(item => item.id === row);
    setobjData(fdata)
  }

  const [viewrejection, setViewrejection] = useState(false)
  const viewrejectiontoggle = () => {
    setViewrejection(!viewrejection)
  }

  const viewrejectiontoggle1 = row => {
    let fdata = {
      id: row.id,
      customername: row.customername,
      ccity: row.ccity,
      customercontactnumber: row.customercontactnumber,
      delearname: row.delearname,
      delearcontactnumber: row.delearcontactnumber,
      productname: row.productname,
      status: row.status,
      reason: row.reason,
      dealeragency: row.dealeragency,
      AMCStartDate: row.AMCStartDate,
      AMCEndDate: row.AMCEndDate,
      remainingdays: parseInt(row.remainingdays, 10),
    }
    setobjData(fdata)
    setViewrejection(!viewrejection)
  }

  const dispatch = useDispatch()
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    // alert("called")
    checkTokenExpiration()
    dispatch(adminBuyBackDataGet({ type: "DC" }, {}))
  }, [dispatch])

  return (
    <Loadder active={adminbuybackdata.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Refurbished" />

          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={adminbuybackdata.count}
            next={adminbuybackdata.next}
            previous={adminbuybackdata.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={modal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>Customer Details</ModalHeader>
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Name</b>
                              <br></br>
                              <p>{objData && objData.customername}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer City</b>
                              <br></br>
                              <p>{objData && objData.ccity}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Customer Number</b>
                              <br></br>
                              <p>{objData && objData.customercontactnumber}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
            <ModalHeader>Customer Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Name</b>
                              <br></br>
                              <p>{objData && objData.productname}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product Registration Date</b>
                              <br></br>
                              <p>{objData && objData.AMCStartDate}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Product AMC Remaning Days</b>
                              <br></br>
                              <p>{objData && objData.remainingdays}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Notes</Label>
                    <Input
                      disabled
                      name="notes"
                      placeholder="Add Notes"
                      type="textarea"
                      className="form-control"
                      id="validationCustom01"
                      value={objData && objData.reason}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalHeader>Dealer Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>DealerAgency Name</b>
                              <br></br>
                              <p>{objData && objData.dealeragency}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Dealer Name</b>
                              <br></br>
                              <p>{objData && objData.delearname}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Dealer Number</b>
                              <br></br>
                              <p>{objData && objData.delearcontactnumber}</p>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div>
                              <b>Dealer City</b>
                              <br></br>
                              <p>Mumbai</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>

        {/* ------------------- View Reason For Rejection------------- */}
        <Modal
          isOpen={viewrejection}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={viewrejectiontoggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={viewrejectiontoggle}>
              Reason for rejection
            </ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <p> </p>
                    <Label htmlFor="validationCustom01">View reason</Label>
                    <Input
                      name="viewreason"
                      placeholder="Enter Text Here"
                      type="textarea"
                      rows={4}
                      className="form-control"
                      id="validationCustom01"
                      disabled
                      value={objData && objData.reason}
                    />
                  </FormGroup>
                </Col>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
