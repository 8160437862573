import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import { toast } from "react-toastify"

const getUserData = JSON.parse(localStorage.getItem("authUser"))
import { HOSTNAME, DEMOMAINTAININVENTORY } from "config/config"
import { demoProductAdd } from "../../../src/store/actions"

import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
//Dropzone
import Dropzone from "react-dropzone"
//Import images
import verificationImg from "../../assets/images/verification-img.png"

import { Link } from "react-router-dom"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
//redux
import { useSelector, useDispatch } from "react-redux"
const MaintainInventory = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    setTimeout(() => {
      // setSubscribemodal(true);
    }, 2000)
  }, [])

  const [reportTempData, setreportTempData] = useState([])
  const dashboardReport = () => {
    let url = `${HOSTNAME}${DEMOMAINTAININVENTORY}`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        setreportTempData(response.data.data)
      })
      .catch(error => {})
  }
  useEffect(() => {
    dashboardReport()
  }, [])

  const reports = [
    {
      title: "Total Demo Products",
      iconClass: "bx-purchase-tag-alt",
      description: `${
        reportTempData.TotalDemoProduct ? reportTempData.TotalDemoProduct : 0
      }`,
    },
    {
      title: "Stock Demo Products",
      iconClass: "bx-purchase-tag-alt",
      description: `${
        reportTempData.StockedDemoProduct
          ? reportTempData.StockedDemoProduct
          : 0
      }`,
    },
    {
      title: "Total Demo Products Assigned to Dealers",
      iconClass: "bx-purchase-tag-alt",
      description: `${
        reportTempData.TotalDemoProductAssignDelaer
          ? reportTempData.TotalDemoProductAssignDelaer
          : 0
      }`,
    },
  ]
  const [modal, setModal] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [selectedFiles, setSelectedFiles] = useState([])

  const [pid, setpid] = useState("")
  const [addresellingproductname, setaddresellingproductname] = useState("")
  const [serialNumbers, setserialNumbers] = useState("")
  const [addresellingproductquantity, setaddresellingproductquantity] =
    useState("")
  const [addmanufacturedproducts, setaddmanufacturedproducts] = useState("")
  const [addmanufacturedquantity, setaddmanufacturedquantity] = useState("")
  const [addmanufacturednumber, setaddmanufacturednumber] = useState("")
  const [manufactureProducts, setmanufactureProducts] = useState([])

  const setValues = () => {
    if (addmanufacturedproducts == "") {
      // alert("add manufactured products required")
      toast.error("add manufactured products required", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else if (addmanufacturedquantity == "") {
      // alert("add manufactured quantity required")
      toast.error("add manufactured quantity required", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else if (addmanufacturednumber == "") {
      // alert("add manufactured number required")
      toast.error("add manufactured number required", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      // alert(addmanufacturedproducts)
      // alert(addmanufacturedquantity)
      let newProductData = {
        mpname: addmanufacturedproducts,
        mpquantity: addmanufacturedquantity,
        mpnumber: addmanufacturednumber,
        mpdescription: "",
      }
      setmanufactureProducts([...manufactureProducts, newProductData])
      setaddmanufacturedproducts("")
      setaddmanufacturedquantity("")
      setaddmanufacturednumber("")
    }
  }

  const togglemodal = () => {
    setModal(!modal)
  }

  const removeManufacturedProduct = index => {
    const updatedProducts = [...manufactureProducts]
    updatedProducts.splice(index, 1)
    setmanufactureProducts(updatedProducts)
  }

  const SendData = e => {
    let data = {
      pname: addresellingproductname,
      pnumber: pid,
      pquantity: addresellingproductquantity,
      pdescription: "",
      manufacturedProduct: manufactureProducts,
      serialNumbers: serialNumbers,
    }
    dispatch(demoProductAdd({ data: data }, {}))
    setTimeout(dashboardReport, 1000)
    setaddresellingproductname("")
    // dashboardReport();
    setpid("")
    setaddresellingproductquantity("")
    setmanufactureProducts([])
    setserialNumbers(""), togglemodal()
    dashboardReport()
    dashboardReport()
    dashboardReport()
  }
  //meta title
  document.title = "Demo Product"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // pid:'AB101',
      // addresellingproductname: 'OKI Printer',
      // addresellingproductquantity: '1',
      // addmanufacturedproducts: 'Black-Toner',
      // addmanufacturedquantity: '5',
    },
    validationSchema: Yup.object({
      pid: Yup.string().required("Please Enter Your Product id"),
      addresellingproductname: Yup.string().required(
        "Please Enter Your Equipment Product Name"
      ),
      addresellingproductquantity: Yup.string().required(
        "Please Enter Your Equipment Product Quantity"
      ),
      addmanufacturedproducts: Yup.string().required(
        "Please Enter Your Consumables Products"
      ),
      addmanufacturedquantity: Yup.string().required(
        "Please Enter Your  Consumables Quantity"
      ),
      addmanufacturednumber: Yup.string().required(
        "Please Enter Your Consumables Number"
      ),
      serialNumbers: Yup.string().required("Please Enter Serial Numbers"),
    }),
    onSubmit: values => {},
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Demo Products")}
          />
          <Row>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          {/* <Row className="justify-content-center mt-lg-5">
            <Col xl="5" sm="8">
              <Card>
                <CardBody>
                  <Row className="justify-content-center mt-5 mb-2">
                    <Col sm="3" xs="3">
                      <div>
                        <img
                          src={verificationImg}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Row className="justify-content-center">
                      <Col lg="10">
                        <h4 className="mt-4 fw-semibold">Add Demo Product</h4>
                        <p className="text-muted mt-3">
                          Itaque earum rerum hic tenetur a sapiente delectus ut
                          aut reiciendis perferendis asperiores repellat.
                        </p>

                        <div className="mt-4">
                         
                          <Button
                            type="button"
                            color="primary"
                            onClick={togglemodal}
                          >
                            Add Product
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>

                 
                  <Modal
                    isOpen={modal}
                    role="dialog"
                    size="lg"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={togglemodal}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={togglemodal}>
                        Add Demo Equipment Product
                      </ModalHeader>

                      <ModalBody>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Product Number
                              </Label>
                              <Input
                                name="pid"
                                placeholder="Product Number"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={e => setpid(e.target.value)}
                                // onBlur={validation.handleBlur}
                                value={pid}
                                invalid={
                                  validation.touched.pid &&
                                  validation.errors.pid
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.pid &&
                              validation.errors.pid ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.pid}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Add Equipment Product Name
                              </Label>
                              <Input
                                name="addresellingproductname"
                                placeholder="Add Equipment Product Name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={e =>
                                  setaddresellingproductname(e.target.value)
                                }
                                // onBlur={validation.handleBlur}
                                value={addresellingproductname}
                                invalid={
                                  validation.touched.addresellingproductname &&
                                  validation.errors.addresellingproductname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.addresellingproductname &&
                              validation.errors.addresellingproductname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.addresellingproductname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Add Equipment Product Quantity
                              </Label>
                              <Input
                                name="addresellingproductquantity"
                                placeholder="Add Equipment Product Quantity"
                                type="number"
                                className="form-control"
                                id="validationCustom02"
                                // onChange={(e) =>setaddresellingproductquantity(e.target.value)}
                                onChange={e => {
                                  const value = parseInt(e.target.value, 10)
                                  if (!isNaN(value) && value >= 1) {
                                    setaddresellingproductquantity(value)
                                  } else {
                                    setaddresellingproductquantity("")
                                  }
                                }}
                                // onBlur={validation.handleBlur}
                                value={addresellingproductquantity}
                                invalid={
                                  validation.touched
                                    .addresellingproductquantity &&
                                  validation.errors.addresellingproductquantity
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.addresellingproductquantity &&
                              validation.errors.addresellingproductquantity ? (
                                <FormFeedback type="invalid">
                                  {
                                    validation.errors
                                      .addresellingproductquantity
                                  }
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Add Equipment Serial Numbers
                              </Label>
                              <Input
                                name="serialNumbers"
                                placeholder="serial Numbers"
                                type="textarea"
                                className="form-control"
                                id="validationCustom02"
                                onChange={e => setserialNumbers(e.target.value)}
                                // onBlur={validation.handleBlur}
                                value={serialNumbers}
                                invalid={
                                  validation.touched.serialNumbers &&
                                  validation.errors.serialNumbers
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.serialNumbers &&
                              validation.errors.serialNumbers ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.serialNumbers}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <Label>
                              <b>Consumables Details</b>
                            </Label>
                          </Col>
                          <Col md="3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Name</Label>
                              <Input
                                name="addmanufacturedproducts"
                                placeholder="mpname"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={e =>
                                  setaddmanufacturedproducts(e.target.value)
                                }
                                // onBlur={validation.handleBlur}
                                value={addmanufacturedproducts}
                                invalid={
                                  validation.touched.addmanufacturedproducts &&
                                  validation.errors.addmanufacturedproducts
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.addmanufacturedproducts &&
                              validation.errors.addmanufacturedproducts ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.addmanufacturedproducts}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Quantity
                              </Label>
                              <Input
                                name="addmanufacturedquantity"
                                placeholder="mquantity"
                                type="number"
                                className="form-control"
                                id="validationCustom02"
                                // onChange={(e) =>setaddmanufacturedquantity(e.target.value)}
                                onChange={e => {
                                  const value = parseInt(e.target.value, 10)
                                  if (!isNaN(value) && value >= 1) {
                                    setaddmanufacturedquantity(e.target.value)
                                  } else {
                                    setaddmanufacturedquantity("")
                                  }
                                }}
                                // onBlur={validation.handleBlur}
                                value={addmanufacturedquantity}
                                invalid={
                                  validation.touched.addmanufacturedquantity &&
                                  validation.errors.addmanufacturedquantity
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.addmanufacturedquantity &&
                              validation.errors.addmanufacturedquantity ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.addmanufacturedquantity}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Consumables Number
                              </Label>
                              <Input
                                name="addmanufacturednumber"
                                placeholder="mpnumber"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={e =>
                                  setaddmanufacturednumber(e.target.value)
                                }
                                // onBlur={validation.handleBlur}
                                value={addmanufacturednumber}
                                invalid={
                                  validation.touched.addmanufacturednumber &&
                                  validation.errors.addmanufacturednumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.addmanufacturednumber &&
                              validation.errors.addmanufacturednumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.addmanufacturednumber}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2"></Col>
                          <Col md="2 mt-4">
                            <Button
                              type="button"
                              onClick={e => setValues(e)}
                              color="primary"
                            >
                              Add New
                            </Button>
                          </Col>
                          <Row>
                            {manufactureProducts.map((product, index) => (
                              <Col key={index} md="4">
                                <UncontrolledAlert
                                  color="secondary"
                                  role="alert"
                                >
                                  {product.mpname} - {product.mpquantity}-{" "}
                                  {product.mpnumber}
                              
                                  <button
                                    onClick={() =>
                                      removeManufacturedProduct(index)
                                    }
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                  ></button>
                                </UncontrolledAlert>
                              </Col>
                            ))}
                          </Row>
                        </Row>
                      </ModalBody>

                      <ModalFooter
                        style={{ display: "flex", justifyContent: "left" }}
                      >
                        <Button
                          type="button"
                          onClick={e => SendData(e)}
                          color="primary"
                        >
                          Submit
                        </Button>

                        <Button
                          type="button"
                          onClick={togglemodal}
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

MaintainInventory.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(MaintainInventory)
