import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, Label } from "reactstrap"

import { useDispatch } from "react-redux"
import { taggedProductCustomerGet } from "store/actions"
import { useEffect } from "react"
import { useState } from "react"
import { GETTAGGEDPRODUCTCUSTOMER, HOSTNAME } from "config/config"

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        //   defaultColumn: { Filter: DefaultColumnFilter },
        initialState: {
          pageIndex: 0,
          pageSize: customPageSize,
        },
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination
    )
  const scrollRef = useRef()

  const dispatch = useDispatch()
  const [query, setquery] = useState("")
  const SearchByMPname = e => {
    if (e.target.value) {
      setquery(e.target.value)
      dispatch(
        taggedProductCustomerGet({ status: status, query: e.target.value }, {})
      )
    } else {
      setquery("")
      dispatch(taggedProductCustomerGet({ status: status }, {}))
    }
  }

  const onclickForPage = (url, number) => {
    setcurrentPage(number)
    dispatch(taggedProductCustomerGet({ url: url }, {}))
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  useEffect(() => {
    const handleScroll = event => {
      // Check if the user is scrolling horizontally (to the left)
      if (event.deltaX !== 0) {
        // Prevent the default horizontal scroll behavior
        event.preventDefault()
      }
    }

    // Add scroll event listener
    window.addEventListener("wheel", handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("wheel", handleScroll)
    }
  }, [])

  const onChangeInInput = event => {
    let url = `${HOSTNAME}${GETTAGGEDPRODUCTCUSTOMER}?status=${status}&query=${query}&page=${event.target.value
      }&status=${status === "3" ? "" : status}`
    setcurrentPage(event.target.value)
    dispatch(taggedProductCustomerGet({ url: url }, {}))
  }
  const [status, setstatus] = useState("3")

  const setAndCallApi = e => {
    dispatch(taggedProductCustomerGet({ status: e.target.value, query: query }, {}))
    setstatus(e?.target?.value)
  }

  const scrollToLeft = () => {
    const element = scrollRef.current

    element.scrollBy({
      left: -window.innerWidth + 100,
      behavior: "smooth",
    })
  }

  const scrollToRight = () => {
    const element = scrollRef.current

    element.scrollBy({
      left: window.innerWidth - 100,
      behavior: "smooth",
    })
  }
  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto mb-1">
                <Label className="visually-hidden" htmlFor="autoSizingInput">
                  Search
                </Label>
                <Input
                  type="text"
                  onChange={e => {
                    SearchByMPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name"
                />
              </div>
            </Col>
            <Col xl={2} className="mt-1 mb-1">
              <div className="col-sm-auto mb-1">
                <select
                  onChange={e => setAndCallApi(e)}
                  className="form-select"
                >
                  <option value="" disabled selected hidden>
                    Status
                  </option>
                  <option value="0">Pending</option>
                  <option value="1">
                    <a>Accepted</a>
                  </option>
                  <option value="2">
                    <a>Rejected</a>
                  </option>
                  <option value="3">
                    <a>All</a>
                  </option>
                </select>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div
        className="table-responsive react-table producttable"
        ref={scrollRef}
      >
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      <div className="justify-content-md-end  mobile-view">
        <div className="d-flex justify-content-md-end justify-content-between my-2  ">
          <a href="#" className="previous round" onClick={scrollToLeft}>
            &#8249;
          </a>
          <a href="#" className="next round " onClick={scrollToRight}>
            &#8250;
          </a>
        </div>
      </div>
      <Row
        className="justify-content-md-end justify-content-center align-items-center mobile-pagination"
        style={{ marginBottom: "20px" }}
      >
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${GETTAGGEDPRODUCTCUSTOMER}?status=${status}&query=${query}&page=${1}&status=${status === "3" ? "" : status
                  }`,
                  1
                )
              }
              className="paginate-left-button"
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${GETTAGGEDPRODUCTCUSTOMER}?status=${status}&query=${query}&page=${currentPage - 1
                  }&status=${status === "3" ? "" : status}`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto middleView">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1 nextView">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${GETTAGGEDPRODUCTCUSTOMER}?status=${status}&query=${query}&page=${currentPage + 1
                  }&status=${status === "3" ? "" : status}`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${GETTAGGEDPRODUCTCUSTOMER}?status=${status}&query=${query}&page=${Math.ceil(
                    count / customPageSize
                  )}&status=${status === "3" ? "" : status}`,
                  Math.ceil(count / customPageSize)
                )
              }
              className="paginate-right-button"
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
