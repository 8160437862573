    // Function to check if the token has expired
  function isTokenExpired() {
    // const data = localStorage.getItem('authUser');
    const getUserData = JSON.parse(localStorage.getItem("authUser")) 
    console.log(getUserData,"->>>>>")
    const token = getUserData.userData.token
    if (!token) {
      // No token, consider the user as logged out
      return true;
    }
  
    const expirationTime = getUserData.userData.payload.exp;
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
    return currentTime > expirationTime;
  }
  
  // Function to log the user out
  function logout() {
    // Clear the token from local storage
    localStorage.removeItem('authUser'); 
    // Clear any user-related state (e.g., user information stored in state or context)
    // Redirect the user to the login page
    window.location.href = '/pre-login';
  }
  // Check token expiration on each page load (you can call this in a top-level component)
  export function checkTokenExpiration() {
    // alert("okay")
    if (isTokenExpired()) {
      logout();
    }
  }

  
  