import {
  ADMINEARNING_DATA_GET,
  ADMINEARNING_DATA_SUCCESS,
  ADMINEARNING_MP_DATA_GET,
  ADMINEARNING_MP_DATA_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  adminEarningDetails: "",
}

const adminEarningGet = (state = initialState, action) => {
  switch (action.type) {
    case ADMINEARNING_DATA_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADMINEARNING_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        adminEarningDetails:action.payload,
      }
      break
      
// Earning manufactured Product

    case ADMINEARNING_MP_DATA_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADMINEARNING_MP_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        adminEarningDetails:action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default adminEarningGet


