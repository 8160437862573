import {
  // Direct Customer
  NEWCUSTOMER_GET,
  NEWCUSTOMER_SUCCESS,
  NEWCUSTOMER_UPLOAD_POST,
  NEWCUSTOMER_UPLOAD_SUCCESS,
  // Dealer`s Customer
  EXISTINGCUSTOMER_GET,
  EXISTINGCUSTOMER_SUCCESS,
  EXISTINGCUSTOMER_UPLOAD_POST,
  EXISTINGCUSTOMER_UPLOAD_SUCCESS,
  //  New Order From Customer
  NEWORDERFROMCUSTOMER_GET,
  NEWORDERFROMCUSTOMER_SUCCESS,
  //  New Order From Dealer To Customer
  NEWORDERFROMDEALERTOCUSTOMER_GET,
  NEWORDERFROMDEALERTOCUSTOMER_SUCCESS,

  //---------Customer-----
  EXISTINGCUSTOMERLIST_GET,
  EXISTINGCUSTOMERLIST_SUCCESS,
  PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET,
  PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  customerDetails: "",
}

const customerGet = (state = initialState, action) => {
  // ------- NEW CUSTOMER------------
  switch (action.type) {
    case NEWCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case NEWCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerDetails: action.payload,
      }
      break
    // ------- EXISTING CUSTOMER------------
    case EXISTINGCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case EXISTINGCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerDetails: action.payload,
      }
      break

    // ------- NEW ORDER FROM CUSTOMER GET------------

    case NEWORDERFROMCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case NEWORDERFROMCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerDetails: action.payload,
      }
      break
    // ------- NEW ORDER FROM DEALER TO CUSTOMER GET------------
    case NEWORDERFROMDEALERTOCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case NEWORDERFROMDEALERTOCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerDetails: action.payload,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break

    //----------- Direct Customer Upload----------
    case NEWCUSTOMER_UPLOAD_POST:
      state = {
        ...state,
        loading: true,
      }
      break
    //----------- Dealer`s Customer Upload----------

    case EXISTINGCUSTOMER_UPLOAD_POST:
      state = {
        ...state,
        loading: true,
      }
      break

    // --------Customer--------------

    case EXISTINGCUSTOMERLIST_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case EXISTINGCUSTOMERLIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerDetails: action.payload,
      }
      break

    // ------- Customer---> product verification of exixting customer

    case PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case PRODUCTVERIFICATIONOFEXISTINGCUSTOMER_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        customerDetails: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default customerGet
