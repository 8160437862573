import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
// Import scss
import "./assets/scss/theme.scss"
import "./app.scss"
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";
// import fakeBackend from "./helpers/AuthType/fakeBackend"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// Activating fake backend

//------------------------------------------
import AdminLayout from "admin/Layout"
import DelearLayout from "delear/Layout"
import CustomerLayout from "customer/Layout"
//------------------------------------------
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case "login":
      Layout = AdminLayout
      break
    case "admin":
      Layout = AdminLayout
      break
    case "delear":
      Layout = DelearLayout
      break
    case "customer":
      Layout = CustomerLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const getUserData = JSON.parse(localStorage.getItem("authUser"))
  const loginState = useSelector(state => state.Login)

  let layoutType = ""
  if (loginState && loginState.userType != "") {
    layoutType = loginState.userType
  } else if (getUserData && getUserData.userType != "") {
    layoutType = getUserData.userType
  }
  const Layout = getLayout(layoutType)

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
