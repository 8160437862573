// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./taglistofdeleartablecontainer"
import {
  newtagListOfDealerGet,
  tagListOfDealerGet,
} from "../../../src/store/actions"

import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import Loadder from "components/Loadder/Loadder"
function extractIdAndPName(item) {
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }

  return {
    id: item.id,
    agencyName: item.agencyName,
    firstName: item.firstName,
    contactNumber: item.contactNumber,
    city: item.city,
    productName: item.productName,
    status: item.status,
    pnumber: item.pnumber,
    statustext: statustext,
    serialNumbers: item.serialNumbers,
    reason: item.reason,
  }
}

function DatatableTablesDemoProductMaster() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Agency Name",
        accessor: "agencyName",
      },
      {
        Header: "Delear Name",
        accessor: "firstName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return row.status == 2 ? (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={e => togglemodal1(row)}>
                  <i
                    className="font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  View Reason
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View Reason
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            ""
          )
        },
      },
    ],
    []
  )

  const [modal, setModal] = useState(false)
  const [rowData, setrowData] = useState([])
  const togglemodal = () => {
    setModal(!modal)
  }
  const togglemodal1 = row => {
    setrowData(row)
    setModal(!modal)
  }

  let data = []
  const dispatch = useDispatch()
  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(newtagListOfDealerGet({}, {}))
  }, [])

  const taglistofdealerData = useSelector(state => state.resellingProductGet)

  if (taglistofdealerData && taglistofdealerData.newresellingProductDetails) {
    data =
      taglistofdealerData.newresellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Equipments Products"

  return (
    <Loadder active={taglistofdealerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Equipments Products" />

          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={taglistofdealerData.newresellingProductDetails.count}
            next={taglistofdealerData.newresellingProductDetails.next}
            previous={taglistofdealerData.newresellingProductDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={modal}
          role="dialog"
          size="lg"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>View Reason</ModalHeader>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    {/* <Label htmlFor="validationCustom01">Product Number</Label> */}
                    <textarea
                      disabled
                      name=""
                      placeholder=""
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      // onBlur={validation.handleBlur}
                      value={rowData && rowData.reason}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="button" onClick={togglemodal} color="secondary">
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTablesDemoProductMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesDemoProductMaster
