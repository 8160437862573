import {
  ADMINEARNING_DATA_GET,
  ADMINEARNING_DATA_SUCCESS,
  ADMINEARNING_MP_DATA_GET,
  ADMINEARNING_MP_DATA_SUCCESS,
} from "./actionTypes"

export const adminEarningDataGet = (query, history) => { 
  // alert("action")
  return {
    type: ADMINEARNING_DATA_GET,
    payload: { query, history },
  }
}

export const adminEarningSuccess = data => {
  return {
    type: ADMINEARNING_DATA_SUCCESS,
    payload: data,
  }
}

//  Earning manufactured product

export const adminEarningMPDataGet = (query, history) => { 
  // alert("action")
  return {
    type: ADMINEARNING_MP_DATA_GET,
    payload: { query, history },
  }
}

export const adminEarningMPSuccess = data => {
  return {
    type: ADMINEARNING_MP_DATA_SUCCESS,
    payload: data,
  }
}