// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import { toast } from "react-toastify"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./viewResellingProducttablecontainer"
import { viewResellingProductGet } from "../../../src/store/actions"
import { resellingProductAdd } from "../../../src/store/actions"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import {
  HOSTNAME,
  VIEWRESELLINGPRODUCTGET,
  DELETEMANUFACTUREPRODUCT,
} from "config/config"
import { useSelector, useDispatch } from "react-redux"
import Loadder from "components/Loadder/Loadder"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
function extractIdAndPName(item) {
  return {
    id: item.id,
    pname: item.pname,
    pnumber: item.pnumber,
    pquantity: item.pquantity,
    serialNumbers: item.serialNumbers,
  }
}
function ViewResellingProduct() {
  const dispatch = useDispatch()

  const moveToDemo = row => {
    let data = new FormData()
    data.append("productId", row.id)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/moveto/demo/product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product moved to demo successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        dispatch(viewResellingProductGet({}, {}))
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Numbers",
        accessor: "serialNumbers",
      },
      {
        Header: "Product Quantity",
        accessor: "pquantity",
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                {/* <a onClick={togglemodal} href="">View Consumables Product</a> */}
                <Link onClick={e => togglemodal1(e, row.original.id)} href="#">
                  View Consumables Products
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "   ",
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              {cellProps.row.original.pquantity > 0 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>
                      <Link
                        to="/admin-tag-to-dealer"
                        state={{ id: cellProps.row.original.id }}
                      >
                        Tag To Dealer
                      </Link>
                    </DropdownItem>

                    <DropdownItem>
                      <Link
                        to="/admin-tag-to-customer"
                        state={{ id: cellProps.row.original.id }}
                      >
                        Tag To Customer
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link
                        to="#"
                        onClick={() => moveToDemo(cellProps.row.original)}
                      >
                        Move to Demo
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : (
                ""
              )}
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  let data = []
  const [selectNumber, setSelectNumber] = useState([])
  const [updateData, setupdateData] = useState({})
  const [pid, setpid] = useState("")
  const [addresellingproductname, setaddresellingproductname] = useState("")
  const [addresellingproductquantity, setaddresellingproductquantity] =
    useState("1")
  const [
    addresellingproductserialNumbers,
    setaddresellingproductserialNumbers,
  ] = useState("")
  const [addmanufacturedproducts, setaddmanufacturedproducts] = useState("")
  const [addmanufacturedquantity, setaddmanufacturedquantity] = useState("")
  const [addmanufacturednumber, setaddmanufacturednumber] = useState("")
  const [manufactureProducts, setmanufactureProducts] = useState([])

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(viewResellingProductGet({}, {}))
  }, [])

  const setValues = () => {
    if (addmanufacturedproducts == "") {
      // alert("add Consumables products required")
      toast.error("add Consumables products required", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else if (addmanufacturedquantity == "") {
      // alert("add Consumables quantity required")
      toast.error("add Consumables quantity required", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else if (addmanufacturednumber == "") {
      // alert("add Consumables number required")
      toast.error("add Consumables number required", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      // alert(addmanufacturedproducts)
      // alert(addmanufacturedquantity)
      let newProductData = {
        mpname: addmanufacturedproducts,
        mpquantity: addmanufacturedquantity,
        mpnumber: addmanufacturednumber,
        mpdescription: "",
      }
      setmanufactureProducts([...manufactureProducts, newProductData])
      setaddmanufacturedproducts("")
      setaddmanufacturedquantity("")
      setaddmanufacturednumber("")
    }
  }

  const viewResellingProductData = useSelector(
    state => state.resellingProductGet
  )

  if (
    viewResellingProductData &&
    viewResellingProductData.resellingProductDetails
  ) {
    data =
      viewResellingProductData.resellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pid: "AB101",
      addresellingproductname: "OKI Printer",
      addresellingproductquantity: "1",
      addmanufacturedproducts: "Black-Toner",
      addmanufacturedquantity: "5",
      addresellingproductserialNumbers: "",
    },
    validationSchema: Yup.object({
      pid: Yup.string().required("Please Enter Your Product id"),
      addresellingproductname: Yup.string().required(
        "Please Enter Your Equipment Product Name"
      ),
      addresellingproductquantity: Yup.string().required(
        "Please Enter Your Equipment Product Quantity"
      ),
      addresellingproductserialNumbers: Yup.string().required(
        "Please Enter Your Equipment Product serial Number"
      ),
      addmanufacturedproducts: Yup.string().required(
        "Please Enter Your Consumables Products"
      ),
      addmanufacturedquantity: Yup.string().required(
        "Please Enter Your  Consumables Quantity"
      ),
    }),
  })
  //meta title
  document.title = "Equipments Product"

  const [modal, setModal] = useState(false)
  const [mdladdnew, setMdladdnew] = useState(false)
  const [mdldelete, setMdldelete] = useState(false)
  const [deleteMData, setdeleteMData] = useState(0)
  const mdltoggle = () => {
    setMdladdnew(!mdladdnew)
  }

  const mdltoggle1 = () => {
    setModal(!modal)
    setMdladdnew(!mdladdnew)
    setpid(updateData.pnumber)
    setaddresellingproductname(updateData.pname)
    setaddresellingproductquantity(updateData.pquantity)
    setaddresellingproductserialNumbers(updateData && updateData.serialNumbers)
    // setaddmanufacturedproducts(updateData.manufacture_data)
    // setaddmanufacturedquantity(updateData.pquantity)
    // setaddmanufacturednumber(updateData.manufacture_data)
    setmanufactureProducts(updateData.manufacture_data)
  }

  const togglemodal = () => {
    setModal(!modal)
  }
  const togglemodal1 = (e, row) => {
    let url = `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}${row}/`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios.request(config).then(response => {
      if (response.data.status_code == 200) {
        setupdateData(response.data.data)
        setModal(!modal)
      }
    })

    setModal(!modal)
  }

  const deletetoggle = () => {
    setMdldelete(!mdldelete)
  }
  const deletetoggle1 = (e, id, type) => {
    if (type == "check") {
      setdeleteMData(id)
    }
    if (type == "yes") {
      // alert(deleteMData)
      // alert(updateData.id)
      let Deleteconfig = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${HOSTNAME}${DELETEMANUFACTUREPRODUCT}${deleteMData}/`,
        headers: {
          Authorization: `${getUserData.userData.token}`,
        },
      }

      axios.request(Deleteconfig).then(response => {
        if (response.data.status_code == 200) {
          let url = `${HOSTNAME}${VIEWRESELLINGPRODUCTGET}${updateData.id}/`
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: url,
            headers: {
              Authorization: `${getUserData.userData.token}`,
            },
          }

          axios.request(config).then(response => {
            if (response.data.status_code == 200) {
              setupdateData(response.data.data && response.data.data)
            }
          })
        }
      })

      setdeleteMData(0)
    }
    setMdldelete(!mdldelete)
  }

  const SendData = e => {
    let data = {
      pname: addresellingproductname,
      pnumber: pid,
      pquantity: addresellingproductquantity,
      pdescription: "",
      manufacturedProduct: manufactureProducts,
      serialNumbers: addresellingproductserialNumbers,
    }

    dispatch(resellingProductAdd({ data: data }, {}))
    setaddresellingproductname("")
    setpid("")
    setaddresellingproductquantity("")
    setaddresellingproductserialNumbers("")
    setmanufactureProducts([])
    mdltoggle()
  }
  return (
    <Loadder active={viewResellingProductData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Equipments Products" />

          <TableContainer
            count={viewResellingProductData.resellingProductDetails.count}
            next={viewResellingProductData.resellingProductDetails.next}
            previous={viewResellingProductData.resellingProductDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />

          <Modal
            isOpen={modal}
            role="dialog"
            size="md"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={togglemodal}
          >
            <div className="modal-content">
              <ModalHeader toggle={togglemodal}>
                Equipment Product Details
              </ModalHeader>
              <ModalBody>
                <SimpleBar style={{ maxHeight: "376px" }}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>{updateData.pname}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </SimpleBar>
              </ModalBody>
              <ModalHeader>Consumables Products Details</ModalHeader>

              <ModalBody>
                <SimpleBar style={{ maxHeight: "250px" }}>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                      </div>
                      {updateData.manufacture_data &&
                      updateData.manufacture_data.length > 0 ? (
                        <>
                          {updateData.manufacture_data &&
                            updateData.manufacture_data.map(item => (
                              <div key={item.id} className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <b>Product Name</b>
                                    <br></br>
                                    {/* <p>{item.mpname} X {item.mpquantity} </p> */}
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        {item.mpname}
                                      </span>
                                      <span style={{ color: "#777" }}> X </span>
                                      <span style={{ color: "#555" }}>
                                        {" "}
                                        {item.mpquantity}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  {updateData.pquantity == 0 ? (
                                    ""
                                  ) : (
                                    <ul className="list-inline mb-0 font-size-16">
                                      <li className="list-inline-item">
                                        <Link
                                          disabled
                                          onClick={e => mdltoggle1(e)}
                                          to="#"
                                          className="text-success p-1"
                                        >
                                          <i className="bx bxs-edit-alt" />
                                        </Link>
                                      </li>
                                      <li className="list-inline-item">
                                        <Link
                                          onClick={e =>
                                            deletetoggle1(e, item.id, "check")
                                          }
                                          to="#"
                                          className="text-danger p-1"
                                        >
                                          <i className="bx bxs-trash" />
                                        </Link>
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div>
                            ))}
                        </>
                      ) : (
                        <>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Product Name</b>
                                <br></br>
                                <p>Data Not Found</p>
                              </div>
                            </div>
                            <div>
                              {updateData && updateData.pquantity == 0 ? (
                                ""
                              ) : (
                                <ul className="list-inline mb-0 font-size-16">
                                  <li disabled className="list-inline-item">
                                    <Link
                                      onClick={e => mdltoggle1(e)}
                                      to="#"
                                      className="text-success p-1"
                                    >
                                      <i className="bx bxs-edit-alt" />
                                    </Link>
                                  </li>
                                  {/* <li className="list-inline-item">
                                                  <Link onClick={deletetoggle}  to="#" className="text-danger p-1">
                                                    <i className="bx bxs-trash" />
                                                  </Link>
                                                </li> */}
                                </ul>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </li>
                  </ul>
                </SimpleBar>
              </ModalBody>
            </div>
          </Modal>

          {/* // Add New Product toggle  */}

          <Modal
            isOpen={mdladdnew}
            role="dialog"
            size="lg"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={mdltoggle}
          >
            <div className="modal-content">
              <ModalHeader toggle={mdltoggle}>
                Add Equipment Product
              </ModalHeader>

              <ModalBody>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Product Number</Label>
                      <Input
                        disabled
                        name="pid"
                        placeholder="Product Number"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        onChange={e => setpid(e.target.value)}
                        // onBlur={validation.handleBlur}
                        value={pid}
                        invalid={
                          validation.touched.pid && validation.errors.pid
                            ? true
                            : false
                        }
                      />
                      {validation.touched.pid && validation.errors.pid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pid}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Add Equipment Product Name
                      </Label>
                      <Input
                        name="addresellingproductname"
                        placeholder="Add Equipment Product Name"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        onChange={e =>
                          setaddresellingproductname(e.target.value)
                        }
                        // onBlur={validation.handleBlur}
                        value={addresellingproductname}
                        invalid={
                          validation.touched.addresellingproductname &&
                          validation.errors.addresellingproductname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addresellingproductname &&
                      validation.errors.addresellingproductname ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addresellingproductname}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom02">
                        Equipment Product Serial Number
                      </Label>
                      <Input
                        disabled
                        name="addresellingproductserialNumbers"
                        placeholder="Add Equipment Product Quantity"
                        type="text"
                        className="form-control"
                        id="validationCustom02"
                        onChange={e =>
                          setaddresellingproductserialNumbers(e.target.value)
                        }
                        // onBlur={validation.handleBlur}
                        value={addresellingproductserialNumbers}
                        invalid={
                          validation.touched.addresellingproductserialNumbers &&
                          validation.errors.addresellingproductserialNumbers
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addresellingproductserialNumbers &&
                      validation.errors.addresellingproductserialNumbers ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addresellingproductserialNumbers}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom02">
                        Add Equipment Product Quantity
                      </Label>
                      <Input
                        disabled
                        name="addresellingproductquantity"
                        placeholder="Add Equipment Product Quantity"
                        type="number"
                        className="form-control"
                        id="validationCustom02"
                        onChange={e =>
                          setaddresellingproductquantity(e.target.value)
                        }
                        // onBlur={validation.handleBlur}
                        value={addresellingproductquantity}
                        invalid={
                          validation.touched.addresellingproductquantity &&
                          validation.errors.addresellingproductquantity
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addresellingproductquantity &&
                      validation.errors.addresellingproductquantity ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addresellingproductquantity}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Label>
                      <b>Consumables Details</b>
                    </Label>
                  </Col>
                  <Col md="3">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <Input
                        name="addmanufacturedproducts"
                        placeholder="mpname"
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        onChange={e =>
                          setaddmanufacturedproducts(e.target.value)
                        }
                        // onBlur={validation.handleBlur}
                        value={addmanufacturedproducts}
                        invalid={
                          validation.touched.addmanufacturedproducts &&
                          validation.errors.addmanufacturedproducts
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addmanufacturedproducts &&
                      validation.errors.addmanufacturedproducts ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addmanufacturedproducts}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom02">Quantity</Label>
                      <Input
                        name="addmanufacturedquantity"
                        placeholder="mquantity"
                        type="number"
                        className="form-control"
                        id="validationCustom02"
                        // onChange={(e) =>setaddmanufacturedquantity(e.target.value)}
                        onChange={e => {
                          const value = parseInt(e.target.value, 10)
                          if (!isNaN(value) && value >= 1) {
                            setaddmanufacturedquantity(e.target.value)
                          } else {
                            setaddmanufacturedquantity("")
                          }
                        }}
                        // onBlur={validation.handleBlur}
                        value={addmanufacturedquantity}
                        invalid={
                          validation.touched.addmanufacturedquantity &&
                          validation.errors.addmanufacturedquantity
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addmanufacturedquantity &&
                      validation.errors.addmanufacturedquantity ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addmanufacturedquantity}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom02">
                        Consumables Number
                      </Label>
                      <Input
                        name="addmanufacturednumber"
                        placeholder="mpnumber"
                        type="text"
                        className="form-control"
                        id="validationCustom02"
                        onChange={e => setaddmanufacturednumber(e.target.value)}
                        // onBlur={validation.handleBlur}
                        value={addmanufacturednumber}
                        invalid={
                          validation.touched.addmanufacturednumber &&
                          validation.errors.addmanufacturednumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.addmanufacturednumber &&
                      validation.errors.addmanufacturednumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.addmanufacturednumber}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2"></Col>
                  <Col md="2 mt-4">
                    <Button
                      type="button"
                      onClick={e => setValues(e)}
                      color="primary"
                    >
                      Add New
                    </Button>
                  </Col>
                  <Row>
                    {manufactureProducts.map((product, index) => (
                      <Col key={index} md="4">
                        <UncontrolledAlert color="secondary" role="alert">
                          {product.mpname} - {product.mpquantity}-{" "}
                          {product.mpnumber}
                        </UncontrolledAlert>
                      </Col>
                    ))}
                  </Row>
                </Row>
              </ModalBody>
              <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
                <Button
                  type="button"
                  onClick={e => SendData(e)}
                  color="primary"
                >
                  Submit
                </Button>

                <Button type="button" onClick={mdltoggle} color="secondary">
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </Modal>

          {/* // Delete Model */}

          <Modal
            size="sm"
            isOpen={mdldelete}
            toggle={deletetoggle}
            centered={true}
          >
            <div className="modal-content">
              <ModalBody className="px-4 py-5 text-center">
                <button
                  type="button"
                  onClick={deletetoggle}
                  className="btn-close position-absolute end-0 top-0 m-3"
                ></button>
                <div className="avatar-sm mb-4 mx-auto">
                  <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                    <i className="mdi mdi-trash-can-outline"></i>
                  </div>
                </div>
                <p className="text-muted font-size-16 mb-4">
                  Are you sure you want to permanently erase the job.
                </p>

                <div className="hstack gap-2 justify-content-center mb-0">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={e => deletetoggle1(e, 0, "yes")}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={deletetoggle}
                  >
                    No
                  </button>
                </div>
              </ModalBody>
            </div>
          </Modal>
        </div>
      </div>
    </Loadder>
  )
}
ViewResellingProduct.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ViewResellingProduct
