import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  SERVICEREQUSETFROMCUSTOMER_GET,
  SERVICEREQUSETFROMCUSTOMER_SUCCESS,
  SERVICEREQUESTDEALERTOCUSTOMER_GET,
  SERVICEREQUESTDEALERTOCUSTOMER_SUCCESS,
  SERVICECENTRICCUSTOMER_GET,
  SERVICECENTRICCUSTOMER_SUCCESS,
} from "./actionTypes"

import {
  serviceRequestFromcustomerGet,
  serviceRequestFromcustomerSuccess,
  serviceRequestdealertocustomerGet,
  serviceRequestdealertocustomerSuccess,
  servicecentriccustomerGet,
  servicecentriccustomerSuccess,
} from "./actions"

import {
  HOSTNAME,
  SERVICEREQUSETFROMCUSTOMERGET,
  SERVICEREQUESTDEALERTOCUSTOMERGET,
  SERVICECENTRICCUSTOMERGET,
} from "config/config"

import { apiError } from "store/actions"
import axios from "axios"
import { toast } from "react-toastify"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function* serviceRequestfromcustomerData({ payload: { query, history } }) {
  console.log('"query', query)

  try {
    // alert(query.query)

    let url = `${HOSTNAME}${SERVICEREQUSETFROMCUSTOMERGET}`
    if ("type" in query) {
      url = `${HOSTNAME}${SERVICEREQUSETFROMCUSTOMERGET}?type=${query.type}`
    }
    if ("query" in query) {
      url += `&query=${query.query}`
    }
    if ("status" in query && !(query.status == 4)) {
      url += `&status=${query.status}`
    }
    if ("url" in query) {
      url = query.url
    }
    11

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(serviceRequestFromcustomerSuccess(response.data))
      console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

//  SERVICE REQUEST DEALER TO CUSTOMER

function* serviceRequestdealertocustomerData({ payload: { query, history } }) {
  try {
    // alert(query.query)

    let url = `${HOSTNAME}${SERVICEREQUESTDEALERTOCUSTOMERGET}`
    if ("type" in query) {
      url = `${HOSTNAME}${SERVICEREQUESTDEALERTOCUSTOMERGET}?type=${query.type}`
    }
    if ("query" in query) {
      url += `&query=${query.query}`
    }
    if ("status" in query && !(query.status == 4)) {
      url += `&status=${query.status}`
    }

    if ("url" in query) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(serviceRequestdealertocustomerSuccess(response.data))
      console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

//  SERVICE CENTRIC CUSTOMER

function* servicecentriccustomerData({ payload: { query, history } }) {
  try {
    // alert(query.query)

    let url = `${HOSTNAME}${SERVICECENTRICCUSTOMERGET}`
    if ("type" in query) {
      url = `${HOSTNAME}${SERVICECENTRICCUSTOMERGET}?type=${query.type}`
    }
    if ("query" in query) {
      url += `&query=${query.query}`
    }
    if ("status" in query && !(query.status == 4)) {
      url += `&status=${query.status}`
    }
    if ("url" in query) {
      url = query.url
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    const response = yield axios.request(config)
    console.log("+++++++++++++", response)
    if (response.status === 200) {
      yield put(servicecentriccustomerSuccess(response.data))
      console.log("---======----==--=-=-=-=-", response.data.results.data)
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      yield put(apiError(response.data.message))
    }
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

function* serviceDataSaga() {
  yield takeEvery(
    SERVICEREQUSETFROMCUSTOMER_GET,
    serviceRequestfromcustomerData
  )
  yield takeEvery(
    SERVICEREQUESTDEALERTOCUSTOMER_GET,
    serviceRequestdealertocustomerData
  )
  yield takeEvery(SERVICECENTRICCUSTOMER_GET, servicecentriccustomerData)
}

export default serviceDataSaga
