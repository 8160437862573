// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./tagtocustomertablecontainer"
import { dealer_tagtocustomerGet } from "../../../src/store/actions"
import { useSelector, useDispatch } from "react-redux"
import Loadder from "components/Loadder/Loadder"
import { useLocation } from "react-router-dom"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

function extractIdAndPName(item) {
  return {
    id: item.id,
    customername: `${item.firstName && item.firstName}  ${
      item.lastName && item.lastName
    }`,
    contactNumber: item.contactNumber,
  }
}

function DatatableTablesDealerMaster() {
  const columns = useMemo(
    () => [
      // {
      //     Header: ' ',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
    ],
    []
  )

  const location = useLocation()
  const { id } = location.state

  let data = []

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(dealer_tagtocustomerGet({}, {}, { id: id }))
  }, [])

  const dealer_tagTocustomerData = useSelector(
    state => state.resellingProductGet
  )

  if (
    dealer_tagTocustomerData &&
    dealer_tagTocustomerData.resellingProductDetails
  ) {
    data =
      dealer_tagTocustomerData.resellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Equipments Products"

  return (
    <Loadder active={dealer_tagTocustomerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Equipment  Products" />
          <TableContainer
            count={dealer_tagTocustomerData.resellingProductDetails.count}
            next={dealer_tagTocustomerData.resellingProductDetails.next}
            previous={dealer_tagTocustomerData.resellingProductDetails.previous}
            columns={columns}
            data={data}
            id={id && id}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesDealerMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesDealerMaster
