// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./tagtodealertablecontainer"
import { tagtoDealerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import { useLocation } from "react-router-dom"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

function extractIdAndPName(item) {
  return {
    id: item.id,
    agencyName: item.agencyName,
    firstName: item.firstName,
    city: item.city,
  }
}

function DatatableTablesDealerMaster() {
  const columns = useMemo(
    () => [
      // {
      //     Header: ' ',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="radio" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Agency Name",
        accessor: "agencyName",
      },
      {
        Header: "Dealer Name",
        accessor: "firstName",
      },
      {
        Header: "City",
        accessor: "city",
      },
    ],
    []
  )
  const location = useLocation()
  const { id } = location.state

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(tagtoDealerGet({}, {}, { id: id }))
  }, [])

  const tagToDealerData = useSelector(state => state.DemoProductGet)

  if (tagToDealerData && tagToDealerData.demoProductDetails) {
    data =
      tagToDealerData.demoProductDetails.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Dealer Master"

  return (
    <Loadder active={tagToDealerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Demo Product" />

          <TableContainer
            id={id}
            count={tagToDealerData.demoProductDetails.count}
            next={tagToDealerData.demoProductDetails.next}
            previous={tagToDealerData.demoProductDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesDealerMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesDealerMaster
