// src/components/filter.
import React, {useState, useMemo,useEffect } from "react";
import PropTypes from 'prop-types';
import SimpleBar from "simplebar-react";
import Loadder from "components/Loadder/Loadder";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './manufacturedproductstablecontainer';
import { useSelector, useDispatch } from "react-redux";
import { adminEarningMPDataGet } from "../../store/actions";
import {checkTokenExpiration} from '../../../src/config/getTokenExpirationTime';
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { Link } from "react-router-dom";

//   const reports = [
//     { title: "Total Number of Consumables Product sold", iconClass: "bx-purchase-tag-alt", description: "150" },

//   ];

  function extractIdAndPName(item) {
    return {

        productname:item.product.mpname,
        mpnumber:item.product.mpnumber,
        customername: `${item.customer.firstName} ${item.customer.lastName}`,
        total_sold:item.total_sold,
        
        city:item.customer && item.customer.city,
        contactNumber:item.customer && item.customer.contactNumber,
        mpnumber:item.product && item.product.mpnumber,
        mpquantity:item.product && item.product.mpquantity,

        pname:item.RPDproduct && item.RPDproduct.pname,
        pnumber:item.RPDproduct && item.RPDproduct.pnumber,
        pquantity:item.RPDproduct && item.RPDproduct.pquantity,
        serialNumbers:item.RPDproduct && item.RPDproduct.serialNumbers,

    };
  }

function DatatableTablesManufacture() {
    const columns = useMemo(
        () => [
            // {
            //     Header: '#',
            //     filterable: false,
            //     Cell: () => {
            //         return <input type="checkbox" className="form-check-input" />;
            //       }
            // },
            // {
            //     Header: 'Sr.No',
            //     accessor: 'srno',
            // },
            {
                Header: 'Product Name',
                accessor: 'productname'
            },
            {
                Header: 'Product Number',
                accessor: 'mpnumber'
            },
            {
                Header: 'Product Serial Number',
                accessor: 'serialNumbers'
            },
            {
                Header: 'Customer Name',
                accessor: 'customername',
            },
            {
                Header: 'No of Product Sold',
                accessor: 'total_sold',
            },
            {
                Header: ' ',
                Cell: (cellProps) => {
                const row = cellProps.row
                  return (
                    <Row>
                        <Col xl={12}>
                          <Link to="#" onClick={e => togglemodal1(row.original)}>View details</Link>
                        </Col>
                    </Row>
                  );
                }
              },
        ],
        []
    );

    const dispatch = useDispatch();
    useEffect(()=>{
        const isUserLoggedIn = localStorage.getItem('isLoggedIn');
        if (!isUserLoggedIn) {
            localStorage.setItem('isLoggedIn', 'true');
            window.location.reload();
        }

        checkTokenExpiration()
        dispatch(adminEarningMPDataGet({"userType":"AC"}, {}))
    },[dispatch])

    let data =[]
    const adminearningMPdata =useSelector(state => state.adminEarningGet.adminEarningDetails)
    console.log("adminearningMPdata=>Bindi",adminearningMPdata)
    if(adminearningMPdata && adminearningMPdata.results){
        console.log(adminearningMPdata.results.data,"=>PPPPPPPPPPP")
        data = adminearningMPdata.results.data.map(extractIdAndPName);   
    } 

    //meta title
    document.title = "Earnings";

    const [objData,setobjData]=useState({})
    const [modal, setModal] = useState(false);
    const togglemodal = () => {
        setModal(!modal);
      };
    
      const togglemodal1 = (row) => {
        // alert(row)
        let fdata ={
        "customername": row.customername,
        "city":row.city,
        "contactNumber":row.contactNumber,
        "productname":row.productname,
        "mpnumber":row.mpnumber,
        "mpquantity":row.mpquantity,
        "total_sold":row.total_sold,
        "pname":row.pname,
        "pnumber":row.pnumber,
        "pquantity":row.pquantity,
        "serialNumbers":row.serialNumbers
        }

       
        setModal(!modal);
        // const foundData = data && data.find(item => item.id === row);
        setobjData(fdata)
        console.log("foundData=>",fdata)
      };

    return (
        <Loadder active={adminearningMPdata.loading}>
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Earnings" />
              
                {/* <Table columns={columns} data={data} /> */}
                <TableContainer
                    count={adminearningMPdata.count}
                    next={adminearningMPdata.next}
                    previous={adminearningMPdata.previous}
                    columns={columns}
                    data={data}
                    result={adminearningMPdata.results}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>

            <Modal
                    isOpen={modal}
                    role="dialog"
                    size="md"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={togglemodal}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={togglemodal}>
                       Customer Details
                      </ModalHeader>
                        <ModalBody>
                        <SimpleBar style={{ maxHeight: "376px"}}>
                            <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Customer Name</b><br></br>
                                                    <p>{objData && objData.customername}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Customer City</b><br></br>
                                                    <p>{objData && objData.city}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                                <br/>

                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Customer Number</b><br></br>
                                                        <p>{objData && objData.contactNumber}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row>                          
                                </li>
                            </ul>
                         </SimpleBar>
                        </ModalBody>
                      <ModalHeader>
                        Customer Equipment Product Details
                      </ModalHeader>
                    
                      <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product Name</b><br></br>
                                                        <p>{objData && objData.pname}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product serialNumbers</b><br></br>
                                                        <p>{objData && objData.serialNumbers}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Number</b><br></br>
                                                    <p>{objData && objData.pnumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> 
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>

                      <ModalHeader>
                        Consumables Product Details
                      </ModalHeader>

                      {/* <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                  <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product Name</b><br/><br/>
                                                        <p>2- Megenta Toner</p>
                                                        <p>2- Black Toner</p>
                                                        <p>2- Megenta Drum</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                  </Row> 

                                </li>
                      </ul>

                      </SimpleBar>
                      </ModalBody> */}

<ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Product Name</b><br></br>
                                                        <p>{objData && objData.productname}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row> 
                                <br/>
                                <Row>
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Number</b><br></br>
                                                    <p>{objData && objData.mpnumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    {/* <Col md={6}>
                                        <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <b>Product Quantity</b><br></br>
                                                    <p>{objData && objData.mpquantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col> */}
                                    <Col md={6}>
                                        <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <div>
                                                        <b>Number of Product Sold</b><br></br>
                                                        <p>{objData && objData.total_sold}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </Col>
                                </Row>  
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>

                    </div>
            </Modal>
           
        </div>
        </Loadder>
    );
}
DatatableTablesManufacture.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTablesManufacture;