export const MANUFACTUREDPRODUCT_GET="MANUFACTUREDPRODUCT_GET"
export const MANUFACTUREDPRODUCT_SUCCESS="MANUFACTUREDPRODUCT_SUCCESS"

export const MANUFACTUREDPRODUCT_UPLOAD_POST="MANUFACTUREDPRODUCT_UPLOAD_POST"
export const MANUFACTUREDPRODUCT_UPLOAD_SUCCESS="MANUFACTUREDPRODUCT_UPLOAD_SUCCESS"

// Consumables Product Maintain Inventory
export const MANUFACTUREDPRODUCT_ADD="MANUFACTUREDPRODUCT_ADD"
export const MANUFACTUREDPRODUCT_ADD_SUCCESS="MANUFACTUREDPRODUCT_ADD_SUCCESS"

// View Consumables Product

export const VIEWMANUFACTUREDPRODUCT_GET="VIEWMANUFACTUREDPRODUCT_GET"
export const VIEWMANUFACTUREDPRODUCT_SUCCESS="VIEWMANUFACTUREDPRODUCT_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

