// export const HOSTNAME = "http://82.180.130.182:8000/";
// export const HOSTNAME = "http://127.0.0.1:8000/";
// export const HOSTNAME = "http://127.0.0.1:8000/";
// export const HOSTNAME = "https://sonastart.co.in/"
export const HOSTNAME = "https://api.sonastarhealthcare.com/"

// Endpoint of APi
export const ADMINDASHBOARD = "api/admin-dashboard-details/"
export const ADMINFINANCIALYEAR = "api/financial-year-details/"

export const PRELOGIN = "api/pre-login/"
export const VERIFYUSER = "api/verify-login-otp/"
//download csv
export const DOWNLOADCSV = "api/download-csv/"
export const GETYEARDATA = "api/years/"
//Master Equipment Product
export const RESELLINGPRODUCTGET = "api/get-reselling-product/"
export const RESELLINGPRODUCTUPLOAD = "api/bulk-reselling-product/"
export const PARENTRESELLINGPRODUCTUPLOAD = "api/bulk-parent-reselling-product/"
export const PARENTRESELLINGPRODUCTGET = "api/get-parent-reselling-product/"
export const PARENTPRODUCTNAMELIST =
  "api/get-parent-reselling-product-name-list/"

export const RESELLINGPRODUCTADD = "api/add-reselling-product-with-manufacture/"
export const RESELLINGCHILDPRODUCTADD =
  "api/add-child-reselling-product-with-manufacture/"

// MAster manufactured Product
export const MANUFACTUREDPRODUCTGET = "api/get-manufacture-product/"
export const MANUFACTUREDPRODUCTUPLOAD = "api/bulk-manufacture-product/"

// Master Delear
export const DELEARPRODUCTGET = "api/get-dealer-product/"
export const DELEARPRODUCTUPLOAD = "api/bulk-dealer-details/"
// Master demo Product
export const DEMOPRODUCTMASTERGET = "api/get-parent-reselling-product/"
export const DEMOPRODUCTMASTERUPLOAD = "api/bulk-parent-reselling-product/"

//  Direct Customer
export const NEWCUSTOMERGET = "api/get-customer-product/"
export const NEWCUSTOMERUPLOAD = "api/bulk-customer-details/"
export const DELETECUSTOMERMUL = "api/delete-customer-product/"
//  Dealer`s Customer
export const EXISTINGCUSTOMERGET = "api/get-customer-product/"
export const EXISTINGCUSTOMERUPLOAD = "api/bulk-customer-details/"
export const DELEARLISTGET = "api/get-dealer-list/"
export const ADDCUSTOMERDATA = "api/get-customer-product/"

// Equipment Product
export const MAINTAININVENTORY = "api/reselling-product-dashboard/"
export const VIEWRESELLINGPRODUCTGET =
  "api/get-reselling-product-with-manufacture/"
export const TAGLISTOFCUSTOMER = "api/reselling-product-tag-list/?type=AC"
export const TAGLISTOFDEALER = "api/reselling-product-tag-list/?type=D"
export const NEWTAGLISTOFDEALER = "api/v1/reselling-product-tag-list/?type=D"
export const TAGTODELEAR = "api/get-dealer-list-reselling-product/"
export const TAGTOCUSTOMER = "api/get-customer-list-reselling-product/"

// Consumables Product
export const MANUFACTUREDMAINTAININVENTORY =
  "api/inventory-manufacture-product-dashboard/"
export const VIEWMANUFACTUREDPRODUCTGET =
  "api/get-reselling-product-with-manufacture/"
export const MANUFACTUREDPRODUCTADD =
  "api/add-reselling-product-with-manufacture/"
export const NEWORDERFROMCUSTOMERGET =
  "api/customer-request-inventory-product/logs/?user_type=AC"
export const BULKCONSUMABLEPRODUCTUPLOAD =
  "api/bulk-inventory-manufacture-product/"
export const BULKCONSUMABLEPRODUCTGET = "api/get-inventory-manufacture-product/"
export const NEWORDERFROMDEALERTOCUSTOMERGET =
  "api/customer-request-inventory-product/logs/?user_type=DC"
export const DELETEMANUFACTUREPRODUCT = "api/delete-manufacture-product/"

//  Demo Product
export const DEMOMAINTAININVENTORY = "api/demo-product-dashboard/"
export const DEMOPRODUCTADD = "api/add-reselling-product-with-manufacture/"
export const VIEWDEMOPRODUCTGET = "api/get-reselling-product-with-manufacture/"
export const TAGTODEALERGET = "api/get-dealer-list-reselling-product/"
export const TAGGEDPRODUCTLISTGET = "api/reselling-product-tag-list/?type=DEMO"
export const NEWTAGGEDPRODUCTLISTGET =
  "api/v1/reselling-product-tag-list/?type=DEMO"
export const DELETEDEMOPRODUCT = "api/delete-manufacture-product/"

// Dealer

export const DEALERLISTGET = "api/get-dealer-product/list/"
export const DELETEMULTIDEALER = "api/delete-dealer-product/"

export const TAGGEDDEALERGET = "api/reselling-product-tag-list/?type=D"
export const TAGTODEALERPRODUCT = "api/tag-reselling-product/"
export const ADDNEWDEALER = "api/add-dealer-product/"
export const REMOVETAGPRODUCT = "api/remove-tag-product/"
export const DEALERPROFILEUPDATE = "api/dealer-profile-update/"

// -- ADMIN AMC CUSTOMER LIST

export const ADMINAMCCUSTOMERLISTGET = "api/get-amc-details/"
export const ADMINSOLDPRODUCT = "api/make-as-sold/"

// BuyBack
export const ADMINBUYBACKGET = "api/buy-back-details/"

//earning
export const ADMINEARNINGGET = "api/reselling-products-earning/"
export const ADMINEARNINGDOWNLOAD = "api/download-products-earning/"

export const ADMINEARNINGMPDATAGET = "api/manufacture-products-earning/"
export const ADMINEARNINGMPDOWNLOAD = "api/download-products-earning/"

//  services

export const SERVICEREQUSETFROMCUSTOMERGET = "api/services-details/?type=AC"
export const SERVICEREQUESTDEALERTOCUSTOMERGET = "api/services-details/?type=DC"
export const SERVICECENTRICCUSTOMERGET = "api/services-centric-details/"

// Customer

export const EXISTINGCUSTOMERLISTGET = "api/get-customer-product/"
export const PRODUCTVERIFICATIONOFEXISTINGCUSTOMERGET =
  "api/get-customer-product-list/"
export const CUSTOMERPROFILEUPDATE = "api/customer-profile-update/"

// ***************************************************************************************
//                                      DEALER
// ***************************************************************************************

export const DEALERDASHBOARD = "api/dealer-dashboard-details/"

// reselling product
export const TAGPRODUCTFROMADMINGET = "api/dealer-taged-reselling-product/"
export const DEALER_RESELLINGPRODUCTLISTGET =
  "api/dealer-reselling-product-list/"
export const DEALER_TAGTOCUSTOMERGET = "api/dealer-customerlist-for-tag/"
export const DEALERNEWPRODUCTREQUEST = "api/dealer-new-product-request/"
export const DEALERRESELLINGTAGPRODUCTLISTCUSTOMER =
  "api/dealer-reselling-taged-customer-product-list/"
export const DEALERMANUFACTUREORDERSGET = "api/dealer-get-manufacture-orders/"
export const DEALERTAGEDACCEPTREJECT = "api/dealer-taged-accept-reject/"
export const DEALERTAGPRODUCTTOCUSTOMER = "api/dealer-tag-product-to-customer/"

export const DEALERGETCUSTOMERDETAILS = "api/dealer-get-customer-details/"
export const DEALERSERVICESDETAILS = "api/dealer-services-details/"
export const DEALERBUYBACKDETAILS = "api/dealer-buyback-details/"
export const DEALERRESELLINGEARNING = "api/dealer-reselling-products-earning/"

// ***************************************************************************************
//                                      Customer
// ***************************************************************************************
export const GETTAGGEDPRODUCTCUSTOMER = "api/get-tagged-product-customer/"
export const ACCEPTREJECTTAGPRODUCT = "api/accpet-or-reject-tag-product/"
export const CUSTOMERVERIFYPRODUCTLIST = "api/verify-product-list/"
export const SENDOTPFORVERIFYPRODUCT = "api/send-otp-for-verify-product/"
export const CUSTOMERVERIFYOTP = "api/send-otp-verify/"
export const ACTIVATEDPRODUCTLIST = "api/activated-product-list/"
export const CUSTOMERADDBUYBACKDATA = "api/add-buyback-details/"
// export const VIEWORDERS = "api/view-orders/"
export const VIEWORDERS = "api/customer-request-inventory-product/"
export const CUSTOMERRESELLINGPRODUCTNAMELIST =
  "api/reselling-product-name-list/"
export const CUSTOMERSERVICESLIST = "api/customer-services-list/"
export const CUSTOMERMANUFACTUREPRODUCTNAMELIST =
  "api/manufacture-product-name-list/"
export const CUSTOMERNEWPRODUCTREQUEST = "api/customer-new-product-request/"
export const CUSTOMERSPENDPRODUCT = "api/reselling-product-spend/"
export const CUSTOMERSERVICES = "api/customer-services/"
export const CUSTOMERCENTRIC = "api/customer-services-centric/"
