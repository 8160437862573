import React, { useState, Fragment, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import { Link } from "react-router-dom"

import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useDispatch } from "react-redux"
import { customerServicesListGet } from "store/actions"
import {
  CUSTOMERRESELLINGPRODUCTNAMELIST,
  CUSTOMERSERVICES,
  HOSTNAME,
} from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const scrollRef = useRef()

  const scrollToLeft = () => {
    const element = scrollRef.current
    element.scrollBy({
      left: -window.innerWidth + 100, // Adjust the value to scroll by a different distance
      behavior: "smooth",
    })
  }

  const scrollToRight = () => {
    const element = scrollRef.current
    // console.log("🚀 ~ file: viewtaggedproducttablecontainer.js:200 ~ scrollToRight ~ element:", element,element.scrollLeft,element.offsetWidth,element.scrollRight)
    element.scrollBy({
      left: window.innerWidth - 100, // Adjust the value to scroll by a different distance
      behavior: "smooth",
    })
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  // const onChangeInInput = event => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };

  // Form validation
  const [btnvisible, setbtnvisible] = useState(false)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pid: "",
      message: "",
    },
    validationSchema: Yup.object({
      pid: Yup.string().required("Please Enter Your Product id"),
      message: Yup.string().required("Please Enter Message"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (btnvisible == false) {
        setbtnvisible(true)

        let data = new FormData()
        data.append("pid", values.pid)
        data.append("msg", values.message)

        let url = `${HOSTNAME}${CUSTOMERSERVICES}`
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            Authorization: `${getUserData.userData.token}`,
          },
          data: data,
        }

        axios
          .request(config)
          .then(response => {
            dispatch(customerServicesListGet({}, {}))
            setModal(!modal)
            resetForm()
            toast.success("Request sent successfully", {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
          .catch(error => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
      }
    },
  })

  const [modal, setModal] = useState(false)

  const togglemodal = () => {
    setModal(!modal)
  }

  //--------
  const dispatch = useDispatch()
  const [query, setquery] = useState("")
  const SearchByMPname = e => {
    if (e.target.value) {
      setstatus(e.target.value)
      dispatch(
        customerServicesListGet({ status: status, query: e.target.value }, {})
      )
    } else {
      setstatus("")
      dispatch(customerServicesListGet({ status: status }, {}))
    }
  }

  const onclickForPage = (url, number) => {
    // alert(number)
    setcurrentPage(number)
    dispatch(customerServicesListGet({ url: url }, {}))
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${CUSTOMERSERVICES}?query=${query}&page=${event.target.value
      }&status=${status === "4" ? "" : status}`
    setcurrentPage(event.target.value)
    dispatch(customerServicesListGet({ url: url }, {}))
  }
  const [status, setstatus] = useState("4")
  const setAndCallApi = e => {
    setstatus(e.target.value)
    dispatch(customerServicesListGet({ status: e.target.value, query: query }, {}))
  }

  const [getResellingProductList, setgetResellingProductList] = useState([])
  const togglemodal1 = () => {
    setbtnvisible(false)
    setModal(!modal)
    // getResellingProductList
    let url = `${HOSTNAME}${CUSTOMERRESELLINGPRODUCTNAMELIST}`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        setgetResellingProductList(response.data.data)
      })
      .catch(error => { })
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4}>
              <div className="col-sm-auto mb-1">
                <Label className="visually-hidden" htmlFor="autoSizingInput">
                  Search
                </Label>
                <Input
                  onChange={e => {
                    SearchByMPname(e)
                  }}
                  type="text"
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name"
                />
              </div>
            </Col>
            <Col xl={2}>
              <div className="col-sm-auto mb-1">
                <select
                  onChange={e => setAndCallApi(e)}
                  className="form-select"
                >
                  <option value="" disabled selected hidden>
                    Status
                  </option>
                  <option value="0">Pending</option>
                  <option value="1">
                    <a>Ongoing</a>
                  </option>
                  <option value="2">
                    <a>Completed</a>
                  </option>
                  <option value="3">
                    <a>Closed</a>
                  </option>
                  <option value="4">
                    <a>All</a>
                  </option>
                </select>
              </div>
            </Col>

            <Col xl={2}>
              <div className="col-sm-auto mb-1">
                <button
                  type="button"
                  onClick={e => togglemodal1()}
                  className="btn btn-primary"
                >
                  Add New Request
                </button>
              </div>
            </Col>

            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <Form onSubmit={validation.handleSubmit}>
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>Add Request</ModalHeader>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">
                      Select Equipment Product
                    </Label>
                    <div className="col-sm-auto">
                      <select
                        name="pid"
                        value={validation.values.pid || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className="form-select"
                      >
                        <option value="" disabled selected hidden>
                          Select product
                        </option>
                        {getResellingProductList &&
                          getResellingProductList.map((option, index) => (
                            <option key={index} value={option.id}>
                              {option.pname}-{option.serialNumbers}
                            </option>
                          ))}
                      </select>
                      {validation.touched.pid && validation.errors.pid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pid}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Message</Label>
                    <Input
                      name="message"
                      placeholder="Add Message"
                      type="textarea"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.message || ""}
                      invalid={
                        validation.touched.message && validation.errors.message
                          ? true
                          : false
                      }
                    />
                    {validation.touched.message && validation.errors.message ? (
                      <FormFeedback type="invalid">
                        {validation.errors.message}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="submit" color="primary">
                Submit
              </Button>
              <Button type="button" onClick={togglemodal} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Form>
      </Modal>

      {/* <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row> */}

      <div
        className="table-responsive react-table producttable"
        ref={scrollRef}
      >
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>
      <div className="mobile-view">
        <div className="d-flex justify-content-between my-2 ">
          <a href="#" className="previous round" onClick={scrollToLeft}>
            &#8249;
          </a>
          <a href="#" className="next round " onClick={scrollToRight}>
            &#8250;
          </a>
        </div>
      </div>
      <Row
        className="justify-content-md-end justify-content-center align-items-center mobile-pagination"
        style={{ marginBottom: "20px" }}
      >
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSERVICES}?query=${query}&page=${1}&status=${status === "4" ? "" : status
                  }`,
                  1
                )
              }
              className="paginate-left-button"
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSERVICES}?query=${query}&page=${currentPage - 1
                  }&status=${status === "4" ? "" : status}`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto middleView">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1 nextView">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSERVICES}?query=${query}&page=${currentPage + 1
                  }&status=${status === "4" ? "" : status}`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${CUSTOMERSERVICES}?query=${query}&page=${Math.ceil(
                    count / customPageSize
                  )}&status=${status === "4" ? "" : status}`,
                  Math.ceil(count / customPageSize)
                )
              }
              className="paginate-right-button"
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
