import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";
import {checkTokenExpiration} from '../../../src/config/getTokenExpirationTime'

import { dealerDashboardDataGet } from "../../store/actions";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import SalesAnalytics from "../charts/sales-analytics"
// import MonthlyEarning from "./MonthlyEarning";
// import SocialSource from "./SocialSource";
// import ActivityComp from "./ActivityComp";
// import TopCities from "./TopCities";
// import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  let periodData =[]
  let periodDataCustomer =[]
  const dealerdashboardData =useSelector(state => state.dealerDashboardGet.dealerDashboardDetails)

  const dispatch = useDispatch();
  useEffect(()=>{
    checkTokenExpiration()
    dispatch(dealerDashboardDataGet({}, {}))
},[dispatch])

  console.log("dealerdashboardData=>",dealerdashboardData)
  console.log("EarningByDealer=>",dealerdashboardData && dealerdashboardData.EarningByDealer)

  if (dealerdashboardData && dealerdashboardData.EarningByCustomer){
    periodDataCustomer = [
      {
        "name":"Customer Earning",
        "data":dealerdashboardData.EarningByCustomer
      }
    ]
  }
  if (dealerdashboardData && dealerdashboardData.EarningByDealer){
    periodData = [
      {
        "name":"Dealer Earning",
        "data":dealerdashboardData.EarningByDealer
      }
    ]
  }


  //meta title
  document.title = "Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            {/* <Col xl="5">
              <WelcomeComp AllOrders={dealerdashboardData && dealerdashboardData.AllOrders} PendingOrders={dealerdashboardData && dealerdashboardData.PendingOrders}/>
              <SalesAnalytics series={[dealerdashboardData && dealerdashboardData.ProductAnalytics.RPD,dealerdashboardData && dealerdashboardData.ProductAnalytics.MPD]} dataColors='["--bs-primary", "--bs-success"]' />
            </Col> */}
            <Col xl="6">
             

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Products Sold By Dealer</h4>
                    
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart periodData={periodData} isround={false} dataColors='["--bs-primary"]' />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
            <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Products Purchased By Customer</h4>
                  
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart  periodData={periodDataCustomer} isround={true} dataColors='["--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>

     

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
