import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"

import { BULKCONSUMABLEPRODUCTUPLOAD, HOSTNAME } from "config/config"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./tableContainer"
import Loadder from "components/Loadder/Loadder"

const BulkConsumablesProduct = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [modal, setModal] = useState(false)
  const [rowData, setRowData] = useState({})
  const [dealers, setDealers] = useState(null)
  const [dealer, setDealer] = useState(null)
  const [qty, setQty] = useState("")
  const [currentPage, setcurrentPage] = useState(1)

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  const getBulkConsumables = () => {
    setLoading(true)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/get-inventory-manufacture-product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getDealer = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/get-dealer-list/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    axios.request(config).then(response => {
      setDealers(response?.data?.data)
    })
  }

  const tagToDealer = () => {
    let data = new FormData()

    data.append("id", rowData?.id)
    data.append("dealerId", dealer)
    data.append("qty", qty)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/tag-inventory-manufacture-product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }
    axios
      .request(config)
      .then(response => {
        setDealers(response?.data?.data)
        toast.success("Product sent to dealer succesfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        getBulkConsumables()
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setModal(false)
      })
  }

  useEffect(() => {
    getBulkConsumables()
  }, [])

  const handleUpload = () => {
    if (!selectedFiles[0]) {
      alert("Please select a CSV file")
      return
    }

    const formData = new FormData()
    formData.append("file", selectedFiles[0])

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${BULKCONSUMABLEPRODUCTUPLOAD}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: formData,
    }

    axios
      .request(config)
      .then(response => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        setselectedFiles([])
        getBulkConsumables()
      })
      .catch(error => {
        toast.error("Equipment Product is missing", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setisOpen(false)
      })
  }

  const togglemodal = row => {
    setRowData(row)
    getDealer()
    setModal(!modal)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product  Consumables Name",
        accessor: "mpname",
      },
      {
        Header: "Product Quantity",
        accessor: "mpquantity",
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link onClick={() => togglemodal(row.original)} href="#">
                  Tag to Dealer
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  // Search
  const [query, setquery] = useState("")
  const onSearch = e => {
    setquery(e.target.value)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/get-inventory-manufacture-product/?query=${e.target.value}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //Pagination function
  const getPaginationData = (url, page) => {
    setcurrentPage(page)
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }
    setLoading(true)
    axios
      .request(config)
      .then(response => {
        setTableData(response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const toggle1 = () => setisOpen(!isOpen)

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <Loadder active={loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Consumables Products" />

          <TableContainer
            count={tableData?.count}
            next={tableData?.next}
            previous={tableData?.previous}
            columns={columns}
            data={tableData?.results?.data || []}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
            onSearch={onSearch}
            currentPage={currentPage}
            getData={getBulkConsumables}
            rightSide={
              <button
                type="button"
                onClick={toggle1}
                className="btn btn-primary"
              >
                Upload
              </button>
            }
            goToNextPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-inventory-manufacture-product/?query=${query}&page=${currentPage + 1
                }`,
                currentPage + 1
              )
            }
            goToLastPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-inventory-manufacture-product/?query=${query}&page=${Math.ceil(
                  tableData?.count / 10
                )}`,
                Math.ceil(tableData?.count / 10)
              )
            }
            goToPreviousPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-inventory-manufacture-product/?query=${query}&page=${currentPage - 1
                }`,
                currentPage - 1
              )
            }
            goToFirstPage={() =>
              getPaginationData(
                `${HOSTNAME}api/get-inventory-manufacture-product/?query=${query}&page=${1}`,
                1
              )
            }
            getInputPaginationData={e =>
              getPaginationData(
                `${HOSTNAME}api/get-inventory-manufacture-product/?query=${query}&page=${e.target.value}`,
                e.target.value
              )
            }
          />

          {/* Tag to Dealer modal */}
          <Modal
            isOpen={modal}
            role="dialog"
            size="lg"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={togglemodal}
          >
            <div className="modal-content">
              <ModalHeader toggle={togglemodal}>
                Tag Cousumables Product to dealer
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="">Products Name</Label>
                      <Input
                        type="text"
                        name="product-name"
                        className="form-control"
                        placeholder="Products Name"
                        value={rowData?.pname}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="">Consumables Product name</Label>
                      <Input
                        type="text"
                        name="add-consumables-products"
                        className="form-control"
                        placeholder="Add Consumables name"
                        value={rowData?.mpname}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <Label htmlFor="">Add Consumables Product Qty</Label>
                      <Input
                        type="number"
                        name="add-consumables-product-qty"
                        className="form-control"
                        placeholder="Add Consumables Product Qty"
                        value={qty}
                        onChange={e => {
                          const value = e.target.value
                          if (value >= 0 || value === "") {
                            setQty(value)
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="exampleSelect">Add Dealer</Label>
                      <Input
                        name="add-dealer"
                        type="select"
                        onChange={e => setDealer(e.target.value)}
                      >
                        <option value="" disabled selected hidden>
                          Add Dealer
                        </option>
                        {dealers?.map(el => (
                          <option key={el?.id} value={el?.id}>
                            {el?.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
                <Button
                  disabled={qty <= 0}
                  type="button"
                  color="primary"
                  onClick={tagToDealer}
                >
                  Submit
                </Button>

                <Button type="button" onClick={togglemodal} color="secondary">
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </Modal>

          {/* Upload CSV Modal */}
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle1}
          >
            <div className="modal-content">
              <ModalHeader toggle={toggle1}>Upload CSV File</ModalHeader>
              <Form>
                <ModalBody>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <h6 className="card-title">
                            Bulk Consumables Products
                          </h6>
                          <CardSubtitle className="mb-3">
                            Upload Only CSV File.
                          </CardSubtitle>

                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button type="button" color="secondary" onClick={toggle1}>
                    Close
                  </Button>
                  <Button
                    disabled={selectedFiles.length === 0}
                    type="button"
                    onClick={handleUpload}
                    color="primary"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            </div>
          </Modal>
        </div>
      </div>
    </Loadder>
  )
}

export default BulkConsumablesProduct
