// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./taggedproductlisttablecontainer"
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { delearResellingTagProductCustomerGet } from "store/actions"
import { useEffect } from "react"
import { ADMINSOLDPRODUCT, HOSTNAME } from "config/config"
import { toast } from "react-toastify"
import axios from "axios"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  let dealercustomerstatustext

  if (item.dealercustomerstatus === 0) {
    dealercustomerstatustext = "Pending"
  } else if (item.status === 1) {
    dealercustomerstatustext = "Accepted"
  } else if (item.status === 2) {
    dealercustomerstatustext = "Rejected"
  } else {
    dealercustomerstatustext = "Unknown Status"
  }
  return {
    id: item.id,
    customerName: `${item.tagcustomer && item.tagcustomer.firstName} ${
      item.tagcustomer && item.tagcustomer.lastName
    }`,
    contactNumber: `${item.tagcustomer && item.tagcustomer.contactNumber}`,
    city: `${item.tagcustomer && item.tagcustomer.city}`,
    pname: `${item.product && item.product.pname}`,
    pnumber: `${item.product && item.product.pnumber}`,
    status: item.status,
    statustext: item.statustext,
    isSold: item.isSold,
    isSoldtext: item.isSold ? "Yes" : "No",
    dealercustomerstatus: item.dealercustomerstatus,
    dealercustomerstatustext: dealercustomerstatustext,
    serialNumbers: `${item.product && item.product.serialNumbers}`,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Customer City",
        accessor: "city",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "dealercustomerstatustext",
      },
      {
        Header: "Is Sold",
        accessor: "isSoldtext",
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          if (row.dealercustomerstatus == 1 && row.isSold == false) {
            return (
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="card-drop">
                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={e => soldData(0, row.id)}>
                    <i
                      className="font-size-16 text-success me-1"
                      id="edittooltip"
                    ></i>
                    Mark as sold this year
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Mark as sold this year
                    </UncontrolledTooltip>
                  </DropdownItem>

                  {/* <DropdownItem onClick={e => soldData(1,row.id)}
                    >
                      <i className="font-size-16 text-success me-1" id="edittooltip"></i>
                      Mark as sold next year
                      <UncontrolledTooltip placement="top" target="edittooltip">
                      Mark as sold next year
                      </UncontrolledTooltip>
                    </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          } else {
            return ""
          }
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(delearResellingTagProductCustomerGet({ isdemo: 1 }, {}))
  }, [])

  const dealer_resellingproductlistData = useSelector(
    state => state.DelearProductGet
  )

  if (
    dealer_resellingproductlistData &&
    dealer_resellingproductlistData.resellingtagproductcustomerDetails
  ) {
    data =
      dealer_resellingproductlistData.resellingtagproductcustomerDetails.results.data.map(
        extractIdAndPName
      )
  }

  const soldData = (year, id) => {
    // alert(year)
    // alert(id)
    let url = `${HOSTNAME}${ADMINSOLDPRODUCT}?year=${year}&oid=${id}&soldType=0`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product sold successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        dispatch(delearResellingTagProductCustomerGet({ isdemo: 1 }, {}))
      })
      .catch(error => {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  //meta title
  document.title = "Demo Product"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Demo Products" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            dealer_resellingproductlistData.resellingtagproductcustomerDetails &&
            dealer_resellingproductlistData.resellingtagproductcustomerDetails
              .count
          }
          next={
            dealer_resellingproductlistData.resellingtagproductcustomerDetails &&
            dealer_resellingproductlistData.resellingtagproductcustomerDetails
              .next
          }
          previous={
            dealer_resellingproductlistData.resellingtagproductcustomerDetails &&
            dealer_resellingproductlistData.resellingtagproductcustomerDetails
              .previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
