import {
  ADMINBUYBACK_DATA_GET,
  ADMINBUYBACK_DATA_SUCCESS
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  adminBuyBackDetails: "",
}

const adminBuyBackGet = (state = initialState, action) => {
  switch (action.type) {
    case ADMINBUYBACK_DATA_GET:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADMINBUYBACK_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        adminBuyBackDetails:action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default adminBuyBackGet


