export const DELEARPRODUCT_GET="DELEARPRODUCT_GET"
export const DELEARPRODUCT_SUCCESS="DELEARPRODUCT_SUCCESS"

export const DELEAR_UPLOAD_POST="DELEAR_UPLOAD_POST"
export const DELEAR_UPLOAD_SUCCESS="DELEAR_UPLOAD_SUCCESS"

// Dealers List 

export const DEALERLIST_GET="DEALERLIST_GET" 
export const DEALERLIST_SUCCESS="DEALERLIST_SUCCESS"

// Tagged Dealer

export const TAGGEDDEALER_GET="TAGGEDDEALER_GET"
export const TAGGEDDEALER_SUCCESS="TAGGEDDEALER_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

// delear-new-product-request
export const DEALER_NEW_PRODUCT_REQUEST_GET = "DEALER_NEW_PRODUCT_REQUEST_GET"
export const DEALER_NEW_PRODUCT_REQUEST_SUCCESS = "DEALER_NEW_PRODUCT_REQUEST_SUCCESS"

// api/dealer-get-manufacture-orders/
export const DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET = "DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET"
export const DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_SUCCESS = "DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_SUCCESS"

export const DEALER_MANUFACTURE_ORDERS_GET = "DEALER_MANUFACTURE_ORDERS_GET"
export const DEALER_MANUFACTURE_ORDERS_SUCCESS = "DEALER_MANUFACTURE_ORDERS_SUCCESS"


//-----------------
// DEALERGETCUSTOMERDETAILS
export const DEALER_CUSTOMER_GET = "DEALER_CUSTOMER_GET"
export const DEALER_CUSTOMER_SUCCESS = "DEALER_CUSTOMER_SUCCESS"

//DEALERSERVICESDETAILS
export const DEALER_SERVICES_GET = "DEALER_SERVICES_GET"
export const DEALER_SERVICES_SUCCESS = "DEALER_SERVICES_SUCCESS"

//DEALERBUYBACKDETAILS
export const DEALER_BUYBACK_GET = "DEALER_BUYBACK_GET"
export const DEALER_BUYBACK_SUCCESS = "DEALER_BUYBACK_SUCCESS"

//DEALERRESELLINGEARNING
export const DEALER_RESELLINGEARNING_GET = "DEALER_RESELLINGEARNING_GET"
export const DEALER_RESELLINGEARNING_SUCCESS = "DEALER_RESELLINGEARNING_SUCCESS"