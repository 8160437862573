import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"

// Login Redux States
import { 
  LOGOUT_USER,
  VIEWDEMOPRODUCT_GET,
  VIEWDEMOPRODUCT_SUCCESS,
  TAGTODEALER_GET,
  TAGTODEALER_SUCCESS,
  TAGGEDPRODUCTLIST_GET,
  TAGGEDPRODUCTLIST_SUCCESS,
  DEMOPRODUCT_ADD,
  DEMOPRODUCT_ADD_SUCCESS,
  DEMOPRODUCTMASTER_GET,
  DEMOPRODUCTMASTER_SUCCESS,
  DEMOPRODUCTMASTER_UPLOAD_POST,

} from "./actionTypes"

import { 
  viewDemoProductGet,
  viewDemoProductSuccess,
  tagtoDealerGet,
  tagtoDealerSuccess,
  taggedProductListGet,
  taggedProductListSuccess,
  demoProductAdd,
  demoProductAddSuccess,
  demoProductMasterGet,
  demoProductMasterSuccess,
  demoProductMasterUploadGet,

} from "./actions"

import { 
  HOSTNAME,
  VIEWDEMOPRODUCTGET,
  TAGTODEALERGET,
  TAGGEDPRODUCTLISTGET,
  DEMOPRODUCTADD,
  DEMOPRODUCTMASTERGET,
  DEMOPRODUCTMASTERUPLOAD

} from "config/config"

import { apiError } from "store/actions"

// const fireBaseBackend = getFirebaseBackend()
const getUserData = JSON.parse(localStorage.getItem("authUser")) 

// View Demo Product Get

function* viewDemoProducts({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${VIEWDEMOPRODUCTGET}?isdemoproduct=1`
            if(query.query){
              url =`${HOSTNAME}${VIEWDEMOPRODUCTGET}?query=${query.query}&isdemoproduct=1`
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(viewDemoProductSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

//  Tag To dealer Get

function* tagtoDealer({ payload: { query, history,id } }) {
  try {
            // alert(query.url)
            let url = `${HOSTNAME}${TAGTODEALERGET}`
            if (id.id){
              // alert(id.id)
              url = url + `${id.id}/`
            }
            
            if(query.query){
              url = url + `?query=${query.query}`
            }
            if(query.url){
              // alert("hey")
              url=query.url
            }
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(tagtoDealerSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

//  Tagged Product List

function* taggedProductList({ payload: { query, history } }) {
  try {
            // alert(query.query)
            // let url = `${HOSTNAME}${TAGGEDPRODUCTLISTGET}`
            // if(query.query){
            //   url =`${HOSTNAME}${TAGGEDPRODUCTLISTGET}?query=${query.query}`
            // }
            // else if(query.url){
            //   url=query.url
            // }
            let url = `${HOSTNAME}${TAGGEDPRODUCTLISTGET}`
            const queryParams = [];
            if (query.query) {
              queryParams.push(`query=${query.query}`);
            }
            if (query.status  && query.status !== '3') {
              queryParams.push(`status=${query.status}`);
            }
            if (query.isdemoproduct) {
              // isdemoproduct = query.isdemoproduct
              queryParams.push(`isdemoproduct=${query.isdemoproduct}`);
            }
            if (queryParams.length > 0) {
              url = `${HOSTNAME}${TAGGEDPRODUCTLISTGET}&${queryParams.join('&')}`;
            }
            if(query.url){
              url = query.url;
            }

            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(taggedProductListSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

// -- maintain inventory add product
function* demoProductAddData({ payload: { data, history } }) {
  try {
            // alert(data)
            // let data = new FormData();
            // data.append('file', fileData.data);
            data.data["isdemoproduct"]=1
            let mydata = JSON.stringify(data.data)
            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${HOSTNAME}${DEMOPRODUCTADD}`,
              headers: { 
                'Authorization': `${getUserData.userData.token}`, 
                'Content-Type': 'application/json', 
              },
              data : mydata
            };


            const response = yield axios.request(config);
            // alert(response.status)
            if(response.status ==400 || response.status ==500)
            {
              toast.error("Invalid Data", {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }
            console.log("+++++++++++++",response);
            if (response.data.status_code === 201) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              // const dispatch = useDispatch()
              // dispatch(manufacturedProductGet({}, {}))
              yield put(viewDemoProductGet({}, {}));
              

            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error("Invalid Data", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}


// ---- Demo Product Master----

function* demoProductMaster({ payload: { query, history } }) {
  try {
            // alert(query.query)
            let url = `${HOSTNAME}${DEMOPRODUCTMASTERGET}?isdemoproduct=1`
            if(query.query){
              url =`${HOSTNAME}${DEMOPRODUCTMASTERGET}?query=${query.query}&isdemoproduct=1`
            }
            else if(query.url){
              url=query.url
            }
            
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url:url,
              headers: { 
                'Authorization': `${getUserData.userData.token}`
              }
            };


            const response = yield axios.request(config);
            console.log("+++++++++++++",response);
            if (response.status === 200) {
              yield put(demoProductMasterSuccess(response.data));
              console.log("---======----==--=-=-=-=-",response.data.results.data)
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}

//  Demo Product Master Upload 

function* demoproductmasterUpload({ payload: { fileData, history } }) {
  try {
            // alert(fileData)
            // let data = new FormData();
            // data.append('file', data);
            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${HOSTNAME}${DEMOPRODUCTMASTERUPLOAD}`,
              headers: { 
                'Authorization': `${getUserData.userData.token}`, 
                'Content-Type': 'multipart/form-data',
              },
              data : fileData.data
            };
            const response = yield axios.request(config);
            if(response.status==400)
            {
              toast.error("File Note Valid", {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }
            console.log("+++++++++++++",response);
            if (response.data.status_code === 201) {
              yield put(demoProductMasterGet({},{}));
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              toast.success(`Total created: ${response.data.data.created}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              toast.success(`Total updated: ${response.data.data.updated}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              

            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              yield put(apiError(response.data.message));
            }

  } catch (error) {
    console.log("eprrrrrr=????>>>>",error)
    toast.error("something went wrong", {
      position: toast.POSITION.TOP_RIGHT,
    })
    yield put(apiError(error))
  }
}


function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/pre-login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* demoProductsSaga() {
  yield takeEvery(VIEWDEMOPRODUCT_GET, viewDemoProducts)
  yield takeEvery(TAGTODEALER_GET, tagtoDealer)
  yield takeEvery(TAGGEDPRODUCTLIST_GET, taggedProductList)
  yield takeEvery(DEMOPRODUCT_ADD, demoProductAddData)
  yield takeEvery(DEMOPRODUCTMASTER_GET, demoProductMaster)
  yield takeEvery(DEMOPRODUCTMASTER_UPLOAD_POST, demoproductmasterUpload)


  yield takeEvery(LOGOUT_USER, logoutUser)  
}

export default demoProductsSaga
