import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
// import { Table, Row, Col, Button, Input, CardBody ,Label} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { DEALERPROFILEUPDATE, HOSTNAME } from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [username, setusername] = useState("Admin")
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const [modal, setModal] = useState(false)

  const profiletoggle = () => {
    setModal(!modal)
    // alert("hello")
  }
  const [dealerData, setdealerData] = useState({})
  const profiletoggle1 = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}${DEALERPROFILEUPDATE}`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        console.log(response.data.data)
        setdealerData(response.data && response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
    setModal(!modal)
    // alert("hello")
  }

  // Form validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      agencyname: dealerData && dealerData.agencyName,
      dealerfname: dealerData && dealerData.firstName,
      dealerlname: dealerData && dealerData.lastName,
      dealerEmail: dealerData && dealerData.email,
      contactNumber: dealerData && dealerData.contactNumber,
      city: dealerData && dealerData.city,
    },
    validationSchema: Yup.object({
      agencyname: Yup.string().required("Please Enter Your Agency Name"),
      dealerfname: Yup.string().required("Please Enter Your Dealer First Name"),
      dealerlname: Yup.string().required("Please Enter Your Dealer Last Name"),
      dealerEmail: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
      contactNumber: Yup.string()
        .required("Contact Number is required")
        .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits"),
      city: Yup.string().required("Please Enter City"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log("values=>>>>", values)
      profiletoggle()

      // ------------------------------------
      let data = new FormData()
      data.append("agencyName", values.agencyname)
      data.append("firstName", values.dealerfname)
      data.append("lastName", values.dealerlname)
      data.append("email", values.dealerEmail)
      data.append("city", values.city)
      data.append("contactNumber", values.contactNumber)

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${HOSTNAME}${DEALERPROFILEUPDATE}`,
        headers: {
          Authorization: `${getUserData.userData.token}`,
        },
        data: data,
      }

      axios
        .request(config)
        .then(response => {
          console.log(JSON.stringify(response.data))
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
        .catch(error => {
          console.log(error)
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        })

      // ----------------------------------------
      resetForm({
        values: {
          agencyname: "",
          dealerfname: "",
          dealerlname: "",
          contactNumber: "",
          city: "",
        },
      })
    },
  })

  console.log("test", validation, validation.values)

  const buttonStyle = {
    // position: 'relative',
    // backgroundColor: '#007bff', // Primary color
    // color: '#ffffff',
    // border: 'none',
    // padding: '10px 20px',
    // fontSize: '16px',
    // cursor: 'pointer'
  }

  const tooltipStyle = {
    visibility: "hidden",
    backgroundColor: "#000",
    color: "#fff",
    textAlign: "center",
    padding: "5px",
    borderRadius: "6px",
    position: "absolute",
    zIndex: "1",
    // bottom: '125%', /* Position the tooltip above the button */
    // left: '50%',
    // marginLeft: '-60px', /* Adjust this value to center the tooltip */
    opacity: "0",
    transition: "opacity 0.3s",
  }

  const handleHover = () => {
    document.getElementById("tooltip").style.visibility = "visible"
    document.getElementById("tooltip").style.opacity = "1"
  }

  const handleLeave = () => {
    document.getElementById("tooltip").style.visibility = "hidden"
    document.getElementById("tooltip").style.opacity = "0"
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to="#"
            className="dropdown-item"
            onClick={() => {
              profiletoggle1()
            }}
          >
            <i className="bx bxs-user-rectangle font-size-16 align-middle me-1 text-primary" />
            <span>{props.t("Profile")}</span>
          </Link>

          <Link
            to="#"
            className="dropdown-item"
            onClick={() => {
              tog_backdrop()
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop()
        }}
        backdrop={"static"}
        id="staticBackdrop"
        size="sm"
      >
        <div className="modal-header">
          <h3 className="modal-title" id="staticBackdropLabel">
            <i
              className="bx bx-power-off align-middle me-1 text-danger"
              style={{ fontSize: "30px" }}
            />
            Logout
          </h3>
        </div>
        <div className="modal-body">
          <b>
            <p>Are you sure you want to logout ???</p>
          </b>
        </div>

        <div className="modal-footer">
          <Link to="/logout">
            <button type="button" className="btn btn-primary">
              Yes
            </button>
          </Link>

          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setmodal_backdrop(false)
            }}
          >
            No
          </button>
        </div>
      </Modal>

      {/* modal */}
      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={profiletoggle}
      >
        <div className="modal-content">
          <Form onSubmit={validation.handleSubmit}>
            <ModalHeader toggle={profiletoggle}>Your Profile</ModalHeader>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Agency Name</Label>
                    <Input
                      name="agencyname"
                      placeholder="Agency Name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={e => {
                        validation.setFieldValue("agencyname", e.target.value)
                      }}
                      value={validation.values.agencyname}
                      invalid={
                        validation.touched.agencyname &&
                        validation.errors.agencyname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.agencyname &&
                    validation.errors.agencyname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.agencyname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">
                      Dealer First Name
                    </Label>
                    <Input
                      name="dealerfname"
                      placeholder="Dealer Name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={e => {
                        validation.setFieldValue("dealerfname", e.target.value)
                      }}
                      value={validation.values.dealerfname}
                      invalid={
                        validation.touched.dealerfname &&
                        validation.errors.dealerfname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.dealerfname &&
                    validation.errors.dealerfname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dealerfname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Dealer Last Name</Label>
                    <Input
                      name="dealerlname"
                      placeholder="Dealer Last Name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={e => {
                        validation.setFieldValue("dealerlname", e.target.value)
                      }}
                      value={validation.values.dealerlname}
                      invalid={
                        validation.touched.dealerlname &&
                        validation.errors.dealerlname
                          ? true
                          : false
                      }
                    />
                    {validation.touched.dealerlname &&
                    validation.errors.dealerlname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dealerlname}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Dealer Email</Label>
                    <Input
                      name="dealerEmail"
                      placeholder="Dealer Email"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={e => {
                        validation.setFieldValue("dealerEmail", e.target.value)
                      }}
                      value={validation.values.dealerEmail}
                      invalid={
                        validation.touched.dealerEmail &&
                        validation.errors.dealerEmail
                          ? true
                          : false
                      }
                    />
                    {validation.touched.dealerEmail &&
                    validation.errors.dealerEmail ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dealerEmail}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Contact Number</Label>
                    <Input
                      name="contactNumber"
                      placeholder="Contact Number"
                      type="number"
                      className="form-control"
                      id="validationCustom01"
                      onChange={e => {
                        validation.setFieldValue(
                          "contactNumber",
                          e.target.value
                        )
                      }}
                      value={validation.values.contactNumber}
                      invalid={
                        validation.touched.contactNumber &&
                        validation.errors.contactNumber
                          ? true
                          : false
                      }
                    />
                    {validation.touched.contactNumber &&
                    validation.errors.contactNumber ? (
                      <FormFeedback type="invalid">
                        {validation.errors.contactNumber}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">City</Label>
                    <Input
                      name="city"
                      placeholder="City"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      // disabled
                      onChange={e => {
                        validation.setFieldValue("city", e.target.value)
                      }}
                      value={validation.values.city}
                      invalid={
                        validation.touched.city && validation.errors.city
                          ? true
                          : false
                      }
                    />
                    {validation.touched.city && validation.errors.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              {/* <Button type="button" title="Your Tooltip Text Here" color="primary">Update</Button> */}

              <Button color="primary" type="submit">
                Update
                {/* <span id="tooltip" style={tooltipStyle}>To update your profile contact Admin</span> */}
              </Button>
              <Button type="button" onClick={profiletoggle} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
