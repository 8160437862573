// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./newproductrequesttablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import { Link } from "react-router-dom"

import { delearNewProductRequestGet } from "../../../src/store/actions"
import { useSelector, useDispatch } from "react-redux"
import { HOSTNAME, DEALERNEWPRODUCTREQUEST } from "config/config"
import Loadder from "components/Loadder/Loadder"

function extractIdAndPName(item) {
  return {
    id: item.id,
    pname: `${item.productName}`,
    notes: `${item.notes}`,
    status: item.status,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="" onClick={e => togglemodal1(row)}>
                  View request
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(delearNewProductRequestGet({}, {}))
  }, [])

  const dealer_resellingproductlistData = useSelector(
    state => state.DelearProductGet
  )

  if (
    dealer_resellingproductlistData &&
    dealer_resellingproductlistData.newproductrequestDetails
  ) {
    data =
      dealer_resellingproductlistData.newproductrequestDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Equipments Products"

  const [modal, setModal] = useState(false)
  const [rowdata, setrowdata] = useState({})
  const togglemodal = () => {
    setModal(!modal)
  }
  const togglemodal1 = row => {
    setModal(!modal)
    setrowdata(row)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Equipments Products" />

        <TableContainer
          count={dealer_resellingproductlistData.newproductrequestDetails.count}
          next={dealer_resellingproductlistData.newproductrequestDetails.next}
          previous={
            dealer_resellingproductlistData.newproductrequestDetails.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>New Product Request</ModalHeader>

          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  {/* <Label htmlFor="validationCustom01">Product Number</Label>
                          <Input
                            name="pid"
                            placeholder="Product Number"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            disabled
                          /> */}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Product Name</Label>
                  <Input
                    name="productname"
                    placeholder="Product Name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    value={rowdata && rowdata.pname}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Notes</Label>
                  <Input
                    name="notes"
                    placeholder="Description"
                    type="textarea"
                    className="form-control"
                    id="validationCustom01"
                    value={rowdata && rowdata.notes}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
