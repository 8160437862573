// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./productverificationofnewcustomertablecontainer"
import { productverificationofexistingcustomerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import { toast } from "react-toastify"
import { ADMINSOLDPRODUCT, HOSTNAME, REMOVETAGPRODUCT } from "config/config"
import { Link } from "react-router-dom"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  return {
    id: item.id,
    pnumber: item.pnumber,
    customername: `${item.customerDetail && item.customerDetail.firstName} ${
      item.customerDetail && item.customerDetail.lastName
    }`,
    contactNumber: `${
      item.customerDetail && item.customerDetail.contactNumber
    }`,
    //   dealerName:`${item.dealerDetail && item.customerDetail.firstName} ${item.dealerDetail && item.customerDetail.lastName}`,
    //   dealercontactNumber : `${item.dealerDetail && item.customerDetail.contactNumber}`,
    city: item.city,
    productName: item.productName,
    isverify: item.isverify ? "Yes" : "No",
    serialNumbers: item.serialNumbers,
    isSold: item.isSold,
    status: item.status,
  }
}

function DatatableTablesManufacture() {
  const autoProductVerification = row => {
    let data = new FormData()
    data.append("tagId", row)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/autoverify/tag/product/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product verified successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        dispatch(productverificationofexistingcustomerGet({ type: "AC" }, {}))
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customername",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Customer City",
        accessor: "city",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Product Verification",
        accessor: "isverify",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return row.original.isverify === "No" ? (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                {row.original.status === 0 ? (
                  <DropdownItem onClick={e => removetoggle1(row.original.id)}>
                    <i
                      className="font-size-16 text-success me-1"
                      id="edittooltip"
                    ></i>
                    Remove Tag Product
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Remove Tag Product
                    </UncontrolledTooltip>
                  </DropdownItem>
                ) : (
                  ""
                )}

                <DropdownItem
                  onClick={() =>
                    autoProductVerification(cellProps.row.original.id)
                  }
                >
                  <i
                    className="font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  Auto Product Verification
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            ""
          )
        },
      },
      {
        Header: "   ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <UncontrolledDropdown>
              {row.original.status == 1 || row.original.status == "1" ? (
                row.original.isSold == false &&
                row.original.isverify == "Yes" ? (
                  <>
                    <DropdownToggle tag="a" className="card-drop">
                      <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={e => soldData(e, row.original.id, 0)}
                      >
                        <i
                          className="font-size-16 text-success me-1"
                          id="edittooltip"
                        ></i>
                        Mark as sold in this year
                        <UncontrolledTooltip
                          placement="top"
                          target="edittooltip"
                        >
                          Mark as sold in this year
                        </UncontrolledTooltip>
                      </DropdownItem>

                      <DropdownItem
                        onClick={e => soldData(e, row.original.id, 1)}
                      >
                        <i
                          className=" font-size-16 text-danger me-1"
                          id="deletetooltip"
                        ></i>
                        Mark as sold in next year
                        <UncontrolledTooltip
                          placement="top"
                          target="deletetooltip"
                        >
                          Mark as sold in next year
                        </UncontrolledTooltip>
                      </DropdownItem>
                    </DropdownMenu>
                  </>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(productverificationofexistingcustomerGet({ type: "AC" }, {}))
  }, [])

  const productverificationofexistingcustomerData = useSelector(
    state => state.CustomerGet
  )

  if (
    productverificationofexistingcustomerData &&
    productverificationofexistingcustomerData.customerDetails
  ) {
    data =
      productverificationofexistingcustomerData.customerDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Customer"

  const soldData = (e, id, type) => {
    // alert(id)
    // alert(type)
    let url = `${HOSTNAME}${ADMINSOLDPRODUCT}?year=${type}&oid=${id}&soldType=0`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        dispatch(productverificationofexistingcustomerGet({ type: "AC" }, {}))
        toast.success("Product sold !", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch(error => {
        toast.error("something worng", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    dispatch(productverificationofexistingcustomerGet({ type: "AC" }, {}))
  }
  const [mdlremove, setRemove] = useState(false)
  const removetoggle = () => {
    setRemove(!mdlremove)
  }

  const [deleteid, setdeleteid] = useState(0)
  const removetoggle1 = id => {
    // alert(id)
    setdeleteid(id)
    setRemove(!mdlremove)
  }

  const deletedata = check => {
    // alert(check)
    if (check == "yes") {
      // alert(deleteid)
      let data = new FormData()
      data.append("tagid", deleteid)
      let url = `${HOSTNAME}${REMOVETAGPRODUCT}`
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          Authorization: `${getUserData.userData.token}`,
        },
        data: data,
      }

      axios
        .request(config)
        .then(response => {
          dispatch(productverificationofexistingcustomerGet({ type: "AC" }, {}))
          toast.success("Tagged product removed successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
        .catch(error => {
          toast.error("Something went Wrong", {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
    }
    setRemove(!mdlremove)
    setdeleteid(0)
  }

  return (
    <Loadder active={productverificationofexistingcustomerData?.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Customers" />

          <TableContainer
            count={
              productverificationofexistingcustomerData.customerDetails.count
            }
            next={
              productverificationofexistingcustomerData.customerDetails.next
            }
            previous={
              productverificationofexistingcustomerData.customerDetails.previous
            }
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          size="sm"
          isOpen={mdlremove}
          toggle={removetoggle}
          centered={true}
        >
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button
                type="button"
                onClick={removetoggle}
                className="btn-close position-absolute end-0 top-0 m-3"
              ></button>
              <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div>
              <b>
                <p className="text-muted font-size-16 mb-4">Are you sure ?</p>
              </b>
              <p className="text-muted font-size-16 mb-4">
                you want to permanently erase the job.
              </p>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={e => deletedata("yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={e => deletedata("no")}
                >
                  No
                </button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTablesManufacture.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesManufacture
