// src/components/filter.
import React, {  useState,useMemo } from "react";
import PropTypes from 'prop-types';
import img2 from "../../assets/images/small/img-6.jpg"
import SimpleBar from "simplebar-react";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './manufacturedproductstablecontainer';
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { delearManufactureOrdersGet } from "store/actions";
import { ADMINSOLDPRODUCT, HOSTNAME } from "config/config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import axios from "axios";
const getUserData = JSON.parse(localStorage.getItem("authUser")) 

function extractIdAndPName(item) {
  let statustext;

    if (item.status === 0) {
        statustext = 'Pending';
    } else if (item.status === 1) {
        statustext = 'Accepted';
    } else if (item.status === 2) {
        statustext = 'Rejected';
    } else {
        statustext = 'Unknown Status';
    }
  return {
      id: item.id,
      customerName:`${item.customerName}`,
      contactNumber:`${item.contactNumber}`,
      city:`${item.city}`,
      pname:item.pname,
      pnumber:item.pnumber,
      mproduct:item.mproduct,
      pimage:item.pimage,
      status: item.status,
      isSold:item.isSold,
      soldtext: item.isSold ? "Yes": "No" ,
      statustext:statustext,
      serialNumbers:item.serialNumbers,
      mproductQty:item.mproductQty
  };
}


function DatatableTables() {
    const columns = useMemo(
        () => [
          {
            Header: 'Sr.No',
            accessor: 'id',
        },
        {
          Header: 'Customer Name',
          accessor: 'customerName'
        },
        {
          Header: 'Contact Number',
          accessor: 'contactNumber'
        },
        {
          Header: 'Customer City',
          accessor: 'city'
        },
        {
            Header: 'Product Name',
            accessor: 'pname'
        },
        {
          Header: 'Product Number',
          accessor: 'pnumber'
      },
      {
        Header: 'Product Serial Number',
        accessor: 'serialNumbers'
    },
        {
          Header: 'Product Report Images',
          Cell: (cellProps) => {
            const row = cellProps.row.original
            return (
              <Row>
                  <Col xl={12}>
                    <Link to="#" onClick={e =>imagtoggle1(row)}  className="mt-2 d-grid" >View Image</Link>
                  </Col>
              </Row>
            );
          }
  },
        {
            Header: 'Status',
            accessor: 'statustext'
        },
      //   {
      //     Header: 'is Sold',
      //     accessor: 'soldtext'
      // },
        {
                Header: ' ',
                Cell: (cellProps) => {
                  const row = cellProps.row.original
                  return (
                    <Row>
                        <Col xl={12}>
                          <Link to="#" onClick={e =>togglemodal1(row)} className="mt-2 d-grid" >View order</Link>
                        </Col>
                    </Row>
                  );
                }
        },
              {
                Header: '  ',
                Cell: (cellProps) => {
                  const row = cellProps.row.original
              if (row.status==1 && row.isSold ==false){
                return (
                  <UncontrolledDropdown>
                    <DropdownToggle tag="a" className="card-drop">
                      <i className="mdi mdi-dots-horizontal font-size-18"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem onClick={e => soldData(0,row.id)}
                      >
                        <i className="font-size-16 text-success me-1" id="edittooltip"></i>
                        Mark as sold this year
                        <UncontrolledTooltip placement="top" target="edittooltip">
                        Mark as sold this year
                        </UncontrolledTooltip>
                      </DropdownItem>
                      {/* <DropdownItem onClick={e => soldData(1,row.id)}
                      >
                        <i className="font-size-16 text-success me-1" id="edittooltip"></i>
                        Mark as sold next year
                        <UncontrolledTooltip placement="top" target="edittooltip">
                        Mark as sold next year
                        </UncontrolledTooltip>
                      </DropdownItem> */}
      
                    </DropdownMenu>
                  </UncontrolledDropdown>
                );
              }
              else{
                return ""
              }
                }
              },
        ],
        []
    );

  let data = [];
  const dispatch = useDispatch()

  useEffect(()=>{
    const isUserLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isUserLoggedIn) {
      localStorage.setItem('isLoggedIn', 'true');
      window.location.reload();
    }

      dispatch(delearManufactureOrdersGet({}, {}))
  },[])

  const dealer_resellingproductlistData =useSelector(state => state.DelearProductGet)
  console.log("manufactureordersDetails==========",dealer_resellingproductlistData)
  if(dealer_resellingproductlistData && dealer_resellingproductlistData.manufactureordersDetails){
      data = dealer_resellingproductlistData.manufactureordersDetails.results.data.map(extractIdAndPName);   
  }

  const soldData = (year,id) =>{
    // alert(year)
    // alert(id)
    let url =`${HOSTNAME}${ADMINSOLDPRODUCT}?year=${year}&oid=${id}`
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
        headers: { 
          'Authorization': `${getUserData.userData.token}`, 
        }
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        toast.success("product sold", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("product not sold", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

    //meta title
    document.title = "Consumables Prodects Orders";

    const [imgmodal, setImgmodal] = useState(false);
    const [modal, setModal] = useState(false);

    const imagtoggle = () => {
      setImgmodal(!imgmodal);
    };

    const imagtoggle1 = (row) => {
      setImgmodal(!imgmodal);
      console.log(row)
      setrowdata(row)
    };

    const togglemodal = () => {
      setModal(!modal);
    };
    
    const [rowdata,setrowdata] = useState({})
    const togglemodal1 = (row) => {
      setModal(!modal);
      // console.log(row,"0000000000")
      setrowdata(row)
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem="Consumables Product Orders" />
                {/* <Table columns={columns} data={data} /> */}
                <TableContainer
                    count={dealer_resellingproductlistData.newproductrequestDetails && dealer_resellingproductlistData.newproductrequestDetails.count}
                    next={dealer_resellingproductlistData.newproductrequestDetails && dealer_resellingproductlistData.newproductrequestDetails.next}
                    previous={dealer_resellingproductlistData.newproductrequestDetails && dealer_resellingproductlistData.newproductrequestDetails.previous}
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>

            <Modal
                    isOpen={imgmodal}
                    role="dialog"
                    size="md"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={imagtoggle}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={imagtoggle}>
                      </ModalHeader>
                        <ModalBody>
                        <Card>
                          <CardBody>                           
                            <div className="">
                              <img src={rowdata && rowdata.pimage} className="img-fluid" alt="Responsive image" />
                            </div><br/>
                            {/* <p className="card-title-desc">Images in Bootstrap are made responsive
                              with <code className="highlighter-rouge">.img-fluid</code>. <code
                                className="highlighter-rouge">max-width: 100%;</code> and <code
                                  className="highlighter-rouge">height: auto;</code> are applied to
                              the image so that it scales with the parent element.</p> */}
                          </CardBody>
                       </Card>
                        </ModalBody>
                    
                    </div>
                      
                     
            </Modal>


            <Modal
                    isOpen={modal}
                    role="dialog"
                    size="md"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={togglemodal}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={togglemodal}>
                       Equipment Product Details
                      </ModalHeader>
                        <ModalBody>
                        <SimpleBar style={{ maxHeight: "376px"}}>
                            <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <div>
                                                <b>Product Name</b><br></br>
                                                <p>{rowdata && rowdata.pname}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                         </SimpleBar>
                        </ModalBody>
                      <ModalHeader>
                        Consumables Product Details
                      </ModalHeader>
                    
                      <ModalBody>
                      <SimpleBar style={{ maxHeight: "250px" }}>
                      <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <div>
                                                <b>Product Name</b><br></br>
                                                <p> {rowdata && rowdata.mproduct}</p>
                                            </div>
                                        </div>                                     
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <div>
                                                <b>Order Quantity</b><br></br>
                                                <p> {rowdata && rowdata.mproductQty}</p>
                                            </div>
                                        </div>                                     
                                    </div>
                                    
                                </li>
                            </ul>

                        </SimpleBar>
                      </ModalBody>
                    </div>  
            </Modal>

        </div>
    );
}
DatatableTables.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTables;