import { all, fork } from "redux-saga/effects";

import resellingProductsSaga from "./resellingProduct/saga";
import manufacturedProductsSaga from "./manufacturedProducts/saga";
import delearProductsSaga from "./delearProduct/saga"
import CustomerSaga from "./customer/saga"
import demoProductsSaga from "./demoProduct/saga"
import AmcCustomerListSaga from "./policyRenewal/saga"
import dashboardDataSaga from "./adminDashboard/saga";
import financialDataSaga from "./financial/saga";
import buyBackDataSaga from "./adminbuyback/saga";
import adminEarningDataSaga from "./adminearning/saga";
import serviceDataSaga from "./service/saga";

// ************* Dealer*************
import dealerDashboardDataSaga from "./dealerDashboard/saga";
import customerProductDataSaga from "./customerProduct/saga"

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import ecommerceSaga from "./e-commerce/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import invoiceSaga from "./invoices/saga";
import jobsSaga from "./jobs/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import dashboardCryptoSaga from "./dashboard-crypto/saga";
import dashboardBlogSaga from "./dashboard-blog/saga";
import dashboardJobSaga from "./dashboard-jobs/saga";

export default function* rootSaga() {
  yield all([
    fork(resellingProductsSaga),
    fork(manufacturedProductsSaga),
    fork(delearProductsSaga),
    fork(CustomerSaga),
    fork(demoProductsSaga),
    fork(AmcCustomerListSaga),
    fork(dashboardDataSaga),
    fork(financialDataSaga),
    fork(buyBackDataSaga),
    fork(adminEarningDataSaga),
    fork(serviceDataSaga),

    fork(dealerDashboardDataSaga),
    fork(customerProductDataSaga),
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(jobsSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardBlogSaga),
    fork(dashboardJobSaga)
  ]);
}
