import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { toast } from "react-toastify"
import axios from "axios"
import {
  ModalFooter,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  Input,
  FormGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./neworderformcustomertablecontainer"
import { newOrderFromCustomerGet } from "../../../src/store/actions"
import { HOSTNAME } from "config/config"
import Loadder from "components/Loadder/Loadder"

const DatatableTables = () => {
  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(newOrderFromCustomerGet({}, {}))
  }, [])

  const newOrderFromCustomerData = useSelector(state => state.CustomerGet)

  if (newOrderFromCustomerData && newOrderFromCustomerData.customerDetails) {
    data = newOrderFromCustomerData.customerDetails.results.data
  }

  //meta title
  document.title = "Consumables Product"

  const [imgmodal, setImgmodal] = useState(false)
  const [modal, setModal] = useState(false)
  const [imgurl, setImgurl] = useState("")
  const [rproduct, setRproduct] = useState("")
  const [mproduct, setMproduct] = useState("")
  const [mproductqty, setMproductqty] = useState("")
  const [rejectModal, setRejectModal] = useState(false)
  const [rowData, setRowData] = useState(null)
  const [rejectReason, setRejectReason] = useState("")
  const [rejectReasonModal, setRejectReasonModal] = useState(false)
  const [reason, setReason] = useState(null)

  const toogelRejectModal = row => {
    setRowData(row)
    setRejectModal(!rejectModal)
  }

  const viewRejectReason = row => {
    setRejectReasonModal(!rejectReasonModal)
    setReason(row?.note)
  }

  const accepted = row => {
    let data = new FormData()
    data.append("tagId", row.id)
    data.append("status", 1)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/accept/tag-inventory-manufacture-product-customer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product accepted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        dispatch(newOrderFromCustomerGet({}, {}))
      })
      .catch(err => {
        toast.error("Insufficient Quantity", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  const reject = () => {
    let data = new FormData()
    data.append("tagId", rowData?.id)
    data.append("status", 2)
    data.append("note", rejectReason)

    const config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${HOSTNAME}api/accept/tag-inventory-manufacture-product-customer/`,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
      data: data,
    }

    axios
      .request(config)
      .then(response => {
        toast.success("Product rejected successfully", {
          position: toast.POSITION.TOP_RIGHT,
        })
        dispatch(newOrderFromCustomerGet({}, {}))
      })
      .catch(err => {
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .finally(() => {
        setRejectModal(false)
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <span>
              {row?.customer?.firstName} {row?.customer?.lastName}
            </span>
          )
        },
      },
      {
        Header: "Product Name",
        accessor: "RPD.pnumber.pname",
      },
      {
        Header: "Product Number",
        accessor: "RPD.pnumber.pnumber",
      },
      // {
      //   Header: "Product Serial Number",
      //   accessor: "RPD.pnumber.serialNumbers",
      // },
      {
        Header: "Product Report Images",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link
                  to="#"
                  onClick={e => imagtoggle1(e, row)}
                  className="mt-2 d-grid"
                >
                  View Image
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link
                  to="#"
                  onClick={e => vieworder(e, row)}
                  className="mt-2 d-grid"
                >
                  View order
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <>
              {row.status === 0 && (
                <div className="d-flex justify-content-between">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      accepted(row)
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => toogelRejectModal(row)}
                    size="sm"
                    color="warning"
                  >
                    Reject
                  </Button>
                </div>
              )}
              {row.status === 1 && (
                <Button size="sm" color="success">
                  Accepted
                </Button>
              )}
              {row.status === 2 && (
                <div className="d-flex justify-content-between align-items-center">
                  <Button size="sm" color="warning">
                    Rejected
                  </Button>
                  <UncontrolledDropdown className="me-2" direction="start">
                    <DropdownToggle size="sm" color="">
                      <i className="bx bx-dots-vertical fs-4"></i>
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem onClick={() => viewRejectReason(row)}>
                        View Reason
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </>
          )
        },
      },
    ],
    []
  )

  const imagtoggle = () => {
    setImgmodal(!imgmodal)
  }
  const togglemodal = () => {
    setModal(!modal)
  }

  const imagtoggle1 = (e, row) => {
    setImgurl(row.original.pimage)
    setImgmodal(!imgmodal)
  }

  const vieworder = (e, row) => {
    setRproduct(row.original.RPD.pnumber.pname)
    setMproduct(row.original.RPD.mpname)
    setMproductqty(row.original.qty)
    setModal(!modal)
  }

  const getUserData = JSON.parse(localStorage.getItem("authUser"))

  return (
    <Loadder active={newOrderFromCustomerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Consumable Products" />
          <TableContainer
            count={newOrderFromCustomerData.customerDetails.count}
            next={newOrderFromCustomerData.customerDetails.next}
            previous={newOrderFromCustomerData.customerDetails.previous}
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        {/* Image Modal */}
        <Modal
          isOpen={imgmodal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={imagtoggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={imagtoggle}></ModalHeader>
            <ModalBody>
              <Card>
                <CardBody>
                  <div className="">
                    <img
                      src={imgurl}
                      className="img-fluid"
                      alt="Responsive image"
                    />
                  </div>
                </CardBody>
              </Card>
            </ModalBody>
          </div>
        </Modal>

        {/* View order */}
        <Modal
          isOpen={modal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={togglemodal}>New Order Request</ModalHeader>
            <ModalBody>
              <SimpleBar style={{ maxHeight: "376px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div>
                          <b>Product Name</b>
                          <br></br>
                          <p>{rproduct}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
            <ModalHeader>Consumables Product Details</ModalHeader>

            <ModalBody>
              <SimpleBar style={{ maxHeight: "250px" }}>
                <ul className="verti-timeline list-unstyled">
                  <li className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div>
                          <b>Product Name</b>
                          <br></br>
                          <p>{mproduct}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div>
                          <b>Order Quantity</b>
                          <br></br>
                          <p>{mproductqty}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </SimpleBar>
            </ModalBody>
          </div>
        </Modal>

        {/* Reject Reason Modal */}
        <Modal
          isOpen={rejectReasonModal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={viewRejectReason}
        >
          <div className="modal-content">
            <ModalHeader toggle={viewRejectReason}>
              Reason for rejection
            </ModalHeader>
            <ModalBody>
              <p> </p>

              <FormGroup className="mb-3">
                <Label htmlFor="">View reason</Label>
                <Input
                  type="textarea"
                  name="product-name"
                  className="form-control"
                  placeholder="Enter Text Here"
                  value={reason}
                  disabled
                />
              </FormGroup>
            </ModalBody>
          </div>
        </Modal>

        {/* ------------------- Write Reason For Rejection------------- */}
        <Modal
          isOpen={rejectModal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={toogelRejectModal}
        >
          <div className="modal-content">
            <ModalHeader toggle={toogelRejectModal}>
              Reason for rejection
            </ModalHeader>
            <ModalBody>
              <p> </p>

              <FormGroup className="mb-3">
                <Label htmlFor="">Write reason</Label>
                <Input
                  type="textarea"
                  name="product-name"
                  className="form-control"
                  placeholder="Enter Text Here"
                  onChange={e => setRejectReason(e.target.value)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button
                disabled={rejectReason?.length <= 0}
                type="button"
                color="primary"
                onClick={reject}
              >
                Submit
              </Button>

              <Button
                type="button"
                onClick={toogelRejectModal}
                color="secondary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
