import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useDispatch } from "react-redux"
import { delearNewProductRequestGet } from "store/actions"
import { DEALERNEWPRODUCTREQUEST, HOSTNAME } from "config/config"
import axios from "axios"
import { toast } from "react-toastify"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  // const onChangeInInput = event => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };

  // Form validation
  const orderRequestValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      productName: "",
      notes: "",
    },
    validationSchema: Yup.object({
      // pid: Yup.string().required("Please Enter Your Product id"),
      productName: Yup.string().required("Please Enter Product Name"),
      notes: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (!buttonClicked) {
        setOrderRequestButtonClicked(true)

        // alert(values)
        let data = new FormData()
        data.append("productName", values.productName)
        data.append("notes", values.notes)

        let url = `${HOSTNAME}api/product-request-email/`
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            Authorization: `${getUserData.userData.token}`,
          },
          data: data,
        }

        axios
          .request(config)
          .then(response => {
            setOrderRequestModal(!orderRequestModal)
            toast.success("Request sent successfully", {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
          .catch(error => { })
        resetForm()
      }
    },
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      productName: "",
      notes: "",
    },
    validationSchema: Yup.object({
      // pid: Yup.string().required("Please Enter Your Product id"),
      productName: Yup.string().required("Please Enter Product Name"),
      notes: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (!buttonClicked) {
        setButtonClicked(true)

        // alert(values)
        let data = new FormData()
        data.append("productName", values.productName)
        data.append("notes", values.notes)

        let url = `${HOSTNAME}${DEALERNEWPRODUCTREQUEST}`
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            Authorization: `${getUserData.userData.token}`,
          },
          data: data,
        }

        axios
          .request(config)
          .then(response => {
            setModal(!modal)
            dispatch(delearNewProductRequestGet({}, {}))
            toast.success("Request sent successfully", {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
          .catch(error => { })
        resetForm()
      }
    },
  })

  const [modal, setModal] = useState(false)
  const [orderRequestModal, setOrderRequestModal] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [orderRequestbuttonClicked, setOrderRequestButtonClicked] =
    useState(false)

  const orderRequestTogglemodal = () => {
    setOrderRequestModal(!orderRequestModal)
    setOrderRequestButtonClicked(false)
  }

  const togglemodal = () => {
    setModal(!modal)
    setButtonClicked(false)
  }

  const dispatch = useDispatch()
  const [query, setquery] = useState("")
  const SearchByPname = e => {
    if (e.target.value) {
      setquery(e.target.value)
      dispatch(delearNewProductRequestGet({ query: e.target.value }, {}))
    } else {
      setquery("")
      dispatch(delearNewProductRequestGet({}, {}))
    }
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${DEALERNEWPRODUCTREQUEST}?query=${query}&page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(delearNewProductRequestGet({ url: url }, {}))
  }
  const onclickForPage = (url, number) => {
    // alert(number)
    setcurrentPage(number)
    dispatch(delearNewProductRequestGet({ url: url }, {}))
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Label className="visually-hidden" htmlFor="autoSizingInput">
                  Search
                </Label>
                <Input
                  type="text"
                  onChange={e => {
                    SearchByPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name"
                />
              </div>
            </Col>
            <Col xl={4} className="mt-1 mb-1"></Col>

            <Col xl={2} className="mt-1 mb-1">
              <button
                type="button"
                onClick={orderRequestTogglemodal}
                className="btn btn-primary"
              >
                NEW PAPER REQUEST
              </button>
            </Col>
            <Col xl={2} className="mt-1 mb-1">
              <button
                type="button"
                onClick={togglemodal}
                className="btn btn-primary"
              >
                NEW PRINTER REQUEST
              </button>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      {/* Order request modal */}
      <Modal
        isOpen={orderRequestModal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={orderRequestTogglemodal}
      >
        <div className="modal-content">
          <Form onSubmit={orderRequestValidation.handleSubmit}>
            <ModalHeader toggle={orderRequestTogglemodal}>
              NEW PAPER REQUEST
            </ModalHeader>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Product Name</Label>
                    <Input
                      name="productName"
                      placeholder="Enter Product Name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={orderRequestValidation.handleChange}
                      onBlur={orderRequestValidation.handleBlur}
                      value={orderRequestValidation.values.productName || ""}
                      invalid={
                        orderRequestValidation.touched.productName &&
                          orderRequestValidation.errors.productName
                          ? true
                          : false
                      }
                    />
                    {orderRequestValidation.touched.productName &&
                      orderRequestValidation.errors.productName ? (
                      <FormFeedback type="invalid">
                        {orderRequestValidation.errors.productName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Notes</Label>
                    <Input
                      name="notes"
                      placeholder="Add Description"
                      type="textarea"
                      className="form-control"
                      id="validationCustom01"
                      onChange={orderRequestValidation.handleChange}
                      onBlur={orderRequestValidation.handleBlur}
                      value={orderRequestValidation.values.notes || ""}
                      invalid={
                        orderRequestValidation.touched.notes &&
                          orderRequestValidation.errors.notes
                          ? true
                          : false
                      }
                    />
                    {orderRequestValidation.touched.notes &&
                      orderRequestValidation.errors.notes ? (
                      <FormFeedback type="invalid">
                        {orderRequestValidation.errors.notes}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="submit" color="primary">
                Submit
              </Button>

              <Button type="button" onClick={togglemodal} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>

      {/* Second modal */}
      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <Form onSubmit={validation.handleSubmit}>
            <ModalHeader toggle={togglemodal}>NEW PRINTER REQUEST</ModalHeader>

            <ModalBody>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Product Name</Label>
                    <Input
                      name="productName"
                      placeholder="Enter Product Name"
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.productName || ""}
                      invalid={
                        validation.touched.productName &&
                          validation.errors.productName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.productName &&
                      validation.errors.productName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.productName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Notes</Label>
                    <Input
                      name="notes"
                      placeholder="Add Description"
                      type="textarea"
                      className="form-control"
                      id="validationCustom01"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.notes || ""}
                      invalid={
                        validation.touched.notes && validation.errors.notes
                          ? true
                          : false
                      }
                    />
                    {validation.touched.notes && validation.errors.notes ? (
                      <FormFeedback type="invalid">
                        {validation.errors.notes}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "left" }}>
              <Button type="submit" color="primary">
                Submit
              </Button>

              <Button type="button" onClick={togglemodal} color="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>

      {/* <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row> */}

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${DEALERNEWPRODUCTREQUEST}?query=${query}&page=${1}`,
                  1
                )
              }
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${DEALERNEWPRODUCTREQUEST}?query=${query}&page=${currentPage - 1
                  }`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${DEALERNEWPRODUCTREQUEST}?query=${query}&page=${currentPage + 1
                  }`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${DEALERNEWPRODUCTREQUEST}?query=${query}&page=${Math.ceil(
                    count / customPageSize
                  )}`,
                  Math.ceil(count / customPageSize)
                )
              }
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
