import React, { Fragment,useState,useEffect } from "react";
import PropTypes from "prop-types";
import {
  InputGroup,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,

} from "react-table";

const reports = [
  { title: "Total Number of Consumables Product sold", iconClass: "bx-purchase-tag-alt", description: "125" },
];

import { Table, Row, Col, Button, Input,Card, CardBody ,Label} from "reactstrap";
import { Filter, DefaultColumnFilter } from "components/Common/filters";
import JobListGlobalFilter from "components/Common/GlobalSearchFilter";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import "react-datepicker/dist/react-datepicker.css"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import { Link } from "react-router-dom";

import { ADMINEARNINGMPDOWNLOAD,ADMINEARNINGMPDATAGET, HOSTNAME } from "config/config";
import { adminEarningMPDataGet } from "store/actions";
import { useSelector, useDispatch } from "react-redux"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>

      </Col>
      {isJobListGlobalFilter && (
        <JobListGlobalFilter />
      )}

    </React.Fragment>
  );
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  result,
  count,
  next,
  previous,

}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const reports = [
    { title: "Total Number of Consumables Product sold", iconClass: "bx-purchase-tag-alt", description:  result && result.MPD_total_sold},
  ];

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const dispatch = useDispatch()
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchData, setsearchData] = useState("");
  // const handleDateChange = (dates) => {
  //   console.log(dates)
  //   setSelectedDateRange(dates);
  //   const startDate = dates[0].toLocaleDateString();
  //   const endDate = dates[1].toLocaleDateString();
  //   dispatch(adminEarningMPDataGet({"userType":"DC","startDate":startDate,"endDate":endDate}, {}))
  // };

  const handleDateChange = (dates) => {
    console.log(dates)
    setSelectedDateRange(dates);
  
    if (dates[0] && dates[1]) {
      const startDate = dates[0].toLocaleDateString();
      const endDate = dates[1].toLocaleDateString();
  
      let newq = {
        startDate: startDate,
        endDate: endDate
      };
      dispatch(adminEarningMPDataGet({"userType":"DC","startDate":startDate,"endDate":endDate}, {}))
    } else {
      console.error("Invalid date format");
    }
  };

  const clearData = (e)=>{
    setSelectedDateRange(null)
    setsearchData("")
    dispatch(adminEarningMPDataGet({"userType":"DC"}, {}))
    
  }

  const SearchByPname =e =>{
    if(e.target.value){
      setsearchData(e.target.value)
      if(selectedDateRange == null){
        dispatch(adminEarningMPDataGet({"userType":"DC","query":e.target.value}, {}))
      }
      else{
        let startDate = selectedDateRange[0].toLocaleDateString();
        let endDate = selectedDateRange[1].toLocaleDateString();
        dispatch(adminEarningMPDataGet({"userType":"DC","query":e.target.value,"startDate":startDate,"endDate":endDate}, {}))
      }
    }
    else{
      setsearchData("")
      dispatch(adminEarningMPDataGet({"userType":"DC"}, {}))
    }
  }

  const [currentPage,setcurrentPage]=useState(1)
  const [isNext,setisNext]=useState(true)
  const [isPast,setisPast]=useState(true)
  const TotalPages = Math.ceil(count/customPageSize)
  
  useEffect(()=>{
    if(next && next !=null ){
      setisNext(false)
    }
    if(previous && previous !=null){
      setisPast(false)
    }
    if(currentPage<TotalPages){
      setisNext(false)
    }
    else{
      setisNext(true)
    }
    if(currentPage==1){
      setisPast(true)
    }
    else{
      setisPast(false)
    }
  })

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${ADMINEARNINGMPDATAGET}?page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(adminEarningMPDataGet({"url":url}, {}))
  };

  return (
    <Fragment>

       <Row className="mb-4">
        <Col xl={12}>
            <Row>

            
            {/* <Col lg={6}> */}
                <Col xl={4}>
                <div className="col-sm-auto">
                      <Input type="text" value={searchData}  onChange={e =>{SearchByPname(e)}} className="form-control" id="autoSizingInput" placeholder="Search by name..." />
                </div>
                </Col>

                <Col xl={2} className="mb-3">
    
                <Flatpickr
                            className="form-control d-block"
                            placeholder="Date Range"
                            onChange={handleDateChange}
                            value={selectedDateRange}
                            options={{
                              mode: "range",
                              // dateFormat: "Y-m-d",
                              dateFormat: "d-m-Y",
                              
                            }}
                          />
                </Col>
 
                <Col xl={1}>
                <div className="col-sm-auto">
                      <button onClick={e =>clearData(e) } type="button" className="btn btn-primary">Clear</button>
                    </div>
                </Col>

                  <Col xl={3}>
                </Col>

                <Col xl={1}>
                <div className="col-sm-auto mt-2">
                <a href={`${HOSTNAME}${ADMINEARNINGMPDOWNLOAD}?userType=DC&dType=MP`}>Download</a>
                    </div>
                </Col>

                
                <Col xl={1}>
                <div className="col-sm-auto mt-2">
                    <Link to="/admin-manufactured-products">Go Back</Link>
                    </div>
                </Col>
            {/* </Col> */}
            

            </Row>   
        </Col>
       
        </Row> 

        <Row>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className=" mt-3 mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
     

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}> 
              {headerGroup.headers.map(column => (
                    <th key={column.id}>
                           {column.render("Header")}
                    </th>
                ))}

            </tr>
            
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
      <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
            type="button"
              color="primary"
              onClick={(e) => onclickForPage(`${HOSTNAME}${ADMINEARNINGMPDATAGET}?page=${1}`,1)}
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
            type="button"
              color="primary"
              onClick={e =>{onclickForPage(next,currentPage-1)}}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count/customPageSize)}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count/customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" type="button" onClick={e=>{onclickForPage(next,currentPage+1)}} disabled={isNext}>
              {">"}
            </Button>
            <Button
            type="button"
              color="primary"
              onClick={(e) => onclickForPage(`${HOSTNAME}${ADMINEARNINGMPDATAGET}?page=${Math.ceil(count/customPageSize)}`,Math.ceil(count/customPageSize))}
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
