// src/components/filter.
import React, { useState, Fragment, useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./neworderrequesttablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerNewProductRequestListGet } from "store/actions"
import { useEffect } from "react"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

function extractIdAndPName(item) {
  return {
    id: item.id,
    productName: item.productName,
    notes: item.notes,
    status: item.status,
    created_at: item.created_at,
    updated_at: item.updated_at,
  }
}
function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row)}>
                  View Request
                </Link>
              </Col>
            </Row>
          )
        },
      },
    ],
    []
  )

  //--
  let data = []
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(customerNewProductRequestListGet({}, {}))
  }, [])

  const customerDatas = useSelector(state => state.customerProductGet)

  if (customerDatas && customerDatas.customernewproductrequestlist) {
    data =
      customerDatas.customernewproductrequestlist.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "New Orders"
  const [modal, setModal] = useState(false)

  const togglemodal = () => {
    setModal(!modal)
  }
  const [obj, setobj] = useState({})
  const togglemodal1 = row => {
    setModal(!modal)
    setobj(row)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="New Orders" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          count={
            customerDatas.customernewproductrequestlist &&
            customerDatas.customernewproductrequestlist.count
          }
          next={
            customerDatas.customernewproductrequestlist &&
            customerDatas.customernewproductrequestlist.next
          }
          previous={
            customerDatas.customernewproductrequestlist &&
            customerDatas.customernewproductrequestlist.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      {/* modal */}
      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>View Product Request</ModalHeader>

          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Product Name</Label>
                  <Input
                    name="productname"
                    placeholder="Enter Product Name"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    disabled
                    value={obj && obj.productName}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Notes</Label>
                  <Input
                    name="notes"
                    placeholder="Add Description"
                    type="textarea"
                    className="form-control"
                    id="validationCustom01"
                    disabled
                    value={obj && obj.notes}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
