// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./newcustomermastertablecontainer"
import { newCustomerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

function extractIdAndPName(item) {
  return {
    id: item.id,
    firstName: item.firstName,
    dealer: item.dealer,
    city: item.city,
  }
}

function DatatableTablesNewCustomerMaster() {
  const columns = useMemo(
    () => [
      // {
      //     Header: ' ',
      //     filterable: false,
      //     Cell: () => {
      //         return <input type="checkbox" className="form-check-input" />;
      //       }
      // },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Customer Name",
        accessor: "firstName",
      },
      // {
      //     Header: 'Delear Name',
      //     accessor: 'dealer'
      // },
      {
        Header: "City",
        accessor: "city",
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(newCustomerGet({}, {}))
  }, [])

  const newCustomerData = useSelector(state => state.CustomerGet)

  if (newCustomerData && newCustomerData.customerDetails) {
    data = newCustomerData.customerDetails.results.data.map(extractIdAndPName)
  }

  //meta title
  document.title = "Direct Customer Master"

  return (
    <Loadder active={newCustomerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Dealers" />

          <TableContainer
            count={newCustomerData.customerDetails.count}
            next={newCustomerData.customerDetails.next}
            previous={newCustomerData.customerDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesNewCustomerMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesNewCustomerMaster
