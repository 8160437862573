import {
  DELEARPRODUCT_GET,
  DELEARPRODUCT_SUCCESS,
  DELEAR_UPLOAD_POST,
  DELEAR_UPLOAD_SUCCESS,
  DEALERLIST_GET,
  DEALERLIST_SUCCESS,
  TAGGEDDEALER_GET,
  TAGGEDDEALER_SUCCESS,
  DEALER_NEW_PRODUCT_REQUEST_GET,
  DEALER_NEW_PRODUCT_REQUEST_SUCCESS,
  DEALER_MANUFACTURE_ORDERS_GET,
  DEALER_MANUFACTURE_ORDERS_SUCCESS,
  DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET,
  DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_SUCCESS,
  DEALER_CUSTOMER_GET,
  DEALER_CUSTOMER_SUCCESS,
  DEALER_SERVICES_GET,
  DEALER_SERVICES_SUCCESS,
  DEALER_BUYBACK_GET,
  DEALER_BUYBACK_SUCCESS,
  DEALER_RESELLINGEARNING_GET,
  DEALER_RESELLINGEARNING_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const delearResellingEarningGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALER_RESELLINGEARNING_GET,
    payload: { query, history },
  }
}

export const delearResellingEarningSuccess = data => {
  return {
    type: DEALER_RESELLINGEARNING_SUCCESS,
    payload: data,
  }
}
//-------

export const delearBuyBackGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALER_BUYBACK_GET,
    payload: { query, history },
  }
}

export const delearBuyBackSuccess = data => {
  return {
    type: DEALER_BUYBACK_SUCCESS,
    payload: data,
  }
}
//-------
export const delearServicesGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALER_SERVICES_GET,
    payload: { query, history },
  }
}

export const delearServicesSuccess = data => {
  return {
    type: DEALER_SERVICES_SUCCESS,
    payload: data,
  }
}

export const delearCustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALER_CUSTOMER_GET,
    payload: { query, history },
  }
}

export const delearCustomerSuccess = data => {
  return {
    type: DEALER_CUSTOMER_SUCCESS,
    payload: data,
  }
}

export const delearResellingTagProductCustomerGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_GET,
    payload: { query, history },
  }
}

export const delearResellingTagProductCustomerSuccess = data => {
  return {
    type: DEALER_RESELLING_TAG_PRODUCT_CUSTOMER_SUCCESS,
    payload: data,
  }
}

export const delearManufactureOrdersGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALER_MANUFACTURE_ORDERS_GET,
    payload: { query, history },
  }
}

export const delearManufactureOrdersSuccess = data => {
  return {
    type: DEALER_MANUFACTURE_ORDERS_SUCCESS,
    payload: data,
  }
}

export const delearNewProductRequestGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALER_NEW_PRODUCT_REQUEST_GET,
    payload: { query, history },
  }
}

export const delearNewProductRequestSuccess = data => {
  return {
    type: DEALER_NEW_PRODUCT_REQUEST_SUCCESS,
    payload: data,
  }
}

export const delearProductGet = (query, history) => { 
  // alert("action")
  return {
    type: DELEARPRODUCT_GET,
    payload: { query, history },
  }
}

export const delearProductSuccess = data => {
  return {
    type: DELEARPRODUCT_SUCCESS,
    payload: data,
  }
}

export const delearUploadGet = (fileData, history) => {
  // alert("action")
  return {
    type: DELEAR_UPLOAD_POST,
    payload: { fileData, history },
  }
}

//  Dealer List 

export const dealerListGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALERLIST_GET,
    payload: { query, history },
  }
}

export const dealerListSuccess = data => {
  return {
    type: DEALERLIST_SUCCESS,
    payload: data,
  }
}

//----------Tagged Dealer----------

export const taggeddealerGet = (query, history) => { 
  // alert("action")
  return {
    type: TAGGEDDEALER_GET,
    payload: { query, history },
  }
}

export const taggeddealerSuccess = data => {
  return {
    type: TAGGEDDEALER_SUCCESS,
    payload: data,
  }
}



