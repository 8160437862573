import {
  ADMINDASHBOARD_DATA_GET,
  ADMINDASHBOARD_DATA_SUCCESS
} from "./actionTypes"

export const adminDashboardDataGet = (query, history) => { 
  // alert("action")
  return {
    type: ADMINDASHBOARD_DATA_GET,
    payload: { query, history },
  }
}

export const adminDashboardDataSuccess = data => {
  return {
    type: ADMINDASHBOARD_DATA_SUCCESS,
    payload: data,
  }
}