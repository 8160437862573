export const PARENT_RESELLINGPRODUCT_GET="PARENT_RESELLINGPRODUCT_GET"
export const PARENT_RESELLINGPRODUCT_SUCCESS="PARENT_RESELLINGPRODUCT_SUCCESS"

export const PARENT_RESELLINGPRODUCT_ADD="PARENT_RESELLINGPRODUCT_ADD"
export const PARENT_RESELLINGPRODUCT_ADD_SUCCESS="PARENT_RESELLINGPRODUCT_ADD_SUCCESS"

export const PARENT_RESELLINGPRODUCT_UPLOAD_POST="PARENT_RESELLINGPRODUCT_UPLOAD_POST"
export const PARENT_RESELLINGPRODUCT_UPLOAD_SUCCESS="PARENT_RESELLINGPRODUCT_UPLOAD_SUCCESS"
//-----
export const RESELLINGPRODUCT_GET="RESELLINGPRODUCT_GET"
export const RESELLINGPRODUCT_SUCCESS="RESELLINGPRODUCT_SUCCESS"

export const RESELLINGPRODUCT_ADD="RESELLINGPRODUCT_ADD"
export const RESELLINGPRODUCT_ADD_SUCCESS="RESELLINGPRODUCT_ADD_SUCCESS"

export const RESELLINGCHILDPRODUCT_ADD="RESELLINGCHILDPRODUCT_ADD"
export const RESELLINGCHILDPRODUCT_ADD_SUCCESS="RESELLINGCHILDPRODUCT_ADD_SUCCESS"

export const RESELLINGPRODUCT_UPLOAD_POST="RESELLINGPRODUCT_UPLOAD_POST"
export const RESELLINGPRODUCT_UPLOAD_SUCCESS="RESELLINGPRODUCT_UPLOAD_SUCCESS"

export const TAGLISTOFCUSTOMER_GET="TAGLISTOFCUSTOMER_GET"
export const TAGLISTOFCUSTOMER_SUCCESS="TAGLISTOFCUSTOMER_SUCCESS"

export const TAGLISTOFDEALER_GET="TAGLISTOFDEALER_GET"
export const TAGLISTOFDEALER_SUCCESS="TAGLISTOFDEALER_SUCCESS"


export const NEWTAGLISTOFDEALER_GET="NEWTAGLISTOFDEALER_GET"
export const NEWTAGLISTOFDEALER_SUCCESS="NEWTAGLISTOFDEALER_SUCCESS"
// export const TAGTODEALER_GET="TAGTODEALER_GET"
// export const TAGTODEALER_SUCCESS="TAGTODEALER_SUCCESS"

export const RESELLINGTAGTODEALER_GET="RESELLINGTAGTODEALER_GET"
export const RESELLINGTAGTODEALER_SUCCESS="RESELLINGTAGTODEALER_SUCCESS"

export const TAGCUSTOMER_GET="TAGCUSTOMER_GET"
export const TAGCUSTOMER_SUCCESS="TAGCUSTOMER_SUCCESS"

// View Equipment Product

export const VIEWRESELLINGPRODUCT_GET="VIEWRESELLINGPRODUCT_GET"
export const VIEWRESELLINGPRODUCT_SUCCESS="VIEWRESELLINGPRODUCT_SUCCESS"


export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"


// ***********************************************************
//                         DEALER 
// ***********************************************************

// RESELLING PRODUCT

export const TAGPRODUCTFROMADMIN_GET="TAGPRODUCTFROMADMIN_GET"
export const TAGPRODUCTFROMADMIN_SUCCESS="TAGPRODUCTFROMADMIN_SUCCESS"

export const DEALER_RESELLINGPRODUCTLIST_GET="DEALER_RESELLINGPRODUCTLIST_GET"
export const DEALER_RESELLINGPRODUCTLIST_SUCCESS="DEALER_RESELLINGPRODUCTLIST_SUCCESS"

export const DELEAR_TAGTOCUSTOMER_GET="DELEAR_TAGTOCUSTOMER_GET"
export const DELEAR_TAGTOCUSTOMER_SUCCESS="DELEAR_TAGTOCUSTOMER_SUCCESS"
