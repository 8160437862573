import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import axios from "axios"
import {
  InputGroup,
  Flatpickr,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
  Button,
  Input,
  Card,
  Form,
  CardSubtitle,
  CardBody,
  Label,
} from "reactstrap"
import { Filter, DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useSelector, useDispatch } from "react-redux"
import {
  existingCustomerGet,
  existingCustomerUploadGet,
} from "../../../src/store/actions"
import {
  HOSTNAME,
  DOWNLOADCSV,
  EXISTINGCUSTOMERGET,
  DELEARLISTGET,
} from "config/config"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              {/* <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              /> */}
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  const [query, setquery] = useState("")
  const dispatch = useDispatch()
  const SearchByMPname = e => {
    if (e.target.value) {
      setquery(e.target.value)
      dispatch(existingCustomerGet({ query: e.target.value }, {}))
    } else {
      setquery("")
      dispatch(existingCustomerGet({}, {}))
    }
  }

  const [selectedFiles, setselectedFiles] = useState([])
  const [isOpen, setisOpen] = useState(false)
  const toggle1 = () => setisOpen(!isOpen)
  const [dealerTempData, setdealerTempData] = useState([])

  const [selectedValue, setSelectedValue] = useState("")

  const handleSelectChange = event => {
    setSelectedValue(event.target.value)
  }

  const toggle12 = () => {
    setisOpen(!isOpen)
    let url = `${HOSTNAME}${DELEARLISTGET}`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      // url: 'http://82.180.130.182:8000/api/get-dealer-list/',
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        console.log(response.data)
        setdealerTempData(response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const SendFile = e => {
    let data = new FormData()
    data.append("file", selectedFiles[0])
    data.append("dealerId", selectedValue)
    dispatch(existingCustomerUploadGet({ data: data }, {}))
    dispatch(existingCustomerGet({}, {}))
    setselectedFiles([])
    toggle1()
  }

  const onclickForPage = (url, number) => {
    // alert(number)
    setcurrentPage(number)
    dispatch(existingCustomerGet({ url: url }, {}))
  }

  const [currentPage, setcurrentPage] = useState(1)
  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)
  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  // File Uploading Function
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const onChangeInInput = event => {
    // const page = event.target.value ? Number(event.target.value) - 1 : 0;
    let url = `${HOSTNAME}${EXISTINGCUSTOMERGET}?query=${query}&type=1&page=${event.target.value}`
    setcurrentPage(event.target.value)
    dispatch(existingCustomerGet({ url: url }, {}))
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row>
            {/* <Col lg={6}> */}
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Input
                  type="text"
                  onChange={e => {
                    SearchByMPname(e)
                  }}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by name..."
                />
              </div>
            </Col>

            <Col xl={6}></Col>
            <Col xl={1} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <button
                  onClick={e => toggle12(e)}
                  type="button"
                  className="btn btn-primary"
                >
                  Upload
                </button>
              </div>
            </Col>
            <Col xl={1} className="mt-1 mb-1">
              <div className="col-sm-auto mt-2">
                <a href={`${HOSTNAME}${DOWNLOADCSV}?usertype=DC`}>Download</a>
              </div>
            </Col>
            {/* </Col> */}
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {/* {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))} */}

            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              type="button"
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${EXISTINGCUSTOMERGET}?query=${query}&type=1&page=${1}`,
                  1
                )
              }
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${EXISTINGCUSTOMERGET}?query=${query}&type=1&page=${currentPage - 1
                  }`,
                  currentPage - 1
                )
              }}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            // defaultValue={currentPage}
            value={currentPage}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={e => {
                onclickForPage(
                  `${HOSTNAME}${EXISTINGCUSTOMERGET}?query=${query}&type=1&page=${currentPage + 1
                  }`,
                  currentPage + 1
                )
              }}
              disabled={isNext}
            >
              {">"}
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={e =>
                onclickForPage(
                  `${HOSTNAME}${EXISTINGCUSTOMERGET}?query=${query}&type=1&page=${Math.ceil(
                    count / customPageSize
                  )}`,
                  Math.ceil(count / customPageSize)
                )
              }
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>

        <Modal
          isOpen={isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={toggle1}
        >
          <div className="modal-content">
            <ModalHeader toggle={toggle1}>Upload CSV File</ModalHeader>
            <Form>
              <ModalBody>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <Col>
                        <div className="col-sm-auto">
                          <select
                            onChange={handleSelectChange}
                            className="form-select"
                          >
                            <option value="" disabled selected hidden>
                              Select Dealer
                            </option>
                            {dealerTempData.map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <CardBody>
                        <h6 className="card-title">Dealer`s Customer Master</h6>

                        <CardSubtitle className="mb-3">
                          {" "}
                          Upload Only CSV File.
                        </CardSubtitle>

                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle1}>
                  Close
                </Button>
                <Button
                  disabled={selectedFiles.length === 0}
                  type="button"
                  onClick={e => {
                    SendFile(e)
                  }}
                  color="primary"
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
