import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © SonaStar.</Col>
            <Col md={6}>
              <span className="d-flex justify-content-end align-items-cente">
                Powered By&nbsp;
                <a
                  href="https://digiglobeads.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="p-0"
                >
                  digiglobeads
                </a>
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
