// src/components/filter.
import React, {useState, useMemo,useEffect } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from './demochildresellingProductMastertablecontainer';
import { resellingProductGet } from "../../../src/store/actions"
import {

    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Form,
    Input,
    FormFeedback,
    Label,
  } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Loadder from "components/Loadder/Loadder";
import {checkTokenExpiration} from '../../../src/config/getTokenExpirationTime'
function extractIdAndPName(item) {
    return {
        id: item.id,
        pname: item.pname,
        pnumber:item.pnumber,
        serialNumbers:item.serialNumbers
    };
}

function DatatableTablesDemoResellingMaster() {

    // const [resellingProductData,setresellingProductData]=useState([])
    const columns = useMemo(
        () => [
            // {
            //     Header: ' ',
            //     filterable: false,
            //     Cell: () => {
            //         return <input type="checkbox" className="form-check-input" />;
            //       }
            // },
            {
                Header: 'Sr.No',
                accessor: 'id',
            },
            {
                Header: 'Product Name',
                accessor: 'pname'
            },
            {
                Header: 'Product Number',
                accessor: 'pnumber'
            },
            {
                Header: 'Product Serial Numbers',
                accessor: 'serialNumbers'
            },
            
        ],
        []
    );
    let data =[]
    const dispatch = useDispatch()
    //meta title
    document.title = "Demo Equipments Product Master";
    useEffect(()=>{
        const isUserLoggedIn = localStorage.getItem('isLoggedIn');
        if (!isUserLoggedIn) {
            localStorage.setItem('isLoggedIn', 'true');
            window.location.reload();
        }

        checkTokenExpiration()
        dispatch(resellingProductGet({"isdemoproduct":1}, {}))
    },[])

    const resellingProductData =useSelector(state => state.resellingProductGet)
    console.log("==========::::",resellingProductData)
    if(resellingProductData && resellingProductData.resellingProductDetails){
        data = resellingProductData.resellingProductDetails.results.data.map(extractIdAndPName);   
    }
    // console.log("resellingProductData.resellingProductDetails",resellingProductData)
    return (
        <Loadder active={resellingProductData.loading}>
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Tables" breadcrumbItem={document.title} />
                <Row>
                    <Col lg={10}>
                    </Col>
                  
                </Row>
                
                <TableContainer
                    count={resellingProductData.resellingProductDetails.count}
                    next={resellingProductData.resellingProductDetails.next}
                    previous={resellingProductData.resellingProductDetails.previous}
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>

           
        </div>
        </Loadder>
    );
}
DatatableTablesDemoResellingMaster.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTablesDemoResellingMaster;