// src/components/filter.
import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./demoproductlisttablecontainer"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { dealer_resellingproductlistGet } from "store/actions"
import { useEffect } from "react"

function extractIdAndPName(item) {
  return {
    id: item.id,
    pname: `${item.product && item.product.pname}`,
    pnumber: `${item.product && item.product.pnumber}`,
    manufactured: item.manufactureData && item.manufactureData,
    serialNumbers: `${item.product && item.product.serialNumbers}`,
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "pname",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => togglemodal1(row.original)}>
                  View Consumables Products
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem>
                  <Link
                    to="/delear-demo-product-tag-to-customer"
                    state={{ id: cellProps.row.original.id }}
                  >
                    Tagged To Customer
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(dealer_resellingproductlistGet({ isdemo: 1 }, {}))
  }, [])

  const dealer_resellingproductlistData = useSelector(
    state => state.resellingProductGet
  )

  if (
    dealer_resellingproductlistData &&
    dealer_resellingproductlistData.resellingProductDetails
  ) {
    data =
      dealer_resellingproductlistData.resellingProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Demo Product"

  const [modal, setModal] = useState(false)

  const togglemodal = () => {
    setModal(!modal)
  }
  const [objData, setobjData] = useState({})
  const togglemodal1 = row => {
    let fdata = { id: row.id, pname: row.pname, manufactured: row.manufactured }

    setModal(!modal)
    setobjData(fdata)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Demo Products" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          // columns={columns}
          // data={data}
          count={dealer_resellingproductlistData.resellingProductDetails.count}
          next={dealer_resellingproductlistData.resellingProductDetails.next}
          previous={
            dealer_resellingproductlistData.resellingProductDetails.previous
          }
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        centered
        id="verificationModal"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>
            Equipment Product Details
          </ModalHeader>
          <ModalBody>
            <SimpleBar style={{ maxHeight: "376px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <b>Product Name</b>
                        <br></br>
                        <p>{objData && objData.pname}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
          <ModalHeader>Consumables Product Details</ModalHeader>

          <ModalBody>
            <SimpleBar style={{ maxHeight: "250px" }}>
              <ul className="verti-timeline list-unstyled">
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                  </div>
                  {objData.manufactured && objData.manufactured.length > 0 ? (
                    <>
                      {objData.manufactured &&
                        objData.manufactured.map(item => (
                          <div key={item.id} className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <b>Product Name</b>
                                {/* <p>{item.mpname}</p> */}
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.mpname}
                                  </span>
                                  <span style={{ color: "#777" }}> X </span>
                                  <span style={{ color: "#555" }}>
                                    {" "}
                                    {item.mpquantity}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <b>Product Name</b>
                            <br></br>
                            <p>Data Not Found</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </li>
              </ul>
            </SimpleBar>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
