// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import img2 from "../../assets/images/small/img-6.jpg"
import SimpleBar from "simplebar-react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./servicecentriccustomertablecontainer"
import { servicecentriccustomerGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  FormFeedback,
  ModalFooter,
  Table,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  UncontrolledAlert,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import { Link } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import { HOSTNAME, SERVICECENTRICCUSTOMERGET } from "config/config"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
import axios from "axios"

function extractIdAndPName(item) {
  return {
    id: item.id,
    firstName: item.firstName,
    contactNumber: item.contactNumber,
    productName: item.productName,
    serviceStatus: item.serviceStatus,
    imgdata: item.imgdata,
    serviceStatustext: item.serviceStatustext,
    pnumber: item.pnumber,
    serialNumbers: item.serialNumbers,
    type: item.type,

    // city:item.city,
    // types:item.types,
    // lastName:item.lastName,
    // pid:item.pid,
    // pnumber:item.pnumber,
    // message:item.message,
    // dealerfirstName:item.dealer && item.dealer.firstName,
    // dealercontactNumber:item.dealer && item.dealer.contactNumber
  }
}

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "srno",
        Cell: cellProps => {
          return <span>{cellProps.row.original.id}</span>
        },
      },
      {
        Header: "Customer Name",
        accessor: "firstName",
      },
      {
        Header: "Customer Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Number",
        accessor: "pnumber",
      },
      {
        Header: "Product Serial Number",
        accessor: "serialNumbers",
      },
      {
        Header: "Status",
        accessor: "serviceStatustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <Row>
              <Col xl={12}>
                <Link to="#" onClick={e => imagtoggle1(e, row)}>
                  View Image
                </Link>
              </Col>
            </Row>
          )
        },
      },
      {
        Header: "  ",
        Cell: cellProps => {
          const row = cellProps.row
          return (
            <UncontrolledDropdown>
              {/* <Row>
                    <Col xl={12}>
                        <a href="">View details</a>
                    </Col>
                    </Row> */}
              {row.original.serviceStatus == 0 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={e =>
                        updateSTatus(1, row.original.id, row.original.type)
                      }
                    >
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      On Going
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        On Going
                      </UncontrolledTooltip>
                    </DropdownItem>

                    <DropdownItem
                      onClick={e =>
                        updateSTatus(2, row.original.id, row.original.type)
                      }
                    >
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      Completed
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Completed
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : row.original.serviceStatus == 1 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={e =>
                        updateSTatus(2, row.original.id, row.original.type)
                      }
                    >
                      <i
                        className="font-size-16 text-success me-1"
                        id="edittooltip"
                      ></i>
                      Completed
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Completed
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : row.original.serviceStatus == 2 ? (
                <>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={e =>
                        updateSTatus(3, row.original.id, row.original.type)
                      }
                    >
                      <i
                        className=" font-size-16 text-danger me-1"
                        id="deletetooltip"
                      ></i>
                      Marke as a Closed
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Marke as a Closed
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </>
              ) : (
                ""
              )}
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  const updateSTatus = (status, id, types) => {
    // alert(status)
    // alert(id)
    let url = `${HOSTNAME}${SERVICECENTRICCUSTOMERGET}?status=${status}&id=${id}`
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        console.log(JSON.stringify(response.data))
        dispatch(servicecentriccustomerGet({ type: types }, {}))
      })
      .catch(error => {
        console.log(error)
      })
  }

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(servicecentriccustomerGet({ type: "AC" }, {}))
  }, [])

  const servicecentriccustomerData = useSelector(state => state.serviceGet)

  if (servicecentriccustomerData && servicecentriccustomerData.serviceDetails) {
    data =
      servicecentriccustomerData.serviceDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Service"

  const [imgmodal, setImgmodal] = useState(false)
  const [imgurl, setImgurl] = useState("")

  const imagtoggle = () => {
    setImgmodal(!imgmodal)
  }

  const imagtoggle1 = (e, row) => {
    setImgurl(row.original.imgdata)
    setImgmodal(!imgmodal)
  }

  return (
    <Loadder active={servicecentriccustomerData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Services" />
          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={servicecentriccustomerData.serviceDetails.count}
            next={servicecentriccustomerData.serviceDetails.next}
            previous={servicecentriccustomerData.serviceDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        </div>

        <Modal
          isOpen={imgmodal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered
          id="verificationModal"
          toggle={imagtoggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={imagtoggle}></ModalHeader>
            <ModalBody>
              <Card>
                <CardBody>
                  <div className="">
                    <img
                      src={imgurl}
                      className="img-fluid"
                      alt="Responsive image"
                    />
                  </div>
                  <br />
                  {/* <p className="card-title-desc">This is a wider card with supporting text below as a natural lead-in to 
                                        additional content. This content is a little bit longer.</p> */}
                </CardBody>
              </Card>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </Loadder>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
