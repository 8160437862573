import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input } from "reactstrap"
import { Modal, ModalBody } from "reactstrap"
import { HOSTNAME } from "config/config"
import { toast } from "react-toastify"
import axios from "axios"
const getUserData = JSON.parse(localStorage.getItem("authUser"))

const TableContainer = ({
  columns,
  data,
  customPageSize,
  className,
  count,
  next,
  previous,
  removeCheckbox,
  removeDelete,
  rightSide,
  onSearch,
  goToNextPage,
  goToLastPage,
  goToPreviousPage,
  goToFirstPage,
  currentPage,
  getInputPaginationData,
  getData,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        //   defaultColumn: { Filter: DefaultColumnFilter },
        initialState: {
          pageIndex: 0,
          pageSize: customPageSize,
        },
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination
    )

  const [isNext, setisNext] = useState(true)
  const [isPast, setisPast] = useState(true)

  const TotalPages = Math.ceil(count / customPageSize)

  useEffect(() => {
    if (next && next != null) {
      setisNext(false)
    }
    if (previous && previous != null) {
      setisPast(false)
    }
    if (currentPage < TotalPages) {
      setisNext(false)
    } else {
      setisNext(true)
    }
    if (currentPage == 1) {
      setisPast(true)
    } else {
      setisPast(false)
    }
  })

  const [mdldelete, setMdldelete] = useState(false)
  const [selectNumber, setSelectNumber] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const deletetoggle = () => {
    setMdldelete(!mdldelete)
  }

  const HandleSelectBox = (e, val) => {
    if (e.target.checked) {
      setSelectNumber([...selectNumber, val])
    } else {
      setSelectNumber(selectNumber.filter(item => item !== val))
    }
  }

  const deletetoggle1 = val => {
    if (val == "yes") {
      if (selectNumber.length === 0) {
        toast.error("Please select first Data", {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        let url = `${HOSTNAME}api/add-inventory-manufacture-product/`

        let data = JSON.stringify({ id: selectNumber })

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${getUserData.userData.token}`,
          },
          data: data,
        }

        axios
          .request(config)
          .then(response => {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            getData()
          })
          .catch(error => {
            console.log(error)
          })
        setSelectAll(!selectAll)
      }
    }

    setMdldelete(!mdldelete)
  }

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectNumber([])
    } else {
      const allRowIds = page.map(row => row.original.id)
      setSelectNumber(allRowIds)
    }

    setSelectAll(!selectAll)
  }

  return (
    <Fragment>
      <Row className="mb-4">
        <Col xl={12}>
          <Row className="d-flex align-items-center">
            {/* <Col lg={6}> */}
            <Col xl={4} className="mt-1 mb-1">
              <div className="col-sm-auto">
                <Input
                  type="text"
                  onChange={onSearch}
                  className="form-control"
                  id="autoSizingInput"
                  placeholder="Search by product name..."
                />
              </div>
            </Col>

            <Col xl={6}></Col>
            <Col xl={1}>{rightSide && rightSide}</Col>
            {removeDelete ? null : (
              <Col xl={1} className="mt-1 mb-1">
                <div className="col-sm-auto">
                  <button
                    type="button"
                    onClick={deletetoggle}
                    className="btn btn-primary"
                    disabled={selectNumber?.length <= 0}
                  >
                    Delete
                  </button>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {removeCheckbox ? null : (
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                )}

                {headerGroup.headers.map(column => (
                  <th key={column.id}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {removeCheckbox ? null : (
                      <td>
                        <input
                          checked={selectNumber.includes(row.original.id)}
                          name="selectNumber"
                          onChange={e => HandleSelectBox(e, row.original.id)}
                          type="checkbox"
                          className="form-check-input"
                        />
                      </td>
                    )}
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              type="button"
              color="primary"
              onClick={goToFirstPage}
              disabled={isPast}
            >
              {"<<"}
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={goToPreviousPage}
              disabled={isPast}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {currentPage} of {Math.ceil(count / customPageSize) || 1}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={Math.ceil(count / customPageSize)}
            defaultValue={currentPage}
            value={currentPage}
            onChange={getInputPaginationData}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              type="button"
              onClick={goToNextPage}
              disabled={isNext}
            >
              {">"}
            </Button>

            <Button
              type="button"
              color="primary"
              onClick={goToLastPage}
              disabled={isNext}
            >
              {">>"}
            </Button>
          </div>
        </Col>

        {/* // Delete Model */}
        <Modal
          size="sm"
          isOpen={mdldelete}
          toggle={deletetoggle}
          centered={true}
        >
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button
                type="button"
                onClick={deletetoggle}
                className="btn-close position-absolute end-0 top-0 m-3"
              ></button>
              <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to permanently erase the job.
              </p>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deletetoggle1("yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => deletetoggle1("no")}
                >
                  No
                </button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
