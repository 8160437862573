import {
  DEALERDASHBOARD_DATA_GET,
  DEALERDASHBOARD_DATA_SUCCESS
} from "./actionTypes"

export const dealerDashboardDataGet = (query, history) => { 
  // alert("action")
  return {
    type: DEALERDASHBOARD_DATA_GET,
    payload: { query, history },
  }
}

export const dealerDashboardDataSuccess = data => {
  return {
    type: DEALERDASHBOARD_DATA_SUCCESS,
    payload: data,
  }
}