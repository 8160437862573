/** Get Dashboard Chart data */
export const TAGGED_PRODUCTCUSTOMER_SUCCESS = "TAGGED_PRODUCTCUSTOMER_SUCCESS";
export const TAGGED_PRODUCTCUSTOMER_GET = "TAGGED_PRODUCTCUSTOMER_GET";

export const CUSTOMER_VERIFY_PRODUCT_LIST_GET = "CUSTOMER_VERIFY_PRODUCT_LIST_GET";
export const CUSTOMER_VERIFY_PRODUCT_LIST_SUCCESS = "CUSTOMER_VERIFY_PRODUCT_LIST_SUCCESS";

export const CUSTOMER_ACTIVATED_PRODUCT_LIST_GET = "CUSTOMER_ACTIVATED_PRODUCT_LIST_GET";
export const CUSTOMER_ACTIVATED_PRODUCT_LIST_SUCCESS = "CUSTOMER_ACTIVATED_PRODUCT_LIST_SUCCESS";

export const CUSTOMER_BUYBACK_PRODUCT_LIST_GET = "CUSTOMER_BUYBACK_PRODUCT_LIST_GET";
export const CUSTOMER_BUYBACK_PRODUCT_LIST_SUCCESS = "CUSTOMER_BUYBACK_PRODUCT_LIST_SUCCESS";

export const CUSTOMER_VIEWORDERS_LIST_GET = "CUSTOMER_VIEWORDERS_LIST_GET";
export const CUSTOMER_VIEWORDERS_LIST_SUCCESS = "CUSTOMER_VIEWORDERS_LIST_SUCCESS";

export const CUSTOMER_NEWPRODUCTREQUEST_LIST_GET = "CUSTOMER_NEWPRODUCTREQUEST_LIST_GET";
export const CUSTOMER_NEWPRODUCTREQUEST_LIST_SUCCESS = "CUSTOMER_NEWPRODUCTREQUEST_LIST_SUCCESS";

export const CUSTOMER_SPEND_LIST_GET = "CUSTOMER_SPEND_LIST_GET";
export const CUSTOMER_SPEND_LIST_SUCCESS = "CUSTOMER_SPEND_LIST_SUCCESS";

export const CUSTOMER_SERVICES_LIST_GET = "CUSTOMER_SERVICES_LIST_GET";
export const CUSTOMER_SERVICES_LIST_SUCCESS = "CUSTOMER_SERVICES_LIST_SUCCESS";

export const CUSTOMER_CENTRIC_LIST_GET = "CUSTOMER_CENTRIC_LIST_GET";
export const CUSTOMER_CENTRIC_LIST_SUCCESS = "CUSTOMER_CENTRIC_LIST_SUCCESS";
