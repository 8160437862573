// src/components/filter.
import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "./taggedproductlisttablecontainer"
import { taggedProductListGet } from "../../../src/store/actions"
import Loadder from "components/Loadder/Loadder"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from "reactstrap"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { ADMINSOLDPRODUCT, HOSTNAME } from "config/config"
const getUserData = JSON.parse(localStorage.getItem("authUser"))
function extractIdAndPName(item) {
  let statustext

  if (item.status === 0) {
    statustext = "Pending"
  } else if (item.status === 1) {
    statustext = "Accepted"
  } else if (item.status === 2) {
    statustext = "Rejected"
  } else {
    statustext = "Unknown Status"
  }
  return {
    id: item.id,
    agencyName: item.agencyName,
    firstName: item.firstName,
    contactNumber: item.contactNumber,
    city: item.city,
    productName: item.productName,
    status: item.status,
    isSold: item.isSold,
    statustext: statustext,
    serialNumbers: item.serialNumbers,
    reason: item.reason,
  }
}

function DatatableTablesDemoProductMaster() {
  const [rejectReasonModal, setRejectReasonModal] = useState(false)
  const [rejectReason, setRejectReason] = useState(null)

  const viewRejectReason = row => {
    setRejectReasonModal(!rejectReasonModal)
    setRejectReason(row?.reason)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Sr.No",
        accessor: "id",
      },
      {
        Header: "Agency Name",
        accessor: "agencyName",
      },
      {
        Header: "Dealer Name",
        accessor: "firstName",
      },
      {
        Header: "Contact Number",
        accessor: "contactNumber",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Tagged Product",
        accessor: "productName",
      },
      {
        Header: "Tagged Product Serial Number",
        accessor: "serialNumbers",
      },

      {
        Header: "Status",
        accessor: "statustext",
      },
      {
        Header: " ",
        Cell: cellProps => {
          const row = cellProps.row.original

          return (
            <>
              {row.status === 2 && (
                <UncontrolledDropdown direction="start">
                  <DropdownToggle size="sm" color="">
                    <i className="bx bx-dots-vertical fs-4"></i>
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem onClick={() => viewRejectReason(row)}>
                      View Reason
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </>
          )
        },
      },
      // {
      //     Header: '    ',
      //     Cell: (cellProps) => {
      //         const  row = cellProps.row
      //       return (
      //         <UncontrolledDropdown>
      //             {
      //                 row.original.status==1 || row.original.status=="1"
      //                 ?
      //                 row.original.isSold==false
      //                 ?
      //                 <>
      //                 <DropdownToggle tag="a" className="card-drop">
      //                     <i className="mdi mdi-dots-horizontal font-size-18"></i>
      //                 </DropdownToggle>

      //                 <DropdownMenu className="dropdown-menu-end">
      //                     <DropdownItem onClick={e=>soldData(e,row.original.id,0)}>
      //                     <i className="font-size-16 text-success me-1" id="edittooltip"></i>
      //                     Mark as sold in this year
      //                     <UncontrolledTooltip placement="top" target="edittooltip">
      //                     Mark as sold in this year
      //                     </UncontrolledTooltip>
      //                     </DropdownItem>

      //                     <DropdownItem onClick={e=>soldData(e,row.original.id,1)}>
      //                     <i className=" font-size-16 text-danger me-1" id="deletetooltip"></i>
      //                     Mark as sold in next year
      //                     <UncontrolledTooltip placement="top" target="deletetooltip">
      //                     Mark as sold in next year
      //                     </UncontrolledTooltip>
      //                     </DropdownItem>
      //                 </DropdownMenu>
      //                 </>
      //                 :
      //                 ""

      //                 :
      //                 ""
      //             }

      //         </UncontrolledDropdown>
      //       );
      //     }
      //   },
    ],
    []
  )

  let data = []
  const dispatch = useDispatch()

  useEffect(() => {
    // const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    // if (!isUserLoggedIn) {
    //   localStorage.setItem("isLoggedIn", "true")
    //   window.location.reload()
    // }
    dispatch(taggedProductListGet({}, {}))
  }, [])

  const taggedProductListData = useSelector(state => state.DemoProductGet)

  if (taggedProductListData && taggedProductListData.demoProductDetails) {
    data =
      taggedProductListData.demoProductDetails.results.data.map(
        extractIdAndPName
      )
  }

  //meta title
  document.title = "Demo Product"

  const soldData = (e, id, type) => {
    // alert(id)
    // alert(type)
    let url = `${HOSTNAME}${ADMINSOLDPRODUCT}?year=${type}&oid=${id}&soldType=0`
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `${getUserData.userData.token}`,
      },
    }

    axios
      .request(config)
      .then(response => {
        dispatch(taggedProductListGet({}, {}))
      })
      .catch(error => {})
    dispatch(taggedProductListGet({}, {}))
  }
  return (
    <Loadder active={taggedProductListData.loading}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Demo Products" />

          {/* <Table columns={columns} data={data} /> */}
          <TableContainer
            count={taggedProductListData.demoProductDetails.count}
            next={taggedProductListData.demoProductDetails.next}
            previous={taggedProductListData.demoProductDetails.previous}
            columns={columns}
            data={data}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />

          <Modal
            isOpen={rejectReasonModal}
            role="dialog"
            size="md"
            autoFocus={true}
            centered
            id="verificationModal"
            toggle={viewRejectReason}
          >
            <div className="modal-content">
              <ModalHeader toggle={viewRejectReason}>
                Reason for rejection
              </ModalHeader>
              <ModalBody>
                <p> </p>

                <FormGroup className="mb-3">
                  <Label htmlFor="">View reason</Label>
                  <Input
                    type="textarea"
                    name="product-name"
                    className="form-control"
                    placeholder="Enter Text Here"
                    disabled
                    value={rejectReason}
                  />
                </FormGroup>
              </ModalBody>
            </div>
          </Modal>
        </div>
      </div>
    </Loadder>
  )
}
DatatableTablesDemoProductMaster.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTablesDemoProductMaster
